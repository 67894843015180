import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import {withRouter} from "react-router-dom";
import axios from "axios";

export default withRouter(FirstLoginRecruiterFinal);

function FirstLoginRecruiterFinal (props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Fetcher */
  const [fetchStatus, setFetchStatus] = useState("asleep");
  const [fetched, setFetched] = useState({status: "", data: ""});
  
  useEffect(() => {
    (async() => {
      try {
        setFetchStatus("fetching");
        const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/ent/u/first/update`, {
          ...props.assistantContext.form
        }, {
          withCredentials: true,
        });
        if(fetched.data.status !== "ok") {
          return setFetchStatus("error");
        }
        await setFetched(fetched.data);
        setFetchStatus("fetched");
      } catch(error) {
        setFetchStatus("error");
      }
    })();
  }, []);
  
  useEffect(() => {
    (async () => {
      switch(fetchStatus) {
        case 'fetched':
          switch(fetched.status) {
            case 'ok':
              await appContextV2.fetchSession();
              props.history.push('/');
              appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
              break;
            
            default:
              return;
          }
          break;
        
        case 'error':
          return;
        case 'fetching':
        case 'asleep':
        default:
      }
    })();
  }, [fetchStatus]);
  
  // if(fetchStatus === "error" || fetchedFinalStep.status === "error") {
    if(fetchStatus === "error") {
      return(<>
      <p><br/><br/></p>
      <p className={'text--center text--magnified15x'}>
        {<>Une erreur est survenue ...</>}
      </p>
      <p><br/><br/></p>
    </>);
  }
  
  return(<>
    <p><br/><br/></p>
    <p className={'text--center text--magnified15x'}>
      {<>Chargement de l'espace ...</>}
    </p>
    <p><br/><br/></p>
  </>);
  
}
