import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {abstractPostFetch, abstractPutFetch} from "../../../../../utils/abstractFetch";
import ButtonWrapper from "../../../layouts/ButtonWrapper";
import {capitalizeMulti} from "../../../../../utils/converters";
import {useForm} from "use-formidable";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {fetchPools} from "../../../../recruitersPages/componentsRecruiterPools/dependenciesRecruiterPools/fetch/fetchPools";
import LoadingStyle2 from "../../Loading/LoadingStyle2";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [fetchedCommunities, setFetchedCommunities] = useState([]);
  const [fetchedStatus, setFetchedStatus] = useState("asleep");
  
  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState("");
  
  useEffect(() => { fetchPools(setFetchedStatus, setFetchedCommunities)}, []);
  devLog("fetchedCommunities", fetchedCommunities);
  
  const sendAnswer = async() => {
    try {
      setFetchedStatus("fetching");
      const fetched = (await axios.post(`${process.env.REACT_APP_API_URL}/pool/ci/export/to/pool`, {
        poolId: appContextV2.appNavigation.poolId,
        toPoolId: selectedCommunity,
        selected: appContextV2.appPanels.window.windowOptions.data.selected,
      }, {
        withCredentials: true,
      }));
      if(fetched.data.status !== "ok") {
        setFetchedStatus("asleep");
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setFetchedStatus("fetched");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      return notification(appContextV2.dispatchPanels, "Candidats exportés", "success");
    } catch (error) {
      devLog(error);
      setFetchedStatus("asleep");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<WindowWrapper size={"small"} status={fetchedStatus as 'asleep' | 'fetching' | 'fetched'} windowTitle={""}>
    <div className={"container"}>
      
      <div>Exporte {appContextV2.appPanels.window.windowOptions.data.selected?.length} candidats vers une communauté. Ils seront dupliqués.</div>
      
      <Spaces n={0}/>
      
      <div className={"text--center"}>
        
        <select className={"input--v2"} value={selectedCommunity} name={"selectedCommunity"} onChange={(e) => {
          setSelectedCommunity(e.target.value);
        }}>
          <option value={""} disabled={true}>-- Sélectionne une communauté --</option>
          {fetchedCommunities.map((item: any, key: number) => (<option value={item._id}>
            {item.name} ({item.number})
          </option>))}
        </select>
        
        <Spaces n={1}/>
        
        <button disabled={!selectedCommunity} className={"btn-secondary"} onClick={() => sendAnswer()}>
          Exporter vers cette communauté
        </button>
      
      </div>
    
    </div>
  </WindowWrapper>);
}
