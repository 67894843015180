import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import Spaces from "../../../../../components/Spaces";

export default (props: any) => {
  
  const [showQuestion, setShowQuestion] = useState(false);
  
  useEffect(() => {
    (() => {
      setShowQuestion(props.mission.defaultQuestions?.training);
      if(!props.mission.defaultQuestions?.training) {
        props.nextStep();
      }
      
    })();
  }, []);
  
  if(!showQuestion) {
    return (<><Spaces n={15}/></>)
  }
  
  return (<div>
    
    <Spaces n={2}/>
    
    <div className={"assistant--v2-title"}>
      Quel est votre niveau de formation ?
    </div>
    <Spaces n={2}/>
    
    {/*<section className={"assistant--v2-container"}>*/}
    {/*    <button className={"btn-assistant-main"} onClick={()=>{props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.training", value: "cap"}});*/}
    {/*        props.nextStep();}}>cap</button>*/}
    {/*    <button className={"btn-assistant-main"} onClick={()=>{  props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.training", value: "bep"}});*/}
    {/*        props.nextStep();}} >bep</button>*/}
    {/*    <button className={"btn-assistant-main"} onClick={()=>{ props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.training", value:" niveau bac"}});*/}
    {/*        props.nextStep();}} >niveau bac</button>*/}
    {/*    <button className={"btn-assistant-main"} onClick={()=>{  props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.training", value: "bac+2"}});*/}
    {/*        props.nextStep();}} >bac + 2</button>*/}
    {/*    <button className={"btn-assistant-main"} onClick={()=>{  props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.training", value:  "bac+3"}});*/}
    {/*        props.nextStep();}} >bac + 3</button>*/}
    {/*    <button className={"btn-assistant-main"} onClick={()=>{  props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.training", value: "bac+4/5"}});*/}
    {/*        props.nextStep();}} >bac + 4/5</button>*/}
    {/*</section>*/}
    <section className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
    <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"}
           value={props.answer.defaultAnswers.training}
           onChange={(e) => {
             props.updateAnswer()({
               target: {
                 type: "custom",
                 name: "defaultAnswers.training",
                 value: e.target.value
               }
             });
           }}/>
    
    <Spaces n={2}/>
  
    <button className={"btn-main"} disabled={!props.answer.defaultAnswers.training}
            onClick={() => { props.nextStep();}}>
      Valider ma réponse
    </button>
    </section>
    
  
  </div>);
}