import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";

export {RecruiterAmbassador};

const RecruiterAmbassador = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return (<article className={"ambassadors--v2__ambassador"}>
    
    <div className={"ambassadors--v2__ambassador__left"}>
      
      <div className={"ambassadors--v2__ambassador__avatar"}>
        {props.item.fullName[0]}{props.item.fullName[1]}
        {props.item.slug === "Da5320" && <img src={"https://static.myshortlist.co/H12.jpeg"} alt={"avatar"}/>}
        {props.item.slug === "Je5777" && <img src={"https://static.myshortlist.co/H20.jpeg"} alt={"avatar"}/>}
        {props.item.slug === "Au9042" && <img src={"https://static.myshortlist.co/F1.jpeg"} alt={"avatar"}/>}
      </div>
      
      {/*<div className={"ambassadors--v2__ambassador__element ambassadors--v2__ambassador__slug"}>*/}
      {/*  {props.item.slug}*/}
      {/*</div>*/}
      
      <div className={"ambassadors--v2__ambassador__element"}>
        <span className={"text--magnified2x"}>{props.item.fullName}</span>
      </div>
    
    </div>
    
    <div className={"ambassadors--v2__ambassador__right"}>
      <div className={"ambassadors--v2__ambassador__element"}>
        candidats = {props.item.numberOfCandidatesCollected ? props.item.numberOfCandidatesCollected : "--"}, recrutements = {props.item.numberOfRecruitmentsCollected ? props.item.numberOfRecruitmentsCollected : "--"}
      </div>
      <button className={"ambassadors--v2__ambassador__button"} onClick={() => {
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW",
          value: {
            type: "updateAmbassador",
            data: {
              item: props.item,
              removeAmbassadorFront: props.removeAmbassadorFront,
              updateAmbassadorFront: props.updateAmbassadorFront,
            }
          }
        });
      }}>
        Modifier
      </button>
    </div>
    
  </article>);
}