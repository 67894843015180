import React, {useState} from "react";
import Spaces from "../../../../../Spaces";
import {shortenText} from "../../../../../../../../utils/shortenText";
import {format} from "date-fns";

export {CW2MailMessage};

const CW2MailMessage = (props: any) => {

  const [extended, setExtended] = useState(false);

  const ExtendButton = () => <button className={"btn-extend"} onClick={() => setExtended((ps: boolean) => !ps)}>
    {extended ? "-" : "+"}
  </button>;

  return(<>

    <article className={`messages--v2__message ${props.item.direction === "received" ? "messages--v2__message--answer" : ""}`}>

      {props.item.title && <div>
        {props.item.title}
          <Spaces n={0}/>
      </div>}

      <div className={"messages--v2__message__text"}>
        {props.item.message.length < 120 && <>{props.item.message}</>}
      </div>

      <div className={"messages--v2__message__text"}>
        {props.item.message.length >= 120 && <>
          {!extended ? <>{shortenText(props.item.message, 120)} <ExtendButton/></> : <>{props.item.message} <ExtendButton/></>}
        </>}
      </div>

      <Spaces n={0}/>

      <div className={"messages--v2__message__date"}>
        {props.item.direction === "received" && <>(réponse)</>} {props.item.phoneNumber ? "SMS" : "Email"} enregistré
        le {format(new Date(props.item.dateOfCreation), "dd-MM-yyyy")}
      </div>

    </article>

  </>);
}