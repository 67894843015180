import React from "react";
import Spaces from "../../sharedComponents/components/Spaces";

export default (props: any) => {
  
  return(<div className={"container-lp"}>
    
    <section className={"landing-page--v2__side-by-side"}>
  
      <article className={"landing-page--v2__side-by-side__box landing-page--v2__side-by-side__box1"}>
        <div className={"landing-page--v2__video-container landing-page--v2__video-container--grey"}>
          <img src={"https://static.myshortlist.co/businesspage.jpg"} className={"landing-page--v2__video-container__image"} />
        </div>
      </article>
      
      <article className={"landing-page--v2__side-by-side__box landing-page--v2__side-by-side__box2"}>
        <h2 className={"landing-page--v2__title"}>
          Créez votre page entreprise<br/>en simple drag and drop
        </h2>
        <Spaces n={0}/>
        <div className={"landing-page--v2__text"}>
          Donnez de la visibilité pour vos futurs candidats et maintenez votre page entreprise à jour facilement et rapidement. Le candidat peut postuler, consulter et partager vos annonces en un clic.
        </div>
      </article>
    
    </section>
  
  </div>);
}