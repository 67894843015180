import React, {useEffect, useState} from "react";
import Spaces from "../../../components/Spaces";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import LoadingStyle2 from "../../../components/Loading/LoadingStyle2";
import {format} from "date-fns";

export default (props: any) => {

    const token: string = props.token || "";


    const [candidateTrigram, setCandidateTrigram]: any = useState([]);
    const [status, setStatus]: any = useState({});
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        (async() => {
            try {
                setIsLoading(true);
                const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/mat/cam/shared/candidate/trigram`, {
                    headers: {
                        token: token
                    },
                    withCredentials: true
                });
                
               setCandidateTrigram(fetched?.data.data);
                setStatus(fetched?.data?.status);
                setIsLoading(false);

            } catch(error) {
                setIsLoading(false);
                devLog(error);
            }
        })();
    }, []);

    return (<section className={"candidate-evaluate__container"}>

        {
            (isLoading===true) && <div className={"space-flex--center"}>
                <Spaces n={0}/>
                <LoadingStyle2/>
                <Spaces n={0}/>
            </div>
        }


        {((isLoading===false) && (candidateTrigram.length === 0)) && <div className={"text--center"}>
            <Spaces n={0}/>
            Aucun message n'est enregistré pour ce candidat
            <Spaces n={0}/>
        </div>}

        {((isLoading===false) && (candidateTrigram.length !== 0)) && <section className={"trigram-v2__messages"}>
            <Spaces n={0}/>
           <div className={"text--center"}>Vos collaborateurs ont laissé les messages suivants:</div>
            <Spaces n={0}/>
            {[...candidateTrigram || []].reverse().map((item: any, key: number) => (
                <article key={key}
                         className={`trigram-v2__messages__message ${(item.source === "human" || !item.source) ? "trigram-v2__messages__message--human" : ""}`}>
                    <div className={"trigram-v2__messages__message__avatar"}>
                        {item.recruiterTrigram?.toUpperCase()}
                    </div>
                    <div className={"trigram-v2__messages__message__body"}>
                        <div className={"trigram-v2__messages__message__header"}>
                            {(item.source === "human" || !item.source) && <>Message laissé
                                par {item.from ? item.from : item.recruiterTrigram} le {format(new Date(item.dateOfCreation || ""), "dd-MM-yyyy")}</>}
                            {item.source === "machine" && <>Message auto-généré
                                le {format(new Date(item.dateOfCreation), "dd-MM-yyyy")}</>}
                            {item.source === "retro" && <>Message enregistré
                                le {format(new Date(item.dateOfCreation), "dd-MM-yyyy")}</>}
                        </div>
                        {
                            item.deleted !== true && <>
                                {(item.appreciation === "good" || item.appreciation ===5) &&
                                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-5.png"}/>}
                                {(item.appreciation ===4) &&
                                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-4.png"}/>}
                                {(item.appreciation === "neutral" || item.appreciation === 3)&&
                                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-3.png"}/>}
                                {(item.appreciation ===2) &&
                                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-2.png"}/>}
                                {(item.appreciation === "bad" || item.appreciation === 1)&&
                                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-1.png"}/>}
                            </>
                        }

                        {item.title ? item.title : ""}

                        {item.body ? item.body?.split('\n').map(function(item: any) {
                            return (
                                <span className={"trigram-v2__messages__message__body--span"}>
              {item}
                                    <br/>
              </span>
                            )
                        }) : ""}
                    </div>
                </article>
            ))}
            <Spaces n={0}/>

        </section>}

    </section>);
}