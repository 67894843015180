import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import CW2Cv from "./subWindows/CW2Cv";
import CW2Doc from "./subWindows/CW2Doc";
import CW2Profile from "./subWindows/CW2Profile";
import CW2Results from "./subWindows/CW2Results";
import CW2Trigram from "./subWindows/CW2Trigram";
import CW2Mail from "./subWindows/CW2Mail";
import CW2History from "./subWindows/CW2History";
import CW2Finder from "./subWindows/CW2Finder";
import CW2Share from "./subWindows/CW2Share";

export default (props: {
  page: any,
  dictionaries: any,
  inherited: any,
  updateCandidateFrontInWindow: any,
  updateCandidacyFrontInWindow: any,
  candidate: any,
  setCandidate: any,
  addShareFrontInWindow:any,
  deleteShareFrontInWindow:any,
  updateCandidateFrontTokenName:any,
}) => {
  
  switch(props.page) {
  
    case "cv":
      return (<CW2Cv {...props}/>);
      
    case "profile":
      return (<CW2Profile {...props}/>);
      
    case "results":
      return (<CW2Results {...props}/>);
      
    case "trigram":
      return (<CW2Trigram {...props}/>);
      
    case "mail" :
      return (<CW2Mail {...props}/>);
  
    case "finder":
      return (<CW2Finder {...props}/>);

    case "share":
      return(<CW2Share {...props}/>);
      
    // case "history":
    //   return (<CW2History {...props}/>);
    
    default:
      return (<div>
        La page demandée est vide
      </div>);
  }
  
}
