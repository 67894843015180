import AppContext, {IAppV2Context} from "./context/AppContext";
import React, {useContext} from "react";
import {Loading} from "../sharedComponents/components/Loading/Loading";
import Routing from "./componentsSwitchAppStatus/Routing";
import FourHundredThree from "../sharedComponents/pages/errors/FourHundredThree";
import ErrorMaintenance from "../sharedComponents/pages/errors/ErrorMaintenance";

export {SwitchAppStatus};

/** ----- Displays the App considering the app-level status */
const SwitchAppStatus = (props: any) => {
  
  const appContext: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  switch(appContext.appSession.app.appStatus) {
    
    case 'pending':
      return (<Loading fullPage={true}/>);
    
    case 'ok':
      return (<Routing/>);
    
    case 'forbidden':
      return (<FourHundredThree page={"FourHundredThree"} fullPage={true} setPage={true}/>);
    
    case 'error':
    
    default:
      return (<ErrorMaintenance page={"ErrorMaintenance"} fullPage={true} setPage={true}/>);
  }
}
