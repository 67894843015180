import React, {useEffect, useState} from "react";
import Spaces from "../../../components/Spaces";
import CW2DocumentDisplayer
    from "../../../components/Window/componentsWindow/componentsCWV2/subWindows/CW2DocumentDisplayer";



export default function(props: any) {

    const [displayCv, setDisplayCv] = useState(true);

    const isCvUrlsDefined = !!props.candidate?.candidate?.cvUrls;
    const isCvUrlDefined = !!props.candidate?.candidate?.cvUrl;
    const isCvUrlsNotEmpty = props.candidate?.candidate?.cvUrls?.length !== 0;

    const cvUrl = props.candidate?.candidate?.cvUrls[(props.candidate?.candidate?.cvUrls?.length) - 1] ??
        props.candidate?.candidate?.cvUrl ?? "";

    const displayCvComponent = isCvUrlDefined || (isCvUrlsDefined && isCvUrlsNotEmpty);

    useEffect(()=>{
        setDisplayCv(false);
        setTimeout(() => {
            setDisplayCv(true);
        }, 300);
    }, [cvUrl]);

    return (<>
        <section className={"candidate-evaluate__container"}>
            <div>

                <div className={"white-container white-container--grey space-margin--small"}>

                    <div className={"profile-edit__title text--center"}>CV</div>

                    <Spaces n={0}/>

                    {
                        displayCv && <>
                            {
                                displayCvComponent && <CW2DocumentDisplayer fileUrl={cvUrl} />
                            }

                            {
                                !displayCvComponent && <div className={"text--center text--grey"}>
                                    Aucun CV pour le candidat
                                </div>
                            }
                        </>
                    }

                    <Spaces n={0}/>

                </div>
            </div>
        </section>
    </>);

}