import React, {useContext, useState} from "react";
import Spaces from "../../../Spaces";
import {notification} from "../../../../../../utils/notification";
import axios from "axios";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import {IColumnData} from "../WindowColumnOptionsV2";

export {WCODeleteV2};

const WCODeleteV2 = (props: {
    type: "kanban" | "preset",
    missionId: string,
    numberOfCandidates: number,
    columnData: IColumnData,
    updateColumnData: Function,
    saveColumnData: Function,
    switchColumnAction: Function,
    isWindowLoading: boolean,
    deleteColumn: Function
}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    /** Ask for confirmation about deletion */
    const [deleteValidation, setDeleteValidation] = useState(false);

    return(<>

        <article className={`white-container white-container--grey`}>
            <div>
                <span className={"text--bold text--magnified11x"}>Supprimer cette colonne</span>
            </div>
            <Spaces n={0}/>

            {
                props.numberOfCandidates !== 0 && <>
                    Il est impossible de supprimer une colonne qui contient des candidatures.
                </>
            }

            {
                props.numberOfCandidates === 0 && <div className={'text--center'}>
                    <input type={"checkbox"} name={"delete"} checked={deleteValidation} onChange={() => setDeleteValidation((ps: any) => !ps)}/> Es-tu sûr·e de vouloir
                    supprimer cette colonne ?
                    <Spaces n={0}/>
                    <button className={"btn-secondary btn-secondary--red"} disabled={!deleteValidation}
                            onClick={()=>{
                                props.deleteColumn();}}>
                        Supprimer
                    </button>
                </div>
            }

        </article>

    </>);
}