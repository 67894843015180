import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import {abstractGetFetch, abstractPostFetch} from "../../../../../../utils/abstractFetch";
import ButtonWrapper from "../../../../layouts/ButtonWrapper";
import {ButtonBack} from "../../../Buttons/ButtonBack";
import Spaces from "../../../Spaces";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const language = appContextV2.appSession.app.language;
  const space: string = appContextV2.appSession.app.space;
  
  const next = props.assistantContext.wizard.nextStep;
  const updateForm = props.assistantContext.updateForm;
  const assistantState = props.assistantContext.form;
  
  const isNumberEmpty: boolean = !assistantState.business.location.number;
  const isStreetEmpty: boolean = !assistantState.business.location.street;
  const isCityEmpty: boolean = !assistantState.business.location.city;
  const isStateEmpty: boolean = !assistantState.business.location.state;
  const isPostalCodeEmpty: boolean = !assistantState.business.location.postalCode || assistantState.business.location.postalCode?.length < 2;
  const isCountryEmpty: boolean = !assistantState.business.location.country;
  
  const isButtonOk = !isCountryEmpty && !isPostalCodeEmpty;
  
  return (<>
    <ButtonBack back={props.assistantContext.wizard.previousStep}/>
  
    <Spaces n={0}/>
    
    <div className={"text--center text--magnified1x title--bold"}>
      {(language === 'FR' && space === 'candidate') && <>Ton adresse</>}
      {(language !== 'FR' && space === 'candidate') && <>Your address</>}
      {(language === 'FR' && space === 'recruiter') && <>Adresse de l'entreprise</>}
      {(language !== 'FR' && space === 'recruiter') && <>Business' address</>}
    </div>
    
    <Spaces n={1}/>
    
    {/*<p className={"text--center"}>*/}
    {/*  <button disabled={this.state.buttonStatus !== 'ready'} className={"btn-geolocalize animation-assistant-appear"} onClick={()=>getLocation()}>*/}
    {/*    <ButtonWrapper buttonStatus={this.state.buttonStatus}>*/}
    {/*      {(language === 'FR') && <>Me géolocaliser</>}*/}
    {/*      {(language !== 'FR') && <>Get location</>}*/}
    {/*    </ButtonWrapper>*/}
    {/*  </button>*/}
    {/*</p>*/}
    
    <form onSubmit={(e) => {
      e.preventDefault();
      if(isButtonOk) {
        next();
      }
    }}>
      
      <p className={"text--center"}>
        <input placeholder={"Pays *"} value={assistantState.business.location.country} type={"text"} name={"business.location.country"}
               className={`animation-assistant-appear ${(!isCountryEmpty) ? "input--ok" : ""} ${(isCountryEmpty) ? "input--wrong" : ""}`}
               onChange={updateForm({maximumLength: 60})}/>
      </p>
      
      <p className={"text--center"}>
        <input placeholder={"Code postal *"} value={assistantState.business.location.postalCode} type={"number"} name={"business.location.postalCode"}
               className={`animation-assistant-appear ${(!isPostalCodeEmpty) ? "input--ok" : ""} ${(isPostalCodeEmpty) ? "input--wrong" : ""}`}
               onChange={updateForm()}/>
      </p>
      
      <p className={"text--center"}>
        <input placeholder={"N°"} value={assistantState.business.location.number} type={"text"} name={"business.location.number"}
               className={`animation-assistant-appear ${(!isNumberEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 10})}/>
      </p>
      
      <p className={"text--center"}>
        <input placeholder={"Adresse"} value={assistantState.business.location.street} type={"text"} name={"business.location.street"}
               className={`animation-assistant-appear ${(!isStreetEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 60})}/>
      </p>
      
      <p className={"text--center"}>
        <input placeholder={"Ville"} value={assistantState.business.location.city} type={"text"} name={"business.location.city"}
               className={`animation-assistant-appear ${(!isCityEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 60})}/>
      </p>
      
      <p className={"text--center"}>
        <input placeholder={"Département"} value={assistantState.business.location.state} type={"text"} name={"business.location.state"}
               className={`animation-assistant-appear ${(!isStateEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 60})}/>
      </p>
      
      <Spaces n={0} />
      
      <p className={"text--center"}>
        <button disabled={!isButtonOk} className={"btn-main"} onClick={() => {
          next();
        }}>
          {language === 'FR' && <>Terminer l'inscription</>}
          {language !== 'FR' && <>Finish subscribing</>}
        </button>
      </p>
    
    </form>
  
  </>);
}
