import React, {useContext} from "react";
import Spaces from "../../../../sharedComponents/components/Spaces";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import axios from "axios";

export default(props: {
  poolName: string,
  areFiltersActive: boolean,
  saveXlsx:Function,
  numberOfCandidates: number,
  numberOfResults: number,
  selected: string[],
  numberOfSelected: number,
  frontUpdatesHandlers: any,
  filters: any,
  selectorHandlers: any,
  idsSelectedCandidates: string[]
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<div className={"kanban-template__nav__content"}>
    
    <Spaces n={1}/>

    {props.poolName}

    <Spaces n={1}/>
    
    <div className={"pool-template__numbers"}>
      <div className={"pool-template__numbers-title"}>Taille de la communauté</div>
      <div className={"pool-template__numbers-circle"}>{props.numberOfCandidates}</div>
  
      <div className={"pool-template__numbers-title"}>Résultats affichés</div>
      <div className={"pool-template__numbers-circle"}>
        {(!props.areFiltersActive) && '--'}
        {(!!props.areFiltersActive && !!props.numberOfResults) ? props.numberOfResults : ''}
        {(!!props.areFiltersActive && !props.numberOfResults) ? 0 : ''}
      </div>
  
      <div className={"pool-template__numbers-title"}>Séléctionnés</div>
      <div className={"pool-template__numbers-circle"}>
        {props.numberOfSelected ? props.numberOfSelected : "0"}
      </div>
    </div>
    
    <Spaces n={2}/>
    
    <h1 className={"title--bold text--center"}>
      Séléction
    </h1>
    <button disabled={false} title={"Cette option revient très vite"} className={`btn-shortlist-filter`}
            onClick={()=>props.selectorHandlers.selectAll()}>
      Sélectionner tous (visibles)
    </button>
    <button disabled={false} title={"Cette option revient très vite"} className={`btn-shortlist-filter`}
            onClick={()=>props.selectorHandlers.unSelectAll()}>
      Dé-selectionner tous
    </button>
    
    <Spaces n={1}/>
    
    <h1 className={"title--bold text--center"}>
      Actions
    </h1>
    <button disabled={props.numberOfSelected === 0 && props.numberOfSelected <= 300} title={""} className={`btn-shortlist-filter`}
            onClick={() => {
              appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
                  type: "sendMailToSelectedCandidates",
                  data: {
                    candidatesIds: props.idsSelectedCandidates,
                    poolId: appContextV2.appNavigation.poolId,
                  }
                }});
            }}>
      Mail de groupe
    </button>
    <button disabled={props.numberOfSelected > 100 || props.numberOfSelected === 0} title={""} className={`btn-shortlist-filter`}
            onClick={() => {
              appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
                  type: "sendSMSToSelectedCandidates",
                  data: {
                    candidatesIds: props.idsSelectedCandidates,
                    poolId: appContextV2.appNavigation.poolId,
                  }
                }});
            }}>
      SMS de groupe
    </button>
    <button disabled={props.numberOfSelected === 0 && props.numberOfSelected <= 300} title={""} className={`btn-shortlist-filter`}
            onClick={() => {
              appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
                  type: "askForCandidatesAvailability",
                  data: {
                    candidatesIds: props.idsSelectedCandidates,
                    poolId: appContextV2.appNavigation.poolId,
                  }
                }});
            }}>
      Demander disponibilités
    </button>
    <button disabled={props.numberOfSelected === 0} title={"Archive le candidat"} className={`btn-shortlist-filter`} onClick={async() => {
      if(!props.filters.showRemovedCandidates) {
        try {
          notification(appContextV2.dispatchPanels, "Retrait des candidats en cours", "information");
          // update front
          props.frontUpdatesHandlers.updateCandidatesBulk(props.filters.idsSelectedCandidates, {deleted: true,});
          // update back
          const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cma/delete/bulk`, {
            poolId: appContextV2.appNavigation.poolId,
            selected: props.filters.idsSelectedCandidates,
          }, {withCredentials: true});
          notification(appContextV2.dispatchPanels, "Succès, candidatures archivées", "success");
        } catch(error) {
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
      } else {
        try {
          notification(appContextV2.dispatchPanels, "Retrait des candidats en cours", "information");
          // update front
          props.frontUpdatesHandlers.updateCandidatesBulk(props.filters.idsSelectedCandidates, {deleted: false,});
          // update back
          const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cma/restore/bulk`, {
            poolId: appContextV2.appNavigation.poolId,
            selected: props.filters.idsSelectedCandidates,
          }, {withCredentials: true});
          notification(appContextV2.dispatchPanels, "Succès, candidatures restaurées", "success");
        } catch(error) {
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
      }
    }}>
      {!props.filters.showRemovedCandidates ? "Retirer de la communauté" : ""}
      {props.filters.showRemovedCandidates ? "Réintégrer à la communauté" : ""}
    </button>
    {/*{*/}
    {/*  props.filters.showRemovedCandidates && <button disabled={true} title={"Cette option revient très vite"} className={`btn-shortlist-filter ${false ? "btn-shortlist-filter--selected" : ""}`}*/}
    {/*          onClick={() => {}}>*/}
    {/*    Retirer définitivement de ce pool*/}
    {/*  </button>*/}
    {/*}*/}
    
    <Spaces n={1}/>
    
    <h1 className={"title--bold text--center"}>
      Import/Export
    </h1>
    <button title={"Ajoute manuellement un candidat dans cette communauté"} className={`btn-shortlist-filter`}
            onClick={()=>{
              appContextV2.dispatchPanels({
                type: "OPEN_WINDOW", value: {
                  type: "createNewCandidateToPool",
                  data: {
                    poolId: appContextV2.appNavigation.poolId,
                    addCandidateFront: props.frontUpdatesHandlers.addCandidateFront,
                  }
                }
              });
            }}>
      Créer un candidat
    </button>
    <button title={"Ajoute des candidats par lot via un fichier excel"} className={`btn-shortlist-filter`}
            onClick={() => {
              appContextV2.dispatchPanels({
                type: "OPEN_WINDOW", value: {
                  type: "importXlsCsv",
                  data: {
                    poolId: appContextV2.appNavigation.poolId,
                    reloadCandidates: props.frontUpdatesHandlers.reloadCandidates,
                    addCandidateFront: props.frontUpdatesHandlers.addCandidateFront,
                  }
                }
              });
            }}>
      Importer XLSX
    </button>
    <button disabled={props.numberOfSelected === 0} title={"Sélectionne des candidats pour les sauvegarder"} className={`btn-shortlist-filter`}
            onClick={()=>{props.saveXlsx()}}>
      Exporter XLSX
    </button>
    <button disabled={props.numberOfSelected === 0} title={""} className={`btn-shortlist-filter`}
            onClick={()=>{
              appContextV2.dispatchPanels({
                type: "OPEN_WINDOW", value: {
                  type: "exportCandidatesFromPoolToPool",
                  data: {
                    poolId: appContextV2.appNavigation.poolId,
                    selected: props.selected,
                  }
                }
              });
            }}>
      Exporter vers une communauté
    </button>
    <button disabled={props.numberOfSelected === 0} title={"Ajoute les candidats sélectionnés à un poste ouvert"} className={`btn-shortlist-filter`}
            onClick={()=>{
              appContextV2.dispatchPanels({
                type: "OPEN_WINDOW", value: {
                  type: "exportCandidatesFromPoolToKanban",
                  data: {
                    poolId: appContextV2.appNavigation.poolId,
                    selected: props.selected,
                  }
                }
              });
            }}>
      Exporter vers un poste
    </button>
    
    <Spaces n={6}/>
  
  </div>)
  
}