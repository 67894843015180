import React from "react";
import {ProfileElementBoolean} from "../../../sharedComponents/layouts/ProfileElementBoolean";

export {RecruiterParametersPublicity}

const RecruiterParametersPublicity = (props: {
  parameters: any,
  updateSingleton: Function,
  assistantGet: any,
}) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Paramètres de publicité (entreprise)</h1>
  
    <ProfileElementBoolean text={"Les offres doivent-elles apparaitre sur la page entreprise ?"}
                                       nameToUpdateBack={"showOtherJobsInBusiness"}
                                       nameToUpdateFront={"showOtherJobsInBusiness"}
                                       description={""}
                                       value={props.parameters?.showOtherJobsInBusiness}
                                       updateData={props.updateSingleton} />
  
    <ProfileElementBoolean text={"Acceptez-vous de partager anonymement vos questions recruteur favorites ?"}
                                       nameToUpdateBack={"shareTemplatesWithCommunity"}
                                       nameToUpdateFront={"shareTemplatesWithCommunity"}
                                       description={""}
                                       value={props.parameters?.shareTemplatesWithCommunity}
                                       updateData={props.updateSingleton} />
  
    <ProfileElementBoolean text={"Acceptez-vous de partager anonymement vos habitudes de navigation à des fins statistiques ?"}
                                       nameToUpdateBack={"shareHabitsWithMyshortlist"}
                                       nameToUpdateFront={"shareHabitsWithMyshortlist"}
                                       description={""}
                                       value={props.parameters?.shareHabitsWithMyshortlist}
                                       updateData={props.updateSingleton} />
                                       
  </div>);
}