import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../../../Spaces";
import {ButtonBigPlusV2} from "../../../../Buttons/ButtonBigPlus";
import {devLog} from "../../../../../../../utils/devLog/devLog";
import {notification} from "../../../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import axios from "axios";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import {useForm} from "use-formidable";
import {FileDrop} from "../../../../Buttons/FileDrop";
import CW2DocumentDisplayer from "./CW2DocumentDisplayer";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [addElementCv, setAddElementCv] = useState(false);
  const [fileCv, updateFileCv, formidableFileCv] = useForm({file: null});
  
  const [addElementDoc, setAddElementDoc] = useState(false);
  const [fileDoc, updateFile, formidableFile] = useForm({file: null});

  let firstDisplayedCv = "";

  if(props.candidate?.candidate?.cvUrls && props.candidate.candidate.cvUrls.length !== 0) {
    firstDisplayedCv = props.candidate.candidate.cvUrls[props.candidate.candidate.cvUrls.length - 1];
  } else if(props.candidate?.candidate?.cvUrl) {
    firstDisplayedCv = props.candidate?.candidate?.cvUrl;
  }

  const [displayedFile, setDisplayedFile] = useState(firstDisplayedCv);
  devLog("displayedFile", displayedFile);
  
  const [isPageLoading, setIsPageLoading] = useState(false);

  /** Update displayed file when candidate profile is loaded */
  /*useEffect(() => {
    if(props.candidate?.candidate?.cvUrls && props.candidate.candidate.cvUrls.length !== 0) {
      setDisplayedFile(props.candidate.candidate.cvUrls[props.candidate.candidate.cvUrl.length - 1])
    }
  }, [props.candidate?.candidate]);*/

  /** Test the file */
  useEffect(() => {
    (async() => {
      if(!!fileCv.file) {
        if(fileCv.file.length > 1) {
          formidableFileCv.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
        if(!formidableFileCv.isFileMime(fileCv.file[0], ["pdf", "docx", "odt", "xlsx"])) {
          formidableFileCv.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge (xlsx, pdf, docx, odt)", "error");
        }
        if(!formidableFileCv.isFileSmallerThan(fileCv.file[0], 7_000_000)) {
          formidableFileCv.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (6mo)", "error");
        }
      }
    })();
  }, [fileCv.file]);
  
  /** Test the file */
  useEffect(() => {
    (async() => {
      if(!!fileDoc.file) {
        if(fileDoc.file.length > 1) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
        if(!formidableFile.isFileMime(fileDoc.file[0], ["pdf", "docx", "odt", "xlsx", "jpeg", "png", "gif"])) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge (xlsx, pdf, docx, odt)", "error");
        }
        if(!formidableFile.isFileSmallerThan(fileDoc.file[0], 7_000_000)) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (6mo)", "error");
        }
      }
    })();
  }, [fileDoc.file]);
  
  const deleteDoc = async(fileName: string,) => {
    try {
      setIsPageLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/delete/document`, {
        candidateId: props.inherited.candidateId,
        fileName: fileName,
      }, {
        timeout: 5000,
        withCredentials: true,
      });
      if(fetched.data.status !== "ok") {
        setIsPageLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      //update front
      props.updateCandidateFrontInWindow({
        docs: [...props.candidate?.candidate?.docs || []].filter((item: any) => (item.url !== fileName)),
      });
      notification(appContextV2.dispatchPanels, "Opération effectuée avec succès", "success");
      setIsPageLoading(false);
      
    } catch (error) {
      setIsPageLoading(false);
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const sendFileDoc = async() => {
    try {
      setIsPageLoading(true);
      // todo: merge the two steps
      // Send the CV
      const dataToSend: any = new FormData();
      dataToSend.append("doc", fileDoc.file[0], fileDoc.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/candidate/document`, dataToSend, {
        withCredentials: true, timeout: 15000, headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
        }
      });
      if(fetched.data.status !== "ok") {
        setIsPageLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      let fileUrl = fetched.data.data;
      // Sending the url
      
      const fetched2: any = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/upload/document`, {
        candidateId: props.inherited.candidateId,
        fileName: fileUrl,
        comment: "",
      }, {
        withCredentials: true, timeout: 7000,
      });
      if(fetched2.data.status !== "ok") {
        setIsPageLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      // updateFront
      props.updateCandidateFrontInWindow({
        docs: [...[...props.candidate?.candidate?.docs || []], fileUrl],
      });
      setIsPageLoading(false);
      setAddElementDoc(false);
      notification(appContextV2.dispatchPanels, "Fichier ajouté avec succès", "success");
    } catch (error) {
      setIsPageLoading(false);
      devLog(error);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const deleteCv = async(fileName: string,) => {
    try {
      setIsPageLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/delete/cv`, {
        candidateId: props.inherited.candidateId,
        fileName: fileName,
      }, {
        timeout: 5000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsPageLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      notification(appContextV2.dispatchPanels, "Opération effectuée avec succès", "success");
      setIsPageLoading(false);
      //update front
      props.updateCandidateFrontInWindow({
        cvUrls: [...props.candidate?.candidate?.cvUrls || []].filter((item: any) => (item !== fileName)),
      });
    } catch (error) {
      setIsPageLoading(false);
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const deleteCvV1 = async() => {
    try {
      setIsPageLoading(true);
      //update front
      props.updateCandidateFrontInWindow({
        cvUrl: "",
      });
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/delete/cv/V1`, {
        candidateId: props.inherited.candidateId,
      }, {
        timeout: 5000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsPageLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      //update front
      notification(appContextV2.dispatchPanels, "Opération effectuée avec succès", "success");
      setIsPageLoading(false);
      
    } catch (error) {
      setIsPageLoading(false);
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const sendFileCv = async() => {
    try {
      setIsPageLoading(true);
      // todo: merge the two steps
      // Send the CV
      const dataToSend: any = new FormData();
      dataToSend.append("cv", fileCv.file[0], fileCv.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/candidate/cv`, dataToSend, {
        withCredentials: true, timeout: 15000, headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
        }
      });
      if(fetched.data.status !== "ok") {
        setIsPageLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      let fileUrl = fetched.data.data;
      // Sending the url
      
      const fetched2: any = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/upload/cv`, {
        candidateId: props.inherited.candidateId,
        fileName: fileUrl,
      }, {
        withCredentials: true, timeout: 7000,
      });
      if(fetched2.data.status !== "ok") {
        setIsPageLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      // updateFront
      props.updateCandidateFrontInWindow({
        cvUrls: [...[...props.candidate?.candidate?.cvUrls || []], fileUrl],
      });
      setIsPageLoading(false);
      setAddElementCv(false);
      notification(appContextV2.dispatchPanels, "Fichier ajouté avec succès", "success");
    } catch (error) {
      setIsPageLoading(false);
      devLog(error);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<div>

    {!!displayedFile && <CW2DocumentDisplayer fileUrl={displayedFile} />}
    
    <div className={"white-container white-container--grey space-margin--small"}>
      
      <div className={"profile-edit__title text--center"}>CV</div>
      
      <div className={"text--center text--grey"}>Consulte, ajoute et retire des CV pour cette personne</div>
      
      <Spaces n={0}/>
      
      {
        (!props.candidate?.candidate?.cvUrls || props.candidate?.candidate?.cvUrls.length === 0) && !props.candidate?.candidate?.cvUrl &&
        <div className={"text--center text--grey"}>
          Aucun CV pour le candidat
        </div>
      }
      
      <div className={"window-candidate-v2__documents"}>
        
        {/*---------------------------- map CVs*/}
        {props.candidate?.candidate?.cvUrls?.reverse().map((item: any, key: number) => (<SingleCV key={key} index={key} item={item} deleteCv={deleteCv}/>))}
        
        {/*------------------------ Fallback for old versions, if there is no array of CV ------ */}
        {
          (!props.candidate?.candidate?.cvUrls || props.candidate?.candidate?.cvUrls.length === 0) && !!props.candidate?.candidate?.cvUrl &&
          <SingleCV index={0} item={props.candidate?.candidate?.cvUrl} deleteCv={deleteCvV1}/>
        }
        
        <Spaces n={0}/>
        
        {/*---------------------------- Button to add a cv*/}
        {
          (props.candidate?.candidate?.cvUrls?.length < 10 || !props.candidate?.candidate?.cvUrls) && !addElementCv && <div className={"space-flex--center"}>
            <ButtonBigPlusV2 cb={() => setAddElementCv(true)} title={"Ajoute un CV"}/>
          </div>
        }
        
        {
          addElementCv && <>
            {
              fileCv.file === null && <div className={"space-flex--center"}>
                <FileDrop square={false} cb={updateFileCv} name={"file"}/>
              </div>
            }
            {
              fileCv.file && <div className={"text--center"}>{fileCv.file[0].name}</div>
            }
            <Spaces n={0}/>
            <div className={"text--center"}>
              <button className={"btn-secondary"} onClick={() => setAddElementCv(false)}>
                Annuler
              </button>
              <button disabled={!fileCv.file || isPageLoading} className={"btn-secondary"} onClick={() => sendFileCv()}>
                Ajouter le fichier
              </button>
            </div>
          </>
        }
        
        {
          isPageLoading && <div className={"space-flex--center"}>
            <LoadingStyle2/>
          </div>
        }
      
      </div>
      
    </div>
    
    <Spaces n={0}/>
    
    {/*----------------------------------------------------------------*/}
    {/*-------------------------------------------------------------- CR*/}
    
    <div className={"white-container white-container--grey space-margin--small"}>
      
      
      <div className={"profile-edit__title text--center"}>CR / Documents</div>
      
      <div className={"text--center text--grey"}>Consulte, ajoute et retire des CV pour cette personne</div>
      
      <Spaces n={0}/>
      
      {/*---------------------------- map Docs */}
      {
        (!props.candidate?.candidate?.docs || props.candidate?.candidate?.docs.length === 0) && <div className={"text--center text--grey"}>
          Aucun document pour ce candidat
        </div>
      }
      
      <div className={"window-candidate-v2__documents"}>
        {props.candidate?.candidate?.docs?.map((item: any, key: number) => (
          <SingleDoc key={key} index={key} item={item} deleteDoc={deleteDoc}/>
        ))}
        
        <Spaces n={0}/>
        
        {/*---------------------------- Button to add a doc*/}
        {
          (props.candidate?.candidate?.docs?.length < 10 || !props.candidate?.candidate?.docUrls) && !addElementDoc && <div className={"space-flex--center"}>
            <ButtonBigPlusV2 cb={() => setAddElementDoc(true)} title={"Ajoute un CV"}/>
          </div>
        }
        {
          addElementDoc && <>
            {
              fileDoc.file === null && <div className={"space-flex--center"}>
                <FileDrop square={false} cb={updateFile} name={"file"}/>
              </div>
            }
            {
              fileDoc.file && <div className={"text--center"}>{fileDoc.file[0].name}</div>
            }
            <Spaces n={0}/>
            <div className={"text--center"}>
              <button className={"btn-secondary"} onClick={() => setAddElementDoc(false)}>
                Annuler
              </button>
              <button disabled={!fileDoc.file || isPageLoading} className={"btn-secondary"} onClick={() => sendFileDoc()}>
                Ajouter le fichier
              </button>
            </div>
          </>
        }
        {
          isPageLoading && <div className={"space-flex--center"}>
            <LoadingStyle2/>
          </div>
        }
      </div>
      
    </div>
  
    <Spaces n={0}/>
    
  </div>);
}

function SingleCV(props: any) {
  
  const [allowDeletion, setAllowDeletion] = useState(false);
  
  return (<div className={"window-candidate-v2__documents__document"}>
    - ({props.index + 1}) <a className={"modern-link text--minified2x"} href={props.item}>Télécharger le CV</a>, <button disabled={!allowDeletion}
                                                                                                                         className={"btn--hollow modern-link text--minified2x"}
                                                                                                                         onClick={() => props.deleteCv(props.item)}>
    Effacer le CV</button>
    <br/>
    <input type={"checkbox"} checked={allowDeletion} onChange={() => setAllowDeletion((ps: any) => !ps)}/> <span className={"text--minified2x"}>Autoriser la suppression</span>
  </div>);
}

function SingleDoc(props: any) {
  
  const [allowDeletion, setAllowDeletion] = useState(false);
  
  return (<div key={props.key} className={"window-candidate-v2__documents__document"}>
    - ({props.index + 1}) <a className={"modern-link text--minified2x"} href={props.item.url}>Télécharger le Document</a>, <button disabled={!allowDeletion}
                                                                                                                                   className={"btn--hollow modern-link text--minified2x"}
                                                                                                                                   onClick={() => props.deleteDoc(props.item.url)}>
    Effacer le Document</button>
    <br/>
    <input type={"checkbox"} checked={allowDeletion} onChange={() => setAllowDeletion((ps: any) => !ps)}/> <span className={"text--minified2x"}>Autoriser la suppression</span>
  </div>);
}