import React, {useContext} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import AssistantLayout from "../../../layouts/AssistantLayout";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  const assistantTitle: string = ((language: string) => {
    switch(language) {
      case 'FR':
        return "Une erreur est survenue";
      case 'EN':
      default:
        return "An error occured";
    }
  })(language);
  
  const assistantComment: any = <div className={"text--center"}>
    {/*<button className={"btn-main"} disabled={false} onClick={(e) => {*/}
    {/*  e.preventDefault();*/}
    {/*  this.props.windowContext.nextStep(1);*/}
    {/*}}>*/}
    {/*  {this.context.appState.language === 'FR' ? "Rééssayer" : ""}*/}
    {/*  {this.context.appState.language === 'EN' ? "Retry" : ""}*/}
    {/*</button>*/}
  </div>;
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    
    <AssistantLayout title={assistantTitle} comment={assistantComment} animated={true}>
      {language === 'FR' ? "L'erreur n'est apparemment pas qu'humaine ! N'hésitez pas à contacter l'équipe à l'aide du widget en bas à droite de l'écran." : ""}
      {language === 'EN' ? "It seems machines do errors too! If it persists, feel free to contact the team.." : ""}
    </AssistantLayout>
    :(
  
  </WindowWrapper>);
}

//
// export default class WindowSubscribeError extends React.Component<any, any> {
//
//   static contextType = AppContext;
//
//   constructor(props: any) {
//     super(props);
//   }
//
//   render() {
//
//     const assistantTitle: string = ((language: string) => {
//       switch(language) {
//         case 'FR':
//           return "Une erreur est survenue";
//         case 'EN':
//         default:
//           return "An error occured";
//       }
//     })(this.context.appState.language);
//
//     const assistantComment: any = <p className={"text--center"}>
//       <button className={"btn-main"} disabled={false} onClick={(e) => {
//         e.preventDefault();
//         this.props.windowContext.nextStep(1);
//       }}>
//         {this.context.appState.language === 'FR' ? "Rééssayer" : ""}
//         {this.context.appState.language === 'EN' ? "Retry" : ""}
//       </button>
//     </p>;
//
//     return (
//       <AssistantLayout title={assistantTitle} comment={assistantComment} animated={true}>
//         {this.context.appState.language === 'FR' ? "L'erreur n'est apparemment pas qu'humaine! Si elle persiste, merci de contacter l'équipe." : ""}
//         {this.context.appState.language === 'EN' ? "It seems machines do errors too! If it persists, feel free to contact the team.." : ""}
//       </AssistantLayout>
//     );
//
//   }
//
// }
//
