import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";

export {BusinessInvited};

const BusinessInvited = (props: {
  item: any,
  teamId: string,
  updateInviteFront: Function,
  deleteInviteFront: Function,
  drag: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<div className={"teams__team__member teams__team__member--invite"}
               onClick={() => {
                 appContextV2.dispatchPanels({
                   type: "OPEN_WINDOW", value: {
                     type: "manageInvite",
                     data: {
                       inviteId: props.item._id,
                       teamId: props.teamId,
                       email: props.item.email,
                       role: props.item.role,
                       deleteInviteFront: props.deleteInviteFront,
                       updateInviteFront: props.updateInviteFront,
                     }
                   }
                 });
               }}
               draggable={true}
               onDragStart={(e) => {
                 e.dataTransfer.setData('text/plain', '');
                 props.drag("invite", props.item._id, props.teamId);
               }}
               onDragOver={(e) => {e.preventDefault();}}>
    <div className={"teams__team__member__avatar teams__team__member__avatar--empty"}>
    </div>
    
    <div className={"teams__team__member__content"}>
      <div className={"teams__team__member__content__title"}>
        {props?.item?.status === "pending" && "Invitation envoyée"}
        {props?.item?.status === "refused" && "Invitation refusée"}
      </div>
      <div className={"teams__team__member__content__email"}>
        {props?.item?.email}
      </div>
      <div className={"teams__team__member__content__role"}>
        {props.item?.role === "admin" && "Admin"}
        {props.item?.role === "manager" && "Manager"}
        {props.item?.role === "user" && "Utilisateur"}
      </div>
    </div>
  </div>);
}