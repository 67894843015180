import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";

export {fetchCandidatesToPools};

const fetchCandidatesToPools = async(args: {poolId: string, setFetchStatus: Function, formidableCandidates: any, appContextV2: any}) => {
  try {
    args.setFetchStatus("fetching");
    const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/pool/cm/candidates`, {
      headers: {
        poolid: args.poolId
      },
      withCredentials: true
    }));
    if(fetched.data.status !== "ok") {
      return args.setFetchStatus("error");
    }
    args.formidableCandidates.setForm(fetched.data.data);
    args.setFetchStatus("fetched");
  } catch (error) {
    devLog(error);
    args.setFetchStatus("error");
    notification(args.appContextV2.dispatchPanels, "Une erreur est survenue", "error");
  }
}