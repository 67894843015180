import React, {useContext, useState} from "react";
import Spaces from "../../../Spaces";
import {notification} from "../../../../../../utils/notification";
import axios from "axios";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";

export {WCODelete};

const WCODelete = (props: {
  white: boolean,
  data: any,
  isPageLoading: boolean,
  setIsLoading: Function,
  numberOfCandidates: number
  deleteColumnFront: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  /** Ask for confirmation about deletion */
  const [deleteValidation, setDeleteValidation] = useState(false);
  
  return(<>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      <div>
        <span className={"text--bold text--magnified11x"}>Supprimer cette colonne</span>
      </div>
      <Spaces n={0}/>
    
      {
        props.numberOfCandidates !== 0 && <>
          Il est impossible de supprimer une colonne qui contient des candidatures.
        </>
      }
    
      {
        props.numberOfCandidates === 0 && <div className={'text--center'}>
          <input type={"checkbox"} name={"delete"} checked={deleteValidation} onChange={() => setDeleteValidation((ps: any) => !ps)}/> Es-tu sûr·e de vouloir
          supprimer cette colonne ?
          <Spaces n={0}/>
          <button className={"btn-secondary btn-secondary--red"} disabled={!deleteValidation} onClick={async() => {
            try {
              // update front
              props.deleteColumnFront(props.data._id);
              // update back
              notification(appContextV2.dispatchPanels, "Désindexation de la colonne", "information");
              props.setIsLoading(true);
              // Update front
              // props.frontUpdates.addColumnFront();
              // Update back
              const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/mat/com/delete/column`, {
                missionId: appContextV2.appNavigation.missionId,
                columnId: props.data._id,
              },{withCredentials: true});
              if(fetched.data.status !== "ok") {
                props.setIsLoading(false);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
              }
              props.setIsLoading(false);
              notification(appContextV2.dispatchPanels, "Colonne supprimée avec succès", "success");
              appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
            } catch(error) {
              props.setIsLoading(false);
              return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
          }}>
            Supprimer
          </button>
        </div>
      }
  
    </article>
  
  </>);
}