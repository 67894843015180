import React from "react";
import Spaces from "../../../components/Spaces";

export {CASError};

function CASError(props: any) {
  
  return(<>
    <Spaces n={2}/>
    <div className={"text--center"}>
      Une erreur est survenue. Contactez le support si le problème persiste.
    </div>
    <Spaces n={2}/>
  </>);

}