import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import LoadingStyle2 from "../../Loading/LoadingStyle2";

export {WindowSendMailToSelectedCandidates};

const WindowSendMailToSelectedCandidates = () => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [fetchedStatus, setFetchedStatus] = useState("asleep");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");
  
  const poolId: string [] = appContextV2.appPanels.window.windowOptions.data.poolId;
  const candidatesIds: string [] = appContextV2.appPanels.window.windowOptions.data.candidatesIds;
  const isButtonActive: boolean = fetchedStatus === "asleep" && !!emailSubject && !!emailContent;
  
  const sendMassMail = async() => {
    try {
      
      setFetchedStatus("fetching");
      notification(appContextV2.dispatchPanels, "Envoie de la demande", "information");
      
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/pool/cma/mass/mail`, {
        poolId: poolId,
        candidatesIds: candidatesIds,
        emailSubject: emailSubject,
        emailContent: emailContent,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        setFetchedStatus("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return;
      }
      
      setFetchedStatus("fetched");
      
      notification(appContextV2.dispatchPanels, "Email envoyé avec succès", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch (error) {
      
      devLog(error);
      setFetchedStatus("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      
    }
  }
  
  return (<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
    <div className={"text--center"}>
      Tu es sur le point d'envoyer un email à {candidatesIds.length} personne{candidatesIds.length === 1 ? "" : "s"}.
    </div>
    <br/><br/>
    <div className={"white-container white-container--grey"}>
      <input className={"input--v2 input--v2--full-width"}
             id={"subject"}
             value={emailSubject}
             placeholder={"Sujet"}
             name={"mailSubject"}
             onChange={(e) => {
               if(e.target.value?.length < 200) {
                 setEmailSubject(e.target.value);
               }
             }}/>
      <br/><br/>
      <textarea className={"textarea--v2 textarea--v2--full-width"} value={emailContent}
                id={"content"}
                placeholder={"Contenu du mail (celui ci commencera automatiquement par \"Bonjour \"nom_du_candidat\"\" et se terminera par le prénom de l'opérateur qui a effectué l'action)"}
                name={"mailContent"}
                onChange={(e) => {
                  if(e.target.value?.length < 1000) {
                    setEmailContent(e.target.value);
                  }
                }}>
      </textarea>
    </div>
    <Spaces n={1}/>
    <div className={"text--center"}>
      <button disabled={!isButtonActive} className={"btn-secondary"} onClick={() => {sendMassMail()}}>
        Envoyer
      </button>
    </div>
    {
      fetchedStatus === "fetching" && <div className={"space-flex--center"}>
        <LoadingStyle2/>
      </div>
    }
  </WindowWrapper>);
}
