import React from "react";
import Spaces from "../../../components/Spaces";

export default (props: any) => {

    return (<>
        <section className={"candidate-evaluate__container"}>
            <div>
                <div className={"space-small-text-margin"}>
                    Identité:
                    <span
                        className={"text--new-blue"}> {props?.candidate?.candidate?.firstName} {props?.candidate?.candidate?.lastName}</span>
                </div>

                {/*<div>*/}
                {/*  Email:*/}
                {/*  <span*/}
                {/*    className={"text--new-blue"}> {props?.candidate?.candidate?.email}</span>*/}
                {/*</div>*/}
                {/*<Spaces n={0}/>*/}

                {/*<div>*/}
                {/*  Téléphone:*/}
                {/*  <span*/}
                {/*    className={"text--new-blue"}> {props?.candidate?.candidate?.phone}</span>*/}
                {/*</div>*/}
                {/*<Spaces n={0}/>*/}

                <div className={"space-small-text-margin"}>
                    Fonctions occupées:
                    {
                        props?.candidate?.mission?.positionsWanted?.length !== 0 && <div>
                            {props?.candidate?.mission?.positionsWanted?.map((item: any, key: number) => (<>{item && <div key={key}>
                                - <span className={"text--new-blue"}>{item}</span>
                            </div>}</>))}
                        </div>
                    }
                    {
                        props?.candidate?.mission?.positionsWanted?.length === 0 && <span className={"text--new-blue"}>
                    - Aucune fonction
                </span>
                    }
                </div>

                <div className={"space-small-text-margin"}>
                    Secteurs d'activité:
                    {
                        props?.candidate?.candidate?.sectors?.length !== 0 && <div>
                            {props?.candidate?.candidate?.sectors?.map((item: any, key: number) => (<>{item && <div key={key}>
                                - <span className={"text--new-blue"}>{item}</span>
                            </div>}</>))}
                        </div>
                    }
                    {
                        props?.candidate?.candidate?.sectors?.length === 0 && <span className={"text--new-blue"}>
                    - Aucun secteur
                </span>
                    }
                </div>

                <div className={"space-small-text-margin"}>
                    Mobilité:
                    {
                        props?.candidate?.candidate?.mobility?.length !== 0 && <div>
                            {props?.candidate?.candidate?.mobility?.map((item: any, key: number) => (<>{item && <div key={key}>
                                - <span className={"text--new-blue"}>{item}</span>
                            </div>}</>))}
                        </div>
                    }
                    {
                        props?.candidate?.candidate?.mobility?.length === 0 && <span className={"text--new-blue"}>
                    - Aucune mobilite
                </span>
                    }
                </div>

                <div className={"space-small-text-margin"}>
                    Langages:
                    {
                        props?.candidate?.candidate?.languages?.length !== 0 && <div>
                            {props?.candidate?.candidate?.languages?.map((item: any, key: number) => (<div key={key}>
                                - <span className={"text--new-blue"}>{item.value}</span>
                            </div>))}
                        </div>
                    }
                    {
                        props?.candidate?.candidate?.languages?.length === 0 && <span className={"text--new-blue"}>
                    - Aucun langage
                </span>
                    }
                </div>

                <div className={"space-small-text-margin"}>
                    Logiciels:
                    {
                        props?.candidate?.candidate?.softwares?.length !== 0 && <div>
                            {props?.candidate?.candidate?.softwares?.map((item: any, key: number) => (<div key={key}>
                                - <span className={"text--new-blue"}>{item.value}</span>
                            </div>))}
                        </div>
                    }
                    {
                        props?.candidate?.candidate?.softwares?.length === 0 && <span className={"text--new-blue"}>
                    - Aucun logiciel
                </span>
                    }
                </div>

                <div className={"space-small-text-margin"}>
                    Compétences:
                    {
                        props?.candidate?.candidate?.skills?.length !== 0 && <div>
                            {props?.candidate?.candidate?.skills?.map((item: any, key: number) => (<>{item && <div key={key}>
                                - <span className={"text--new-blue"}>{item}</span>
                            </div>}</>))}
                        </div>
                    }
                    {
                        props?.candidate?.candidate?.skills?.length === 0 && <span className={"text--new-blue"}>
                    - Aucune competence
                </span>
                    }
                </div>
                <Spaces n={0}/>

            </div>
        </section>
    </>);

}