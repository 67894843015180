import AppContext, {IAppContext} from "../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/Spaces";


export default function Policy(props: {page: string}) {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (
    <>
      <PageLayout page={props.page} status={"ok"}>
        <Spaces n={1}/>
        <p className={"text--center"}>
          Myshortlist.co est un produit de Black-belt.io.
          <br/>
          <br/>
          Adresse: 123 rue de Reuilly 75012 Paris, France
        </p>
        
        <Spaces n={2} />
        
        <p>
          En accord avec le Règlement Général sur la Protection des Données 2016/679, ce document présente l'étendue des données récoltées par la plateforme myshortlist.co et l'utilisation qui en est faite.
        </p>
  
        <Spaces n={2}/>
  
        <h1 className={"title--bold text--center"}>Politique sur l'utilisation des cookies</h1>
        <Spaces n={0}/>
        <p className={"text--center"}>
          Date de mise à jour: 2 Mai 2020
        </p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>1. Les cookies, c'est quoi ?</h1>
        
        <p className={"text--justify"}>Un cooky (au pluriel cookies) est un fichier texte géré par votre navigateur internet. Il nous permet d'identifier celui-ci et de vous offrir des fonctionnalités essentielles au bon fonctionnement du site.</p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>2. Pourquoi les utilisez-vous ?</h1>
        
        <p className={"text--justify"}>Nous utilisons les cookies pour des opérations essentielles: pour reconnaitre votre session lorsque vous reviendrez, pour connaitre la fréquence de vos connexions au site pour vous offrir des outils d'analyse de la charge de travail de votre équipe et une experience adapté à votre utilisation.</p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>3. Les utilisez vous à d'autres fins ?</h1>
        
        <p className={"text--justify"}>Non, toutes ces données individuelles ne sont ni utilisées à des fins publicitaires ni revendues à des tiers. Elles restent strictement confidentielles.</p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>4. Information sur des cookies tiers</h1>
        <p className={"text--justify"}>
          Comme toutes les plateformes modernes, myshortlist.co utilise des outils tiers pour déléguer certaines tâches. Ces outils tiers peuvent utiliser des cookies.
        </p>
        <br/>
        <p className={"text--justify"}>
        - La connexion via LinkedIn utilise des cookies tiers, gérés par LinkedIn. Myshortlist n'a pas de contrôle sur ces cookies qui relèvent de la politique de confidentialité de LinkedIn.
          <br/><br/>
        - La géolocalisation via Google Map utilise des cookies tiers, gérés par Google Map. Myshortlist n'a pas de contrôle sur ces cookies qui relèvent de la politique de confidentialité de Google.
          <br/><br/>
        - Google Analytics utilise des cookies tiers. Myshortlist n'a pas de contrôle sur ces cookies qui relèvent de la politique de confidentialité de Google.
        </p>
  
        <Spaces n={3}/>
        
        
        {/* ---------------------------------------------------------------------------------------------- */}
        {/* ---------------------------------------------------------------------------------------------- */}
  
        <h1 className={"title--bold text--center"}>Politique de confidentialité</h1>
        <Spaces n={0}/>
        <p className={"text--center"}>
          Date de mise à jour: 2 Mai 2020
        </p>
        <Spaces n={2}/>
  
        <p className={"text--justify"}>
          La présente politique de confidentialité à pour but d'informer les utilisateurs du site sur :
          <br/>
          <br/>
          - La manière dont sont collectées, conservées et traitées les données à caractère personnel les concernant.
          <br/>
          - Leurs droits concernant ces données, leur consultation et leur suppression.
          <br/>
          - Où contacter leur interlocuteur unique concernant le traitement des données.
          <br/>
          - L'utilisation qui est faite des cookies.
          <br/><br/>
          La politique de confidentialité complète les conditions générales (lien disponible en pied de page).
        </p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>Données personnelles</h1>
  
        Certaines données sont impératives car elles sont indispensables au bon fonctionnement du site. Il est donc obligatoire de les fournir. De telles données sont généralement assorties d'un astérix "*".
        <br/><br/>
        Certaines données sont facultatives. L'utilisateur est libre de les renseigner ou non à sa libre discrétion. L'utilisateur est averti que ces données ont vocation à être traitées par des algorithmes pour lui proposer un service de meilleure qualtié. Ces données ne sont pas transmises à des tiers.
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>Où sont stockées mes données ?</h1>
        
        <p className={"text--justify"}>
          Tous nos serveurs (hébergement des sites et des bases de donnée) sont sur le territoire européen.
        </p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>Le traitement des données</h1>
  
        <p className={"text--justify"}>
          Tout utilisateur est averti que les données qui le concernent sont susceptibles d'être analysées par des super-calculateurs dans le but de leur faire des propositions (de candidat / d'offre d'emploi). Ces données sont toujours anonymes.
        </p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>Les données vidéo</h1>
  
        <p className={"text--justify"}>
          Les données vidéo ne sont pas conservées sans l'accord excplicite des parties à la vidéo, qui seront invitées à renouveler leur consentement à chaque entretien vidéo passé sur la plateforme. Les vidéoss enegistrées ne pourront être visionnées que dans un délai de dix jours par le recruteur et son équipe.
        </p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>Mes droits quant à ces données</h1>
        
        Tout utilisateur à le droit de consulter les données qui le concernent, mais également le droit de les retirer ou de les faire modifier. Nous vous invitons à contacter votre interlocuteur unique pour toute question liée à l'utilisation et la suppression de ces données.
  
        <Spaces n={1}/>
  
        <p className={"text--center"}>
          Votre interlocuteur unique pour les questions de confidentialité :
          <br/>
          <span className={"text--bold"}>ezra@myshortlist.co</span>
        </p>
        
      </PageLayout>
    </>
  );
}