import React from "react";

export {PagesComponent};

const PagesComponent = (props: {
  page: number,
  pages: number,
  setPage: Function,
}) => {
  
  if(props.pages === 1) {
    return (<>
    </>);
  }
  
  const pages: number[] = [];
  
  for(let i = 1; i <= props.pages; i++) {
    pages.push(i);
  }
  
  return (<>
    {pages.map((item: number, key: number) => (<button className={`btn-page ${item === props.page ? "btn-page--selected" : ""}`}
                                                       key={key} onClick={() => {props.setPage(item, false);}}>
      {item}
    </button>))}
  </>);
}
