import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import NavBarLogoContent from "../componentsNavBar/NavBarLogoContent";
import BackToLandingPage from "./shared/BackToLandingPage";
import SubNavLinkGenerator from "./shared/SubNavLinkGenerator";

export function SubMenuRecruiterMission(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const currentSubPage: string = appContextV2.appNavigation.subPage;
  
  return (<>
    <div className={'hide-on-computers'}>
      <NavBarLogoContent/>
    </div>
    
    <ul className={"subnavbar-new hide-on-smartphones animation-straight-fade-in"}>
      
      <BackToLandingPage/>
      
      <SubNavLinkGenerator fr={"Éditeur"} en={"Editor"} active={currentSubPage === "RecruiterMissionEditor"}
                           uri={`/business/mission/${appContextV2.appNavigation.missionId}/editor`}
                           alt={"editor"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>
                           
      <SubNavLinkGenerator fr={"Partage"} en={"Links"} active={currentSubPage === "RecruiterMissionLinks"}
                           uri={`/business/mission/${appContextV2.appNavigation.missionId}/links`}
                           alt={"form"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>
                           
      <SubNavLinkGenerator fr={"Kanban"} en={"Kanban"} active={currentSubPage === "RecruiterMissionKanban"}
                           uri={`/business/mission/${appContextV2.appNavigation.missionId}/kanban`}
                           alt={"kanban"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>
                           
      {/*<SubNavLinkGenerator fr={"Smart Data"} en={"Smart Data"} active={currentSubPage === "RecruiterMissionStats"}*/}
      {/*                     uri={`/business/mission/${appContextV2.appNavigation.missionId}/statistics`}*/}
      {/*                     alt={"shortlists"} icon={"https://static.myshortlist.co/btn-list.svg"} smaller={false}/>*/}
                           
    </ul>
  </>);
}