import React, {useEffect, useState} from "react";
import {devLog} from "../../../../../../../utils/devLog/devLog";
import Spaces from "../../../../../components/Spaces";

export default (props: {
  question: any
  formIndex: number,
  answer: any,
  updateAnswer: Function,
  nextQuestion: Function,
}) => {
  
  // currentAnswer is not used by singleChoice
  const [currentAnswer, setCurrentAnswer]: [any, Function] = useState(null);
  const [answerStartedOn, setAnswerStartedOn] = useState(0);
  devLog("currentAnswer", currentAnswer);
  
  /** Initialises the answer, sets the timer ... at each step */
  useEffect(() => {
    console.log('-------------------------------------------question update');
    
    /** Initializes the answer for this question */
    switch(props.question.type) {
      case 'freeText':
        setCurrentAnswer("");
        break;
      case 'singleChoice':
        setCurrentAnswer(-1);
        break;
      case 'multipleChoice':
        setCurrentAnswer([]);
        break;
      default:
        console.log('Question was not recognized')
    }
    
    setAnswerStartedOn(new Date().getTime());
    
  }, [props.question]);
  
  const validateQuestion = (currentAnswerB: any) => {
    
    const answerToAppend = {
      question: props.question,
      answer: currentAnswerB,
      timeSpent: Math.floor((new Date().getTime() - answerStartedOn) / 1000),
    }
    
    props.updateAnswer()({target: {type: "custom", name: "answers", value: [...props.answer.answers, answerToAppend]}});
    return props.nextQuestion();
  }
  
  return (<>
    
    <Spaces n={0}/>
    
    {/*<div className={"assistant--v2-container--no-flex"}>*/}
    {/*  <div className={"space-light"}>*/}
    {/*    <ButtonBack back={() => {}}/>*/}
    {/*  </div>*/}
    {/*</div>*/}
    
    <div className={"assistant--v2-title"}>
      {props.question.question}
    </div>
    <Spaces n={0}/>
    <div className={"text--center text--grey text--minified2x"}>
      {props.question.type === "freeText" ? "(Texte libre)" : ""}
      {props.question.type === "singleChoice" ? "(Question à choix unique)" : ""}
      {props.question.type === "multipleChoice" ? "(Question à choix multiple)" : ""}
    </div>
    
    <Spaces n={1}/>
    
    <ApplyStepV2Question {...props} currentAnswer={currentAnswer} setCurrentAnswer={setCurrentAnswer} validateQuestion={validateQuestion}/>
    
    <Spaces n={2}/>
  
  </>);
}

function ApplyStepV2Question(props: any) {
  
  const toggleMultipleAnswer = (index: number) => {
    console.log(props.currentAnswer)
    if(props.currentAnswer?.includes(index)) {
      props.setCurrentAnswer((ps: any) => ps.filter((item: any) => item !== index));
    } else {
      props.setCurrentAnswer((ps: any) => [...ps, index]);
    }
  }
  
  const isAnswerSelected = (index: number) => {
    if(!props.currentAnswer || props.currentAnswer.length === 0 || !Array.isArray(props.currentAnswer)) {
      return false;
    }
    return props.currentAnswer?.includes(index);
  }
  
  switch(props.question.type) {
    
    case 'freeText':
      return (<>
        <section className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
          <textarea className={"apply-textarea"} value={props.currentAnswer} onChange={(e) => {
            props.setCurrentAnswer(e.target.value);
          }}>
          </textarea>
          <Spaces n={1}/>
          <button disabled={!props.currentAnswer} className={"btn-main"} onClick={() => props.validateQuestion(props.currentAnswer)}>
            Valider ma réponse
          </button>
        </section>
      </>);
      
    case 'singleChoice':
      return (<section className={"assistant--v2-container"}>
        {props.question.answers.map((item: any, key: number) => (<button key={key} className={"btn-assistant-main"} onClick={() => {
          props.setCurrentAnswer(key);
          props.validateQuestion(key);
        }}>
          {item.answer}
        </button>))}
      </section>);
      
    case 'multipleChoice':
      return (<><section className={"assistant--v2-container assistant--v2-container--column"}>
        {props.question.answers.map((item: any, key: number) => (<article key={key}
                                                                          className={`assistant--v2-container__label cursor--pointer ${isAnswerSelected(key) ? "assistant--v2-container__label--color2" : ""}`}
                                                                          onClick={() => toggleMultipleAnswer(key)}>
          {item.answer}
        </article>))}
      </section>
        <Spaces n={1}/>
        <div className={"text--center"}>
        <button className={"btn-main"} onClick={() => props.validateQuestion(props.currentAnswer)}>
          Valider ma réponse
        </button>
        </div>
      </>);
    default:
      return (<>Error</>);
  }
}