export default (args: {dispatchDataV2: Function}) => (candidacyId: string, toColumnId: string) => {
  
  args.dispatchDataV2({
    type: "MOVE_CANDIDACY",
    value: {
      candidacyId,
      toColumnId
    }
  });
  
}