import {ClassType, FunctionComponent} from "react";
import Terms from "../components/sharedComponents/pages/informationPages/Terms";
import Policy from "../components/sharedComponents/pages/informationPages/Policy";
import AboutUs from "../components/sharedComponents/pages/informationPages/AboutUs";
import ContactUs from "../components/sharedComponents/pages/informationPages/ContactUs";
import HelpCenter from "../components/sharedComponents/pages/informationPages/HelpCenter";
import Login from "../components/sharedComponents/pages/Login";
import Invite from "../components/sharedComponents/pages/Invite";
import {BusinessPage} from "../components/sharedComponents/pages/PublicProfilesAndApply/BusinessPage";
import CandidateFeedbackOnPlatform from "../components/sharedComponents/pages/CandidateFeedbackOnPlatform";
import CandidateMatchs from "../components/candidatesPages/CandidateMatchs";
import PageLink from "../components/sharedComponents/pages/PageLink";
import RecruiterNewMission from "../components/recruitersPages/RecruiterNewMission";
import RecruiterCalendar from "../components/recruitersPages/RecruiterCalendar";
import RecruiterPools from "../components/recruitersPages/RecruiterPools";
import {RecruiterAmbassadors} from "../components/recruitersPages/RecruiterAmbassadors";
import RecruiterProfiles from "../components/recruitersPages/RecruiterProfiles";
import RecruiterParameters from "../components/recruitersPages/RecruiterParameters";
import Prices from "../components/publicPages/Prices";
import {RecruiterMissions} from "../components/recruitersPages/RecruiterMissions";
import {RecruiterLinks} from "../components/recruitersPages/RecruiterLinks";
import RecruiterPoolPageRouter from "../components/recruitersPages/componentsRecruiterPools/RecruiterPoolPageRouter";
import RecruiterMissionPageRouter from "../components/recruitersPages/componentsRecruiterMissions/RecruiterMissionPageRouter";
import {RecruiterResearch} from "../components/recruitersPages/RecruiterResearch";
import {CandidateAction} from "../components/sharedComponents/pages/CandidateAction";
import {LinkedinApply} from "../components/sharedComponents/pages/LinkedinApply";
import {EvaluateCandidate} from "../components/sharedComponents/pages/EvaluateCandidate";
import LandingPageV2 from "../components/publicPages/LandingPageV2";
import FeedbacksFromCandidates from "../components/sharedComponents/pages/FeedbacksFromCandidates";
import {AllCandidatesEvaluations} from "../components/sharedComponents/pages/PublicProfilesAndApply/AllCandidatesEvaluations";
import {SharedCandidate} from "../components/sharedComponents/pages/SharedCandidate";

export {routes};

export interface IRoute {
  
  path: string;
  page: string;
  component: FunctionComponent<any> | ClassType<any, any, any>;
  exact: boolean;
}

interface IRoutes {
  
  public: IRoute[];
  shared: IRoute[];
  candidates: IRoute[],
  recruiters: IRoute[],
}

const routes: IRoutes = {
  
  public: [],
  shared: [],
  candidates: [],
  recruiters: [],
};

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== SHARED PAGES =========================================== */

/* ----- Routes regarding casual front navigation --------------------------- */
routes.shared.push({exact: true, path: '/terms', page: 'Terms', component: Terms});
routes.shared.push({exact: true, path: '/policy', page: 'Policy', component: Policy});
routes.shared.push({exact: true, path: '/aboutus', page: 'AboutUs', component: AboutUs});
routes.shared.push({exact: true, path: '/contact', page: 'ContactUs', component: ContactUs});
routes.shared.push({exact: true, path: '/helpcenter', page: 'HelpCenter', component: HelpCenter});
routes.shared.push({exact: true, path: '/login/:token', page: 'Login', component: Login});
routes.shared.push({exact: true, path: '/invite/:token', page: 'Invite', component: Invite});

/* ----- Routes regarding business & personal pages access --------------------------- */
routes.shared.push({exact: false, path: '/corpo/:slugname/:slugnumber', page: 'BusinessPage', component: BusinessPage});
routes.shared.push({exact: false, path: '/apply/:slugname/:slugnumber/:rank', page: 'BusinessPage', component: BusinessPage});

/* ----- Routes regarding candidate actions --------------------------- */
routes.shared.push({exact: true, path: '/candidate/action/:action/:token', page: 'CandidateAction', component: CandidateAction});
routes.shared.push({exact: true, path: '/candidate/feedback/:token', page: 'CandidateFeedbackOnPlatform', component: CandidateFeedbackOnPlatform});
routes.shared.push({exact: false, path: '/oAuth/v2/linkedin/apply', page: 'LinkedinApply', component: LinkedinApply});
routes.shared.push({exact: true, path: '/recruiter/action/evaluate/:token', page: 'EvaluateCandidate', component: EvaluateCandidate});
routes.shared.push({exact: true, path: '/action/candidate/shared/:token', page: 'SharedCandidate', component: SharedCandidate});

routes.shared.push({exact: true, path: '/recruiter/action/allEvaluations/:token', page: 'AllCandidatesEvaluations', component: AllCandidatesEvaluations});
routes.shared.push({exact: true, path: '/feedback', page: 'FeedbacksFromCandidates', component: FeedbacksFromCandidates});

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== PUBLIC SPACE PAGES ============================================== */

/* ----- Routes --------------------------- */
routes.public.push({exact: true, path: '/', page: 'LandingPage', component: LandingPageV2});
routes.public.push({exact: true, path: '/prices', page: 'Prices', component: Prices});

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== CANDIDATE SPACE PAGES =========================================== */
routes.public.push({exact: true, path: '/', page: 'CandidateMatchs', component: CandidateMatchs});

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== RECRUITER SPACE PAGES =========================================== */

/* ----- Main Routes (first-level) --------------------------- */
routes.recruiters.push({exact: true, path: '/business/new/job', page: 'RecruiterNewMission', component: RecruiterNewMission});
routes.recruiters.push({exact: true, path: '/business/jobs', page: 'RecruiterMissions', component: RecruiterMissions});
routes.recruiters.push({exact: true, path: '/business/calendar', page: 'RecruiterCalendar', component: RecruiterCalendar});
routes.recruiters.push({exact: true, path: '/business/communities', page: 'RecruiterPools', component: RecruiterPools});
routes.recruiters.push({exact: true, path: '/business/ambassadors', page: 'RecruiterAmbassadors', component: RecruiterAmbassadors});
routes.recruiters.push({exact: true, path: '/business/link', page: 'PageLink', component: RecruiterLinks});
routes.recruiters.push({exact: true, path: '/business/research', page: 'RecruiterResearch', component: RecruiterResearch});

/* ----- Parameters and options       --------------------------- */
routes.recruiters.push({exact: false, path: '/business/profiles', page: 'RecruiterProfiles', component: RecruiterProfiles});
routes.recruiters.push({exact: false, path: '/business/parameters', page: 'RecruiterParameters', component: RecruiterParameters});

/* ----- Specific routes to a mission --------------------------- */
routes.recruiters.push({exact: false, path: '/business/mission/:missionid', page: 'RecruiterMissionPageRouter', component: RecruiterMissionPageRouter});

/* ----- Specific routes to a pool --------------------------- */
routes.recruiters.push({exact: false, path: '/business/community/:poolid', page: 'RecruiterPoolPageRouter', component: RecruiterPoolPageRouter});

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== CANDIDATE SPACE PAGES =========================================== */

/* ----- Main Routes (first-level) --------------------------- */