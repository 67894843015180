import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import Spaces from "../../../../sharedComponents/components/Spaces";
import {shortenText} from "../../../../../utils/shortenText";
import {capitalizeMulti} from "../../../../../utils/converters";

export default (props: {
  
  columnId: string,
  columnData: any,
  rank: number,
  title: string,
  draggedFromColumnId: any,
  setIsLoading: Function,
  keywords: string,
  
  shortlist: any,
  shortlistUnsubscribed: any,
  
  drag: Function,
  drop: Function,
  
  missionId: string,
  customKanbanView: any,
  
  areFiltersActive: boolean,
  
  frontUpdates: any,
  
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const displayMoveButtons: boolean = !["1", "10"].includes(props.columnId);
  
  const isThereMailAction: boolean = props.columnData?.activateMail && props.columnData?.mailSubject?.length !== 0 && props.columnData?.mailContent?.length !== 0;
  const isTherePoolAction: boolean = props.columnData?.activatePool && props.columnData?.poolId?.length !== 0;
  const isThereSMSAction: boolean = props.columnData?.activateSms && props.columnData?.smsText?.length !== 0;
  const isThereAskCvAction: boolean = props.columnData?.activateAskCv;
  const isThereNotifyAction: boolean = props.columnData?.activateNotify && props.columnData?.notifyEmails?.length !== 0;
  const doesColumnHaveAnAction: boolean = isThereMailAction || isThereSMSAction || isTherePoolAction || isThereAskCvAction || isThereNotifyAction;
  
  const moveColumnHandler = async(columnId: string, direction: number) => {
    try {
      
      // update front
      props.frontUpdates.moveColumnFront(columnId, direction);
      notification(appContextV2.dispatchPanels, "Réindexation de la colonne", "information");
      props.setIsLoading(true);
      // Update back
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/mat/com/move/column`, {
        missionId: appContextV2.appNavigation.missionId,
        columnId: columnId,
        direction: direction,
      }, {withCredentials: true});
      if(fetched.data.status !== "ok") {
        props.setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      props.setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Colonne déplacée avec succès", "success");
    } catch(error) {
      props.setIsLoading(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  console.log("shortlist",props.shortlist);

  return (<section className={"kanban__column animation-fade-in"}
                   onDragOver={(e) => {
                     e.preventDefault();
                   }}
                   onDrop={() => {
                     props.drop(props.columnId)
                   }}>
    
    {doesColumnHaveAnAction &&
    <div title={"Une action est définie sur cette colonne"} className={"kanban__column__action"}><img
      src={"https://static.myshortlist.co/icon-lightning.svg"}/></div>}
    
    {/*--------------------------------------------------------- Header*/}
    <article className={"kanban__column__header"}>
      <article className={"kanban__column__header__title"}>
        {props.title}
      </article>
      <article className={"kanban__column__header__number"}>
        {props.shortlist?.length}
      </article>
    </article>
    
    <article className={"kanban__column__header__buttons"}>
      {displayMoveButtons && <>
        <button disabled={props.rank === 2} title={"Déplace cette colonne à gauche"}
                onClick={() => moveColumnHandler(props.columnId, -1)}
                className={"kanban__column__header__buttons__btn"}>
          <img src={"https://static.myshortlist.co/btn-arrow--left.svg"}/>
        </button>
        <button title={"Ouvre les paramètres de cette colonne"}
                onClick={() => {
                  appContextV2.dispatchPanels({
                    type: "OPEN_WINDOW", value: {
                      type: "columnOptionsV2",
                      data: {
                        columnData: props.columnData,
                        updateColumnFront: props.frontUpdates.updateColumnFront,
                        deleteColumnFront: props.frontUpdates.deleteColumnFront,
                        numberOfCandidates: props.shortlist.length,
                        setIsLoading: props.setIsLoading,
                        type: "kanban",
                
                      }
                    }
                  })
                }}
                className={"kanban__column__header__buttons__btn"}>
          <img src={"https://static.myshortlist.co/btn-parameters.svg"}/>
        </button>
        <button disabled={props.rank === props.customKanbanView.length + 1} title={"Déplace cette colonne à droite"}
                onClick={() => moveColumnHandler(props.columnId, 1)}
                className={"kanban__column__header__buttons__btn"}>
          <img src={"https://static.myshortlist.co/btn-arrow--right.svg"}/>
        </button>
      </>}
    </article>

    {/*------------------------------------------------------------ Candidates to display */}
    {props.shortlist.map((item: any, key: number) => (
      <article
        className={`kanban__candidacy--v2 ${["withdrawed", "rejected"].includes(item.status) ? "kanban__candidacy--v2__hollow" : ""} ${getCandidacyColor(item)} ${props.areFiltersActive ? "kanban__candidacy--v2__filters-active" : ""}`}
        key={key}
        onClick={() => {
          appContextV2.dispatchPanels({
            type: "OPEN_WINDOW", value: {
              type: "candidateWindowV2",
              data: {
                updateCandidacyFront: props.frontUpdates.updateCandidacyFront,
                keywords: props.keywords,
                category: "mission",
                candidateId: item.candidateId,
                missionId: appContextV2.appNavigation.missionId,
                basicData: {
                  firstName: item.firstName,
                  lastName: item.lastName,
                  avatarUrl: item.avatarUrl,
                },
                frontUpdatePool: {
                  updateCandidatesBulk: () => {
                  },
                  reloadCandidates: () => {
                  },
                  addCandidateFront: () => {
                  }
                },
                frontUpdateMission: {
                  updateCandidaciesBulk: props.frontUpdates.updateCandidaciesBulk,
                },
              }
            }
          })
        }}
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.setData('text/plain', '');
          props.drag(item._id, 'candidacy');
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <div className={"kanban__candidacy--v2__avatar"}>
          {!!item.avatarUrl && <img src={item.avatarUrl}/>}
          {!item.avatarUrl && <>{(item.firstName || "N")[0] ? (item.firstName || "N")[0].toUpperCase() : "N"}{(item.lastName || "N")[0] ? (item.lastName || "N")[0].toUpperCase() : "N"}</>}
        </div>
        <div className={"kanban__candidacy--v2__name"}>
          {shortenText(capitalizeMulti(item.firstName), 13)}
        </div>
        <div className={"kanban__candidacy--v2__checkbox"}>
        </div>
        <div className={"kanban__new-label"}>
          {appContextV2.appNotifications.recruiter?.notifications?.filter((notification: any) =>
              (notification.actionType === "newCandidacy" && notification.seenByMe=== false && notification.onCandidateId===item.candidateId)).length!==0
          && <div className={"kanban__new-candidate"}> New </div>}
          {appContextV2.appNotifications.recruiter?.notifications?.filter((notification: any) =>
          (notification.actionType === "newMessage" && notification.seenByMe=== false && notification.onCandidateId===item.candidateId)).length!==0
          && <div className={"kanban__new-candidate"}> Message </div>}
          {appContextV2.appNotifications.recruiter?.notifications?.filter((notification: any) =>
              (notification.actionType === "newTrigram" && notification.seenByMe=== false && notification.onCandidateId===item.candidateId)).length!==0
          && <div className={"kanban__new-candidate"}> Note </div>}
          {appContextV2.appNotifications.recruiter?.notifications?.filter((notification: any) =>
              (notification.actionType === "newEvaluation" && notification.seenByMe=== false && notification.onCandidateId===item.candidateId)).length!==0
          && <div className={"kanban__new-candidate"}> Evaluation </div>}
        </div >
      
      </article>))}
    
    {/* --------------------------- Candidates that didn't come back on myshortlist */}
    
    <div className={"kanban__unsorted__separator"}>
    </div>
    
    {
      props.shortlistUnsubscribed.length !== 0 && !props.areFiltersActive && <>

        <div className={"text--center text--minified2x text--grey"}>
          Les candidats ci-dessous n'ont pas postulé via Myshortlist.
        </div>

        <Spaces n={0}/>
        
        {props.shortlistUnsubscribed?.map((item: any, key: any) => (
          <article className={`kanban__candidacy--v2 kanban__candidacy--v2--white`}
                   key={key}
                   draggable={true}
                   onDragStart={(e) => {
                     e.dataTransfer.setData('text/plain', '');
                     props.drag(item._id, 'potential');
                   }}
                   onDragOver={(e) => {
                     e.preventDefault();
                   }}
                   onClick={() => {
                     appContextV2.dispatchPanels({
                       type: "OPEN_WINDOW", value: {
                         type: "candidateNotRegisteredWindow",
                         data: {
                           column: props.columnData,
                           candidacy: item,
                           removeUnregisteredCandidacyFront: props.frontUpdates.removeUnregisteredCandidacyFront,
                           updateUnregisteredCandidacyFront: props.frontUpdates.updateUnregisteredCandidacyFront
                         }
                       }
                     })
                   }}>
            {
              getWebOrigin(item.mailUsedAsId) && <>
                <div className={"kanban__candidacy--v2--white__web-origin"}>
                  {getWebOrigin(item.mailUsedAsId)}
                </div>
              </>
            }
            {shortenText(item.mailUsedAsId?.replace('%40', '@'), 20)}
          </article>))}

      </>
    }
  
  
  </section>);
}

function getCandidacyColor(item: any) {
  
  if(item.imported === true) {
    return "kanban__candidacy--v2--imported";
  }
  
  if(item.blockedByAlgorithm === true || item.status === "blocked") {
    return "kanban__candidacy--v2--blocked";
  }
  
  const average: number = Math.floor((2 * item.markForm + item.markSkills) / 3);
  
  if(item.firstName === "Soumia") {
    console.log('Soumia', item.markForm, item.markSkills, average);
  }
  
  if(average < 50) {
    if(item.firstName === "Camille") {
      console.log('camille', 'color4');
    }
    return "kanban__candidacy--v2--color4";
  }
  
  if(average < 70) {
    if(item.firstName === "Camille") {
      console.log('camille', 'color3');
    }
    return "kanban__candidacy--v2--color3";
  }
  
  if(average < 90) {
    if(item.firstName === "Camille") {
      console.log('camille', 'color2');
    }
    return "kanban__candidacy--v2--color2";
  }
  
  if(average <= 100) {
    if(item.firstName === "Camille") {
      console.log('camille', 'color1');
    }
    return "kanban__candidacy--v2--color1";
  }
  
  return "";
}

export function getWebOrigin(token: string) {
  
  if(!token) return "";
  
  if(token.toLowerCase().includes("indeed")) return "Indeed";
  
  if(token.toLowerCase().includes("pole")) return "Pôle emploi";
  
  if(token.toLowerCase().includes("region")) return "RégionJob";
  
  return "";
  
}