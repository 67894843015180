import AppContext, {IAppV2Context} from "../context/AppContext";
import React, {useContext, useState} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {NavBar} from "../../sharedComponents/components/Navigation/NavBar";
import LandingPageV2 from "../../publicPages/LandingPageV2";
import FourHundredFour from "../../sharedComponents/pages/errors/FourHundredFour";
import Footer from "../../sharedComponents/components/Footer";
import {Window} from "../../sharedComponents/components/Window/Window";
import Login from "../../sharedComponents/pages/Login";
import CandidateMatchs from "../../candidatesPages/CandidateMatchs";
import RecruiterResearchEditorNewFormItem
  from "../../recruitersPages/componentsRecruiterEditor/RecruiterResearchEditorNewFormItem";
import FirstLoginAssistant from "../../sharedComponents/components/FirstLoginAssistants/FirstLoginAssistant";
import MenuDropFromBottom from "../../sharedComponents/components/Navigation/MenuDropFromBottom";
import Notifications from "../../sharedComponents/components/Notifications";
import {routes, IRoute} from "../../../routes/routes";
import {AbstractRoute} from "./componentsRouting/AbstractRoute";
import {CandidateRoute, PublicRoute, RecruiterRoute} from "./componentsRouting/RoutesWrapper";
import {RecruiterMissions} from "../../recruitersPages/RecruiterMissions";
import {NavBarSmartphones} from "../../sharedComponents/components/Navigation/NavBarSmartphones";
import ProductionBanner from "../../sharedComponents/components/ProductionBanner";
import FirstLoginTutorial from "../../sharedComponents/components/FirstLoginTutorial/FirstLoginTutorial";
import WindowCookies from "../../sharedComponents/components/Cookies/WindowCookies";
import WelcomeDiv from "../../sharedComponents/components/WelcomeDiv";

export default function Routing(props: any) {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  const isSpaceCandidate: boolean = appContextV2.appSession.app.space === "candidate";
  const isSpaceRecruiter: boolean = appContextV2.appSession.app.space === "recruiter";

  const landingPageToDisplay: "candidate" | "recruiter" | "public" = (isSpaceCandidate ? "candidate" : (isSpaceRecruiter ? "recruiter" : "public"));

  const pagesWithoutNavbar = ["Login", "OAuth"];
  const displayNavBar = !pagesWithoutNavbar.includes(appContextV2.appNavigation.page);

  const pagesWithoutFooter = ["Login", "OAuth"];
  const displayFooter = !pagesWithoutFooter.includes(appContextV2.appNavigation.page);

  const displayTutorial = appContextV2.appSession.app.displayTutorial;
  const isUserAuthorized = appContextV2.appSession.session.userStatus === 'authorized';
  const isSpaceNotPublic = appContextV2.appSession.app.space !== 'public';
  
  const displayWelcome = appContextV2.displayWelcome && !displayTutorial && isUserAuthorized && isSpaceRecruiter && appContextV2.appSession?.session?.firstName && appContextV2.appNavigation?.page === "RecruiterMissions";

  return (<>
      <Router>

        {displayWelcome && <WelcomeDiv setDisplay={appContextV2.setDisplayWelcome} />}
        {appContextV2.appSession.app.mode !== "production" && <ProductionBanner/>}
        {appContextV2.appPanels.notifications.isWindowNotificationVisible && <Notifications/>}
        {appContextV2.appSession.session.displayCookies  && <WindowCookies/>}
        {appContextV2.appSession.session.userStatus === 'subscribed' && <FirstLoginAssistant/>}
        {appContextV2.appPanels.editorPanel.isEditorPanelVisible && <RecruiterResearchEditorNewFormItem/>}
        {appContextV2.appPanels.menuFromBottom.isMenuFromBottomVisible && <MenuDropFromBottom/>}
        {appContextV2.appPanels.window.isWindowVisible && <Window/>}
        {displayTutorial && isSpaceNotPublic && isUserAuthorized  && <FirstLoginTutorial/>}
        {displayNavBar && <NavBar/>}

        {displayNavBar && <NavBarSmartphones/>}

        <Switch>

          {/* Specific switch for the landing pages ---------------------------------------------------------------- */}

          {(landingPageToDisplay === "public") && <PublicRoute exact={true} path={"/"}
                                                               component={<LandingPageV2 page={"LandingPage"}/>}
                                                               space={appContextV2.appSession.app.space}/>}
          {(landingPageToDisplay === "candidate") && <CandidateRoute exact={true} path={"/"}
                                                                     component={<CandidateMatchs
                                                                       page={"CandidateMatchs"}/>}
                                                                     space={appContextV2.appSession.app.space}/>}
          {(landingPageToDisplay === "recruiter") && <RecruiterRoute exact={true} path={"/"}
                                                                     component={<RecruiterMissions
                                                                       page={"RecruiterMissions"}/>}
                                                                     space={appContextV2.appSession.app.space}/>}

          {/* Routes for the public space ------------------------------------------------- */}
          {routes.public.filter((item: IRoute) => item.path !== "/")?.map((item: IRoute, key: number) => (
            <AbstractRoute {...item} key={key} authorisedSpaces={["public"]}/>))}
          )}

          {/* Routes for the candidate space ------------------------------------------------- */}
          {routes.candidates.filter((item: IRoute) => item.path !== "/")?.map((item: IRoute, key: number) => (
            <AbstractRoute {...item} key={key} authorisedSpaces={["candidate"]}/>))}
          )}

          {/* Routes for the recruiter space ------------------------------------------------- */}
          {routes.recruiters.filter((item: IRoute) => item.path !== "/")?.map((item: IRoute, key: number) => (
            <AbstractRoute {...item} key={key} authorisedSpaces={["recruiter"]}/>))}
          )}

          {/* Shared routes ------------------------------------------------- */}
          {routes.shared.filter((item: IRoute) => item.path !== "/")?.map((item: IRoute, key: number) => (
            <AbstractRoute {...item} key={key} authorisedSpaces={["public", "candidate", "recruiter"]}/>))}
          )}

          {/* 404 ------------------------------------------------- */}
          <Route render={(componentProps) => <FourHundredFour {...componentProps} page="FourHundredFour" fullPage={true}
                                                              setPage={true}/>}/>

        </Switch>

        {displayFooter && <Footer/>}

      </Router>
    </>
  );
}
