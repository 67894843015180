import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import {notification} from "../../../../../utils/notification";
import {devLog} from "../../../../../utils/devLog/devLog";
import axios from "axios";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const missionId = appContextV2.appPanels.menuFromBottom.menuFromBottomOptions.data.missionId;
  const missionStatus = appContextV2.appPanels.menuFromBottom.menuFromBottomOptions.data.status;
  const updateFront = appContextV2.appPanels.menuFromBottom.menuFromBottomOptions.data.updateFront;
  const deleteFront = appContextV2.appPanels.menuFromBottom.menuFromBottomOptions.data.deleteFront;
  const isMissionMine = appContextV2.appPanels.menuFromBottom.menuFromBottomOptions.data.isMissionMine;
  
  // NB: in this version, failed and success are not used
  const showPublish: boolean = !["published"].includes(missionStatus);
  const showCollaboration: boolean = !["collaboration"].includes(missionStatus);
  const showDraft: boolean = !["draft"].includes(missionStatus) && isMissionMine;
  const showClose: boolean = !["closed"].includes(missionStatus);
  const showDelete: boolean = ["closed"].includes(missionStatus);

  const updateMissionStatus = async(newStatus: 'published' | 'closed' | 'draft' | 'collaboration') => {
    try {
      
      // update front
      updateFront(missionId, {status: newStatus,});
      
      notification(appContextV2.dispatchPanels, "Réorganisation du registre des postes ouverts", "information");
      
      // update back
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/ent/m/status/${newStatus}`, {
        missionId: missionId,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        appContextV2.dispatchPanels({type: "CLOSE_MENU_FROM_BOTTOM", value: null});
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      if(newStatus === "published") {
        appContextV2.dispatchSession({type: "UPDATE_MISSIONS_NUMBER", value: 1});
        return notification(appContextV2.dispatchPanels, "Les candidats peuvent maintenant postuler à l'annonce", "information");
      }
      if(missionStatus === "published") {
        appContextV2.dispatchSession({type: "UPDATE_MISSIONS_NUMBER", value: -1});
        return notification(appContextV2.dispatchPanels, "Les candidats ne peuvent plus postuler à l'annonce", "warning");
      }
      
      appContextV2.dispatchPanels({type: "CLOSE_MENU_FROM_BOTTOM", value: null});
      
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      devLog(error);
    }
  }
  
  return (<MenuDefaultWrapper>
    <ul className={"navbar-smartphone__items"}>
      {
        showPublish && <li>
          <button title={"Lorsque la mission est 'publiée' les candidats peuvent ouvrir le lien et postuler."} disabled={false} className={`btn--hollow`}
                  onClick={() => updateMissionStatus('published')}>
            Publier
          </button>
        </li>
      }
      {
        showCollaboration && <li>
          <button
            title={"Lorsque la mission est en 'collaboration', vos collègues ont également accès à la mission pour la modifier. Les candidats ne peuvent cependant pas postuler."}
            disabled={false} className={`btn--hollow`} onClick={() => updateMissionStatus('collaboration')}>
            Mettre en collaboration
          </button>
        </li>
      }
      {
        showDraft && <li>
          <button title={"Lorsque la mission est en brouillon, vous seul y avez accès."}
                  disabled={false} className={`btn--hollow`} onClick={() => updateMissionStatus('draft')}>
            Mettre en brouillon
          </button>
        </li>
      }
      {
        showClose && <li>
          <button
            title={"Le poste a été pourvu, 'est plus d'actualité ou n'est pas pertinent ? Tu peux l'archiver (tu y auras toujours accès via l'historique)"}
            disabled={false} className={`btn--hollow`} onClick={() => updateMissionStatus('closed')}>
            Archiver
          </button>
        </li>
      }
      {
        showDelete && <li>
          <button title={'Cette action est définitive.'} disabled={false} className={`btn--hollow`} onClick={() => {
            appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
                type: "deleteMission",
                data: {
                  missionId: missionId,
                  deleteFront: deleteFront,
                }
              }});
            appContextV2.dispatchPanels({type: "CLOSE_MENU_FROM_BOTTOM", value: null});
          }}>
            Supprimer définitivement
          </button>
        </li>
      }
    
    </ul>
  </MenuDefaultWrapper>);
}

function MenuDefaultWrapper(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<>
    
    {props.children}
  
  </>)
}

