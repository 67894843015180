import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import React, {useContext, useState} from "react";
import Spaces from "../../../Spaces";

export {WKAANotify};

const WKAANotify = (props: {columnData: any,
    updateColumnData: Function,
    white: boolean,}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    const [editorMode, setEditorMode] = useState(false);

    return (<>

        <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>

            <div className={"text--center profile-edit__title"}>
               Demander une évaluation
            </div>

            <Spaces n={0}/>
    
            <div className={"text--center text--grey"}>
                Les personnes dont vous avez entré les email recevront une notification pour tout candidat entrant dans cette colonne. Cette notification les invitera à évaluer les candidats.
            </div>
    
            <Spaces n={0}/>
            
            {props.columnData?.notifyEmails?.map((item: any, key: number) => <div>- {item}</div>)}

        </article>

    </>);
}