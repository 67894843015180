import React, {useContext, useState} from "react";
import {LastElementInColumn} from "./componentsRPBMedias/LastElementInColumn";
import {AddImageToHeaderElements} from "./componentsRPBMedias/AddImageToHeaderElements";
import Spaces from "../../../sharedComponents/components/Spaces";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import {ProfileEditorColumnElementV2} from "./componentsRPBMedias/ProfileEditorColumnElementV2";
import {notification} from "../../../../utils/notification";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";

export {RecruiterProfilesBusinessMedias};

const RecruiterProfilesBusinessMedias = (props: {
  assistantGet: any,
  profile: any,
  updateSingleton: Function,
  updateProfileFront: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [dragFromColumn, setDragFromColumn] = useState("");
  const [dragId, setDragId] = useState("");
  
  const [disableSave, setDisableSave] = useState(true);
  
  /**
   * Function called when an element is dragged
   */
  const drag = (dragFromColumn: string, dragId: string) => {
    console.log(dragFromColumn, dragId)
    setDragFromColumn(dragFromColumn);
    setDragId(dragId);
  }
  
  /**
   * Function called when an element is dropped over another element
   */
  const drop = async(dragToColumn: string, dragToPosition: string) => {
    
    if(dragToPosition === dragId) {
      return;
    }
    
    // Get original moved item
    let movedItem = [];
    switch(dragFromColumn) {
      case 'column1':
        movedItem = (props.profile?.mediasCol1.filter((item: any) => (item._id === dragId)))[0];
        break;
      case 'column2':
        movedItem = (props.profile?.mediasCol2.filter((item: any) => (item._id === dragId)))[0];
        break;
      case 'column3':
        movedItem = (props.profile?.mediasCol3.filter((item: any) => (item._id === dragId)))[0];
    }
    if(!movedItem) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
    
    let moveToIndex: number = 0;
    let newDestinationColumn = [];
    let newOriginColumn: any = [];
    
    // Remove item from origin
    switch(dragFromColumn) {
      case 'column1':
        newOriginColumn = props.profile?.mediasCol1.filter((item: any) => (item._id !== dragId));
        break;
      case 'column2':
        newOriginColumn = props.profile?.mediasCol2.filter((item: any) => (item._id !== dragId));
        break;
      case 'column3':
        newOriginColumn = props.profile?.mediasCol3.filter((item: any) => (item._id !== dragId));
    }
  
    // Get index of destination
    switch(dragToColumn) {
      case 'column1':
        moveToIndex = props.profile?.mediasCol1.findIndex((item: any) => item._id === dragToPosition);
        break;
      case 'column2':
        moveToIndex = props.profile?.mediasCol2.findIndex((item: any) => item._id === dragToPosition);
        break;
      case 'column3':
        moveToIndex = props.profile?.mediasCol3.findIndex((item: any) => item._id === dragToPosition);
    }
  
    if(dragFromColumn !== dragToColumn) {
  
      // Add item to destination column
      switch(dragToColumn) {
        case 'column1':
          const copy1 = [...props.profile?.mediasCol1];
          copy1.splice(moveToIndex, 0, movedItem);
          newDestinationColumn = copy1;
          break;
        case 'column2':
          const copy2 = [...props.profile?.mediasCol2];
          copy2.splice(moveToIndex, 0, movedItem);
          newDestinationColumn = copy2;
          break;
        case 'column3':
          const copy3 = [...props.profile?.mediasCol3];
          copy3.splice(moveToIndex, 0, movedItem);
          newDestinationColumn = copy3;
      }
  
      // Update front
      await props.updateProfileFront(`mediasCol${dragFromColumn[dragFromColumn.length - 1]}`, newOriginColumn);
      await props.updateProfileFront(`mediasCol${dragToColumn[dragToColumn.length - 1]}`, newDestinationColumn);
  
      setDisableSave(false);
      
    } else {
  
      // Checks if object is moved up or down
      const moveToOriginalIndex = moveToIndex;
      let moveFromOriginalIndex = 0;
      switch(dragFromColumn) {
        case 'column1':
          moveFromOriginalIndex = props.profile?.mediasCol1.findIndex((item: any) => item._id === dragId);
          break;
        case 'column2':
          moveFromOriginalIndex = props.profile?.mediasCol2.findIndex((item: any) => item._id === dragId);
          break;
        case 'column3':
          moveFromOriginalIndex = props.profile?.mediasCol3.findIndex((item: any) => item._id === dragId);
      }
      
      if(moveFromOriginalIndex < moveToOriginalIndex) {
        moveToIndex = newOriginColumn?.findIndex((item: any) => item._id === dragToPosition) + 1;
      } else {
        moveToIndex = newOriginColumn?.findIndex((item: any) => item._id === dragToPosition);
      }
      
      const copy = [...newOriginColumn];
      copy.splice(moveToIndex, 0, movedItem);
      newDestinationColumn = copy;
  
      // Update front
      props.updateProfileFront(`mediasCol${dragToColumn[dragToColumn.length - 1]}`, newDestinationColumn);
  
      setDisableSave(false);
    }
    
  }
  
  /**
   * Function called when an element is dropped in last position
   */
  const dropOnLast = async(dragToColumn: string) => {
  
    // Get original moved item
    let movedItem = [];
    switch(dragFromColumn) {
      case 'column1':
        movedItem = (props.profile?.mediasCol1.filter((item: any) => (item._id === dragId)))[0];
        break;
      case 'column2':
        movedItem = (props.profile?.mediasCol2.filter((item: any) => (item._id === dragId)))[0];
        break;
      case 'column3':
        movedItem = (props.profile?.mediasCol3.filter((item: any) => (item._id === dragId)))[0];
    }
    if(!movedItem) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  
    // Remove item from origin
    let newOriginColumn;
    switch(dragFromColumn) {
      case 'column1':
        newOriginColumn = props.profile?.mediasCol1.filter((item: any) => (item._id !== dragId));
        break;
      case 'column2':
        newOriginColumn = props.profile?.mediasCol2.filter((item: any) => (item._id !== dragId));
        break;
      case 'column3':
        newOriginColumn = props.profile?.mediasCol3.filter((item: any) => (item._id !== dragId));
    }
  
    let newDestinationColumn;
    
    if(dragToColumn !== dragFromColumn) {
  
      // Add item to the last position
      switch(dragToColumn) {
        case 'column1':
          const copy1 = [...props.profile?.mediasCol1, movedItem];
          newDestinationColumn = copy1;
          break;
        case 'column2':
          const copy2 = [...props.profile?.mediasCol2, movedItem];
          newDestinationColumn = copy2;
          break;
        case 'column3':
          const copy3 = [...props.profile?.mediasCol3, movedItem];
          newDestinationColumn = copy3;
      }
  
      // Update front
      props.updateProfileFront(`mediasCol${dragFromColumn[dragFromColumn.length - 1]}`, newOriginColumn);
      props.updateProfileFront(`mediasCol${dragToColumn[dragToColumn.length - 1]}`, newDestinationColumn);
  
      setDisableSave(false);
      
    } else {
  
      // Add item to the last position
      switch(dragToColumn) {
        case 'column1':
          const copy1 = [...newOriginColumn, movedItem];
          newDestinationColumn = copy1;
          break;
        case 'column2':
          const copy2 = [...newOriginColumn, movedItem];
          newDestinationColumn = copy2;
          break;
        case 'column3':
          const copy3 = [...newOriginColumn, movedItem];
          newDestinationColumn = copy3;
      }
  
      // Update front
      props.updateProfileFront(`mediasCol${dragToColumn[dragToColumn.length - 1]}`, newDestinationColumn);
  
      setDisableSave(false);
  
    }
    
  }
  
  const saveNewDisposition = async() => {
    try {
  
      notification(appContextV2.dispatchPanels, "Mise à jour en cours", "information");
  
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/ent/b/update/business`, {
        mediasCol1: props.profile?.mediasCol1 || [],
        mediasCol2: props.profile?.mediasCol2 || [],
        mediasCol3: props.profile?.mediasCol3 || [],
      },{withCredentials: true});
  
      if(fetched.data.status !== "ok") {
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      setDisableSave(true);
      return notification(appContextV2.dispatchPanels, "Mise à jour effectuée", "success");
      
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Contenu de la page entreprise</h1>
    
    <p className={"text--grey text--center container"}>
      Construisez votre page entreprise en drag-and-drop.<br/>
      (La forme et l'emplacement des trois premières image est fixe)
    </p>
    
    <Spaces n={0}/>
    
    <section className={"profile-editor-V2 hide-on-smartphones"}>
      
      {/*------------- 3 first pictures on top of the page ------------------------------ */}
      <article className={"profile-editor-V2__header"}>
        
        <section className={"profile-editor-V2__header__element-right"}>
          
          {/*---------- Pic 1 -----------------------*/}
          <article className={"profile-editor-V2__header__element1"} onDragOver={(e) => {e.preventDefault();}}
                   onDrop={() => {
                     notification(appContextV2.dispatchPanels, "Action interdite", "warning");
                   }}>
            {props.profile?.media1?.url && <img src={props.profile.media1.url} className={"element-content--image"}/>}
            <AddImageToHeaderElements updateProfileFront={props.updateProfileFront} positionKey={"media1"}/>
          </article>
        </section>
        
        <section className={"profile-editor-V2__header__elements-left"}>
          
          {/*---------- Pic 2 -----------------------*/}
          <article className={"profile-editor-V2__header__element2"} onDragOver={(e) => {e.preventDefault();}}
                   onDrop={() => {
                     notification(appContextV2.dispatchPanels, "Action interdite", "warning");
                   }}>
            {props.profile?.media2?.url && <img src={props.profile.media2.url} className={"element-content--image"}/>}
            <AddImageToHeaderElements updateProfileFront={props.updateProfileFront} positionKey={"media2"}/>
          </article>
          
          {/*---------- Pic 3 -----------------------*/}
          <article className={"profile-editor-V2__header__element3"} onDragOver={(e) => {e.preventDefault();}}
                   onDrop={() => {
                     notification(appContextV2.dispatchPanels, "Action interdite", "warning");
                   }}>
            {props.profile?.media3?.url && <img src={props.profile.media3.url} className={"element-content--image"}/>}
            <AddImageToHeaderElements updateProfileFront={props.updateProfileFront} positionKey={"media3"}/>
          </article>
        </section>
      
      </article>
      
      {/*------------- 3 columns -------------------------------- */}
      <article className={"profile-editor-V2__columns"}>
        
        {/*------------- Column 1 ----------------------------------------------------- */}
        <section className={"profile-editor-V2__columns__column"}>
          
          {props.profile.mediasCol1?.map((item: any, key: number) =>
            (<ProfileEditorColumnElementV2 item={item} key={key} positionKey={"column1"}
                                           drop={drop} drag={drag}
                                           updateProfileFront={props.updateProfileFront}/>))}
          
          {props.profile.mediasCol1?.length < 12 && <LastElementInColumn dropOnLast={dropOnLast} updateProfileFront={props.updateProfileFront} positionKey={"column1"}/>}
        
        </section>
        
        {/*------------- Column 2 ----------------------------------------------------- */}
        <section className={"profile-editor-V2__columns__column"}>
          
          {props.profile.mediasCol2?.map((item: any, key: number) =>
            (<ProfileEditorColumnElementV2 item={item} key={key} positionKey={"column2"}
                                           drop={drop} drag={drag}
                                           updateProfileFront={props.updateProfileFront}/>))}
          
          {props.profile.mediasCol2?.length < 12 && <LastElementInColumn dropOnLast={dropOnLast} updateProfileFront={props.updateProfileFront} positionKey={"column2"}/>}
        
        </section>
        
        {/*------------- Column 3 ----------------------------------------------------- */}
        <section className={"profile-editor-V2__columns__column"}>
          
          {props.profile.mediasCol3?.map((item: any, key: number) =>
            (<ProfileEditorColumnElementV2 item={item} key={key} positionKey={"column3"}
                                           drop={drop} drag={drag}
                                           updateProfileFront={props.updateProfileFront}/>))}
          
          {props.profile.mediasCol3?.length < 12 && <LastElementInColumn dropOnLast={dropOnLast} updateProfileFront={props.updateProfileFront} positionKey={"column3"}/>}
        
        </section>
      
      </article>
      
      <div className={"text--center"}>
        <button className={"btn-main"} disabled={disableSave} onClick={() => saveNewDisposition()}>
          Sauvegarder la disposition
        </button>
      </div>
    
    </section>
    
    {/*-------------- Fallback for smartophones*/}
    <div className={"hide-on-computers"}>
      L'éditeur de profil n'est pas disponible sur portable
    </div>
  
  </div>);
  
}
