import {IAppV2Session} from "./IAppV2";

export {initialSession};

const initialSession: IAppV2Session = {
  
  app: {
    mode: '',
    appStatus: 'pending',
    space: 'public',
    allowSignup: true,
    language: 'FR',
    displayTutorial: false,

  },
  
  session: {
    firstName: "",
    lastName: "",
    trigram: "",
    userAvatar: "",
    ban: "",
    profileLink: {
      name: "",
      number: 0,
    },
    userStatus: "",
    userRole: "",
    isEmailVerified: true,
    isListeningToMarket: false,
    displayCookies:false,
  },
  sessionBusiness: {
    businessName: "",
    openedMissions: 0,
    creditForSms: 0,
    isRecruitingAgency: false,
    profileLink: {
      name: "",
      number: 0,
    },
    plan: 0,
    businessAvatar: "",
    teamId: "",
    teams: [],
    members: []
  }
  
}