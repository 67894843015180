import React, {useEffect, useState} from "react";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import Spaces from "../../../../Spaces";

import {pdfjs} from 'react-pdf';
import {devLog} from "../../../../../../../utils/devLog/devLog";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default (props: {
  fileUrl: string,
}) => {
  
  const [displayComponent, setDisplayComponent] = useState(true);
  
  useEffect(() => {
    setDisplayComponent(false);
    setTimeout(() => {
      setDisplayComponent(true);
    }, 250);
  }, [props.fileUrl]);
  
  devLog("Should display cv ", props.fileUrl);
  
  let fileType = "";
  if(props.fileUrl?.includes("pdf") || props.fileUrl?.includes("PDF")) {
    fileType = "pdf";
  }
  if(props.fileUrl?.includes("docx") || props.fileUrl?.includes("doc") || props.fileUrl?.includes("DOCX") || props.fileUrl?.includes("DOC")) {
    fileType = "doc";
  }
  if(props.fileUrl?.includes("jpg") || props.fileUrl?.includes("jpeg") || props.fileUrl?.includes("png")) {
    fileType = "img";
  }
  
  const isFileTypeIdentified = ["pdf", "doc", "img"].includes(fileType);
  
  if(!props.fileUrl) return (<>Pas de CV</>);
  
  if(props.fileUrl && !isFileTypeIdentified) return (<>Le fichier n'est pas reconu. <a className={"modern-link"}
                                                                      href={props.fileUrl}>Télécharger</a> </>);
  
  if(!props.fileUrl || !displayComponent) return (<></>);
  
  return (<>
    <div className={"content-displayer"}>
      {fileType === "pdf" && <>
        <PDFViewer0 fileUrl={props.fileUrl}/>
      </>}
      
      {fileType === "doc" && <>
        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${props.fileUrl}`} width='100%' height='700px'
                frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft
          Office</a> document, powered by <a
          target='_blank' href='http://office.com/webapps'>Office Online</a>.
        </iframe>
      </>}
      
      {fileType === "img" && <>
        <img src={props.fileUrl} className={"content-displayer__img"} alt={"CV"}/>
      </>}
    </div>
    <div className={"text--center"}><a className={"modern-link text--minified2x"} href={props.fileUrl}>Télécharger</a></div>
  </>);
  
}

class PDFViewer0 extends React.Component<any, any> {
  
  ticker = setTimeout(() => {}, 100000000);
  
  constructor(props: any) {
    super(props);
    this.state = {
      hide: false,
      isLoaded: false,
    }
  }
  
  componentDidMount() {
    this.setState({
      hide: false,
      isLoaded: false,
    });
    this.ticker = setInterval(this.updatePdf, 13_000);
  }
  
  componentWillUnmount() {
    clearInterval(this.ticker);
    this.setState({
      hide: false,
      isLoaded: false,
    });
  }
  
  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
  }
  
  updatePdf = () => {
    this.setState({
      hide: true,
      isLoaded: false,
    }, () => {
      this.setState({
        hide: false,
        isLoaded: false,
      });
    });
  }
  
  documentLoaded = () => {
    clearInterval(this.ticker);
    this.setState({
      hide: false,
      isLoaded: true,
    });
  }
  
  render() {
    
    if(this.state.hide) {
      return (<></>);
    }
    
    return (<>
      
      {
        !this.state.isLoaded && <div>
          <Spaces n={2}/>
          <LoadingStyle2/>
        </div>
      }
      
      <iframe id={"iframePdf"}
              onLoad={() => this.documentLoaded()}
              width={"100%"}
              frameBorder={"10px"}
              height={"700px"}
              src={`https://docs.google.com/viewer?url=${this.props.fileUrl}&embedded=true`}>
      </iframe>
    
    
    </>);
  }
  
}

//
// class PDFViewer extends React.Component<any, any> {
//
//   private readonly viewerRef: any;
//   private backend: any;
//
//   constructor(props: any) {
//     super(props);
//
//     this.viewerRef = React.createRef();
//     // this.backend = new props.backend();
//   }
//
//   componentDidMount() {
//     const {src} = this.props;
//     const element = this.viewerRef.current;
//     this.init(src, element);
//   }
//
//   init = async (source: any, element: any) => {
//
//     // https://ms-medias-test.s3.eu-west-3.amazonaws.com/cv-1603191171990.pdf
//
//     const file = await fetch(source);
//
//     alert(file.blob());
//
//
//     //
//     // then(async(r: any) => {
//     //   let urlBlob = "";
//     //   urlBlob = await r.blob();
//     //   console.log(urlBlob);
//     //   const iframe = document.createElement('iframe');
//     //   iframe.src = `/pdfjs/web/viewer.html?file=${urlBlob}`;
//     //   iframe.width = '100%';
//     //   iframe.height = '100%';
//     //   element.appendChild(iframe);
//     // });
//
//
//   }
//
//   render() {
//     return (
//       <div ref={this.viewerRef} id='viewer' style={{width: '100%', height: '700px'}}>
//
//       </div>
//     );
//   }
// }
//
// function PDFViewer2(props: any) {
//
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);
//
//   function onDocumentLoadSuccess({ numPages }: any) {
//     setNumPages(numPages);
//   }
//
//   return(<>
//     <Document
//       file={{
//         url: props.fileUrl,
//         withCredentials: true,
//         httpHeaders: {
//
//         }
//       }}
//       onLoadSuccess={onDocumentLoadSuccess}
//     >
//       <Page pageNumber={pageNumber}
//       />
//     </Document>
//     <p>Page {pageNumber} of {numPages}</p>
//   </>);
//
//
//
// }