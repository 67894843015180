import React, {useContext} from "react";
import AppContext, {IAppContext} from "../../../componentsApp/context/AppContext";


function LinkedInIcon(props: any) {
  
  return (<>
    <div className={"btn-social--linkedin"}><img src={"https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/btn-linkedin.svg"}/></div>
  </>);
}

export default LinkedInIcon;