import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {useForm} from "use-formidable";
import {useWizard} from "use-wizard";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import axios from "axios";

export {WindowManageTeam};

const WindowManageTeam = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const teamId: string = appContextV2.appPanels.window.windowOptions.data?.teamId;
  const size: number = appContextV2.appPanels.window.windowOptions.data?.size;
  const baseName: string = appContextV2.appPanels.window.windowOptions.data?.teamName;
  
  const [step, wizard] = useWizard(["entry", "warningDeletion", "pending"]);
  
  const [form, updateForm, formidable] = useForm({name: appContextV2.appPanels.window.windowOptions.data?.teamName || ""});
  
  const deleteTeam = () => {
    (async()=>{
      try {
  
        notification(appContextV2.dispatchPanels, "Mise à jour de la liste d'équipes en cours", "information");
        wizard.goToStep('pending');
  
        const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/ent/t/delete`, {
          teamId: teamId,
        }, {withCredentials: true});
  
        if(fetched.data.status !== "ok") {
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
  
        appContextV2.appPanels.window?.windowOptions?.data?.deleteTeamFront(teamId);
        notification(appContextV2.dispatchPanels, "Suppression réussie", "success");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
  
      } catch(error) {
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }
    })();
  }
  
  const updateTeam = () => {
    (async()=>{
      try {
        
        notification(appContextV2.dispatchPanels, "Mise à jour de l'équipe en cours", "information");
        wizard.goToStep('pending');
  
        const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/ent/t/manage`, {
          teamId: teamId,
          name: form.name,
          description: form.description,
        }, {withCredentials: true});

        if(fetched.data.status !== "ok") {
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
  
        appContextV2.appPanels.window?.windowOptions?.data?.manageTeamFront(teamId, form.name);
        notification(appContextV2.dispatchPanels, "Mise à jour réussie", "success");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
  
      } catch(error) {
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }
    })();
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  if(step === "warningDeletion") {
    return (<WindowWrapper windowTitle={""} status={"ok"}>
      <div className={"text--center"}>
        Es-tu sûr(e) de vouloir supprimer l'équipe '{form.name}' ?
      </div>
      <Spaces n={1}/>
      <div className={"text--center"}>
        <button className={"btn-main"} onClick={() => {
          wizard.goToStep("entry");
        }}>
          Annuler
        </button>
        <button className={"btn-main"} onClick={()=>deleteTeam()}>
          Supprimer
        </button>
      </div>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center"}>
      Nom de l'équipe: <br/>
      <input type={"text"} name={"name"} value={form.name} onChange={updateForm({maximumLength: 30})}/>
    </div>
  
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      Taille de l'équipe: {size}
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button disabled={size !== 0} className={"btn-main"} onClick={() => {
        wizard.goToStep("warningDeletion");
      }} title={size === 0 ? "Supprime cette équipe" : "Pour supprimer cette équipe, il faut d'abord en retirer les membres"}>
        Supprimer
      </button>
      <button className={"btn-main"} title={"Enregistre tes modifications"} disabled={form.name === baseName} onClick={()=>updateTeam()}>
        Modifier
      </button>
    </div>
  </WindowWrapper>);
}