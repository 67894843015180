import React from "react";
import {useForm} from "use-formidable";

export {HeaderBan};

const HeaderBan = (props: {
  src: string,
  userQuality: string,
  isEditorOn: boolean
}) => {
  
  return(<>
  
    {!!props.src && <img className={"page-layout__header-background"} src={props.src} />}
  
    {!props.src && <div className={"page-layout__header-background--empty"}>
    </div>}

  </>);
}