import React, {useContext} from "react";
import {capitalizeSentence} from "../../../../utils/converters";
import getContract from "../../../../utils/getContract";
import getExperience from "../../../../utils/getExperience";
import getStarting from "../../../../utils/getStarting";
import getSalary from "../../../../utils/getSalary";
import {getLocation} from "../../../../utils/getLocation";
import {ProfileElementBoolean} from "../../../sharedComponents/layouts/ProfileElementBoolean";
import Spaces from "../../../sharedComponents/components/Spaces";
import EditorJobDesc from "./EditorJobDesc";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const updateBoolean = (nameToUpdateFront: string, nameToUpdateBack: string, value: boolean) => {
    props.setForm()({target: {type: "custom", name: nameToUpdateBack, value: value}});
  }
  
  const updateKeyValuePair = (keyToUpdate: string, value: boolean) => {
    props.setForm()({target: {type: "custom", name: keyToUpdate, value: value}});
  };
  
  return (<div className={"container white-container"}>
    
    <h1 className={"profile-edit__title"}>Détails du poste</h1>
    
    <EditorSumUpElement title={"Titre du poste"}
                        value={`${capitalizeSentence(props.form.positionWanted)} F/H`}
                        slug={"positionWanted"}
                        mission={{...props.form}}
                        modifier={updateKeyValuePair}
    />
    
    <EditorSumUpElement title={"Contrat"}
                        value={`${getContract(props.form.contract)}`}
                        slug={"contract"}
                        mission={{...props.form}}
                        modifier={updateKeyValuePair}
    />
    
    {
      props.form.contract !== "FREELANCE" && <ProfileElementBoolean text={"Le poste est-il à temps plein ?"}
                                                                    nameToUpdateBack={"isJobFullTime"}
                                                                    nameToUpdateFront={"isJobFullTime"}
                                                                    description={``}
                                                                    value={props.form.isJobFullTime}
                                                                    updateData={updateBoolean}
      />
    }
    
    <EditorSumUpElement title={"Expérience requise"}
                        value={`${getExperience(props.form.yearsOfExperience)}`}
                        slug={"yearsOfExperience"}
                        mission={{...props.form}}
                        modifier={updateKeyValuePair}
    />
    
    <EditorSumUpElement title={"Prise de poste"}
                        value={`${getStarting(
                          props.form.from,
                          props.form.asSoonAsPossible,
                          props.form.duration,
                          props.form.permanent)}`}
                        slug={"starting"}
                        mission={{...props.form}}
                        modifier={updateKeyValuePair}
    />
    
    <EditorSumUpElement title={"Salaire proposé"}
                        value={`${getSalary(
                          props.form.salaryMin,
                          props.form.salaryMax,
                          props.form.salaryBy,
                          props.form.salaryCurrency)}`}
                        slug={"salary"}
                        mission={{...props.form}}
                        modifier={updateKeyValuePair}
    />
    
    <EditorSumUpElement title={"Lieu du poste"}
                        value={`${getLocation(
                          props.form.remote,
                          props.form.roaming,
                          props.form.roamingPlace,
                          props.form.location,
                        )}`}
                        slug={"location"}
                        mission={{...props.form}}
                        modifier={updateKeyValuePair}
    />
    
    {/*<ProfileElementBoolean text={"Le recrutement se fait-il sans CV ?"}*/}
    {/*                       nameToUpdateBack={"withoutCv"}*/}
    {/*                       nameToUpdateFront={"withoutCv"}*/}
    {/*                       description={``}*/}
    {/*                       value={props.form.withoutCv}*/}
    {/*                       updateData={updateBoolean}*/}
    {/*/>*/}
    
    {/*{*/}
    {/*  !props.form.withoutCv && <ProfileElementBoolean text={"Le candidat est-il obligé de poster un CV pour postuler ?"}*/}
    {/*                                                  nameToUpdateBack={"cvMandatory"}*/}
    {/*                                                  nameToUpdateFront={"cvMandatory"}*/}
    {/*                                                  description={``}*/}
    {/*                                                  value={props.form.cvMandatory}*/}
    {/*                                                  updateData={updateBoolean}*/}
    {/*  />*/}
    {/*}*/}
  
    <ProfileElementBoolean text={"Un CV est-il indispensable pour postuler ?"}
                           nameToUpdateBack={"cvMandatory"}
                           nameToUpdateFront={"cvMandatory"}
                           description={``}
                           value={props.form.cvMandatory}
                           updateData={updateBoolean}
    />

    <ProfileElementBoolean text={"Un profil linkedin est-il obligatoire pour postuler ?"}
                           nameToUpdateBack={"linkedinMandatory"}
                           nameToUpdateFront={"linkedinMandatory"}
                           description={``}
                           value={props.form.linkedinMandatory}
                           updateData={updateBoolean}
    />
    
    {/*-------------------- SKILLS*/}
    <div className={"profile-element__container animation-fade-in"}>
      <div>
        <div>
          Compétences nécéssaires: <span className={"text--new-blue"}>{props.value}</span>
        </div>
        {
          props.form.skills?.length !== 0 && <div>
            {props.form.skills?.map((item: any, key: number) => (<div key={key}>
              - <span className={"text--new-blue"}>{item.skill}</span>
            </div>))}
          </div>
        }
        {
          props.form.skills?.length === 0 && <div>
            - Aucune compétence
          </div>
        }
      </div>
      <button className={"btn--grey-art"} onClick={() => {
        appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
            type: "updateMissionField",
            data: {
              fieldToUpdate: "skills", // Generic name, can concern many values
              modifier: updateKeyValuePair, // Function to update front
              mission: props.form, // Mission profile
            }
          }})
      }}>
        Modifier
      </button>
    </div>
    
    {/*------------------------ ADVANTAGES */}
    <div className={"profile-element__container animation-fade-in"}>
      <div>
        <div>
          Avantages de l'entreprise: <span className={"text--new-blue"}>{props.value}</span>
        </div>
        {
          props.form.advantages?.length !== 0 && <div>
            {props.form.advantages?.map((item: any, key: number) => (<div key={key}>
              - <span className={"text--new-blue"}>{item}</span>
            </div>))}
          </div>
        }
        {
          props.form.advantages?.length === 0 && <div>
            - Aucun avantage
          </div>
        }
      </div>
      <button className={"btn--grey-art"} onClick={() => {
        appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
            type: "updateMissionField",
            data: {
              fieldToUpdate: "advantages", // Generic name, can concern many values
              modifier: updateKeyValuePair, // Function to update front
              mission: props.form, // Mission profile
            }
          }})
      }}>
        Modifier
      </button>
    </div>
    
    <EditorSumUpElement title={"Description du poste"}
                        value={props.form.description}
                        mission={props.form}
                        slug={"description"}
                        modifier={updateKeyValuePair}
    />
  
    <div className={"profile-element__container animation-fade-in"}>
      <div className={"full-width"}>
        Fiche de poste:
      <br/>
        <EditorJobDesc {...props} setJobDesc={props.setJobDesc}/>
      </div>
    </div>
  
  </div>);
}

function EditorSumUpElement(props: {
  title: string,
  value: string,
  slug: string,
  modifier: Function,
  mission: any,
}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<div className={"profile-element__container animation-fade-in"}>
    <div>
      {props.title}: <span className={"text--new-blue"}>{props.value}</span>
    </div>
    <button className={"btn--grey-art"} onClick={() => {
      appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
          type: "updateMissionField",
          data: {
            fieldToUpdate: props.slug, // Generic name, can concern many values
            modifier: props.modifier, // Function to update front
            mission: props.mission, // Mission profile
          }
        }})
    }}>
      Modifier
    </button>
  </div>);
}