import Spaces from "../../../../../components/Spaces";
import SubPageLayout from "../../../../../layouts/SubPageLayout";
import React from "react";

export default (props: {
  wizard2: any;
  subPage: string;
}) => {
  
  return(<>
  
    <SubPageLayout status={"ok"} subPage={props.subPage}>
    
      <Spaces n={2}/>
      <div className={"assistant--v2-title"}>
        Vous avez presque terminé !
      </div>
      <Spaces n={0}/>
      <div className={"text--grey text--center"}>
        Entrer vos informations de contact est nécéssaire pour être contacté par le recruteur
      </div>
      <Spaces n={2}/>
      <div className={"text--center"}>
        <button className={"btn-main"} onClick={() => props.wizard2.goToStep("direct")}>Postuler sans LinkedIn</button>
        <button className={"btn-main btn-main--linkedin"} onClick={() => props.wizard2.goToStep("linkedin")}>Postuler
          avec LinkedIn
        </button>
      </div>
    </SubPageLayout>
  </>);
}
