import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import Spaces from "../../../../../components/Spaces";

export default (props: any) => {
    
    const [showQuestion, setShowQuestion] = useState(false);

    useEffect(() => {
        (() => {
            setShowQuestion (props.mission.defaultQuestions?.salaryWanted);
            if(!props.mission.defaultQuestions?.salaryWanted){
                props.nextStep();
            }
        })();
    }, []);

    if(!showQuestion) {
        return (<><Spaces n={15}/></>)
    }

    return (<div>

        <Spaces n={2}/>

        <div className={"assistant--v2-title"}>
            Quelles sont vos prétentions en terme de salaire annuel brut ?
        </div>
        <Spaces n={0}/>
        <div className={"text--grey text--center"}>
            En euros
        </div>
        <Spaces n={2}/>

        <section className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
            <input type={"number"} className={"assistant--v2-input assistant--v2-input--full-width"} value={props.answer.defaultAnswers.salaryWanted}
               onChange={(e)=> {
                   props.updateAnswer()({
                       target: {
                           type: "custom",
                           name: "defaultAnswers.salaryWanted",
                           value: e.target.value
                       }
                   });
               }}  />
        <Spaces n={2}/>
        <button  className={"btn-main"} disabled={!props.answer.defaultAnswers.salaryWanted}
                 onClick={() => {  props.nextStep();}}>
            Valider ma réponse
        </button>
        </section>

    </div>);
}