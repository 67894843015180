import React, {useContext, useState} from "react";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../components/Spaces";
import {format} from "date-fns"

export default (props: {
  candidateToEvaluate: any;
  
}) => {
  
  return (<section className={"candidate-evaluate__container "}>
    
    <h1 className={"profile-edit__title"}>Mon évaluation</h1>
    <Spaces n={1}/>
    
    {props.candidateToEvaluate?.evaluationDateOfCreation && <>
      <div className={"text--grey text--center"}> Évaluation faite
        le: {format(new Date(props.candidateToEvaluate.evaluationDateOfCreation), "dd-MM-yyyy")}</div>
      <Spaces n={0}/>
    </>}
    
    {
      props.candidateToEvaluate?.appreciation && <>
        <div className={"text--center"}>
          {(props.candidateToEvaluate.appreciation === "good" || props.candidateToEvaluate.appreciation ==5) &&
          <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-5.png"}/>}
          {(props.candidateToEvaluate.appreciation ==4) &&
          <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-4.png"}/>}
          {(props.candidateToEvaluate.appreciation === "neutral" || props.candidateToEvaluate.appreciation == 3)&&
          <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-3.png"}/>}
          {(props.candidateToEvaluate.appreciation ==2) &&
          <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-2.png"}/>}
          {(props.candidateToEvaluate.appreciation === "bad" || props.candidateToEvaluate.appreciation == 1)&&
          <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-1.png"}/>}
        </div>

        <Spaces n={0}/>
      </>
    }
    
    {
      props.candidateToEvaluate?.comment && <div className={"text--center"}> {props.candidateToEvaluate.comment}</div>
    }
  
  </section>);
  
}