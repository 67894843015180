import React from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const isButtonActive: boolean = !!props.form.roamingPlace;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Précise le ou les lieux du poste *
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
      
      <input type={"text"} className={"assistant--v2-input"} name={"roamingPlace"}
             value={props.form.roamingPlace} onChange={props.updateForm({maximumLength: 120})}/>
      
      <Spaces n={1}/>
      
      <button className={"btn-assistant-main"}
              disabled={!isButtonActive}
              onClick={async() => {
                props.wizard.goToStep("lastAssistantStep");
              }}>
        Suivant
      </button>
    
    </div>
  
    <Spaces n={1}/>
  
    <div className={"text--center text--grey assistant--v2-container--no-flex"}>
      * L'annonce concerne plusieurs lieux fixes ? Le poste demande-t-il une mobilité particulière nationale ou internationale ?
    </div>
  
  </section>);
}