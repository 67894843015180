import React, {useContext} from "react";
import AppContext, {IAppContext} from "../../componentsApp/context/AppContext";


export default function SwitchLanguage(props: any) {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (
    <>
      <span className={"navbar__items__languages"}>
        {/*<button*/}
        {/*  className={`navbar__items__languages__language btn--hollow ${appContext.appState.language === 'FR' && 'navbar__items__languages__language--selected'}`}*/}
        {/*  disabled={appContext.appState.language === 'FR'}*/}
        {/*  onClick={() => appContext.setSessionParameter('language', 'FR')}>*/}
        {/*  FR*/}
        {/*</button> | <button*/}
        {/*  className={`navbar__items__languages__language btn--hollow ${appContext.appState.language === 'EN' && 'navbar__items__languages__language--selected'}`}*/}
        {/*  disabled={appContext.appState.language === 'EN'}*/}
        {/*  onClick={() => appContext.setSessionParameter('language', 'EN')}>*/}
        {/*      EN*/}
        {/*</button>*/}
      </span>
    </>
  );
}