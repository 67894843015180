import WindowWrapper from "../../../layouts/WindowLayout";
import React, {useContext, useState} from "react";
import {useForm} from "use-formidable";
import Spaces from "../../Spaces";
import {useWizard} from "use-wizard";
import {devLog} from "../../../../../utils/devLog/devLog";
import axios from "axios";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";

export {WindowRepportQuestion};

const WindowRepportQuestion = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [form, updateForm, formidable] = useForm({
    message: ""
  });
  
  const [isSending, setIsSending] = useState(false);
  
  const repportQuestion = async() => {
    try {
  
      setIsSending(true);
      
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/ent/mfc/report/question`, {
        missionId: appContextV2.appPanels.window.windowOptions.data.missionId || '',
        question: appContextV2.appPanels.window.windowOptions.data.question || '',
        message: form.message || '',
      }, {withCredentials: true});

      if(fetched.data.status !== "ok") {
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        return notification(appContextV2.dispatchPanels, "Erreur (vous pouvez contacter l'éauipe)", "error");
      }
      
      notification(appContextV2.dispatchPanels, "Merci de ton retour, nos équipes ont été averties", "success");

      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Erreur (vous pouvez contacter l'éauipe)", "error");
      setIsSending(false);
      devLog(error);
    }
  }
  
  if(isSending) {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center text--magnified2x"}>Pourquoi désire-tu repporter cette question ?</div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <input type={"text"} name={"message"} onChange={updateForm({maximumLength: 300})}/>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button disabled={isSending} className={"btn-main"} onClick={() => repportQuestion()}>
        Repporter
      </button>
    </div>
  
  </WindowWrapper>);
}