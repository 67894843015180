import React from "react";
import Spaces from "../../sharedComponents/components/Spaces";

export default (props: any) => {
  
  return(<div className={"landing-page--v2-bg--shape4"}>
  
    <Spaces n={3}/>
    
    <div className={"container-lp"}>
    
    <section className={"landing-page--v2__side-by-side"}>
      
      <article className={"landing-page--v2__side-by-side__box landing-page--v2__side-by-side__box2"}>
        <h2 className={"landing-page--v2__title"}>
          Créez des entités autonomes au sein de vos équipes
        </h2>
        <Spaces n={0}/>
        <div className={"landing-page--v2__text"}>
          Myshortlist.co vous permet de monter des équipes au sein d'un même compte. Chaque équipe reste concentrée sur ses propres postes.
        </div>
      </article>
  
      <article className={"landing-page--v2__side-by-side__box landing-page--v2__side-by-side__box1"}>
        <div className={"landing-page--v2__video-container landing-page--v2__video-container--grey"}>
          <video width="100%" height="100%" playsInline={true} controls={false} autoPlay={true} loop={true} muted={true}>
            <source src="https://static.myshortlist.co/videos/v5_teams.webm" type="video/webm" />
            <source src="https://static.myshortlist.co/videos/v5_teams.mp4" type="video/mp4" />
            <source src="https://static.myshortlist.co/videos/v5_teams.ogg" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </article>
    
    </section>
  
  </div>
  
    <Spaces n={3}/>
  </div>);
}