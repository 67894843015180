import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import CloseButton from "../../Buttons/CloseButton";
import {IAppV2Notifications} from "../../../../componentsApp/state/IAppV2";
import DisplayNotifications from "./DisplayNotificationSwitch";
import Spaces from "../../Spaces";

export function NotificationButton(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  async function seenNotifications() {
    try {
      const answer = await axios.put(`${process.env.REACT_APP_API_URL}/notifications/seen`, {
        notifications: appContextV2.appNotifications,
      }, {withCredentials: true});
      appContextV2.dispatchNotifications(
        {type: 'UPDATE_SEEN_ALL', value: null}
      )
    } catch(error) {
      devLog(error);
    }
  }
  
  const displayNotifications = appContextV2.appNotifications?.recruiter?.notifications?.length !== 0;
  const newNotifications = appContextV2.appNotifications?.recruiter?.notifications?.filter((item: any) => (item.seenByMe === false && item.actionType !== "newCandidacy"));
  
  return (<div className={"navbar__notifications-container"}>
      
      <button className={"navbar__notifications-container__bell"} title={displayNotifications ? "" : "Aucune notification"}>
        <img src={"https://static.myshortlist.co/bell.svg"}/>
        {newNotifications.length !== 0 &&
        <div className={"navbar__notifications-container__bell__red-dot"}> {newNotifications.length}</div>}
      </button>
      
      {
        displayNotifications && <div className={"navbar__notifications-container__window"}>
          <div className={"navbar__notifications__title"}>Notifications <button
            className={"navbar__notifications__button"} onClick={() => seenNotifications()}>Tout marquer comme
            lus</button>
          </div>
          <div className={"navbar__notifications-container__window__notifications"}>
            {appContextV2.appNotifications?.recruiter?.notifications.map((item: any) => (
              <DisplayNotifications item={item}/>))}
          </div>
        </div>
      }
    
    </div>
  );
}