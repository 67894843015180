import AppContext, {IAppContext} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import {Link, withRouter} from "react-router-dom";
import MenuDropDownLogout from "./MenuDropDownLogout";
import DropDownLinkGenerator from "./shared/DropDownLinkGenerator";


export default function MenuRecruiterDropDownContent(props: any) {
  
  return (<>
      <DropDownLinkGenerator uri={"/business/profiles"} active={true} fr={"Profils"} en={"Profiles"} />
      {/*<DropDownLinkGenerator uri={"/business/calendar"} active={true} fr={"Calendrier"} en={"Calendar"} />*/}
      <DropDownLinkGenerator uri={"/business/parameters"} active={true} fr={"Paramètres"} en={"Parameters"} />
      <MenuDropDownLogout />
    </>
  );
}
