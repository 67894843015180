import React, {useContext, useEffect, useState} from "react";
import Spaces from "../sharedComponents/components/Spaces";
import {ButtonBigPlusV2} from "../sharedComponents/components/Buttons/ButtonBigPlus";
import PageLayoutWithHeader from "../sharedComponents/layouts/PageLayoutWithHeader";
import AppContext, {IAppV2Context} from "../componentsApp/context/AppContext";
import fetchAmbassadors from "./componentsRecruiterAmbassadors/dependenciesRecruiterAmbasssadors/fetch/fetchAmbassadors";
import {devLog} from "../../utils/devLog/devLog";
import {RecruiterAmbassadorsEmpty} from "./componentsRecruiterAmbassadors/RecruiterAmbassadorsEmpty";
import ambassadorAddFront from "./componentsRecruiterAmbassadors/dependenciesRecruiterAmbasssadors/updateFront/ambassadorAddFront";
import ambassadorRemoveFront from "./componentsRecruiterAmbassadors/dependenciesRecruiterAmbasssadors/updateFront/ambassadorRemoveFront";
import ambassadorUpdateFront from "./componentsRecruiterAmbassadors/dependenciesRecruiterAmbasssadors/updateFront/ambassadorUpdateFront";
import {RecruiterAmbassador} from "./componentsRecruiterAmbassadors/RecruiterAmbassador";

export {RecruiterAmbassadors}

/**
 * --------------------------------------
 * Display and manage ambassadors
 * Primary-level page
 * --------------------------------------
 */
const RecruiterAmbassadors = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  /** Downloaded ambassadors for a specific team and fetch status */
  const [ambassadors, setAmbassadors]: [any, any] = useState([]);
  const [fetchStatusAmbassador, setFetchStatusAmbassadors]: [any, any] = useState("asleep");
  
  /** Initial ambassadors-fetch (of the current team only) */
  useEffect(() => {fetchAmbassadors(setFetchStatusAmbassadors, setAmbassadors)}, []);
  devLog(ambassadors);
  
  /** Front update */
  const addFront = ambassadorAddFront(setAmbassadors);
  const removeAmbassadorFront = ambassadorRemoveFront(setAmbassadors);
  const updateAmbassadorFront = ambassadorUpdateFront(setAmbassadors);
  
  return (<PageLayoutWithHeader grey={true} title={"Ambassadeurs"} small={true} fullWidth={true} page={props.page} status={fetchStatusAmbassador}>
    
    {/*<Spaces n={2}/>*/}
    
    {ambassadors?.length === 0 && <RecruiterAmbassadorsEmpty/>}
    
    {ambassadors?.length !== 0 && <section className={"ambassadors--v2"}>
      {ambassadors?.map((item: any, key: number) => <RecruiterAmbassador key={key}
                                                                         item={item}
                                                                         removeAmbassadorFront={removeAmbassadorFront}
                                                                         updateAmbassadorFront={updateAmbassadorFront}/>)}
    </section>}
    
    <Spaces n={2}/>
    
    {
      ambassadors.length < 30 && <p className={"space-flex--center"}>
        <ButtonBigPlusV2 cb={() => {
          appContextV2.dispatchPanels({
            type: "OPEN_WINDOW",
            value: {
              type: "addAmbassador",
              data: {
                addAmbassadorFront: addFront,
              }
            }
          });
        }} title={"Ajoute un ambassadeur"}/>
      </p>
    }
  
    <Spaces n={3}/>
  
  </PageLayoutWithHeader>);
}
