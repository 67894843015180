import React, {useContext, useState} from "react";
import Spaces from "../../../sharedComponents/components/Spaces";
import {shortenText} from "../../../../utils/shortenText";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";

export {QuestionPreset}

const QuestionPreset = (props: any) => {
  
  const [extended, setExtended] = useState(false);
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [deleteQuestion, setDeleteQuestion] = useState(false);
  
  function getImgKind(kind: 'freeText' | 'singleChoice' | 'multipleChoice') {
    switch(kind) {
      case 'freeText':
        return "https://static.myshortlist.co/btn-text.svg";
      case 'singleChoice':
        return "https://static.myshortlist.co/btn-radio.svg";
      case 'multipleChoice':
        return "https://static.myshortlist.co/btn-checkbox.svg";
    }
  }
  
  async function addTheQuestion(formId: any, question: any, positionWanted: any) {
    try {
      props.addQuestion(formId, question);
      notification(appContextV2.dispatchPanels, "Succès de l'import", "success");
      await axios.put(`${process.env.REACT_APP_API_URL}/dictionary/preset/import`, {
        questionId: question._id,
        positionWanted: positionWanted
      }, {
        withCredentials: true
      });
    } catch(error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
      throw (error);
    }
  }
  
  async function deleteTheQuestion(question: any) {
    try {
      setDeleteQuestion(false);
      await axios.put(`${process.env.REACT_APP_API_URL}/dictionary/preset/delete`, {
        questionId: question._id,
      }, {
        withCredentials: true
      });
      props.removePreset(question._id);
      notification(appContextV2.dispatchPanels, "Succès de la suppression", "success");
    } catch(error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
      throw (error);
    }
  }
  
  return (<div className={"question-preset"}>
    <div className={`q-p__header ${extended ? "q-p__header--selected" : ""}`}>
      <div className={" q-p__header__left"}>
        <img className={"img-type"} src={getImgKind(props.item?.question?.type)}/>
        { <button className={"btn--hollow"} disabled={props.item?.question?.type === "freeText"} onClick={() => {
          setExtended(prevState => (!prevState));
        }}>
          <img className={`text--hollow arrow-btn ${extended ? "arrow-btn--activated" : ""}`}
               src={"https://static.myshortlist.co/btn-arrow-list.svg"}
               alt={"toggle"}/>
        </button>}
        <span className={`${extended ? "text--grey" : ""}`}>
          {shortenText(props.item.question.question, 85)}
        </span>
      </div>
      <div className={"q-p__header__right"}>
        {!deleteQuestion &&
        <button className={"mission--V2__buttons__parameters"} onClick={() => setDeleteQuestion(true)}>
          <img src={"https://static.myshortlist.co/btn-bin.svg"} alt={"supprimer"}/>
        </button>}
        {deleteQuestion && <div className={"space-flex-left"}>
          <button className={"mission--V2__buttons__parameters"} onClick={() => deleteTheQuestion(props.item)}>
            <img src={"https://static.myshortlist.co/btn-check.svg"} alt={"supprimer"} title={"Supprimer"}/>
          </button>
          <button className={"mission--V2__buttons__parameters"} onClick={() => setDeleteQuestion(false)}>
            <img src={"https://static.myshortlist.co/btn-cross.svg"} alt={"annuler"} title={"Ne pas supprimer"}/>
          </button>
        </div>}
        <button className={"mission--V2__buttons__parameters mission--V2__buttons__parameters--small-pic"}
                onClick={() => addTheQuestion(props.formId, props.item.question, props.positionWanted)}
                title={"Importer la question"}>
          <img src={"https://static.myshortlist.co/btn-download.svg"}/>
        </button>
      </div>
    </div>
    {
      extended &&
      <div className={"q-p__answers"}>
        {props?.item?.question?.answers?.map((item: any, key: any) => (
          <div key={key} className={"q-p__answer animation-fade-in"}>
            <img className={"img-drag"}
                 src={"https://static.myshortlist.co/icon-drag.svg"}/> Réponse {key + 1}: {item.answer} </div>))}

        <Spaces n={0}/>
      </div>
    }
  </div>)
  
}