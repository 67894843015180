import React, {useContext, useEffect} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import FourHundredThree from "../pages/errors/FourHundredThree";
import ErrorPage from "../pages/errors/ErrorPage";
import {Loading} from "../components/Loading/Loading";
import {scrollUp} from "../../../utils/dom/scrollUp";
import Spaces from "../components/Spaces";

interface IPageLayoutProps {
  
  page: string,
  fullWidth?: boolean,
  status: 'pending' | 'error' | 'forbidden' | 'ok' | 'asleep' | 'fetching' | 'fetched',
  children: any,
  small?: boolean,
  verySmall?: boolean,
  grey?: boolean,
  title?: string,
}

export default function (props: IPageLayoutProps) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(() => {
    appContextV2.dispatchNavigation({type: "SET_PAGE", value: props.page});
    scrollUp();
  }, []);
  
  return (<section
    className={`page-layout ${!!props.grey ? "page-layout--grey" : ""} ${!!props.small ? "page-layout--small" : ""} ${props.fullWidth ? "" : "container"}`}>
    
    <div className={"animation-fade-in"}>
      
      {
        props.title && <div className={"text--center"}>
          <h1 className={"page-title"}>
            {props.title}
          </h1>
        </div>
      }
      
      {!props.verySmall && <Spaces n={1}/>}
      
      <PageLayoutContent {...props} children={props.children} />

    </div>
    
  </section>);
}

function PageLayoutContent(props: IPageLayoutProps) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  switch(appContextV2.appSession.session.userStatus) {
    
    case 'subscribed':
    
    case 'authorized':
      switch(props.status) {
        
        case 'asleep':
        case "fetching":
        case 'pending':
          return (<Loading fullPage={false}/>);
          
        case 'forbidden':
          return (<FourHundredThree page={"FourHundredThree"} fullPage={true} setPage={false}/>);
  
        case "fetched":
        case 'ok':
          return (<>
            <section className={"animation-fade-in"}>
              {props.children}
            </section>
          </>);
          
        default:
        case 'error':
          return (<ErrorPage page={"ErrorPage"} code={0} fullPage={true} setPage={false}/>);
      }
      
    case 'blocked':
      return (<>Votre compte a été temporairement bloqué</>);
      
    default:
      return (<ErrorPage page={"ErrorPage"} code={0} fullPage={true} setPage={false}/>);
  }
}
