import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import Spaces from "../../Spaces";


export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return(<>
    <Spaces n={1}/>
    <p className={"text--magnified15x text--center"}>
      L'espace candidat n'existe pas encore, mais il arrive bientôt !
    </p>
    <Spaces n={1}/>
  </>);
}
