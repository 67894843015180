
import React  from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import {AppV2} from "./components/AppV2";

ReactDOM.render(<AppV2/>, document.getElementById('root'));

serviceWorker.unregister();
