import Burger from "../../Buttons/Burger";
import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";

export {MenuDropDownSmartphones};

const MenuDropDownSmartphones = () => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<>
    {/*----- On smartphones */}
    <span className={"hide-on-computers"}>
        <Burger size={3} eventOnClick={() => {
          // appContext.toggleMenuFromBottomVisibility("open", {type: "menu", data: {}})
          appContextV2.dispatchPanels({
            type: "OPEN_MENU_FROM_BOTTOM", value: {
              type: "menu",
              data: {},
            }
          });
        }}/>
      </span>
  </>);
}