import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import {MenuDropDownCandidatePc, MenuDropDownPublicPc, MenuDropDownRecruiterPc} from "./NavBarDropDownPc";
import {MenuDropDownSmartphones} from "./MenuDropDownSmartphones";

export {MenuDropDown};

function MenuDropDown(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  switch(appContextV2.appSession.app.space) {
    
    case "recruiter":
      return (<>
        <MenuDropDownRecruiterPc/>
        <MenuDropDownSmartphones/>
        </>);
    
    case "candidate":
      return (<>
        <MenuDropDownCandidatePc/>
        <MenuDropDownSmartphones/>
      </>);
    
    case 'public':
      return (<>
        <MenuDropDownPublicPc/>
        <MenuDropDownSmartphones/>
      </>);
  
    default:
      return(<></>);
  }
}