import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import {isPhoneNumberValid} from "../../../../../../utils/typeGuards";
import {ButtonBack} from "../../../Buttons/ButtonBack";
import Spaces from "../../../Spaces";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language = appContextV2.appSession.app.language;
  
  const next = props.assistantContext.wizard.nextStep;
  const updateForm = props.assistantContext.updateForm;
  
      return (<>
        <ButtonBack back={props.assistantContext.wizard.previousStep}/>
  
        <Spaces n={0}/>
        
        <div className={"text--center text--magnified1x title--bold"}>
          {language === 'FR' && <>À propos de votre entreprise</>}
          {language !== 'FR' && <>About your business</>}
        </div>
        
        <p><br/><br/></p>
        
        <p className={"text--center"}>
          Quelle est la taille de votre entreprise ?
        </p>
        
        <p><br/><br/></p>
        
        <p className={"text--center"}>
          
          <button key={12}  className={"animation-assistant-appear btn-main space-light"} onClick={async() => {
            updateForm()({target: {value: "1", name: "business.businessSize", type: "custom" }});
            next();
          }}>
            {language === 'FR' && <>de 1 à 50</>}
            {language !== 'FR' && <>from 1 to 50</>}
          </button>
          
          <button key={13}  className={"animation-assistant-appear btn-main space-light"} onClick={async() => {
            updateForm()({target: {value: "51", name: "business.businessSize", type: "custom" }});
            next();
          }}>
            {language === 'FR' && <>de 51 à 700</>}
            {language !== 'FR' && <>from 51 to 700</>}
          </button>
          
          <button key={14}  className={"animation-assistant-appear btn-main space-light"} onClick={async() => {
            updateForm()({target: {value: "701", name: "business.businessSize", type: "custom" }});
            next();
          }}>
            {language === 'FR' && <>de 701 à 5000</>}
            {language !== 'FR' && <>from 701 to 5000</>}
          </button>
          
          <button key={15}  className={"animation-assistant-appear btn-main space-light"} onClick={async() => {
            updateForm()({target: {value: "5001", name: "business.businessSize", type: "custom" }});
            next();
          }}>
            {language === 'FR' && <>de 5001 à 10.000</>}
            {language !== 'FR' && <>from 5001 to 10.000</>}
          </button>
          
          <button key={16}  className={"animation-assistant-appear btn-main space-light"} onClick={async() => {
            updateForm()({target: {value: "10001", name: "business.businessSize", type: "custom" }});
            next();
          }}>
            {language === 'FR' && <>+ de 10.000</>}
            {language !== 'FR' && <>+ than 10.000</>}
          </button>
        </p>
        
        <p><br/><br/></p>
      
      </>);
    
}
