import {devLog} from "../../../../../../utils/devLog/devLog";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";

export default (args: {
  draggedType: string,
  displayedShortlist: any,
  dragged: any,
  fetchedDataV2: any,
  setIsLoading: any,
  frontUpdates: any,
  draggedFromColumnId: any,
  appContextV2: any,
}) => async (toColumnId: string) => {

  devLog("dropped ", toColumnId);

  try {

    /**
     * Normal Strategy for candidates who applied on Myshortlist
     */

    if (args.draggedType === "candidacy") {

      const candidateData = args.displayedShortlist.find((item: any) => (item._id === args.dragged));
      const fromColumnId = candidateData.kanbanColumn;
      const columnData = args.fetchedDataV2.missionProfile?.customKanbanView?.find((item: any) => (item._id === toColumnId));
      const isThereMailAction: boolean = columnData?.activateMail && columnData?.mailSubject && columnData.mailContent;
      const isThereSmsAction: boolean = columnData?.activateSms && columnData?.smsText;
      const isThereAskCvAction: boolean = columnData?.activateAskCv;
      const isThereNotifyAction: boolean = columnData?.activateNotify && columnData?.notifyEmails?.length !== 0;
      const wasCandidateAlreadyInPool: boolean = candidateData.kanbanColumnHistory?.includes(toColumnId);

      /** Case 0: candidate dragged on the same column: just do nothing */
      if (fromColumnId === toColumnId) {
        return;
      }

      /** Case -1: candidate dragged on column 10 or 1 */
      if (["1", "10"].includes(toColumnId)) {
        args.setIsLoading(true);
        // Update front
        args.frontUpdates.moveCandidacyFront(candidateData._id, toColumnId);
        // Update back
        const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/mat/cam/move/candidacy`, {
          missionId: args.appContextV2.appNavigation.missionId,
          toColumnId: toColumnId,
          shortCandidacyId: candidateData._id,
          fromColumnId: args.draggedFromColumnId,
          candidacyId: candidateData.candidacyId,
          candidateId: candidateData.candidateId,
          bypassActions: true,
          mailData: {},
          smsData: {},
        }, {
          withCredentials: true
        }));
        if (fetched.data.status !== "ok") {
          args.setIsLoading(false);
          return notification(args.appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        args.setIsLoading(false);
        return notification(args.appContextV2.dispatchPanels, "Candidature déplacée dans la pile", "success");
      }

      /**
       * Case 1: there is no mail or sms action on the column (i.e. no action or the action is poolTransfer), or candidate already was in this column
       */
      if (!isThereMailAction && !isThereSmsAction && !isThereAskCvAction && !isThereNotifyAction) {
        args.setIsLoading(true);
        // Update front
        args.frontUpdates.moveCandidacyFront(candidateData._id, toColumnId);
        // Update back
        const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/mat/cam/move/candidacy`, {
          missionId: args.appContextV2.appNavigation.missionId,
          toColumnId: toColumnId,
          shortCandidacyId: candidateData._id,
          fromColumnId: args.draggedFromColumnId,
          candidacyId: candidateData.candidacyId,
          candidateId: candidateData.candidateId,
          bypassActions: true,
          mailData: {},
          smsData: {},
        }, {
          withCredentials: true
        }));
        if (fetched.data.status !== "ok") {
          args.setIsLoading(false);
          return notification(args.appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        args.setIsLoading(false);
        return notification(args.appContextV2.dispatchPanels, "Candidature déplacée dans la pile", "success");
      }

      /**
       * Case 2: there is a mail action and / or an sms action on the column and candidate never was in it
       */
      if (isThereMailAction || isThereSmsAction || isThereAskCvAction || isThereNotifyAction) {
        // Open window
        args.appContextV2.dispatchPanels({
          type: "OPEN_WINDOW", value: {
            type: "kanbanActionAlert",
            data: {
              columnData,
              candidateData,
              fromColumnId,
              toColumnId,
              moveCandidacyFront: args.frontUpdates.moveCandidacyFront,
              missionId: args.appContextV2.appNavigation.missionId,
              setIsLoading: args.setIsLoading,
              wasCandidateAlreadyInPool:wasCandidateAlreadyInPool,
            }
          }
        });
        return;
      }
    }

    /**
     * Strategy for potential candidacies from indeed and other
     */
    if (args.draggedType === "potential") {

      // Update front
      args.frontUpdates.moveUnsubscribedCandidacyFront(args.dragged, toColumnId);

      // Update back
      const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/mat/cam/move/unsubscribe/candidacy`, {
        missionId: args.appContextV2.appNavigation.missionId,
        toColumnId: toColumnId,
        candidacyId: args.dragged,
      }, {
        withCredentials: true
      }));
      if (fetched.data.status !== "ok") {
        args.setIsLoading(false);
        return notification(args.appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      args.setIsLoading(false);
      return notification(args.appContextV2.dispatchPanels, "Candidature déplacée dans la pile", "success");
    }

  } catch (error) {
    devLog(error);
    notification(args.appContextV2.dispatchPanels, "Une erreur est survenue", "error");
  }
}