import React, {useContext, useEffect, useState} from "react";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import WindowWrapper from "../../layouts/WindowLayout";
import Spaces from "../Spaces";
import {FileDrop} from "../Buttons/FileDrop";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [step, wizard] = useWizard(["addCv", "addProfile", "pending"]);
  const [profile, updateProfile, formidableProfile] = useForm({
    email: "",
  });
  const [file, updateFile, formidableFile] = useForm({
    file: null,
  });
  
  /** Called when a file is added */
  useEffect(() => {
    (async() => {
      if(!!file.file) {
        if(file.file.length > 1) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
        if(!formidableFile.isFileMime(file.file[0], ["pdf", "docx", "odt"])) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge (pdf, docx, odt)", "error");
        }
        if(!formidableFile.isFileSmallerThan(file.file[0], 8_000_000)) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 8Mo)", "error");
        }
        wizard.goToStep('addProfile');
      }
    })();
  }, [file.file]);
  
  const sendFile = async() => {
    try {
      const dataToSend: any = new FormData();
      dataToSend.append("cv", file.file[0], file.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/candidate/cv`, dataToSend, {
        withCredentials: true, timeout: 15000, headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
        }
      });
      if(fetched.data.status !== "ok") {
        return "";
      }
      return fetched.data.data;
    } catch(error) {
      devLog(error);
      return "";
    }
  }
  
  const sendNewCandidate = async() => {
    try {
      wizard.goToStep('pending');
      let cvUrl: string = "";
      if(file.file) {
        cvUrl = await sendFile();
      }
      
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/pool/ci/new/entry`, {
        poolId: appContextV2.appNavigation.poolId,
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        positionsWanted: [profile.position],
        cvUrl: cvUrl || "",
        email: profile.email || "",
        phone: profile.phone || "",
      }, {
        withCredentials: true, timeout: 15000,
      });
      
      if(fetched.data.status !== "ok") {
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
  
      // update front
      appContextV2.appPanels.window.windowOptions.data.addCandidateFront(fetched.data.data);
      notification(appContextV2.dispatchPanels, "Ajout avec succès", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch(error) {
      devLog(error);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const isButtonActive = formidableProfile.isEmail(profile.email) && !!profile.firstName;
  
  switch(step) {
    
    case 'addCv':
      return (<WindowWrapper size={"small"} status={"ok"} windowTitle={""}>
        <div className={"text--center"}>Ajoute un CV</div>
        <div className={"space-flex--center"}>
          <FileDrop square={false} cb={updateFile} name={"file"}/>
        </div>
        <Spaces n={0}/>
        <div className={"text--center"}>
          <button className={"btn--hollow modern-link"} onClick={() => wizard.goToStep('addProfile')}>
            Continuer sans CV
          </button>
        </div>
      </WindowWrapper>);
    
    case 'addProfile':
      return (<WindowWrapper size={"small"} status={"ok"} windowTitle={""}>
        <div className={"container"}>
          
          <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
            <label className={"text--minified2x"}>Prénom *</label>
            <br/>
            <input className={"input--v2"} type={"text"} name={"firstName"} value={profile.firstName} onChange={updateProfile({maximumLength: 60,})}/>
            <br/>
            <label className={"text--minified2x"}>Nom</label>
            <br/>
            <input className={"input--v2"} type={"text"} name={"lastName"} value={profile.lastName} onChange={updateProfile({maximumLength: 60,})}/>
            <br/>
            <label className={"text--minified2x"}>Poste principal</label>
            <br/>
            <input className={"input--v2"} type={"text"} name={"position"} value={profile.position} onChange={ updateProfile({maximumLength: 60,})}/>
            <br/>
            <label className={"text--minified2x"}>Email *</label>
            <br/>
            <input className={"input--v2"} type={"text"} name={"email"} value={profile.email} onChange={updateProfile({maximumLength: 120, toLowerCase: true,})}/>
            <br/>
            <label className={"text--minified2x"}>Téléphone</label>
            <br/>
            <input className={"input--v2"} type={"text"} name={"phone"} value={(profile.phone)}
                   onChange={updateProfile({maximumLength: 60, toKeepNumbers: true})}/>
            <br/>
            <label className={"text--minified2x"}>Profil linkedin</label>
            <br/>
            <input className={"input--v2"} type={"text"} name={"linkedin"} value={profile.linkedin} onChange={updateProfile({maximumLength: 180,})}/>
            <br/>
          </article>
          <Spaces n={1}/>
          <div className={"text--center"}>
            <button disabled={!isButtonActive} className={"btn-secondary"} onClick={() => sendNewCandidate()}>
              Enregistrer le profil
            </button>
          </div>
        
        </div>
      </WindowWrapper>);
    
    case 'pending':
    default:
      return (<WindowWrapper size={"small"} status={"pending"} windowTitle={""}>
      </WindowWrapper>);
  }
  
}