import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import SubPageLayout from "../../sharedComponents/layouts/SubPageLayout";
import Spaces from "../../sharedComponents/components/Spaces";
import PageLayoutWithHeader from "../../sharedComponents/layouts/PageLayoutWithHeader";
import {useAssistant} from "../../../utils/useAssistant/useAssistant";
import axios from "axios";
import {devLog} from "../../../utils/devLog/devLog";
import {BusinessTeam} from "./componentsRecruiterParametersTeam/BusinessTeam";
import {BusinessAddTeam} from "./componentsRecruiterParametersTeam/BusinessAddTeam";
import {notification} from "../../../utils/notification";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [teams, statusGet, updateDataGet, assistantGet] = useAssistant([]);
  
  const [type, setType] = useState("");
  const [elementId, setElementId] = useState("");
  const [teamId, setTeamId] = useState("");
  
  const isItPossibleToAddTeams: boolean = teams?.teams?.length < 10 && appContextV2.appSession.session.isEmailVerified;
  
  useEffect(() => {
    (async() => {
      try {
        assistantGet.fetching();
        const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/ent/t/teams`, {withCredentials: true})).data;
        if(fetched.status === "ok") {
          assistantGet.fetched();
          assistantGet.setForm(fetched.data);
        } else {
          assistantGet.error();
        }
      } catch (error) {
        devLog(error);
        assistantGet.error();
      }
    })()
  }, []);
  
  devLog(teams);
  
  const addTeamFront = (teamId: string, name: string, description: string) => {
    updateDataGet()({
      target: {
        type: "custom",
        name: "teams",
        value: [...(teams.teams || []), {
          _id: teamId,
          name: name || "",
          description: description || "",
        }],
      }
    });
  }
  
  const deleteTeamFront = (teamId: string) => {
    updateDataGet()({
      target: {
        type: "custom",
        name: "teams",
        value: [...(teams.teams || []).filter((item: any) => (item._id !== teamId))],
      }
    });
  }
  
  const manageTeamFront = (teamId: string, newName: string) => {
    updateDataGet()({
      target: {
        type: "custom",
        name: "teams",
        value: [...(teams.teams || []).map((item: any) => ({
          _id: item._id,
          name: item._id === teamId ? newName : item.name,
        }))],
      }
    });
  }
  
  const inviteTeamMemberFront = (data: {teamId: string, email: string,
    role: string, firstName: string, lastName: string, inviteId: string}) => {
    updateDataGet()({
      target: {
        type: "custom",
        name: "invited",
        value: [...(teams.invited || []), {
          _id: data.inviteId,
          teamId: data.teamId,
          email: data.email,
          role: data.role,
          firstName: data.firstName,
          lastName: data.lastName,
        }],
      }
    });
  }
  
  const updateInviteFront = () => {}
  
  const deleteInviteFront = (inviteId: string) => {
    updateDataGet()({
      target: {
        type: "custom",
        name: "invited",
        value: [...teams.invited?.filter((item: any) => (item._id !== inviteId))],
      }
    });
  }
  
  const deleteMemberFront = (memberId: string) => {
    updateDataGet()({
      target: {
        type: "custom",
        name: "team",
        value: [...teams.team?.filter((item: any) => (item._id !== memberId))],
      }
    });
  }
  
  const updateMemberFront = (memberId: string, role: string) => {
    updateDataGet()({
      target: {
        type: "custom",
        name: "team",
        value: [...teams.team?.map((item: any) => ({
          ...item,
          role: memberId !== item._id ? item.role : role,
        }))],
      }
    });
  }
  
  const drag = (dragType: "member" | "invite", draggedElementId: string, fromTeamId: string) => {
    setType(dragType);
    setElementId(draggedElementId);
    setTeamId(fromTeamId);
  }
  
  const drop = async(toTeamId: string) => {
    try {
      
      if(teamId === toTeamId) {
        return;
      }
      
      if(type === "member") {
        
        notification(appContextV2.dispatchPanels, "Modification en cours", "information");
        
        // Update front
        updateDataGet()({
          target: {
            type: "custom",
            name: "team",
            value: [...teams.team?.map((item: any) => ({
              ...item,
              teamId: item._id === elementId ? toTeamId : item.teamId,
            }))],
          }
        });
        
        // Update Back
        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/ent/t/member/move`, {
          teamId: teamId,
          memberId: elementId,
          toTeamId: toTeamId,
        }, {withCredentials: true});
        
        if(fetched.data.status !== "ok") {
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
  
        return notification(appContextV2.dispatchPanels, "Sauvegarde effectuée", "success");
  
      }
      
      if(type === "invite") {
  
        notification(appContextV2.dispatchPanels, "Modification en cours", "information");
  
        // Update front
        updateDataGet()({
          target: {
            type: "custom",
            name: "invited",
            value: [...teams.invited?.map((item: any) => ({
              ...item,
              teamId: item._id === elementId ? toTeamId : item.teamId,
            }))],
          }
        });
  
        // Update Back
        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/ent/t/invite/move`, {
          teamId: teamId,
          inviteId: elementId,
          toTeamId: toTeamId,
        }, {withCredentials: true});
  
        if(fetched.data.status !== "ok") {
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
  
        return notification(appContextV2.dispatchPanels, "Sauvegarde effectuée", "success");
      }
    
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<SubPageLayout status={statusGet} subPage={props.subPage}>
    
    {/*<Spaces n={0}/>*/}
    
    <div className={"text--center text--grey"}>
      Construit les équipes en drag and drop
    </div>
    
    <Spaces n={0}/>
    
    <section className={"teams"}>
      
      {teams?.teams?.map((item: any, key: number) => (
        <BusinessTeam deleteTeamFront={deleteTeamFront}
                      manageTeamFront={manageTeamFront}
                      updateInviteFront={updateInviteFront}
                      deleteInviteFront={deleteInviteFront}
                      inviteTeamMemberFront={inviteTeamMemberFront}
                      deleteMemberFront={deleteMemberFront}
                      updateMemberFront={updateMemberFront}
                      item={item}
                      drag={drag}
                      drop={drop}
                      teamId={item._id}
                      invited={teams?.invited?.filter((item2: any) => (item2.teamId === item._id))}
                      members={teams?.team?.filter((item2: any) => (item2.teamId === item._id))} key={key}/>))}
      
      {isItPossibleToAddTeams && <BusinessAddTeam addTeamFront={addTeamFront}/>}
    
    </section>
  
  
  </SubPageLayout>);
}
