import Spaces from "../../../../../components/Spaces";
import ButtonInformation from "../../../../../components/Buttons/ButtonInformation";
import LoadingStyle2 from "../../../../../components/Loading/LoadingStyle2";
import SubPageLayout from "../../../../../layouts/SubPageLayout";
import React, {useContext, useEffect} from "react";
import {useForm} from "use-formidable";
import {notification} from "../../../../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import {FileDrop} from "../../../../../components/Buttons/FileDrop";

export default (props: {
  answer: any,
  updateAnswer: any,
  sending: boolean,
  formidableAnswer: any,
  subPage: any,
  setSending: any,
  pageInfos: any,
  missionId: any,
  wizard: any,
  setCandidacy: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Cv file */
  const [cv, updateCv, formidableCv] = useForm({
    file: null,
    accepted: false,
    sending: false,
  });
  
  const [avatar, updateAvatar, formidable] = useForm({
    file: null,
    temporary: "https://static.myshortlist.co/koala.png",
    accepted: false,
  });
  
  useEffect((): any => {
    (() => {
      if(!!avatar.file) {
        
        if(avatar.file.length > 1) {
          updateAvatar()({target: {type: "custom", name: "file", value: null}});
          updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
        
        if(!formidable.isFileMime(avatar.file[0], ["jpeg", "png"])) {
          updateAvatar()({target: {type: "custom", name: "file", value: null}});
          updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
        }
        
        if(!formidable.isFileSmallerThan(avatar.file[0], 600_000)) {
          updateAvatar()({target: {type: "custom", name: "file", value: null}});
          updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 600ko)", "error");
        }
        
        updateAvatar()({target: {type: "custom", name: "accepted", value: true}});
        updateAvatar()({target: {type: "custom", name: "temporary", value: URL.createObjectURL(avatar.file[0])}});
      }
    })();
  }, [avatar.file]);
  
  /** When CV Updates **/
  useEffect((): any => {
    (() => {
      if(!!cv.file) {
        
        if(cv.file.length > 1) {
          updateCv()({target: {type: "custom", name: "file", value: null}});
          updateCv()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
        
        if(!formidableCv.isFileMime(cv.file[0], ["pdf", "docx", "doc", "xslx", "xsl", "odt", "jpeg", "png"])) {
          updateCv()({target: {type: "custom", name: "file", value: null}});
          updateCv()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
        }
        
        if(!formidableCv.isFileSmallerThan(cv.file[0], 3_000_000)) {
          updateCv()({target: {type: "custom", name: "file", value: null}});
          updateCv()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 3mo)", "error");
        }
        
        updateCv()({target: {type: "custom", name: "accepted", value: true}});
        
        return notification(appContextV2.dispatchPanels, "Fichier correctement ajouté", "success");
      }
    })();
  }, [cv.file]);
  
  const sendFile = async() => {
    try {
      const dataToSend: any = new FormData();
      dataToSend.append("cv", cv.file[0], cv.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/candidate/cv`, dataToSend, {
        withCredentials: true, timeout: 15000, headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
        }
      });
      if(fetched.data.status !== "ok") {
        return "";
      }
      return fetched.data.data;
    } catch(error) {
      return "";
    }
  }
  
  const sendAvatar = async() => {
    try {
      const dataToSend: any = new FormData();
      dataToSend.append("avatar", avatar.file[0], avatar.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/unique/candidate/avatar`, dataToSend, {
        withCredentials: true, timeout: 15000, headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
        }
      });
      if(fetched.data.status !== "ok") {
        return "";
      }
      devLog("avatar", fetched.data.data);
      return fetched.data.data;
    } catch(error) {
      devLog(error);
      return "";
    }
  }
  
  const sendCandidacy = async() => {
    try {
      notification(appContextV2.dispatchPanels, "Envoi de la candidature", "information");
      
      props.setSending(true);
  
      /** Add the cv */
      let cvUrl = "";
      if(cv.accepted) {
        updateCv()({target: {type: "custom", name: "sending", value: true}});
        let fetchFileUrl = await sendFile();
        updateCv()({target: {type: "custom", name: "sending", value: false}});
        devLog("fetchFileUrl", fetchFileUrl);
        if(fetchFileUrl === "") {
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue dans l'envoi du CV", "error");
        }
        cvUrl = fetchFileUrl;
        props.updateAnswer()({target: {type: "custom", name: "cvUrl", value: fetchFileUrl}});
      }
      
      let avatarUrl = "";
      if(avatar.accepted && !!avatar.file && avatar.file.length !== 0) {
        avatarUrl = await sendAvatar();
      }
      
      const dataToSend: any = {
        missionId: props.missionId,
        ...props.answer,
        cvUrl: cvUrl,
        ...props.pageInfos,
        avatarUrl: avatarUrl,
      }
      
      // Post candidacy
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/mat/ca/candidacy`, dataToSend, {
        withCredentials: true,
        timeout: 10000,
      });
      
      props.setCandidacy(fetched.data?.data);
      
      if(fetched.data.status !== "ok") {
        props.wizard.goToStep("error");
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      notification(appContextV2.dispatchPanels, "Succès", "success");
      props.wizard.goToStep('success');
      
    } catch(error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      props.setSending(false);
    }
  }
  
  const iFirstNameOk: boolean = !!props.answer.firstName;
  const iLastNameOk: boolean = !!props.answer.lastName;
  const isEmailOk: boolean = !!props.answer.email && props.formidableAnswer.isEmail(props.answer.email);
  const isPhoneOk: boolean = !!props.answer.phone;
  
  const isButtonActive = iFirstNameOk && iLastNameOk && isEmailOk && isPhoneOk && cv.accepted && !props.sending;
  
  return (<>
    <SubPageLayout status={"ok"} subPage={props.subPage}>
      
      <Spaces n={2}/>
      
      <div className={"assistant--v2-title"}>
        Vous avez presque terminé !
      </div>
      
      <Spaces n={0}/>
      
      <div className={"text--grey text--center"}>
        Entrer vos informations de contact est nécéssaire pour être contacté par le recruteur
      </div>
      
      <Spaces n={2}/>
      
      <div className={"text--center"}>
        <img src={avatar.temporary} className={"avatar-display--middle"}/>
        <Spaces n={0}/>
        {/*<input type={"file"} name={"file"} onChange={updateAvatar()} />*/}
        
        <div className={"space-flex"}>
          <div className={"header__upload-image__button"} title={"Ajoute un avatar pour l'entreprise"}>
            <input type={"file"} name={"file"} id={"candidateAvatar"} className="input-camera"
                   onChange={updateAvatar()}/>
            <label htmlFor={"candidateAvatar"} className={`btn-camera2 animation-straight-fade-in space-medium`}>
              <img src={"https://static.myshortlist.co/btn-camera.svg"} alt={"modify avatar"}/>
            </label>
          </div>
        </div>
        
        <Spaces n={0}/>
        <p className={"text--center"}>Ajouter une photo augmente la visibilité de votre candidature de 40%</p>
      </div>
      
      <Spaces n={1}/>
      
      <section className={"container text--center"}>
        
        <article className={"white-container white-container--grey"}>
          
          <label className={"text--minified2x"}>Prénom*<br/>
            <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"firstName"}
                   placeholder={"Prénom*"} value={props.answer.firstName}
                   onChange={props.updateAnswer({maximumLength: 50})}/>
          </label>
          
          <Spaces n={0}/>
          
          <label className={"text--minified2x"}>Nom*<br/>
            <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"lastName"}
                   placeholder={"Nom*"}
                   value={props.answer.lastName} onChange={props.updateAnswer({maximumLength: 50})}/>
          </label>
          <Spaces n={0}/>
          
          <label className={"text--minified2x"}>Email*<br/>
            
            <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"email"}
                   placeholder={"Email*"}
                   value={props.answer.email} onChange={props.updateAnswer({maximumLength: 100})}/>
          </label>
          
          <Spaces n={0}/>
          
          <label className={"text--minified2x"}>Téléphone*<br/>
            <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"phone"}
                   placeholder={"Téléphone*"}
                   value={props.answer.phone}
                   onChange={props.updateAnswer({maximumLength: 20, toKeepNumbers: true})}/>
          </label>
          
          <Spaces n={0}/>
          
          <label className={"text--minified2x"}>Commentaire pour le recruteur<br/>
            <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"comment"}
                   placeholder={"Commentaire libre"}
                   value={props.answer.comment} onChange={props.updateAnswer({maximumLength: 200})}/>
          </label>
          <Spaces n={0}/>
        
        </article>
        
        <Spaces n={0}/>
  
        {/*--------------------------------- CV */}
        <article className={"white-container white-container--grey"}>
          {!cv.accepted && <div className={"text--center apply-V2__title"}>CV nécéssaire pour continuer</div>}
          {cv.accepted && <div className={"text--center apply-V2__title"}>CV accepté</div>}
          {
            (!cv.accepted) && <>
              <br/>
              <div className={"space-flex--center"}>
                <FileDrop square={false} name={"file"} cb={updateCv}/>
              </div>
            </>
          }
        </article>
  
        <Spaces n={0}/>
        
        <div className={"text--center text--minified2x text--red"}>
          {(!iFirstNameOk || !iLastNameOk) && <>Nom et/ou Prénom non valide(s)<br/></>}
          {(!isEmailOk) && <>Email non valide<br/></>}
          {(!isPhoneOk) && <>Téléphone non valide<br/></>}
        </div>
        
        <Spaces n={0}/>
        
        <div className={"text--center"}>
          <button className={"btn-main"} disabled={!isButtonActive} onClick={() => sendCandidacy()}>
            Postuler
          </button>
        </div>
        
        {(props.sending || cv.sending) && <>
          <br/>
          <LoadingStyle2/>
        </>}
        
        <Spaces n={0}/>
      
      </section>
    
    </SubPageLayout>
  </>);
}