import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<div className={"landing-page--v2__navbar"}>
    
    <div className={"landing-page--v2__navbar__logo"}>
      <div className={"landing-page--v2__navbar__logo__square"}>
        <div className={"landing-page--v2__navbar__logo__square__text"}>
          ms
        </div>
      </div>
      <div className={"landing-page--v2__navbar__logo__text"}>
        myshortlist.co
      </div>
    </div>
    
    <div className={"landing-page--v2__navbar__buttons"}>
      <button className={"landing-page--v2__navbar__button"} onClick={() => {
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW", value: {
            type: "login",
            data: {},
          }
        });
      }}>
        Connexion
      </button>
    </div>
  
  </div>);
}