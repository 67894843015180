import React, {useContext, useState} from "react";
import {ProfileElementBoolean} from "../../../sharedComponents/layouts/ProfileElementBoolean";
import Spaces from "../../../sharedComponents/components/Spaces";
import LoadingStyle2 from "../../../sharedComponents/components/Loading/LoadingStyle2";
import {devLog} from "../../../../utils/devLog/devLog";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";

export {RecruiterParametersTokenForLinkedin}

const RecruiterParametersTokenForLinkedin = (props: {
  parameters: any,
  updateMyLinkedinAddonToken: Function,
  assistantGet: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [isLoading, setIsLoading] = useState(false);
  
  return (<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Code de connexion à l'extension pour LinkedIn</h1>
    
    <Spaces n={0}/>
    
    <div className={"text--grey text--center"}>
      Ce code est personnel. Il permet ton identification dans l'extension.
      <br/><br/>
      Il doit être copié dans les paramètres de l'extension.
    </div>
    
    <Spaces n={1}/>
    
    {
      !props.parameters.tokenForLinkedinAddon && <div className={"text--center"}>
        <button disabled={isLoading} className={"btn-secondary"} onClick={async() => {
          try {
            setIsLoading(true);
            await props.updateMyLinkedinAddonToken();
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
            devLog(error);
          }
        }}>
          Obtenir un code
        </button>
      </div>
    }
    
    {
      props.parameters.tokenForLinkedinAddon && <>
        <div className={"link-box2 link-box2--grey cursor--pointer"} title={"Click pour copier le code"} onClick={() => {
          notification(appContextV2.dispatchPanels, "Code copié", "success");
          navigator.clipboard.writeText(props.parameters.tokenForLinkedinAddon);
        }}>
          {props.parameters.tokenForLinkedinAddon}
        </div>
        <div className={"text--center text--grey text--minified3x"}>(clique sur le code pour le copier)</div>
        <Spaces n={0}/>
        <div className={"text--center"}>
          <button disabled={isLoading} className={"btn-secondary"} onClick={async() => {
            try {
              setIsLoading(true);
              await props.updateMyLinkedinAddonToken();
              setIsLoading(false);
            } catch (error) {
              setIsLoading(false);
              devLog(error);
            }
          }}>
            Générer un nouveau code
          </button>
        </div>
      </>
    }
    
    {
      isLoading && <div className={"space-flex--center"}>
        <LoadingStyle2/>
      </div>
    }
  
  </div>);
}