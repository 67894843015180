import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import {capitalizeMulti} from "../../../utils/converters";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(() => {
    const timer = setTimeout(() => props.setDisplay(false), 1_800);
    return () => clearInterval(timer);
  }, []);
  
  // if(!display) return (<></>);
  
  return (<section className={"welcome-div"}>
    <article className={"welcome-div__window"}>
      <div className={"welcome-div__window__text"}>Bienvenue {capitalizeMulti(appContextV2.appSession?.session?.firstName ?? "")} !</div>
    </article>
  </section>);
}