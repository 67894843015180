
import React from "react";

export default (props: any) => {
  return(<>
    <div className={"profile-links--v2"}>
      {
        props.businessProfile.twitterUrl && <a href={props.businessProfile.twitterUrl} target={"_blank"}>
          <button className={"profile-links--v2__media"}>
            <img src={"https://static.myshortlist.co/btn-black-twitter.svg"}/>
          </button>
        </a>
      }
      {
        props.businessProfile.facebookUrl && <a href={props.businessProfile.facebookUrl} target={"_blank"}>
          <button className={"profile-links--v2__media"}>
            <img src={"https://static.myshortlist.co/btn-black-facebook.svg"}/>
          </button>
        </a>
      }
      {
        props.businessProfile.linkedinUrl && <a href={props.businessProfile.linkedinUrl} target={"_blank"}>
          <button className={"profile-links--v2__media"}>
            <img src={"https://static.myshortlist.co/btn-black-linkedin.svg"}/>
          </button>
        </a>
      }
      {
        props.businessProfile.snapchatUrl && <a href={props.businessProfile.snapchatUrl} target={"_blank"}>
          <button className={"profile-links--v2__media"}>
            <img src={"https://static.myshortlist.co/btn-black-snapchat.svg"}/>
          </button>
        </a>
      }
      {
        props.businessProfile.youtubeUrl && <a href={props.businessProfile.youtubeUrl} target={"_blank"}>
          <button className={"profile-links--v2__media"}>
            <img src={"https://static.myshortlist.co/btn-black-youtube.svg"}/>
          </button>
        </a>
      }
      {
        props.businessProfile.businessUrl && <a href={props.businessProfile.businessUrl} target={"_blank"}>
          <button className={"profile-links--v2__media"}>
            site
          </button>
        </a>
      }
    
    </div>
  </>);
}