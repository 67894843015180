import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import Spaces from "./Spaces";
import {Link} from "react-router-dom";
import {notification} from "../../../utils/notification";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return (<>
    
    {props.displayedCandidates?.filter((item: any) => item.status === props.status).length === 0 &&
    <>
      <div className={"text--magnified11x text--center"}>Il n'y a pas de candidats.</div>
      <Spaces n={0}/>
    </>}
    
    {props.displayedCandidates?.filter((item: any) => item.status === props.status).map((item: any, key: number) => (
      
      <div
        className={`candidates-evaluations-container ${props.isResearchActive ? "candidates-evaluations-container--selected" : ""}`}>
        <div className={"candidates-evaluations-header"}>
          <img className={"candidates-evaluations-avatar"} src={item.candidate?.avatarUrl}
               alt={"avatar"}/>
          
          <div className={"candidates-evaluations-name-container"}>
            <div className={"candidates-evaluations-name"}>{item.candidate?.firstName}</div>
            <div className={"candidates-evaluations-name"}>{item.candidate?.lastName?.toUpperCase()}</div>
          </div>
          
          <div
            className={"text--grey text--minified2x candidates-evaluations-positionWanted "}>{item.mission?.positionWanted}</div>
        </div>
        <div className={"candidates-evaluations-header"}>
          <Link to={`/recruiter/action/evaluate/${item.token}`}>
            <button className={`candidates-evaluations-btn ${props.status === "pending" ? "candidates-evaluations-btn--todo" : ""}`}>
              {props.status === "pending" && <>Évaluer</>}
              {props.status === "done" && <>Visualiser</>}
            </button>
          </Link>
          <button className="mission--V2__buttons__parameters" onClick={async() => {
            try {
              await axios.put(`${process.env.REACT_APP_API_URL}/mat/cam/delete/candidate/evaluation`, {
                evaluationId: item.evaluationId,
              }, {
                withCredentials: true
              });
              props.setCandidates((ps: any) => ({
                ...ps,
                candidatesToEvaluate: ps.candidatesToEvaluate?.filter((item2: any) => (item2.evaluationId !== item.evaluationId))
              }));
              notification(appContextV2.dispatchPanels, "Succès de la suppression", "success");
              
            } catch(error) {
              notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
              throw (error);
            }
          }}>
            <img src="https://static.myshortlist.co/btn-bin.svg" alt={"supprimer"}/>
          </button>
        </div>
      </div>
    
    ))}
  </>)
}
