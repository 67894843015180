import React, {useContext} from "react";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import {devLog} from "../../../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";

export {WKAAButtons};

const WKAAButtons = (props: {
  isPageLoading: boolean,
  setIsPageLoading: Function,
  setIsLoading: Function,
  moveCandidacyFront: Function,
  candidateData: any,
  fromColumnId: any,
  columnData: any,
  toColumnId: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  console.log("notify",props?.columnData?.notifyEmails);

  return(<>
  
    <div>
      {/*----------------------------------------- Do not send actions the mail*/}
      
      <button disabled={props.isPageLoading} className={"btn-secondary"} onClick={async() => {
        try {
          props.setIsPageLoading(true);
          props.setIsLoading(true);
          // update front
          props.moveCandidacyFront(props.candidateData._id, props.columnData._id);
        
          // Update back
          const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/mat/cam/move/candidacy`, {
            missionId: appContextV2.appNavigation.missionId,
            toColumnId: props.toColumnId,
            fromColumnId: props.fromColumnId,
            shortCandidacyId: props.candidateData._id,
            candidacyId: props.candidateData.candidacyId,
            candidateId: props.candidateData.candidateId,
            bypassActions: true,
            mailData: {},
            smsData: {},
          }, {
            withCredentials: true
          }));
          if(fetched.data.status !== "ok") {
            props.setIsLoading(false);
            props.setIsPageLoading(false);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          }
          props.setIsLoading(false);
          props.setIsPageLoading(false);
          notification(appContextV2.dispatchPanels, "Candidature déplacée dans la pile", "success");
        
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        } catch (error) {
          props.setIsPageLoading(false);
          props.setIsLoading(false);
          devLog(error);
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
      
      }}>
        Déplacer le candidat sans effectuer l'action
      </button>
      
      {/*----------------------------------------- Send actions the mail*/}
      
      <button disabled={props.isPageLoading} className={"btn-secondary"} onClick={async() => {
      
        try {
        
          props.setIsPageLoading(true);
          props.setIsLoading(true);
        
          props.moveCandidacyFront(props.candidateData._id, props.columnData._id);
        
        
          // Update back
          const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/mat/cam/move/candidacy`, {
            missionId: appContextV2.appNavigation.missionId,
            toColumnId: props.toColumnId,
            fromColumnId: props.fromColumnId,
            shortCandidacyId: props.candidateData._id,
            candidacyId: props.candidateData.candidacyId,
            candidateId: props.candidateData.candidateId,
            bypassActions: false,
            mailData: {
              activateMail: props.columnData?.activateMail,
              mailSubject: props.columnData?.mailSubject,
              mailContent: props.columnData?.mailContent,
              mailDelay: props.columnData?.mailDelay,
            },
            smsData: {
              activateSms: props.columnData?.activateSms,
              smsText: props.columnData?.smsText
            },
          }, {
            withCredentials: true
          }));
          if(fetched.data.status !== "ok") {
            props.setIsLoading(false);
            props.setIsPageLoading(false);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          }
          props.setIsLoading(false);
          notification(appContextV2.dispatchPanels, "Candidature déplacée dans la pile", "success");
          props.setIsPageLoading(false);
        
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        
        } catch(error) {
          props.setIsPageLoading(false);
          props.setIsLoading(false);
          devLog(error);
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
      
      }}>
        Effectuer l'action
      </button>
    </div>
    
  </>);
}