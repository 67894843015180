import React, {useContext, useEffect} from "react";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import LoadingStyle2 from "./LoadingStyle2";

interface ILoadingProps {
  fullPage: boolean;
}

export {Loading};

const Loading = (props: ILoadingProps) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(()=>{
    props.fullPage && (appContextV2.dispatchNavigation({type: "SET_PAGE", value: "Loading"}));
  }, []);
  
  return (<section className={props.fullPage ? "loading-animation--fullpage" : "empty-page"}>
    {/*<div className="loader">*/}
    {/*  <span className="dot dot_1"></span>*/}
    {/*  <span className="dot dot_2"></span>*/}
    {/*  <span className="dot dot_3"></span>*/}
    {/*  <span className="dot dot_4"></span>*/}
    {/*</div>*/}
    <LoadingStyle2 />
  </section>);
};
