import {devLog} from "../../../utils/devLog/devLog";
import React, {useEffect, useState} from "react";
import PageLayout from "../layouts/PageLayout";
import Spaces from "../components/Spaces";
import axios from "axios";
import SCHeader from "./ComponentsSharedCandidate/SCHeader";
import SCNavBar from "./ComponentsSharedCandidate/SCNavBar";
import SCCandidateDetails from "./ComponentsSharedCandidate/SCCandidateDetails";

export {SharedCandidate};

const SharedCandidate = (props: any) => {

    const token: string = props.match.params.token || "";

    const [candidateShared, setCandidateShared]: any = useState({});
    const [status, setStatus]: any = useState({});

    const [fetchedStatus, setFetchedStatus]: ["asleep" | "fetching" | "fetched" | "forbidden" | "error", Function] = useState("asleep");
    const [tab, setTab] = useState<"cv" | "profile" | "candidacy" | "trigram">( "cv");
    
    useEffect(() => {
        (async() => {
            try {
                setFetchedStatus("fetching");
                const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/mat/cam/shared/candidate`, {
                    headers: {
                        token: token
                    },
                    withCredentials: true
                });
                
                setCandidateShared(fetched?.data.data);
                setStatus(fetched?.data?.status);
                setFetchedStatus("fetched");

            } catch(error) {
                setFetchedStatus("error");
                devLog(error);
            }
        })();
    }, []);



    if(status === "noCandidate" || status === "noToken") {
        return (<PageLayout page={props.page} status={"ok"}>
            <Spaces n={8}/>
            <div className={"text--magnified11x text--center"}>Le lien ne semble pas valide</div>
            <Spaces n={8}/>
        </PageLayout>);
    }


    return (<PageLayout fullWidth={true} grey={true} page={props.page} status={fetchedStatus}>

        <Spaces n={6}/>

        <SCHeader candidate={candidateShared?.candidate} positionWanted={candidateShared?.mission?.positionWanted ?? ""}/>

        <Spaces n={0}/>

        <SCNavBar tab={tab}
                  setTab={setTab}
                  candidacyImported={candidateShared?.candidacy?.imported}
        />

        <SCCandidateDetails candidate={candidateShared}
                            tab={tab}
                             token={token}/>

        <Spaces n={0}/>


    </PageLayout>);

}

