import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import AssistantLayout from "../../../../layouts/AssistantLayout";
import {abstractPostFetch, abstractPutFetch} from "../../../../../../utils/abstractFetch";
import ButtonWrapper from "../../../../layouts/ButtonWrapper";


export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  const assistantTitle1: string = ((language: string) => {
    switch(language) {
      case 'FR':
        return "Un compte avec cet email existe déjà";
      case 'EN':
      default:
        return "The user already exists";
    }
  })(language);
  
  const assistantComment1: any = <p className={"text--center"}>
    <button className={"btn-main"} disabled={false} onClick={(e) => {
      e.preventDefault();
      appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
          type: "login",
          data: {
            email: props.windowContext.form.email || "",
          }
        }});
    }}>
      Je me connecte
    </button>
  </p>;
  
  return(<>
    <AssistantLayout title={assistantTitle1} comment={assistantComment1} animated={true}>
      <div className={"text--center"}>
        {language === 'FR' ? "Un compte avec cet email existe déjà. Clique sur 'Je me connecte' pour accéder à l'espace de connexion." : ""}
        {language === 'EN' ? "This account already exists. Click on 'Send link' to receive a smart-login link." : ""}
      </div>
    </AssistantLayout>
  </>);
}
