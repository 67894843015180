import React, {useContext} from "react";
import {IRoute} from "../../../../routes/routes";
import {Route} from "react-router-dom";
import AppContext, {IAppContext, IAppV2Context} from "../../context/AppContext";
import FourHundredThree from "../../../sharedComponents/pages/errors/FourHundredThree";

export {AbstractRoute};

interface IAbstractRoute extends IRoute {
  
  key: number;
  authorisedSpaces: ("public" | "candidate" | "recruiter")[];
}

/**
 * Return a single route
 * @authorisedSpaces is an array and must contain user statuses that are allowed to access the page.
 */
const AbstractRoute = (props: IAbstractRoute) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /**
   * Checks if user is allowed to access the page
   */
  const isRouteAllowedToUser: boolean = props.authorisedSpaces.includes(appContextV2.appSession.app.space as "public" | "recruiter" | "candidate");
  
  if(!isRouteAllowedToUser) {
    return (<Route exact={props.exact} path={props.path}
                   render={(componentProps) => <FourHundredThree page={"FourHundredThree"} fullPage={true} setPage={false}/>}/>);
  }
  
  return (<Route exact={props.exact} path={props.path}
                 render={(componentProps) => <props.component {...componentProps} page={props.page}/>}/>);
}
