import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../componentsApp/context/AppContext";
import PageLayoutWithHeader from "../sharedComponents/layouts/PageLayoutWithHeader";
import Spaces from "../sharedComponents/components/Spaces";
import {ButtonBigPlusV2} from "../sharedComponents/components/Buttons/ButtonBigPlus";
import {Link} from "react-router-dom";
import getNumberOfPages from "../../utils/getNumberOfPages";
import {devLog} from "../../utils/devLog/devLog";
import {PagesComponent} from "../sharedComponents/components/PagesComponent";
import RecruiterMissionsEmpty from "./componentsRecruiterMissions/RecruiterMissionsEmpty";
import RecruiterMissionsNoResult from "./componentsRecruiterMissions/RecruiterMissionsNoResult";
import {fetchMissions} from "./componentsRecruiterMissions/dependenciesRecruiterMissions/fetchMissions";
import applyFiltersMissions from "./componentsRecruiterMissions/dependenciesRecruiterMissions/applyFiltersMissions";
import {RecruiterMissionsMissionV2} from "./componentsRecruiterMissions/RecruiterMissionsMissionV2";
import {RecruiterMissionsFilters} from "./componentsRecruiterMissions/RecruiterMissionsFilters";
import missionsPinFront from "./componentsRecruiterMissions/dependenciesRecruiterMissions/frontUpdate/missionsPinFront";
import missionsDeleteFront from "./componentsRecruiterMissions/dependenciesRecruiterMissions/frontUpdate/missionsDeleteFront";
import missionsUpdateFront from "./componentsRecruiterMissions/dependenciesRecruiterMissions/frontUpdate/missionsUpdateFront";

export {RecruiterMissions};

/**
 * ----------------------------------------
 * Displays and manage missions (open jobs)
 * Primary-level page
 * ----------------------------------------
 */
const RecruiterMissions = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  /** Downloaded pools for a specific team and fetch status */
  const [missions, setMissions] = useState([]);
  const [fetchStatusMissions, setFetchStatusMissions]: [any, any] = useState("asleep");
  
  /** Displayed missions according to filters */
  const [displayedMissions, setDisplayedMissions] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [olderThan, setOlderThan] = useState("");
  const [earlierThan, setEarlierThan] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  
  /** Handle pages */
  const [page, setPage] = useState(1);
  const resultsPerPage = 5;
  const numberOfPages: number = getNumberOfPages(displayedMissions.length, resultsPerPage);
  
  /** Initial pools-fetch (of the current team only) */
  useEffect(() => {fetchMissions(setFetchStatusMissions, setMissions);}, []);
  devLog(missions);

  const reloadMissions = () => {fetchMissions(setFetchStatusMissions, setMissions);}
  
  /** Display pools according to various filters */
  useEffect(() => {applyFiltersMissions(missions, setDisplayedMissions, createdBy, title, olderThan, earlierThan, showHistory, status);},
    [missions, createdBy, title, earlierThan, olderThan, showHistory, status]);
  const isResearchActive: boolean = (!!createdBy || !!title || !!olderThan || !!earlierThan || showHistory || !!status);
  
  /** Front update */
  const pinFront = missionsPinFront(setMissions);
  const deleteFront = missionsDeleteFront(displayedMissions, page, resultsPerPage, numberOfPages, setMissions, setPage);
  const updateFront = missionsUpdateFront(setMissions);
  
  return (<PageLayoutWithHeader grey={true} title={"Postes ouverts"} small={true} fullWidth={true} page={props.page} status={fetchStatusMissions}>
    
    {/*------------------------------------------------------------------ Filters */}
    {/*missions?.length !== 0*/ <RecruiterMissionsFilters setCreatedBy={setCreatedBy}
                                                         createdBy={createdBy}
                                                         listOfTrigrams={displayedMissions.map((item: any) => (item.creatorTrigram)).reduce((accumulator: any, item: string) => {
                                                           if(accumulator.indexOf(item) < 0) {
                                                             accumulator.push(item)
                                                           }
                                                           return accumulator;
                                                         }, []).sort()}
                                                         title={title}
                                                         earlierThan={earlierThan}
                                                         olderThan={olderThan}
                                                         showHistory={showHistory}
                                                         status={status}
                                                         setStatus={setStatus}
                                                         page={page}
                                                         setPage={setPage}
                                                         setTitle={setTitle}
                                                         setShowHistory={setShowHistory}
                                                         setOlderThan={setOlderThan}
                                                         setEarlierThan={setEarlierThan}
                                                         reloadMissions={reloadMissions}/>}
    
    {/*------------------------------------------------------------------ Text if there are no mission in the team */}
    {missions?.length === 0 && <><Spaces n={1}/><RecruiterMissionsEmpty/></>}
    
    {/*--------------------------------------------------------------------- Research gave no result*/}
    {(missions.length !== 0 && displayedMissions?.length === 0) && <RecruiterMissionsNoResult/>}
    
    {/*------------------------------------------------------------------ Missions */}
    {(missions.length !== 0 && displayedMissions?.length !== 0) && <section className={"missions--v2"}>
      {displayedMissions
      .slice((page * resultsPerPage - resultsPerPage), (page * resultsPerPage))
      .map((item: any, key: number) => (<RecruiterMissionsMissionV2 item={item}
                                                                    key={key}
                                                                    isResearchActive={isResearchActive}
                                                                    pinFront={pinFront}
                                                                    deleteFront={deleteFront}
                                                                    updateFront={updateFront}
      />))}
    </section>}
    
    <Spaces n={2}/>
    
    {/*------------------------------------------------------------------ Button to add a mission*/}
    {
      appContextV2.appSession.sessionBusiness.openedMissions < 50 && <div className={"space-flex--center"}>
        <Link to={"/business/new/job"}>
          <ButtonBigPlusV2 cb={() => {}} title={"Ajoute un nouveau poste"}/>
        </Link>
      </div>
    }
    
    <Spaces n={1}/>
    
    {/*------------------------------------------------------ Pages*/}
    <div className={"text--center"}>
      <PagesComponent page={page} pages={numberOfPages} setPage={setPage}/>
    </div>
  
  </PageLayoutWithHeader>);
}
