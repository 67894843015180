import React, {useContext} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";
import tickJob from "../dependencies/tickJob";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const isButtonActive = !!props.form.positionWanted;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Tu souhaites recruter une ou un:
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
      
      <input type={"text"} list={"jobs"} className={"assistant--v2-input"} name={"positionWanted"}
             value={props.form.positionWanted} onChange={props.updateForm({maximumLength: 40})}/>
      
      <datalist id={"jobs"}>
        {props.jobs?.public?.sort().map((item: any, key: number) => (<option>{item}</option>))}
      </datalist>
      
      <Spaces n={1}/>
      
      <button className={"btn-assistant-main"}
              disabled={!isButtonActive}
              onClick={async() => {
                tickJob(props.form.positionWanted, appContextV2.appSession.app.language);
                props.wizard.goToStep("contract");
              }}>
        Suivant
      </button>
    
    </div>
  
  </section>);
}