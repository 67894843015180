import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/Spaces";
import {Link} from "react-router-dom";
import ApplyStepV2 from "./componentsBPAV3Custom/ApplyStepV2";
import {isQuestionReady} from "../../../../../recruitersPages/componentsRecruiterEditor/FormItem";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [showPage, setShowPage] = useState(false);
  
  const [formIndex, setFormIndex] = useState(-1);
  
  useEffect(() => {
    (() => {
      let isFormEmpty = props.mission.form.length === 0;
      let firstValidIndex = -1;
      for(let i = 0; i < props.mission.form.length; i++) {
        if(isQuestionReady(props.mission.form[i])) {
          firstValidIndex = i;
          break;
        }
      }
      if((firstValidIndex === -1) || isFormEmpty) {
        return props.wizard.nextStep();
      }
      setFormIndex(firstValidIndex);
      setShowPage(true);
    })();
  }, []);
  
  const nextQuestion = () => {
    let wasQuestionLast = props.mission.form.length -1 === formIndex;
    let nextValidIndex = -1;
    for(let i = formIndex + 1; i < props.mission.form.length; i++) {
      if(isQuestionReady(props.mission.form[i])) {
        nextValidIndex = i;
        break;
      }
    }
    if(wasQuestionLast || (nextValidIndex === -1)) {
      return props.wizard.nextStep();
    }
    setFormIndex(nextValidIndex);
  };
  
  if(!showPage) {
    return (<><Spaces n={15}/></>)
  }
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={2}/>
    
    <ApplyStepV2 question={props.mission.form[formIndex]} answer={props.answer} updateAnswer={props.updateAnswer} formIndex={formIndex} nextQuestion={nextQuestion} />
    
    <Spaces n={3}/>
    
    <div className={"text--center"}>
      <button className={"text--grey btn--hollow"} onClick={() => {
        appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
            type: "reportQuestion",
            data: {
              missionId: props.mission._id,
              question: props.mission.form[formIndex].question
            }
          }})
      }}>
        Signaler la question
      </button>
    </div>
  
    <Spaces n={2}/>
  
  </SubPageLayout>);
}