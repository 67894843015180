import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import axios from "axios";

export default function (props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [step, wizard] = useWizard(["entry", "pending"]);
  
  const [form, updateForm, formidable] = useForm({
    fullName: "",
    email: "",
    phone: "",
  });
  
  const addMember = async() => {
    try {
  
      wizard.goToStep('pending');
  
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/amb/m/add`, {
        fullName: form.fullName,
        email: form.email,
        phone: form.phone,
      }, {withCredentials: true});
  
      if(fetched.data.status !== "ok") {
        wizard.goToStep('entry');
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }
  
      // Update front
      appContextV2.appPanels.window.windowOptions.data?.addAmbassadorFront({
        _id: fetched.data.data._id,
        slug: fetched.data.data.slug,
        fullName: form.fullName,
        email: form.email,
        phone: form.phone,
      });
  
      notification(appContextV2.dispatchPanels, "Ambassadeur ajouté avec succès", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch(error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      devLog(error);
    }
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  return(<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center text--magnified2x"}>
      Informations sur l'ambassadeur
    </div>
    
    <Spaces n={1} />
    
    <div className={"text--center"}>
      Nom complet<br/>
      <input type={"text"} name={"fullName"} onChange={updateForm({maximumLength: 40})}/>
    </div>
  
    <div className={"text--center"}>
      Email<br/>
      <input type={"text"} name={"email"} onChange={updateForm({maximumLength: 100})}/>
    </div>
  
    <div className={"text--center"}>
      Téléphone<br/>
      <input type={"text"} name={"phone"} onChange={updateForm({maximumLength: 20})}/>
    </div>
  
    <Spaces n={1} />
    
    <div className={"text--center"}>
      <button disabled={!form.fullName} className={"btn-main"} onClick={()=>addMember()}>
        Ajouter
      </button>
    </div>
  </WindowWrapper>);
}
