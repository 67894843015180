import {IAppV2Navigation} from "./IAppV2";

export {initialNavigation};

const initialNavigation: IAppV2Navigation = {
  
  page: "",
  subPage: "",
  missionId: "",
  poolId: "",
  
  scrolled: false,
  isHeaderOpenning: false,
  isHeaderClosing: false,
}