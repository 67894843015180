import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import Spaces from "../../../components/Spaces";
import LoadingStyle2 from "../../../components/Loading/LoadingStyle2";
import axios from "axios";
import {notification} from "../../../../../utils/notification";
import {CASError} from "./CASError";
import {CASSuccess} from "./CASSuccess";

export {CASUnavailable};

function CASUnavailable(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [dataFetchingStatus, setDataFetchingStatus] = useState("asleep");
  
  useEffect(()=>{
    (async() => {
      try {
        setDataFetchingStatus("fetching");
        const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/pool/ca/unavailable`, {
          token: props.token,
        },{
          withCredentials: true,
        });
        if(fetched.data.status !== "ok") {
          setDataFetchingStatus("error");
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          return;
        }
        setDataFetchingStatus("fetched");
        notification(appContextV2.dispatchPanels, "Action enregistrée", "success");
        return;
      } catch(error) {
        setDataFetchingStatus("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, []);
  
  if(dataFetchingStatus === "error") return(<CASError />);
  
  if(dataFetchingStatus === "fetched") return(<CASSuccess />);
  
  return(<>
    <div className={"text--center"}>
      Envoi de la réponse en cours ...
    </div>
    <Spaces n={1}/>
    <div className={"space-flex--center"}>
      <LoadingStyle2/>
    </div>
  </>);
}