import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../layouts/SubPageLayout";
import Spaces from "../../../components/Spaces";
import BusinessPageJobsHideOpennedJobs from "./componentsBusinessPageJobsV3/BusinessPageJobsHideOpennedJobs";
import BusinessPageJobsNoJobs from "./componentsBusinessPageJobsV3/BusinessPageJobsNoJobs";
import {Link} from "react-router-dom";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={4}/>
    
    {!props.business?.showOtherJobsInBusiness && <BusinessPageJobsHideOpennedJobs/>}
    
    {(props.business?.showOtherJobsInBusiness && props.business?.opennedPositions?.length === 0) && <BusinessPageJobsNoJobs/>}
    
    {(props.business?.showOtherJobsInBusiness && props.business?.opennedPositions?.length !== 0) && <section className={"business-page-jobs"}>
      <div className={"text--center text--magnified2x"}>
        Postes ouverts chez {props.business?.businessName}
      </div>
      
      <Spaces n={2}/>
      
      <div className={"business-page-jobs__container"}>
        {props.business?.opennedPositions?.map((item: any, key: number) => (
          
          <button key={key} className={""} onClick={() => {
          }}>
            <a href={`${window.location.origin}/apply/${props.pageInfos.slugname}/${props.pageInfos.slugnumber}/${item.rank}/apply`}>
            {item.positionWanted}
            </a>
          </button>
        
        ))}
      </div>
      <Spaces n={2}/>
    </section>}
  </SubPageLayout>);
}