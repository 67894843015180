import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import MicButton from "../../Buttons/MicButton";
import StatsButton from "../../Buttons/StatsButton";
import LinkButton from "../../Buttons/LinkButton";
import MenuRecruiterDropDownContent from "../DropDownComponents/MenuRecruiterDropDownContent";
import Burger from "../../Buttons/Burger";
import MenuCandidateDropDownContent from "../DropDownComponents/MenuCandidateDropDownContent";
import SwitchLanguage from "../../SwitchLanguage";
import MenuPublicDropDownContent from "../DropDownComponents/MenuPublicDropDownContent";
import {Link} from "react-router-dom";
import {NotificationButton} from "./NotificationButton";

export function MenuDropDownRecruiterPc(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<>
      
      {/*----- On computers */}
      <span className={"hide-on-smartphones"}>
        <ul className={"navbar__items"}>
          <li className={"navbar__items__item"}>
            <Link to={"/business/parameters/plan"}>
              <div className={"business-parameter-plan--small"}>
                {appContextV2.appSession.sessionBusiness.plan === 4 ? "Pilote" : ""}
                {appContextV2.appSession.sessionBusiness.plan === 3 ? "Essai" : ""}
                {appContextV2.appSession.sessionBusiness.plan === 2 ? "Startup" : ""}
                {appContextV2.appSession.sessionBusiness.plan === 1 ? "Entreprise" : ""}
              </div>
            </Link>
          </li>
          <li className={"navbar__items__item"}>
            <span className={"btn-holder"}>
              {/*<MicButton/>*/}
              {/*<StatsButton/>*/}
              <LinkButton/>
              <NotificationButton/>
            </span>
          </li>
          <li className={"navbar__items__item navbar__dropdown-trigger"}>
            <ul className={"navbar__dropdown"}>
              <MenuRecruiterDropDownContent/>
            </ul>
            <Burger size={3} eventOnClick={() => {}}/>
          </li>
        </ul>
      </span>
    
    </>
  );
}

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export function MenuDropDownCandidatePc(props: any) {
  
  return (
    <>
      <span className={"hide-on-smartphones"}>
        <ul className={"navbar__items"}>
          <li className={"navbar__items__item"}>
            <span className={"btn-holder"}>
              <LinkButton/>
            </span>
          </li>
          <li className={"navbar__items__item navbar__dropdown-trigger"}>
            <ul className={"navbar__dropdown"}>
              <MenuCandidateDropDownContent/>
            </ul>
            <Burger size={3} eventOnClick={() => {}}/>
          </li>
        </ul>
      </span>
    
    </>
  );
}

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

export function MenuDropDownPublicPc(props: any) {
  
  return (
    <>
      <span className={"navbar__items"}>
        
        <ul className={"navbar__items hide-on-smartphones"}>
          <MenuPublicDropDownContent/>
        </ul>
        
      </span>
    </>
  );
}
