import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../componentsApp/context/AppContext";
import SubPageLayout from "../../sharedComponents/layouts/SubPageLayout";
import Spaces from "../../sharedComponents/components/Spaces";
import {useAssistant} from "../../../utils/useAssistant/useAssistant";
import axios from "axios";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import {RecruiterParametersDictionaryQuestions} from "./componentsRecruiterParametersDictionaries/RecruiterParametersDictionaryQuestions";
import {RecruiterParametersDictionaryLanguages} from "./componentsRecruiterParametersDictionaries/RecruiterParametersDictionaryLanguages";
import {RecruiterParametersDictionaryMobilities} from "./componentsRecruiterParametersDictionaries/RecruiterParametersDictionaryMobilities";
import {RecruiterParametersDictionarySoftwares} from "./componentsRecruiterParametersDictionaries/RecruiterParametersDictionarySoftwares";
import {RecruiterParametersDictionarySectors} from "./componentsRecruiterParametersDictionaries/RecruiterParametersDictionarySectors";
import {RecruiterParametersDictionaryAdvantages} from "./componentsRecruiterParametersDictionaries/RecruiterParametersDictionaryAdvantages";
import {RecruiterParametersDictionaryPositions} from "./componentsRecruiterParametersDictionaries/RecruiterParametersDictionaryPositions";
import {RecruiterParametersDictionarySkills} from "./componentsRecruiterParametersDictionaries/RecruiterParametersDictionarySkills";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Assistant for fetching dictionaries */
  const [dictionaries, setDictionaries] = useState({
    skills: [],
    positions: [],
    mobilities: [],
    sectors: [],
    languages: [],
    softwares: [],
  });
  const [fetchStatus, setFetchStatus] = useState("asleep");
  devLog(dictionaries);
  
  /** Fetch dictionaries */
  useEffect(() => {
    (async() => {
      try {
        setFetchStatus("fetching");
        const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/data/get/dictionaries/full`, {withCredentials: true});
        if(fetched.data.status === "ok") {
          setFetchStatus("fetched");
          setDictionaries(fetched.data.data);
        } else {
          setFetchStatus("error");
        }
      } catch (error) {
        devLog(error);
        setFetchStatus("error");
      }
    })();
  }, []);
  
  /** Fetch questions with dictionaries ? */
  
  const updateSingletonDict = async(nameToUpdateFront: string, nameToUpdateBack: string, newValue: any) => {
    try {
      // todo
      // notification(appContextV2.dispatchPanels, "Envoi de la mise à jour...", "information");
      // updateDataGet()({target: {type: "custom", name: nameToUpdateFront, value: newValue}});
      // const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/ent/b/update/business`, {
      //   [nameToUpdateBack]: newValue,
      // }, {withCredentials: true})).data;
      // if(fetched.status === "ok") {
      //   notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
      // } else {
      //   notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      // }
    } catch (error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<SubPageLayout status={fetchStatus as "asleep"} subPage={props.subPage}>
    
    {/*<Spaces n={2}/>*/}
  
    <div className={"text--center text--grey"}>
      Attention, les dictionnaires sont définis à l'échelle de l'entreprise, et pas à l'échelle de l'équipe.
    </div>
    <Spaces n={1}/>
    <RecruiterParametersDictionarySkills dictionary={dictionaries.skills} updateSingleton={updateSingletonDict}/>
    <Spaces n={1}/>
    {/*<RecruiterParametersDictionaryAdvantages dictionary={dictionaries.advantages} updateSingleton={updateSingletonDict}/>*/}
    {/*<Spaces n={1}/>*/}
    <RecruiterParametersDictionaryPositions dictionary={dictionaries.positions} updateSingleton={updateSingletonDict}/>
    <Spaces n={1}/>
    <RecruiterParametersDictionarySectors dictionary={dictionaries.sectors} updateSingleton={updateSingletonDict}/>
    <Spaces n={1}/>
    <RecruiterParametersDictionarySoftwares dictionary={dictionaries.softwares} updateSingleton={updateSingletonDict}/>
    <Spaces n={1}/>
    <RecruiterParametersDictionaryMobilities dictionary={dictionaries.mobilities} updateSingleton={updateSingletonDict}/>
    <Spaces n={1}/>
    <RecruiterParametersDictionaryLanguages dictionary={dictionaries.languages} updateSingleton={updateSingletonDict}/>
    {/*<Spaces n={1}/>*/}
    {/*<RecruiterParametersDictionaryQuestions dictionary={dictionaries} updateSingleton={updateSingletonDict}/>*/}
  
  </SubPageLayout>);
}

// export default class RecruiterParametersParameters extends React.Component<any, any> {
//
//   static contextType = AppContext;
//
//   constructor(props: any) {
//     super(props);
//     this.state = {
//       focusedValue: "",
//       fetched: {},
//       videoCode: "",
//       positions: [],
//       mobilities: [],
//       sectors: [],
//       languages: [],
//       softwares: [],
//       skills: [],
//       advantages: [],
//     }
//   }
//
//   componentDidMount = () => {
//     this.fetchProfile();
//     this.getDictionaries();
//     this.getAdvantages();
//   }
//
//   getDictionaries = async() => {
//     try {
//       const fetched: any = await abstractGetFetch('/data/get/dictionaries/full', {}, this.context.appState?.session?.mode !== "production");
//
//       console.log(fetched);
//
//       if(fetched.status === 'ok') {
//         this.setState({
//           skills: fetched.data.skills || [],
//           positions: fetched.data.positions || [],
//           mobilities: fetched.data.mobilities || [],
//           sectors: fetched.data.sectors || [],
//           languages: fetched.data.languages || [],
//           softwares: fetched.data.softwares || [],
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
//
//   getAdvantages = async() => {
//     try {
//       const fetched: any = await abstractGetFetch('/data/get/advantages', {});
//       if(fetched.status === 'ok') {
//         this.setState({
//           advantages: fetched.data || [],
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
//
//   sendValueToBusiness = async(key: string, value: any) => {
//     try {
//       const dataToSend: any = {};
//       dataToSend[key] = value;
//       if(value !== this.state.focusedValue) {
//         const answer: any = await abstractPutFetch("/ent/update/business", dataToSend);
//         if(answer.status === "ok") {
//           // this.fetchProfile();
//           this.context.showUpdate('Profil entreprise mis à jour');
//         }
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
//
//   fetchProfile = async() => {
//     try {
//       const fetched: any = await abstractGetFetch("/ent/get/my/business/profile");
//       if(fetched.status === "ok") {
//         this.setState({fetched: fetched.data});
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
//
//   setFocus = (value: any) => {
//     this.setState({focusedValue: value})
//   }
//
//   postFile = async(fileType: string, file: any) => {
//     try {
//       let fetched: any = {}
//       if(typeof file === "undefined") {
//         return "";
//       }
//
//       switch(fileType) {
//         case 'publicBan':
//           fetched = await abstractPostFileFetch("/upload/publicban", "publicBan", file);
//           break;
//         case 'addPicture':
//           fetched = await abstractPostFileFetch("/upload/business/gallery", "gallery", file);
//           const fileUrl: string = fetched.data?.data;
//           const fetched2: any = await abstractPostFetch("/ent/business/media/add", {
//             type: "image",
//             url: fileUrl,
//             description: "",
//           });
//           break;
//         case 'addVideo':
//           fetched = await abstractPostFetch("/ent/business/media/add", {
//             type: "video",
//             url: this.state.videoCode,
//             description: "",
//           });
//           this.setState({videoCode: ""})
//       }
//       if(fetched?.data?.status === 'ok' || fetched?.status === "ok") {
//         this.fetchProfile();
//       }
//     } catch (error) {
//       alert("Erreur")
//       console.log(error);
//     }
//   }
//
//   dragMedia = (index: number) => {
//     this.setState({
//       dragMediaFrom: index,
//     });
//   }
//
//   dropMedia = async (mediaId: string, direction: number) => {
//     try {
//
//       // await abstractPutFetch("/business/media/move", {
//       //   mediaId: mediaId,
//       //   direction: direction,
//       // });
//
//       // alert(`${mediaId}, ${businessId}, ${direction}`);
//
//       alert('A venir');
//
//     } catch(error) {
//       console.log(error);
//     }
//   }
//
//   deleteMedia = async (mediaId: string) => {
//     try {
//       let medias: any = [...this.state.fetched.medias];
//       medias = medias.filter((item: any) => item._id !== mediaId);
//       this.setState((ps: any) => ({
//         ...ps,
//         fetched: {
//           ...ps.fetched,
//           medias: medias,
//         },
//       }));
//       const answer: any = await abstractPutFetch("/ent/update/business", {"medias": medias});
//       if(answer.status === "ok") {
//         this.context.showUpdate("Le contenu multimedia a été supprimé");
//       }
//     } catch(error) {
//       console.log(error);
//     }
//   }
//
//   updateValueToBusiness = async(key: string, value: any) => {
//     try {
//
//       // Handle front
//       const business: any = {...this.state.fetched};
//       business[key] = value;
//       this.setState((pv: any)=>({
//         ...pv,
//         fetched: business,
//       }));
//
//       await this.sendValueToBusiness(key, value);
//
//     } catch(error) {
//       console.log(error);
//     }
//   }
//
//   deleteDictionaryEntry = async(args: {dictionaryId: string, key: string, stringValue: string, deleteInProfiles: boolean}) => {
//     try {
//
//       console.log(args);
//
//       const fetched: any = await abstractPutFetch('/data/delete/dictionary/entry', {
//         dictionaryId: args.dictionaryId,
//         key: args.key,
//         stringValue: args.stringValue,
//         deleteInProfiles: args.deleteInProfiles
//       });
//
//       if(fetched.status === "ok") {
//         this.context.showUpdate('Succès');
//         this.getDictionaries();
//       }
//
//     } catch(error) {
//       console.log(error);
//     }
//   }
//
//   updateDictionaryEntry = async(args: {dictionaryId: string, key: string, stringValue: string, newStringValue: string, updateInProfiles: boolean}) => {
//     try {
//
//       const fetched: any = await abstractPutFetch('/data/update/dictionary/entry', {
//         dictionaryId: args.dictionaryId,
//         key: args.key,
//         stringValue: args.stringValue,
//         newStringValue: args.newStringValue,
//         updateInProfiles: args.updateInProfiles
//       });
//
//       if(fetched.status === "ok") {
//         this.context.showUpdate('Succès');
//         this.getDictionaries();
//       }
//
//     } catch(error) {
//       console.log(error);
//     }
//   }
//
//   render() {
//
//     const language: string = this.context.appState.language;
//     const title: string = language === 'FR' ? "Paramètres" : "Parameters";
//
//     const medias: any = this.state.fetched?.medias || [];
//
//     const isVideoCodeOk: boolean = !!this.state.videoCode;
//
//     return (
//       <>
//         <SubPageLayout title={title} subPage={this.props.subPage} small={true} status={"ok"}>
//
//           <Spaces n={2}/>
//
//           {/*------------------------------      -------------------*/}
//
//           <h1 className={"profile-edit__title"}>Options de publicité (à l'échelle de l'entreprise)</h1>
//
//           <table>
//             <tr>
//               <td>
//                 Les offres doivent-elle apparaitre sur la page entreprise ?
//               </td>
//               <td>
//                 <button className={`btn-check ${this.state.fetched?.showOtherJobsInBusiness ? "btn-check--checked":""}`}
//                         onClick={()=>{this.updateValueToBusiness("showOtherJobsInBusiness", true)}}>
//                   Oui
//                 </button>
//                 <button className={`btn-check ${!this.state.fetched?.showOtherJobsInBusiness ? "btn-check--checked":""}`}
//                         onClick={()=>{this.updateValueToBusiness("showOtherJobsInBusiness", false)}}>
//                   Non
//                 </button>
//               </td>
//             </tr>
//             {/*<tr>*/}
//             {/*  <td>*/}
//             {/*    Les candidats de la communauté peuvent-ils consulter vos annonces ?*/}
//             {/*  </td>*/}
//             {/*  <td>*/}
//             {/*    <button className={`btn-check ${this.state.fetched?.showJobToCommunity ? "btn-check--checked":""}`}*/}
//             {/*            onClick={()=>{this.updateValueToBusiness("showJobToCommunity", true)}}>*/}
//             {/*      Oui*/}
//             {/*    </button>*/}
//             {/*    <button className={`btn-check ${!this.state.fetched?.showJobToCommunity ? "btn-check--checked":""}`}*/}
//             {/*            onClick={()=>{this.updateValueToBusiness("showJobToCommunity", false)}}>*/}
//             {/*      Non*/}
//             {/*    </button>*/}
//             {/*  </td>*/}
//             {/*</tr>*/}
//             <tr>
//               <td>
//                 Acceptez-vous de partager anonymement vos questions recruteur favorites ?
//               </td>
//               <td>
//                 <button className={`btn-check ${this.state.fetched?.shareTemplatesWithCommunity ? "btn-check--checked":""}`}
//                         onClick={()=>{this.updateValueToBusiness("shareTemplatesWithCommunity", true)}}>
//                   Oui
//                 </button>
//                 <button className={`btn-check ${!this.state.fetched?.shareTemplatesWithCommunity ? "btn-check--checked":""}`}
//                         onClick={()=>{this.updateValueToBusiness("shareTemplatesWithCommunity", false)}}>
//                   Non
//                 </button>
//               </td>
//             </tr>
//             <tr>
//               <td>
//                 Acceptez-vous de partager anonymement vos habitudes de navigation avec la plateforme à des fins statistiques ?
//               </td>
//               <td>
//                 <button className={`btn-check ${this.state.fetched?.shareHabitsWithMyshortlist ? "btn-check--checked":""}`}
//                         onClick={()=>{this.updateValueToBusiness("shareHabitsWithMyshortlist", true)}}>
//                   Oui
//                 </button>
//                 <button className={`btn-check ${!this.state.fetched?.shareHabitsWithMyshortlist ? "btn-check--checked":""}`}
//                         onClick={()=>{this.updateValueToBusiness("shareHabitsWithMyshortlist", false)}}>
//                   Non
//                 </button>
//               </td>
//             </tr>
//           </table>
//
//
//           {/*------------------------------      -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Ordre de tri</h1>
//
//           <p>Si vous notez les candidats de 1 à 5, quelle est la meilleure note pour vous ?</p>
//           <p className={"text--center"}>
//             <button className={`btn-check ${!this.state.fetched?.invertRanking ? "btn-check--checked":""}`}
//                     onClick={()=>{this.updateValueToBusiness("invertRanking", false)}}>
//               La meilleure note est 1
//             </button>
//             <button className={`btn-check ${this.state.fetched?.invertRanking ? "btn-check--checked":""}`}
//                     onClick={()=>{this.updateValueToBusiness("invertRanking", true)}}>
//               La meilleure note est 5
//             </button>
//           </p>
//
//
//           {/*------------------------------   Dictionnaire des langues   -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Dictionnaire des compétences</h1>
//
//           <DisplayDictionary keyValue={"skill"} data={this.state.skills || []} updateDictionaryEntry={this.updateDictionaryEntry} deleteDictionaryEntry={this.deleteDictionaryEntry} />
//
//           {/*------------------------------   Dictionnaire des langues   -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Dictionnaire des avantages de l'entreprise</h1>
//
//           <DisplayDictionary keyValue={"advantage"} data={this.state.advantages || []} updateDictionaryEntry={this.updateDictionaryEntry} deleteDictionaryEntry={this.deleteDictionaryEntry}  />
//
//           {/*------------------------------   Dictionnaire des langues   -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Dictionnaire des fontions</h1>
//
//           <DisplayDictionary keyValue={"position"} data={this.state.positions || []} updateDictionaryEntry={this.updateDictionaryEntry} deleteDictionaryEntry={this.deleteDictionaryEntry}  />
//
//           {/*------------------------------   Dictionnaire des secteurs   -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Dictionnaire des secteurs d'activité</h1>
//
//           <DisplayDictionary keyValue={"sector"} data={this.state.sectors || []} updateDictionaryEntry={this.updateDictionaryEntry} deleteDictionaryEntry={this.deleteDictionaryEntry}  />
//
//           {/*------------------------------   Dictionnaire des logiciels   -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Dictionnaire des logiciels</h1>
//
//           <DisplayDictionary keyValue={"software"} data={this.state.softwares || []} updateDictionaryEntry={this.updateDictionaryEntry} deleteDictionaryEntry={this.deleteDictionaryEntry}  />
//
//           {/*------------------------------   Dictionnaire des mobilités   -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Dictionnaire des mobilités</h1>
//
//           <DisplayDictionary keyValue={"mobility"} data={this.state.mobilities || []} updateDictionaryEntry={this.updateDictionaryEntry} deleteDictionaryEntry={this.deleteDictionaryEntry}  />
//
//           {/*------------------------------   Dictionnaire des langues   -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Dictionnaire des langues</h1>
//
//           <DisplayDictionary keyValue={"language"} data={this.state.languages || []} updateDictionaryEntry={this.updateDictionaryEntry} deleteDictionaryEntry={this.deleteDictionaryEntry} />
//
//           {/*------------------------------   Dictionnaire des langues   -------------------*/}
//           <Spaces n={2}/>
//           <h1 className={"profile-edit__title"}>Questions enregistrées</h1>
//           <p className={"text--center"}>
//             À venir
//           </p>
//
//           {/*<DisplayDictionary data={this.state.languages || []} />*/}
//
//
//         </SubPageLayout>
//       </>
//     );
//   }
// }
//
//
//
interface IDisplayDictionaryProps {
  data: any[],
  updateDictionaryEntry: Function,
  deleteDictionaryEntry: Function,
  keyValue: string
}
//
export class DisplayDictionary extends React.Component<IDisplayDictionaryProps, any> {
  
  constructor(props: IDisplayDictionaryProps) {
    super(props);
    this.state = {
      expanded: false,
    }
  }

  deleteDictionnaryEntry = async(entryId: string) => {
    try {

    // const fetched: any = await abstractPutFetch("/data/delete/dictionary/entry", {
    //   entryId: entryId,
    // });

    } catch(error) {
      console.log(error);
    }
  }

  render() {

    if(!this.props.data || this.props.data.length === 0) {
      return(<p className={"text--center text--magnified15x"}>
        Aucune donnée
      </p>)
    }

    if(this.props.data.length > 20 && !this.state.expanded) {
      return(<>
        {[...this.props.data || []].filter((item: any)=>(!!item && !!item.stringValue)).sort((a, b)=>(a.stringValue > b.stringValue) ? 1 : -1).splice(0,12).map((item: any)=>(<>
          <DictionaryEntry {...this.props} item={item} />
        </>))}

        <hr/>
        <p className={"text--center"}>
          <button className={"btn--hollow text--magnified2x"} onClick={()=>{
            this.setState((ps: any) => ({expanded: !ps.expanded}))
          }}>...</button>
        </p>

      </>)
    }

    return(<>
      {this.props.data?.filter((item: any)=>(!!item && !!item.stringValue)).sort((a, b)=>(a.stringValue > b.stringValue) ? 1 : -1).map((item: any)=>(<>
        <DictionaryEntry {...this.props} item={item} />
      </>))}

      {
        this.props.data.length > 20 && <>
          <hr/>
          <p className={"text--center"}>
            <button className={"btn--hollow text--magnified2x"} onClick={()=>{
              this.setState((ps: any) => ({expanded: !ps.expanded}))
            }}>...</button>
          </p>
        </>
      }

    </>);
  }
}
//
class DictionaryEntry extends React.Component <any,any> {

  constructor(props: any) {
    super(props);
    this.state = {
      step: "default",
      newStringValue: "",
    }
  }

  render() {

    // const displayActionAll: boolean = !["language", "software"].includes(this.props.keyValue)

    const displayActionAll: boolean = true;

    return(<>
      <div className={"hover-grey space-flex--space-between margin--light"}>

        <span>
          {(this.state.step === "default" || this.state.step === "delete") && <>
            {this.props.item.stringValue}
          </>}
          {this.state.step === "update" && <>
            <input type={"text"} className={"input--wide--flex"} placeholder={this.props.item.stringValue}
                   value={this.state.newStringValue} onChange={(e)=>{
              this.setState({newStringValue: e.target.value})
            }}/>
          </>}
        </span>


        <span className={"space-flex--right"}>

          {/*----------------------------- First choice: edit or delete*/}
          {this.state.step === "default" && <>
            
            <span className={"text--minified2x text--grey"}>L'édition revient vite</span>
            {/*{*/}
            {/*  displayActionAll &&  <button className={"btn-inline-dictionary animation-fade-in"} onClick={()=>{*/}
            {/*    this.setState({step: "update"})*/}
            {/*  }}>*/}
            {/*    Éditer*/}
            {/*  </button>*/}
            {/*}*/}
            
            {/*<button className={"btn-inline-dictionary animation-fade-in"} onClick={()=>{*/}
            {/*  this.setState({step: "delete"})*/}
            {/*}}>*/}
            {/*  Supprimer*/}
            {/*</button>*/}

          </>}

          {/*----------------------------- Delete*/}
          {this.state.step === "delete" && <>
           <button className={"btn-inline-dictionary animation-fade-in"} onClick={async()=>{
            await this.props.deleteDictionaryEntry({
              dictionaryId: this.props.item._id,
              key: this.props.keyValue,
              stringValue: this.props.item.stringValue,
              deleteInProfiles: false});
            this.setState({step: "default", newStringValue: ""})
           }}>
             Supprimer
           </button>
            {
              displayActionAll && <button className={"btn-inline-dictionary animation-fade-in"} onClick={async()=>{
                await this.props.deleteDictionaryEntry({
                  dictionaryId: this.props.item._id,
                  key: this.props.keyValue,
                  stringValue: this.props.item.stringValue,
                  deleteInProfiles: true});
                this.setState({step: "default", newStringValue: ""})
              }}>
                Supprimer dans les profils
              </button>
            }

            <button className={"btn-inline-dictionary animation-fade-in"} onClick={()=>{
              this.setState({step: "default", newStringValue: ""})
            }}>
              Annuler
            </button>
          </>}

          {/*----------------------------- Edit*/}
          {this.state.step === "update" && <>
            <button disabled={!this.state.newStringValue} className={"btn-inline-dictionary animation-fade-in"} onClick={async()=>{
              await this.props.updateDictionaryEntry({
                dictionaryId: this.props.item._id,
                key: this.props.keyValue,
                stringValue: this.props.item.stringValue,
                newStringValue: this.state.newStringValue,
                updateInProfiles: false});
              this.setState({step: "default", newStringValue: ""})
            }}>
              Modifier
            </button>

            {
              displayActionAll && <button disabled={!this.state.newStringValue} className={"btn-inline-dictionary animation-fade-in"} onClick={async()=>{
                await this.props.updateDictionaryEntry({
                  dictionaryId: this.props.item._id,
                  key: this.props.keyValue,
                  stringValue: this.props.item.stringValue,
                  newStringValue: this.state.newStringValue,
                  updateInProfiles: true});
                this.setState({step: "default", newStringValue: ""})
              }}>
                Modifier dans les profils
              </button>
            }

            <button className={"btn-inline-dictionary animation-fade-in"} onClick={()=>{
              this.setState({step: "default", newStringValue: ""})
            }}>
              Annuler
            </button>
          </>}

        </span>
      </div>
    </>);
  }

}
//
