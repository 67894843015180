import React, {useEffect, useReducer, useState,} from "react";
import "./componentsApp/style/App.scss";
import {BrowserRouter as Router} from "react-router-dom";
import {AppProvider, IAppV2Context} from "./componentsApp/context/AppContext";
import {IAppV2Props} from "./componentsApp/state/IAppV2";
import {initialSession} from "./componentsApp/state/initialSession";
import {initialNavigation} from "./componentsApp/state/initialNavigation";
import {initialPanels} from "./componentsApp/state/initialPanels";
import {sessionReducer} from "./componentsApp/reducers/sessionReducer";
import {navigationReducer} from "./componentsApp/reducers/navigationReducer";
import {panelsReducer} from "./componentsApp/reducers/panelsReducer";
import welcomeConsole from "../utils/dom/welcomeConsole";
import {escapeKeyListener} from "../utils/listeners/escapeKeyListener";
import {SwitchAppStatus} from "./componentsApp/SwitchAppStatus";
import {devLog} from "../utils/devLog/devLog";
import {fetchSession} from "./componentsApp/fetchers/fetchSession";
import setSession from "./componentsApp/fetchers/setSession";
import axios from "axios";
import {notificationsReducer} from "./componentsApp/reducers/notificationsReducer";
import {initialNotifications} from "./componentsApp/state/initialNotifications";

export {AppV2};

const AppV2 = (props: IAppV2Props) => {
  
  useEffect(() => {
    welcomeConsole();
  }, []);
  
  /** ----- App state */
  const [appSession, dispatchSession] = useReducer(sessionReducer, initialSession);
  const [appNavigation, dispatchNavigation] = useReducer(navigationReducer, initialNavigation);
  const [appPanels, dispatchPanels] = useReducer(panelsReducer, initialPanels);
  const [appNotifications, dispatchNotifications] = useReducer(notificationsReducer, initialNotifications);
  const [globalResearch, setGlobalResearch] = useState<string>("");
  
  /** ----- Session fetcher */
  const [sessionFetchedStatus, setSessionFetchedStatus] = useState("asleep");
  const [fetchedSession, setFetchedSession] = useState({});
  
  const [displayWelcome, setDisplayWelcome] = useState(true);
  
  /** ----- Fetches current session */
  useEffect(() => {
    fetchSession({dispatchPanels, setSessionFetchedStatus, setFetchedSession})();
  }, []);
  
  /** ----- Sets session */
  useEffect(() => {
    setSession({sessionFetchedStatus, fetchedSession, dispatchSession})
  }, [sessionFetchedStatus]);
  
  /** ---- Load parameters */
  useEffect(() => {
    (async function() {
      try {
        const answer = await axios.get(`${process.env.REACT_APP_API_URL}/session/s/parameters`, {withCredentials: true});
        dispatchSession({type: 'SET_PARAMETERS', value: answer.data});
      } catch(error) {
        devLog(error);
      }
    })()
  }, []);
  
  /** ---- Load and Reload notifications (Not a good practise) */
  useEffect(() => {
    (async function() {
      try {
        const answer = await axios.get(`${process.env.REACT_APP_API_URL}/business/all/notifications`, {withCredentials: true});
        dispatchNotifications({type: 'SET_NOTIFICATIONS_RECRUITER', value: answer.data.data});
      } catch(error) {
        devLog(error);
      }
    })();
    const timer = setInterval(async() => {
      const answer = await axios.get(`${process.env.REACT_APP_API_URL}/business/all/notifications`, {withCredentials: true});
      dispatchNotifications({type: 'SET_NOTIFICATIONS_RECRUITER', value: answer.data.data});
    }, 30 * 60 * 1000);
    return () => {
      clearInterval(timer);
    }
  }, []);
  
  useEffect(() => {
    document.title = `Recrutez mieux, plus vite`;
  }, []);
  
  /** ----- Add the escape key listener */
  useEffect(escapeKeyListener(() => {
    dispatchPanels({type: "CLOSING_WINDOW", value: null});
    const timer = setTimeout(() => {
      dispatchPanels({type: "CLOSE_WINDOW", value: null});
      clearTimeout(timer);
    }, 130);
  }), []);
  
  /** ----- Sets App context */
  let contextValue: IAppV2Context = {
    appSession: appSession,
    appNavigation: appNavigation,
    appPanels: appPanels,
    appNotifications:appNotifications,
    dispatchNotifications:dispatchNotifications,
    globalResearch: globalResearch,
    setGlobalResearch: setGlobalResearch,
    dispatchSession: dispatchSession,
    dispatchNavigation: dispatchNavigation,
    dispatchPanels: dispatchPanels,
    fetchSession: fetchSession({dispatchPanels, setSessionFetchedStatus, setFetchedSession}),
    displayWelcome,
    setDisplayWelcome
  };
  
  devLog("contextValue", contextValue);
  
  return (<AppProvider value={contextValue}>
    
    <Router>
      
      <SwitchAppStatus/>
    
    </Router>
  
  </AppProvider>);
}
