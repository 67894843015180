import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import Spaces from "../../../../../components/Spaces";

export default (props: any) => {
  
  const [showQuestion, setShowQuestion] = useState(false);
  
  useEffect(() => {
    (() => {
      setShowQuestion(props.mission.defaultQuestions?.currentSalary);
      if(!props.mission.defaultQuestions?.currentSalary) {
        props.nextStep();
      }
    })();
  }, []);
  
  if(!showQuestion) {
    return (<><Spaces n={15}/></>)
  }
  
  return (<div>
    
    <Spaces n={2}/>
    
    <div className={"assistant--v2-title"}>
      Quelle est votre niveau de rémunération brute annuelle actuelle ?
    </div>
    <Spaces n={0}/>
    <div className={"text--grey text--center"}>
      En euros
    </div>
    <Spaces n={2}/>
    
    <section className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
      <input type={"number"} className={"assistant--v2-input assistant--v2-input--full-width"}
             value={props.answer.defaultAnswers.currentSalary}
             onChange={(e) => {
               props.updateAnswer()({
                 target: {
                   type: "custom",
                   name: "defaultAnswers.currentSalary",
                   value: e.target.value
                 }
               });
             }}/>
      <Spaces n={2}/>
      <button className={"btn-main"} disabled={!props.answer.defaultAnswers.currentSalary}
              onClick={() => { props.nextStep();}}>
        Valider ma réponse
      </button>
    </section>
  
  </div>);
}