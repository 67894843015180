import React from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const isDurationSet: boolean = !!props.form.duration;
  
  const isButtonActive: boolean = props.form.duration >= 0 && props.form.duration < 36;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Durée en mois
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
      
      <input type={"number"} className={"assistant--v2-input assistant--v2-input--center"} name={"duration"}
             value={props.form.duration} onChange={props.updateForm()}/>
      
      <Spaces n={1}/>
      
      <button disabled={!isButtonActive && isDurationSet} className={"btn-assistant-main"}
              onClick={async() => {
                props.wizard.goToStep("salaryBy");
              }}>
        {isDurationSet && "Suivant"}
        {!isDurationSet && "Ne pas renseigner"}
      </button>
    
    </div>
  
  </section>);
}