import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import NotificationDisplay from "./DisplayNotification";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const displayCandidate = (candidateId: string) => async() => {
    appContextV2.dispatchPanels({
      type: "OPEN_WINDOW", value: {
        type: "candidateWindowV2",
        data: {
          updateCandidacyFront: () => {},
          keywords: "",
          category: "pool",
          candidateId: candidateId,
          missionId: "",
          basicData: {
            firstName: "",
            lastName: "",
            avatarUrl: "",
          },
          frontUpdatePool: {
            updateCandidatesBulk: () => {
            },
            reloadCandidates: () => {
            },
            addCandidateFront: () => {
            }
          },
          frontUpdateMission: {
            updateCandidaciesBulk: () => {},
          },
        }
      }
    })
  };
//
  switch(props.item?.actionType) {

    case 'newInvite':
      return (<NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"linkButton"}
        link={`/business/parameters/team`}
        btnContent={"Équipes"}
        seenByMe={props.item.seenByMe}
        message={`${props.item.actionDoneByTrigram} a invité ${props.item.data} à rejoindre l'équipe`}/>);

    case "inviteAccepted":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"linkButton"}
        link={`/business/parameters/team`}
        btnContent={"Équipes"}
        seenByMe={props.item.seenByMe}
        message={`${props.item.data} a accepté l'invitation à rejoindre l'équipe`}/>

    case "inviteRefused":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"linkButton"}
        link={`/business/parameters/team`}
        btnContent={"Équipes"}
        seenByMe={props.item.seenByMe}
        message={`${props.item.data} a refusé l'invitation à rejoindre l'équipe`}/>

    case "newMission":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"linkButton"}
        link={`/business/jobs`}
        btnContent={"Postes"}
        seenByMe={props.item.seenByMe}
        message={`${props.item.actionDoneByTrigram} a crée un nouveau poste pour la fonction de "${props.item.data}"`}/>

    case "newMissionStatus":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"linkButton"}
        link={`/business/jobs`}
        btnContent={"Postes"}
        seenByMe={props.item.seenByMe}
        message={`${props.item.actionDoneByTrigram}  a changé le statut du poste "${props.item.data2}" en "${props.item.data === "draft" ? "brouillon":""}${props.item.data === "collaboration" ? "collaboration":""}${props.item.data === "published" ? "publiée":""}"`}/>

    case "movedCandidacy":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"linkButton"}
        link={`/business/mission/${props.item.toMissionId}`}
        btnContent={"Kanban"}
        seenByMe={props.item.seenByMe}
        message={`${props.item.actionDoneByTrigram}  a déplacé ${props.item.data2} dans "${props.item.data3}" pour le poste de "${props.item.data}"`}/>

    case "newCommunity":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"linkButton"}
        link={`/business/communities`}
        btnContent={"Communautés"}
        seenByMe={props.item.seenByMe}
        message={`${props.item.actionDoneByTrigram} a créé une nouvelle communauté`}/>

    case "newMessage":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"button"}
        btnContent={"Candidat"}
        function={displayCandidate(props.item.onCandidateId)}
        seenByMe={props.item.seenByMe}
        message={`${props.item.data} a envoyé un ${props.item.data3}`}/>

    case "newTrigram":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"button"}
        btnContent={"Candidat"}
        function={displayCandidate(props.item.onCandidateId)}
        seenByMe={props.item.seenByMe}
        message={`${props.item.actionDoneByTrigram} a laissé une note à propos de "${props.item.data}"`}/>

    case "newEvaluation":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"button"}
        btnContent={"Candidat"}
        function={displayCandidate(props.item.onCandidateId)}
        seenByMe={props.item.seenByMe}
        message={`${props.item.data} a laissé feedback manager à propos de "${props.item.data2}"`}/>

    case "newCv":
      return <NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"button"}
        btnContent={"Candidat"}
        function={displayCandidate(props.item.onCandidateId)}
        seenByMe={props.item.seenByMe}
        message={`${props.item.actionDoneByTrigram} a enregistré un CV pour ${props.item.data}`}/>

    case 'newDoc':
      return (<NotificationDisplay
        dateOfCreation={props.item?.dateOfCreation}
        btnType={"button"}
        btnContent={"Candidat"}
        function={displayCandidate(props.item.onCandidateId)}
        seenByMe={props.item.seenByMe}
        message={`${props.item.data.actionDoneByTrigram} a enregistré un document pour ${props.item.data}`}/>);

    default:
      return (<></>)
  }

}