import AppContext, {IAppContext, IAppV2Context} from "../../../componentsApp/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import FirstLoginRecruiter from "./componentsFirstLoginAssistant/FirstLoginRecruiter";
import Spaces from "../Spaces";
import {withRouter} from 'react-router-dom'
import FirstLoginCandidate from "./componentsFirstLoginAssistant/FirstLoginCandidate";
import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";

const WrapperWithRouter = withRouter(FirstLoginAssistantWrapper);

export default function FirstLoginAssistant(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language: string = appContextV2.appSession.app.language;
  
  switch(appContextV2.appSession.app.space) {
    
    case 'recruiter':
      return (<WrapperWithRouter>
        <FirstLoginRecruiter/>
      </WrapperWithRouter>);
    
    case 'candidate':
      return (<WrapperWithRouter>
        <FirstLoginCandidate/>
      </WrapperWithRouter>);
    
    default:
      return (<></>);
  }
}

function FirstLoginAssistantWrapper(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language: string = appContextV2.appSession.app.language;
  
  /** Fetcher */
  const [fetchStatus, setFetchStatus] = useState("asleep");
  const [fetched, setFetched] = useState({status: "", data: ""});
  
  const logout = async() => {
    try {
      setFetchStatus("fetching");
    
      const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/session/sl/logout`, {withCredentials: true});
    
      if(fetched.data.status !== "ok") {
        return setFetchStatus("error");
      }
    
      await setFetched(fetched.data);
      setFetchStatus("fetched");
    
    } catch(error) {
      return setFetchStatus("error");
      devLog(error);
    }
  };
  
  useEffect(() => {
    (async() => {
      switch(fetchStatus) {
        
        case 'fetched':
          switch(fetched.status) {
            
            case 'ok':
              await appContextV2.fetchSession(`${process.env.REACT_APP_API_URL}/session/s/whoami`, {
                method: "GET",
                credentials: "include",
              });
              props.history.push('/');
              appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
              break;
            
            case 'error':
              alert('Erreur');
              break;
              
            default:
            
          }
          break;
        
        case 'error':
          alert('Erreur');
          break;
        
        case 'fetching':
        case 'asleep':
        default:
      }
    })();
  }, [fetchStatus]);
  
  return (<div className={`window-container window-container--appear`}>
    <section className={`window window--appear`}>
      <article className={"animation-fade-in--faster"}>
        <p className={"text--right"}>
          <button className={'btn--hollow text--hollow animation--fade-in'} onClick={() => {
            if(fetchStatus == "asleep") {
              logout();
            }
          }}>
            {
              ["asleep", "error"].includes(fetchStatus) && <>
                {language === "FR" && <>Se déconnecter</>}
                {language === "EN" && <>Logout</>}
              </>
            }
            {["fetching", "fetched"].includes(fetchStatus) && <>En cours...</>}
          </button>
        </p>
        <Spaces n={1}/>
        {props.children}
      </article>
    </section>
  </div>);
}

