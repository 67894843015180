import React, {useContext} from "react";
import {BusinessAddMember} from "./BusinessAddMember";
import {BusinessMember} from "./BusinessMember";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import {BusinessInvited} from "./BusinessInvited";
import Spaces from "../../../sharedComponents/components/Spaces";

export {BusinessTeam};

const BusinessTeam = (props: {
  
  item: any;
  teamId: string,
  members: any[];
  invited: any[];
  inviteTeamMemberFront: Function,
  deleteInviteFront: Function,
  deleteTeamFront: Function,
  manageTeamFront: Function,
  updateInviteFront: Function,
  deleteMemberFront: Function,
  updateMemberFront: Function,
  drag: Function,
  drop: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  let maxSlots = 20;

  return(<div className={"teams__team"}
              onDragOver={(e) => {e.preventDefault();}}
              onDrop={() => {props.drop(props.teamId)}}>
    <div className={"teams__team__title1"}>
      Équipe
    </div>
    <div className={"teams__team__title2"}>
      {props.item.name}
    </div>
    <div className={"text--center"}>
      <button className={"teams__team__button"} onClick={()=>{
        appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
            type: "manageTeam",
            data: {
              teamId: props.item._id,
              teamName: props.item.name,
              teamDescription: props.item.description,
              size: (props.members || []).length + (props.invited || []).length,
              deleteTeamFront: props.deleteTeamFront,
              manageTeamFront: props.manageTeamFront,
            }
          }});
      }}>Paramètres</button>
    </div>
    <div className={"text--center text--grey"}>
      {props.members?.length + props.invited?.length} / {maxSlots}
    </div>

    <Spaces n={1}/>
    
    {props.members?.filter((item: any) => item.role === "admin").map((item: any, key: number) => (<BusinessMember drag={props.drag} teamId={props.teamId}
              deleteMemberFront={props.deleteMemberFront} updateMemberFront={props.updateMemberFront} item={item} key={key}/>))}
  
    {props.members?.filter((item: any) => item.role === "manager").map((item: any, key: number) => (<BusinessMember drag={props.drag} teamId={props.teamId}
              deleteMemberFront={props.deleteMemberFront} updateMemberFront={props.updateMemberFront} item={item} key={key}/>))}
  
    {props.members?.filter((item: any) => item.role === "user").map((item: any, key: number) => (<BusinessMember drag={props.drag} teamId={props.teamId}
             deleteMemberFront={props.deleteMemberFront} updateMemberFront={props.updateMemberFront} item={item} key={key}/>))}
  
    {props.invited?.filter((item: any) => item.role === "admin").map((item: any, key: number) => (<BusinessInvited drag={props.drag} teamId={props.teamId} deleteInviteFront={props.deleteInviteFront} updateInviteFront={props.updateInviteFront} item={item} key={key}/>))}
  
    {props.invited?.filter((item: any) => item.role === "manager").map((item: any, key: number) => (<BusinessInvited drag={props.drag} teamId={props.teamId} deleteInviteFront={props.deleteInviteFront} updateInviteFront={props.updateInviteFront} item={item} key={key}/>))}
  
    {props.invited?.filter((item: any) => item.role === "user").map((item: any, key: number) => (<BusinessInvited drag={props.drag} teamId={props.teamId} deleteInviteFront={props.deleteInviteFront} updateInviteFront={props.updateInviteFront} item={item} key={key}/>))}
    
    {((props.members?.length + props.invited?.length) <= maxSlots) && <BusinessAddMember teamName={props.item.name} inviteTeamMemberFront={props.inviteTeamMemberFront} teamId={props.item._id} />}

  </div>);
}
