
export default (ISODate: string, language: 'FR'|'EN', short: boolean = false,) => {
  
  const unitDays: string = (()=>{
    switch(language) {
      case 'FR':
        switch(short) {
          case false: return "jour(s)";
          case true: return "j";
        }
      default:
        switch(short) {
          case false: return "day(s)";
          case true: return "d";
        }
    }
  })();
  
  const unitHours: string = (()=>{
    switch(language) {
      case 'FR':
        switch(short) {
          case false: return "heure(s)";
          case true: return "h";
        }
      default:
        switch(short) {
          case false: return "hour(s)";
          case true: return "h";
        }
    }
  })();
  
  const unitMinutes: string = (()=>{
    switch(language) {
      case 'FR':
        switch(short) {
          case false: return "minute(s)";
          case true: return "min";
        }
      default:
        switch(short) {
          case false: return "minute(s)";
          case true: return "min";
        }
    }
  })();
  
  const dateOfInscription: Date = new Date(ISODate);
  const dateNow: Date = new Date();
  const timeSinceInscription: number = (dateNow.getTime() - dateOfInscription.getTime()) / 1000;
  
  const days: number = Math.floor ( timeSinceInscription / (60*60*24) )
  const hours: number = Math.floor ( ( timeSinceInscription - days * 24*60*60 ) / (60 * 60 ) )
  const minutes: number = Math.floor ( (timeSinceInscription - days*24*60*60 - hours*60*60) / 60 )
  
  if(days>0){return `${days} ${unitDays}`}
  if(hours>0){return `${hours} ${unitHours}`}
  return `${minutes} ${unitMinutes}`;
  
}
