import React from "react";
import Spaces from "../../sharedComponents/components/Spaces";

export default (props: any) => {
  
  return (<div>
  
    <Spaces n={3}/>
    
    <div className={"container-lp"}>
      
      <section className={"landing-page--v2__side-by-side"}>
        
        <article className={"landing-page--v2__side-by-side__box landing-page--v2__side-by-side__box1"}>
          <div className={"landing-page--v2__video-container landing-page--v2__video-container--grey"}>
            <video width="100%" height="100%" playsInline={true} controls={false} autoPlay={true} loop={true} muted={true}>
              <source src="https://static.myshortlist.co/videos/v2_kanban.webm" type="video/webm"/>
              <source src="https://static.myshortlist.co/videos/v2_kanban.mp4" type="video/mp4"/>
              <source src="https://static.myshortlist.co/videos/v2_kanban.ogg" type="video/ogg"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </article>
        
        <article className={"landing-page--v2__side-by-side__box landing-page--v2__side-by-side__box2"}>
          <h2 className={"landing-page--v2__title"}>
            Triez et centralisez toutes vos candidatures
          </h2>
          <Spaces n={0}/>
          <div className={"landing-page--v2__text"}>
            Myshortlist.co trie et centralise toutes vos candidatures dans une vue kanban simplifiée. Des actions programmées vous permettent de gagner du temps
            sur toutes les tâches chronophages.
          </div>
        </article>
      
      </section>
    
    </div>
  
    <Spaces n={3}/>
  </div>);
}