import React, {useContext, useState} from "react";
import Spaces from "../../../Spaces";
import {notification} from "../../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";

export default (props: {
  fieldToUpdate: string,
  modifier: Function,
  mission: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [value, setValue]: [number, Function] = useState(props.mission?.yearsOfExperience);
  
  return (<>
    <div className={"white-container white-container--grey"}>
      <input className={"input--v2"} type={"number"} value={value} onChange={(e) => {
        (e.target.value.length < 4) && (setValue(e.target.value));
      }}/>
    </div>
    
    <Spaces n={0}/>
    
    <div className={"text--center"}>
      <button className={"btn-secondary"} onClick={() => {
        props.modifier("yearsOfExperience", value);
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        Mettre à jour
      </button>
    </div>
  </>);
  
}