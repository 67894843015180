import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  
  /** Delay page showing if this step must be jumped */
  const [display, setDisplay] = useState(false);
  
  useEffect(() => {
    const doesSavedLocationExist: boolean = !!props.defaultLocation?.postalCode && !!props.defaultLocation?.country;
    if(!doesSavedLocationExist) {
      props.wizard.goToStep("location");
    } else {
      setDisplay(true);
    }
  }, []);
  
  if(!display) {
    return(<></>);
  }
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Une adresse à été détectée ( {props.defaultLocation.city} {props.defaultLocation.postalCode} {props.defaultLocation.country} ), veux-tu l'utiliser comme lieu du poste ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "location", value: props.defaultLocation}});
                props.wizard.goToStep('lastAssistantStep');
              }}>
        Oui, utiliser cette adresse
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.wizard.goToStep('location');
              }}>
        Non, définir une autre adresse
      </button>
    
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center text--grey assistant--v2-container--no-flex"}>
    </div>
  
  </section>);
}