import React, {useContext} from "react";
import {ButtonBigPlusV2} from "../../../sharedComponents/components/Buttons/ButtonBigPlus";
import Spaces from "../../../sharedComponents/components/Spaces";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<div className={"space-flex--center"}>
    
    <button className={"btn-plus--v2 btn-plus--v2--wide-margin"} title={"Importer une question sauvegardee"}
            onClick={() => props.openPreset(props.id)}>
      <img src={"https://static.myshortlist.co/btn-download--blue.svg"} height={'20px'} width={'20px'}/>
    </button>
    
    <ButtonBigPlusV2 cb={() => appContextV2.dispatchPanels({
      type: 'OPEN_EDITOR_PANEL', value: {
        type: 'default',
        data: {
          addQuestion: props.addQuestion,
          formId: props.id,
        }
      }
    })} title={"Ajoute une question"}/>
  </div>);
}