import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {abstractPostFetch, abstractPutFetch} from "../../../../../utils/abstractFetch";
import ButtonWrapper from "../../../layouts/ButtonWrapper";
import {capitalizeMulti} from "../../../../../utils/converters";
import {useForm} from "use-formidable";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {fetchPools} from "../../../../recruitersPages/componentsRecruiterPools/dependenciesRecruiterPools/fetch/fetchPools";
import LoadingStyle2 from "../../Loading/LoadingStyle2";
import {fetchMissions} from "../../../../recruitersPages/componentsRecruiterMissions/dependenciesRecruiterMissions/fetchMissions";

export default function (props: any) {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

  const [fetchedMissions, setFetchedMissions] = useState([]);
  const [fetchedCandidacies, setFetchedCandidacies] = useState([]);
  const [fetchedStatus, setFetchedStatus] = useState("asleep");
  const [fetchedCandidaciesStatus, setFetchedCandidaciesStatus] = useState("asleep");
  const [selectedMission, setSelectedMission] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchMissions(setFetchedStatus, setFetchedMissions)
  }, []);
  devLog("fetchedMissions", fetchedMissions);
  devLog("fetchedCandidacies", fetchedCandidacies);

  const selected = appContextV2.appPanels.window.windowOptions.data.selected || [];
  const poolId = appContextV2.appPanels.window.windowOptions.data.poolId || "";

  const sendAnswer = async () => {
    try {
      setFetchedStatus("fetching");
      const fetched = (await axios.post(`${process.env.REACT_APP_API_URL}/pool/ci/export/to/mission`, {
        poolId: poolId,
        selected: selected,
        toMissionId: selectedMission
      }, {
        withCredentials: true,
      }));
      if (fetched.data.status !== "ok") {
        setFetchedStatus("asleep");
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setFetchedStatus("fetched");
      // appContextV2.appPanels.window.windowOptions.data.addCandidacyFront(fetched.data.data);
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      return notification(appContextV2.dispatchPanels, "Candidature importée", "success");
    } catch (error) {
      devLog(error);
      setFetchedStatus("asleep");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }

  const isButtonActive = !!selectedMission && !isLoading && fetchedStatus === "fetched";

  return (<WindowWrapper size={"big"} status={fetchedStatus as 'asleep' | 'fetching' | 'fetched'} windowTitle={""}>
    <div className={"container"}>

      <div>Choisis un poste ouvert vers lequel envoyer les {selected.length} candidats.</div>

      <Spaces n={0}/>

      <div className={"text--center"}>

        <select className={"input--v2"} value={selectedMission} name={"selectedMission"}
                onChange={(e) => setSelectedMission(e.target.value)}>
          <option value={""} disabled={true}>-- Sélectionne un poste --</option>
          {fetchedMissions?.sort((a: any, b: any) => {
            if (a.positionWanted > b.positionWanted) return 1;
            return -1;
          }).filter((item: any) => (item._id !== appContextV2.appNavigation.missionId)).map((item: any, key: number) => (
            <option value={item.missionId}>
              {item.positionWanted}, {item.contract} ({item.numberOfCandidacies})
            </option>))}
        </select>

        <Spaces n={0}/>

        <div className={"text--center"}>
          <button disabled={!isButtonActive} className={"btn-main"} onClick={() => sendAnswer()}>
            Envoyer
          </button>
        </div>

        <Spaces n={0}/>

        {(fetchedCandidaciesStatus === "fetching" || isLoading) && <><LoadingStyle2/><Spaces n={1}/></>}

      </div>

    </div>
  </WindowWrapper>);
}
