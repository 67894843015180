import React from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const isButtonActive: boolean = !!props.form.from;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Quand débute la prise de poste ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
      
      <input type={"date"} className={"assistant--v2-input"} name={"from"}
             value={props.form.from} onChange={props.updateForm()}/>
      
      <Spaces n={1}/>
      
      <button className={"btn-assistant-main"}
              disabled={!isButtonActive}
              onClick={async() => {
                
                const contractsWithoutDurationStep: string[] = ["CDI"];
                if(contractsWithoutDurationStep.includes(props.form.contract)) {
                  props.wizard.goToStep("salaryBy");
                } else {
                  props.wizard.goToStep('duration');
                }
                
              }}>
        Suivant
      </button>
    
    </div>
  
  </section>);
}