import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/Spaces";
import {Link} from "react-router-dom";
import ApplyStepV2 from "./componentsBPAV3Custom/ApplyStepV2";
import {isQuestionReady} from "../../../../../recruitersPages/componentsRecruiterEditor/FormItem";
import BPAV3Splash from "./BPAV3Splash";
import BPAV3Custom from "./BPAV3Custom";
import BPAV3Skills from "./BPAV3Skills";
import BPAV3Send from "./BPAV3Send";
import BPAV3Success from "./BPAV3Success";
import BPAV3Error from "./BPAV3Error";
import BPAV3DQIsWorking from "./BPAV3DefaultQuestions/BPAV3DQIsWorking";
import BPAV3DQIsAvailable from "./BPAV3DefaultQuestions/BPAV3DQIsAvailable";
import BPAV3DQCurrentSalary from "./BPAV3DefaultQuestions/BPAV3DQCurrentSalary";
import BPAV3DQSalaryWanted from "./BPAV3DefaultQuestions/BPAV3DQSalaryWanted";
import BPAV3DQYearsOfExperience from "./BPAV3DefaultQuestions/BPAV3DQYearsOfExperience";
import BPAV3DQTraining from "./BPAV3DefaultQuestions/BPAV3DQTraining";
import BPAV3DQLastCase from "./BPAV3DefaultQuestions/BPAV3DQLastCase";

export default (props: any) => {

    const [step, setStep] = useState(1);
    
    const nextStep = () => setStep((ps: number) => ps + 1);

    switch (step) {

        // case 1:
            // return (<BPAV3DQIsWorking setStep={setStep}
            //                           mission={props.mission}
            //                           answer={props.answer}
            //                           updateAnswer={props.updateAnswer}/>);

        case 1:
            return (<BPAV3DQIsAvailable setStep={setStep}
                                        mission={props.mission}
                                        answer={props.answer}
                                        nextStep={nextStep}
                                        updateAnswer={props.updateAnswer}
            />);

        case 2:
            return (<BPAV3DQCurrentSalary setStep={setStep}
                                          mission={props.mission}
                                          answer={props.answer}
                                          nextStep={nextStep}
                                          updateAnswer={props.updateAnswer}
            />);

        case 3:
            return (<BPAV3DQSalaryWanted setStep={setStep}
                                         mission={props.mission}
                                         answer={props.answer}
                                         nextStep={nextStep}
                                         updateAnswer={props.updateAnswer}
            />);

        case 4:
            return (<BPAV3DQYearsOfExperience setStep={setStep}
                                              mission={props.mission}
                                              answer={props.answer}
                                              nextStep={nextStep}
                                              updateAnswer={props.updateAnswer}
            />);

        case 5:
            return (<BPAV3DQTraining setStep={setStep}
                                     mission={props.mission}
                                     answer={props.answer}
                                     nextStep={nextStep}
                                     updateAnswer={props.updateAnswer}
            />);

        case 6:
            return (<BPAV3DQLastCase wizard={props.wizard}/>);


        default:
            return <div>Error</div>

    }
}