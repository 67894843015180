import AppContext, {IAppContext} from "../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/Spaces";


export default function Terms(props: {page: string}) {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (
    <>
      <PageLayout page={"PageLayout"} status={"ok"}>
  
  
  
  
        <Spaces n={1}/>
        <p className={"text--center"}>
          Myshortlist.co est un produit de Myshortlist.co.
          <br/>
          <br/>
          Adresse: 123 rue de Reuilly 75012 Paris, France
        </p>
  
        <Spaces n={2}/>
  
        <h1 className={"title--bold text--center"}>Conditions générales d'utilisation</h1>
        <Spaces n={0}/>
        <p className={"text--center"}>
          Date de mise à jour: 2 Mai 2020
        </p>
  
        <Spaces n={1}/>
        <p className={"text--center"}>
          Les early-adopters sont particulièrement avertis de la possible instabilité de la plateforme et des changements fréquents qui peuvent intervenir.
        </p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>1. Objet</h1>
  
        <p className={"test--justify"}>
          Myshortlist.co offre des services dématerialisés dédiés au recrutement et à la recherche d'emploi via sa plateforme éponyme (dénomée "Plateforme"). Les présentes conditions générales d’utilisation ont pour objet de définir les modalités et conditions d’utilisation des Services proposés sur la Plateforme Myshortlist.co et de définir les droits et obligations des parties dans ce cadre. Elles n’ont pas pour objet la définition de conditions générales de prestation dans le cadre du recrutement “Hors Plateforme” mis en oeuvre par l'entreprise Myshortlist.co.
          <br/><br/>
          Ces conditions générales d’utilisation sont notamment accessibles et imprimables à tout moment par un lien direct en pied de page de la page d’accueil du site. Elles peuvent être complétées, le cas échéant, par des conditions d’utilisation particulières à certains Services. En cas de contradiction, les conditions particulières prévalent sur ces conditions générales.
        </p>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>2. Termes</h1>
        
        Le mot “Plateforme” fait référence au site accessible via l’url myshortlist.co, peu importe que l’accès à ce dernier se fasse via un ordinateur personnel ou un téléphone intelligent. <br/>
        Un “Talent” ou “Candidat” désigne une personne physique en recherche d’un emploi, inscrit ou non sur la plateforme à cette fin. A l’inscription, une telle personne accède à “l’Espace Candidat” de la plateforme. <br/>
        Un “Recruteur” désigne une personne physique s'étant inscrite pour rechercher un Talent pour le compte de l'entreprise qu'elle représente. A l’inscription, une telle personne accède à “l’Espace Entreprise” de la plateforme. Il sera également désigné comme “Client”. <br/>
        Un “Utilisateur” désigne une personne physique utilisatrice du service en ligne. Cette personne est un Candidat ou un Recruteur. <br/>
        Un “Early adopter” désigne une personne s’étant inscrite sur la plateforme entre le 1er janvier 2020 et la fin du développement de la première version d’exploitation du site. Un Early-Adopter est particulièrement conscient que la plateforme peut changer régulièrement d’aspect, que le service peut être coupé temporairement et subir des modifications importantes.<br/>
        Un “Entretien Vidéo” désigne une rencontre dématérialisée par vidéo interposée entre un Candidat et un Recruteur pour une mission donnée.<br/>
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>3. Désignation des parties</h1>
        
        Les présentes Conditions Générales de prestation sont applicables entre d’une part la société prestataire Myshortlist.co et d’autre part l’utilisateur de la Plateforme. L’utilisateur qui est Recruteur s’engage à avoir la pleine capacité juridique pour s’engager au titre des présentes conditions générales et le cas échéant, à avoir la pleine capacité juridique pour s’engager au nom et pour le compte de la personne morale pour le compte de laquelle il agit.
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>4. Adhésion aux CGU</h1>
        Tout Utilisateur déclare accepter expressément les présentes Conditions générales de façon pleine et entière sans aucune restriction ni réserve. L'inscription sur la plateforme black-belt.io entraîne de plein droit l'adhésion aux Conditions Générales en vigueur à la date de l'inscription. Toute modification de ces conditions générales sera notifiée sur le site. Toute utilisation après une modification des conditions d’utilisation entraîne l’acceptation de ces conditions.
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>5. Modification des CGU</h1>
        Myshortlist.co se réserve la faculté de modifier à tout moment les Conditions Générales. L’Utilisateur sera informé de ces modifications par tout moyen utile et dans un délai raisonnable, et sera invité à renouveler son consentement via la plateforme. Toute utilisation de la plateforme après la publication de nouvelles Conditions Générales sera considérée comme une acceptation de ces nouvelles Conditions. L’Utilisateur ne souhaitant pas adhérer aux nouvelles conditions devra cesser l'utilisation de la plateforme et demander la suppression de son compte et des données associées.
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>6. Désignation des parties</h1>
        6. Les missions de Myshortlist.co
        (1) Myshortlist.co exerce une activité régie par les dispositions prévues aux articles 1984 et suivant du Code Civil sur le mandat. Cette activité consiste à rechercher, sélectionner, conseiller et présenter des candidats pour le compte du Client en accord avec sa recherche. Dans le cadre de l’exécution de ses missions, Myshortlist.co ne saurait être tenu à une obligation de résultat.
        (2) Toute mission de pourvoir un poste pris en charge par la plateforme et publiée sur la Plateforme par le Recruteur est réputée appartenir au champ de la Plateforme Myshortlist.co. Elle entre de plein droit dans le cadre de ces Conditions Générales.
        (3) Toute mission de pourvoir un poste non pris en charge par la plateforme est réputée appartenir au champ “Hors Plateforme” de Myshortlist.co. Elle n’entre pas dans le cadre de ces Conditions Générales, mais dans le cadre d’un contrat de prestation.
        (4) Toute mission de pourvoir un poste pris en charge par la plateforme mais sans inscription ni recherche active de la part du Client est réputée appartenir au champ “Hors Plateforme” de Myshortlist.co. Elle n’entre pas dans le cadre de ces Conditions Générales, mais dans le cadre d’un contrat de prestation.
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>7. Honoraires de recrutement</h1>
        
        (1) L’accès et l’utilisation de la plateforme est gratuite pour le Candidat et pour le Recruteur. Ce n’est qu’en cas de recrutement du Candidat par le Recruteur ou par l’entreprise qu’il représente que des honoraires de recrutement sont dus par le Recruteur ou par l’entreprise qu’il représente.
        (2) Le Recruteur s’engage à avertir immédiatement Myshortlist.co dès qu’un candidat sélectionné et présenté par Myshortlist.co est recruté où qu’une collaboration salariée ou non salariée est conclue avec le candidat.
        (3) Dans le cadre d’un recrutement en CDI ou en CDD, les honoraires sont dus pour moitié (50%) à la signature du contrat de promesse d’embauche, et pour moitié (50%) au premier jour d’entrée en fonction du candidat dans l’entreprise d’accueil ou à la date de commencement de la collaboration.
        (4) Dans le cadre d’un recrutement en intérim, les factures sont dues mensuellement en fin de mois. Pour les besoins de la facturation, le Recruteur s’engage à valider les heures de travail déclarées par le candidat dès réception des heures déclarées par le salarié.
        (5) Dans le cas de la conclusion d’une collaboration non salariée et à défaut d’accord préalable dans ce sens, le client sera redevable d’une somme forfaitaire de 20.000 euros au titre de la recherche, sélection et présentation du candidat.
        (6) Les honoraires sont intégralement dus dans le cas où le candidat serait embauché pour un autre poste que celui de la recherche initiale.
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>8. Clause de réserve</h1>
        
        Tout recrutement du candidat par l’entreprise d’accueil ou par l’une de ses filiales intervenant dans les 12 mois suivant sa présentation entre dans le champ de ce contrat. Le Recruteur ou l’entreprise qu’il représente sera alors redevable des honoraires au titre de ce contrat. Cette clause de réserve s’applique que le candidat soit embauché directement ou indirectement. Elle s’applique également si le candidat est présenté par le Recruteur à une autre entreprise et que cette dernière l’embauche ou collabore avec lui.
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>10. Montant et calcul des honoraires de recrutement</h1>
        
        (1) Dans le cadre d’une embauche en CDI ou en CDD, nos honoraires pour l'exécution de la mission s’élèveront à 12% de la rémunération annuelle brute négociée avec le candidat. Cette rémunération comprend le salaire fixe et le salaire variable avec un minimum d’honoraires de 6500€. Le cas échéant, la rémunération annuelle brute peut être majorée de toute prime accordée au candidat (comprenant prime de logement, prime à l’embauche, prime à l’expatriation …) et d’une somme forfaitaire de 7500€ en cas de mise à disposition d’une voiture de fonction au candidat.
        (2) Dans le cadre d’une embauche en intérim, un contrat de mission sera signé par le Client. En cas de toute mention contraire, le montant facturé au Client est calculé par l’application d’un coefficient de 2,2 au salaire mensuel brut du salarié délégué majoré par des éventuelles indemnités ou primes. Pour les besoins de la facturation, le Client s’engage à valider les heures de travail déclarées par le candidat dès réception de ces déclarations d’heures.
  
        <Spaces n={1}/>
        <h1 className={"title--bold text--grey"}>11. Retard de paiement</h1>
        
        Tout retard de paiement donnera lieu à l’application d’un intérêt de retard égal à trois fois le taux d’intérêt légal dès le lendemain de la date d’échéance et à la perception d’une indemnité de 40€ pour frais de recouvrement. En outre le défaut de paiement d’une facture non réparé dans un délai de huit (8) jours après mise en demeure de payer entraînera la déchéance du terme de toutes les autres factures qui deviendront exigibles à réception.
        12. Recrutements multiples
        Si pour une mission donnée le Client décide de recruter ou faire collaborer plusieurs candidats présentés par Myshortlist.co, le Client sera redevable des honoraires pour chacun des candidats ainsi placés.
        13. Changements de contrat du candidat
        (1) Tout candidat délégué dans le cadre d’un contrat de mise à disposition peut être librement recruté par l’entreprise d’accueil.
        (2) Ce recrutement sera sans frais s’il intervient après quatre (4) mois d’embauche en intérim. Ce recrutement donnera lieu à des honoraires s’il intervient moins de quatre (4) mois après son embauche en intérim.
       
        15. Rémunération du Candidat
        Pour un travail intérimaire, un Candidat sera rémunéré mensuellement par Myshortlist.co suivant les modalités du travail intérimaire et au tarif proposé dans son contrat de mise à disposition. Pour un contrat en CDD ou en CDI, un candidat sera rémunéré par l'entreprise avec laquelle il aura signé ledit contrat. Black-belt ne sera pas responsable du versement du salaire.
        
        16. Qualité
        Myshortlist.co mettra en oeuvre les moyens nécéssaire afin de fournir au Client un service de haute qualité et garantit l’intégralité de ses prestations. Myshortlist.co ne garantit pas l’aptitude des candidats présentés au Client en terme d’obligations de résultat. Myshortlist.co ne saurait être tenu responsable des dommages, pertes, et frais, réclamations ou dépenses, ni de tout dommage indirect, notamment perte de profit, supportées ou encourues par le Client du fait du recrutement ou de la collaboration avec les candidats. A cet égard, le Client se chargera de la vérification de tout document, attestation et diplômes demandés au candidat ou présentés par lui, et renonce à tout recours contre Myshortlist.co.
        17. Inscription
        Les parties s’inscrivent librement via la Plateforme. Toute recherche via la Plateforme suppose une inscription préalable.
        18. Données personnelles
        Pour s'inscrire et ensuite utiliser la plateforme, l'Utilisateur devra fournir des données obligatoires. En l'absence de ces données, Black-belt se réserve la possibilité d'interdire l'accès de la Plateforme à l'Utilisateur. Pour le fonctionnement de la plateforme l'Utilisateur pourra fournir ou non des données facultatives. L'absence de ces données n'entraînera pas le blocage du compte. Le statut de données 'obligatoires' ou 'facultatives' peuvent être modifiées au fil des versions de la Plateforme. Le cas échéant L'Utilisateur devra alors fournir de nouvelles données obligatoires manquantes et pourra retirer des données devenues facultatives.
        L’Utilisateur garantit que toutes les informations qu’il fournit sont exactes, à jour et sincères et ne sont entachées d’aucun caractère trompeur. Il s’engage à mettre à jour ces informations dans son Espace Personnel en cas de modifications, afin qu’elles correspondent toujours aux critères susvisés. L’Utilisateur est informé et accepte que les informations saisies aux fins de création ou de mise à jour de son Compte vaillent preuve de son identité. Les informations saisies par l’Utilisateur engagent celui-ci dès leur validation et l’Utilisateur demeure seul responsable desdites informations.
        19. Confidentialité des données
        (1) Les Utilisateurs de la Plateforme apparaissent avec leur prénom et la première lettre de leur nom de famille lorsque leur profils sont consultés. Lorsque l’Utilisateur choisit un avatar, il accepte que celui-ci soit affiché par Myshortlist.co dans les shortlist des Recruteurs et le cas échéant sur la page d’accueil de la plateforme. L’utilisateur peut à tout moment retirer son avatar via son profil.
        (2) L’Utilisateur accepte le stockage et la diffusion de son image et de sa voix dans le cadre des échanges vidéo entre Recruteur et Candidat via la plateforme.
        (3) L’identité des Utilisateurs est communiquée au moment de la signature des contrats.
        (4) A tout moment l’Utilisateur a accès aux données le concernant. Il peut faire une demande pour obtenir les données étendues le concernant.
        20. Prise de référence
        (1) Dans le cadre des prises de références, des données concernant le sérieux professionnel du Candidat seront publiées sur la plateforme. Le Candidat authorise Myshortlist.co à les publier. Le Candidat peut librement partager ces références dans le cadre de ses recherches.
        (2) La personne fournissant une référence accepte que son commentaire public soit publié via la plateforme. Elle peut, comme tout Utilisateur de la plateforme, avoir un droit de modification et/ou de rétractation.
        21. Formalités d’embauche
        Le Client prendra en charge toutes les formalités d’embauche prévues par la loi et les règlements et obtiendra le cas échéant les autorisations nécessaires. Il fera procéder aux examens médicaux requis par la loi.
        22. Spécificités liées à la nature des contrats de travail
        (1) Le recruteur peut émettre une offre d’emploi via la plateforme, que le Candidat peut accepter. Cet échange de volontés exprime le souhait des parties de collaborer ensemble et les engage dans ce cens. Cependant, Myshortlist.co ne saurait être tenu d’aucune manière par cet engagement et peut unilatéralement annuler cet échange pour tout motif. C’est la signature des contrats décrits aux points ci-dessous qui auront un effet juridique.
        (2) Dans le cadre d’une mission en intérim, toute mise à disposition d’un salarié intérimaire fera l’objet d’un contrat préalable de mise à disposition avec le Recruteur et d’un contrat de travail temporaire avec le Candidat. C’est la signature de ces contrats qui clos définitivement la recherche.
        (3) Dans le cadre d’un poste en CDD ou en CDI, un contrat de promesse d’embauche sera proposé au Recruteur. La signature de ce contrat clos la recherche.
        23. Comportements frauduleux
        (1) Myshortlist.co se réserve la possibilité de bloquer ou interdire l'accès discrétionnairement à toute personne suspectée d'avoir entré des données trompeuses. Le cas échéant Black-belt se réserve le droit de poursuivre au civil ou au pénal tout Utilisateur ayant un comportement trompeur ou frauduleux.
        (2) Au sens du présent contrat, un exemple de comportement frauduleux est de détourner l'utilisation de la plateforme pour recruter, prospecter, chercher des offres d'emploi sans avoir l'intention de souscrire aux présentes conditions générales et sans avoir l'intention d'être soumis aux tarifs de Black-belt. Détourner la base de donnée, construire une base client pour le compte d'une entreprise de recrutement, détourner la base Candidats pour leur proposer des opportunités pour le compte d'une autre entreprise de recrutement sans se soumettre à la tarification de Black-belt sont des exemples de comportements frauduleux.
        (3) Tout comportement frauduleux donne lieu à l’application d’une clause pénale de vingt-mille (20.000) euros nonobstant des poursuites civiles et pénales qui peuvent en découler.
        24. Obligations de l'Utilisateur
        L’Utilisateur s’engage, dans le cadre de l’utilisation des Services, à respecter les lois et règlements en vigueur et à ne pas porter atteinte aux droits de tiers ou à l’ordre public. Il est notamment seul responsable du bon accomplissement de toutes les formalités notamment administratives, fiscales et/ou sociales et de tous les paiements de cotisations, taxes ou impôts de toutes natures qui lui incombent, le cas échéant, en relation avec son utilisation des Services. Il est également responsable du respect de la réglementation applicable aux relations contractuelles auquel il est partie. La responsabilité de Myshortlist.co ne pourra en aucun cas être engagée à ce titre.
        Les Utilisateurs s’engagent à utiliser les Services de bonne foi et de manière loyale, à ne pas contourner la Plateforme et les Services aux fins notamment d’exercer une activité similaire ou concurrente.
        L’Utilisateur est informé et accepte que la mise en œuvre des Services nécessite qu’il soit connecté à internet et que la qualité des Services dépend directement de cette connexion, dont il est seul responsable.
        L’Utilisateur est également seul responsable des relations qu’il pourra nouer avec les autres Utilisateurs et des informations qu’il leur communique dans le cadre de la réalisation des Services. Il lui appartient d’exercer la prudence et le discernement appropriés
        dans ces relations et communications. L’Utilisateur s’engage en outre, dans ses échanges avec les autres Utilisateurs, à respecter les règles usuelles de politesse et de courtoisie. L’Utilisateur s’engage à faire un usage strictement personnel des Services. Il s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses droits ou obligations au titre des présentes à un tiers, de quelque manière que ce soit.
        l’Utilisateur s’engage à coopérer activement avec Myshortlist.co en vue de la bonne exécution des services.
        L’Utilisateur est seul responsable des contenus (ci-après désignés : les « Contenus») de toute nature (rédactionnels, graphiques, audiovisuels ou autres, en ce compris la dénomination et/ou l’image éventuellement choisies par l’Utilisateur pour l’identifier sur la Plateforme) qu’il diffuse dans le cadre du Service. L’Utilisateur garantit à Myshortlist.co qu’il dispose de tous les droits et autorisations nécessaires à la diffusion de ces Contenus. L’Utilisateur s’engage à ce que lesdits Contenus soient licites, ne portent pas atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers, n’enfreignent aucune disposition législative ou règlementaire et plus généralement, ne soient aucunement susceptibles de mettre en jeu la responsabilité civile ou pénale de Myshortlist.co.
        L’Utilisateur doit prendre les mesures nécessaires pour sauvegarder par ses propres moyens les informations de son Espace Personnel qu’il juge nécessaires, dont aucune copie ne lui sera fournie en dehors des obligations de Myshortlist.co relatives au traitement des données personnelles.
        Tout Utilisateur et tout Visiteur s’interdit de procéder à toute extraction du contenu de la Plateforme pour une activité similaire ou concurrente, ou à des fins de recrutement. Le non-respect de cet engagement entraînera l’application automatique d’une indemnité forfaitaire de cinq-mille (5000) euros due à Myshortlist.co par infraction à la présente clause, sans préjudice de toute autre indemnité ou action en responsabilité qui pourrait être engagée contre eux.
        Sont également strictement interdits pour tout Utilisateur et tout Visiteur :
        - Tous comportements de nature à interrompre, suspendre, ralentir ou empêcher la continuité du Service
        - Toutes intrusions ou tentatives d’intrusions dans les systèmes de Myshortlist.co ou de ses fournisseurs ;
        - Tous détournements des ressources système de la Plateforme,
        - Toutes actions de nature à imposer une charge disproportionnée sur les infrastructures de ce dernier,
        - Toutes atteintes aux mesures de sécurité et d’authentification.
        L’’Entreprise Utilisatrice s’engage à coopérer activement avec Myshortlist.co en vue de la bonne exécution du Contrat.
        L’Entreprise Utilisatrice est responsable du respect des dispositions prévues aux articles L.1251-1 et suivants du Code de travail.
        Toute personne physique (salarié ou mandataire social) qui s’engage au nom et pour le compte de l’Entreprise Utilisatrice reconnaît être habilitée à conclure le Contrat au nom et pour le compte de ladite Entreprise Utilisatrice. L’Entreprise Utilisatrice ne pourra en aucun cas se dégager de ses obligations contractuelles au motif que cette personne physique n’avait pas pouvoir pour conclure le Contrat.
        L’Entreprise Utilisatrice s’engage à ne pas envoyer de message publicitaire aux Utilisateurs de la plateforme ou à les démarcher.
        L’Entreprise Utilisatrice s’engage à ne pas divulguer à des tiers l’identité des Myshortlist.cors avec qui elle a été mise en relation et avec qui elle a réalisé une Mission.
        De manière générale, l’Entreprise Utilisatrice s’interdit de :
        - publier des offres d’emploi non conformes aux lois et règlements en vigueur, notamment non conformes au droit du travail applicable dans le pays de publication de l’offre
        - publier des offres d’emploi discriminatoires et contraires au principe de l’égalité des chances
        - publier des offres d’emploi fictives et qui ne correspondraient pas à des propositions de travail temporaire sérieuses
        - utiliser les Services Myshortlist.co pour contourner les cas de recours au travail temporaire ou intérim strictement prévus par la loi aux articles L.1251-5 et suivants du Code du travail
        - utiliser les données des Candidats à des fins autres que celles strictement liées aux Services.
        Myshortlist.co se réserve, à sa seule discrétion, le droit de supprimer toute offre d’emploi et/ou contenu(s) publié(s) sur le Site qui serait non conforme aux conditions précitées ou dont le contenu pourrait porter atteinte à Myshortlist.co ou aux Utilisateurs de Myshortlist.co.
        25. Obligations et Responsabilité de Myshortlist.co
        Myshortlist.co s’engage à fournir les Services avec diligence, étant précisé qu’il pèse sur elle une obligation de moyens, à l’exclusion de toute obligation de résultat ou de garantie, ce que les Utilisateurs reconnaissent et acceptent expressément.
        Myshortlist.co décline toute responsabilité en cas de perte éventuelle des informations accessibles dans l’Espace Personnel de l’Utilisateur, celui-ci devant en sauvegarder une copie et ne pouvant prétendre à aucun dédommagement à ce titre.
        Myshortlist.co s’engage à procéder régulièrement à des contrôles afin de vérifier le fonctionnement et l’accessibilité de la Plateforme. À ce titre, Myshortlist.co se réserve la faculté d’interrompre momentanément l’accès à la Plateforme pour des raisons de maintenance. Les Early-adopters sont particulièrement informés de la possibilité de ces coupures.
        Myshortlist.co ne saurait être tenue responsable des difficultés ou impossibilités momentanées d’accès à la Plateforme qui auraient pour origine des circonstances qui lui sont extérieures, la force majeure, ou encore qui seraient dues à des perturbations des réseaux de télécommunication.
        La responsabilité de Myshortlist.co vis à vis des Utilisateurs ne saurait être engagée que pour des dommages directs qui trouveraient leur origine dans un manquement à l’une des obligations de Myshortlist.co au titre du Contrat. Est exclue toute responsabilité pour dommages indirects tels que, et sans que cette liste ne soit considérée comme limitative, perte de chiffre d’affaires, manque à gagner, perte de donnée
        26. Dérogations
        Aucune dérogation aux présentes Conditions Générales ne peut être faite sans le consentement par écrit des parties.
        27. Juridiction compétente
        Les présentes conditions générales sont régies par le droit français. Tous les litiges relatifs à leur application sont de la compétence exclusive du Tribunal de Commerce de Paris.
        
        
        
        
        
        
        
        
        
        
        
        
        
        
      </PageLayout>
    </>
  );
}