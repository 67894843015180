import axios from "axios";
import {notification} from "../../../../utils/notification";
import {devLog} from "../../../../utils/devLog/devLog";

export default (setPageLoading: any, props: any, appContextV2: any) => async(status: 'collaboration' | 'draft' | 'published' | 'save', documentUrl: string) => {
  try {
    
    setPageLoading(true);
    
    const totalTimeSpent: number = Math.floor(new Date().getTime() / 1000) - props.timeStamps.startTime
    
    props.setTimeStamps((ps: any) => ({
      ...ps,
      endTimeEditorInSeconds: totalTimeSpent,
    }));
    
    console.log('_________________ Saving with doc ', documentUrl, "status", status);
    
    const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/ent/m/new/mission`, {
      ...props.form,
      ...props.timeStamps,
      status: status,
      endTimeEditorInSeconds: totalTimeSpent,
      documentUrl: documentUrl,
    }, {withCredentials: true});
    
    if(fetched.data?.status !== "ok") {
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
    
    props.setMissionTokens({
      status: status,
      mailHandler: fetched.data?.data?.mailHandler || "",
      rank: fetched.data?.data?.rank || "",
      token: fetched.data?.data?.token || "",
    });
    
    if(status === "published") {
      appContextV2.dispatchSession({type: "UPDATE_MISSIONS_NUMBER", value: 1});
    }
    
    notification(appContextV2.dispatchPanels, "Poste crée avec succès", "success");
    props.wizard.goToStep('success');
    
  } catch (error) {
    setPageLoading(false);
    notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    devLog(error);
  }
}