import React, {useContext, useEffect} from "react";
import {useForm} from "use-formidable";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";

export {HeaderUpdateban};

const HeaderUpdateban = (props: {
  
  userQuality: string,
  isEditorOn: boolean
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [formBan, updateBan, formidableBan] = useForm({});
  
  useEffect(() => {
    (async() => {
      try {
        if(!!formBan.ban && !!formBan.ban[0]) {
          
          if(formBan.ban.length > 1) {
            return notification(appContextV2.dispatchPanels, "Vous ne pouvez envoyer qu'un seul fichier à la fois", "warning");
          }
          
          if(!formidableBan.isFileMime(formBan.ban[0], ["jpeg", "png", "gif"])) {
            return notification(appContextV2.dispatchPanels, "Le format du fichier n'est pas valide. Vous pouvez essayer de le ré-enregistrer.", "warning");
          }
          
          if(!formidableBan.isFileSmallerThan(formBan.ban[0], 820000)) {
            return notification(appContextV2.dispatchPanels, "La taille du fichier est trop importante (maximum: 800ko).", "warning");
          }
          
          notification(appContextV2.dispatchPanels, "Traitement du fichier ...", "information");
          
          const banType = props.userQuality === "recruiter" ? "businessban" : "userban";
          
          let dataToSend: any = new FormData();
          
          dataToSend.append("avatar", formBan.ban[0], formBan.ban[0].name);
          
          const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/${banType}/avatar`, dataToSend, {
            withCredentials: true, headers: {
              'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
            }
          });
          
          if(fetched.data.status === 'ok') {
            notification(appContextV2.dispatchPanels, "La banière a été mise à jour", "success");
            appContextV2.dispatchSession({
              type: "UPDATE_SESSION", value: {
                key: "ban",
                value: fetched.data.data,
              }
            });
          } else {
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          }
          
        }
      } catch (error) {
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, [formBan.ban]);
  
  return (<>
    
    {
      props.isEditorOn && <div className={"page-layout__header-background-editor hide-on-smartphones"}>

        <div className={"header__upload-image__button"} title={"Ajoute une banière pour l'entreprise"}>

          <input type={"file"} name={"ban"} id={"ban"} className="input-camera" onChange={updateBan()}/>

          <label htmlFor={"ban"} className={`btn-camera2 animation-straight-fade-in space-medium`}>
            <img src={"https://static.myshortlist.co/btn-camera.svg"} alt={"modify avatar"}/>
          </label>

        </div>

      </div>
    }
  </>);
}