import AppContext, {IAppContext} from "../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import PageLayout from "../../layouts/PageLayout";


export default function HelpCenter(props: {page: string}) {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (
    <>
      <PageLayout page={props.page} status={"ok"}>
        
        Help center
        
      </PageLayout>
    </>
  );
}