import React, {useContext, useState} from "react";
import Spaces from "../../../../Spaces";
import EditableCanddiatePoolProfileComponent from "../../componentsWCPP/EditableCanddiatePoolProfileComponent";
import {isHighlighted} from "../dependencies/isHighlighted";
import CloseButton from "../../../../Buttons/CloseButton";
import {notification} from "../../../../../../../utils/notification";
import {devLog} from "../../../../../../../utils/devLog/devLog";
import {ButtonBigPlusV2} from "../../../../Buttons/ButtonBigPlus";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import axios from "axios";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";

export default (props: any) => {
  
  return (<div className={"container text--grey"}>
    
    {/*----------------------------------------------------- Part 1: editable singletons*/}
    
    <div className={"white-container white-container--grey space-margin--small"}>
      
      <div className={"white-board-profile"}>

        <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.firstName}
                                               editable={true}
                                               candidateId={props.inherited.candidateId}
                                               keywords={props.inherited.keywords}
                                               displayedKey={"Prénom"}
                                               keyToUpdate={"firstName"}
                                               updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
        />
        
        <Spaces n={0}/>
        
        <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.lastName}
                                               editable={true}
                                               candidateId={props.inherited.candidateId}
                                               keywords={props.inherited.keywords}
                                               displayedKey={"Nom"}
                                               keyToUpdate={"lastName"}
                                               updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
        />
  
        <Spaces n={0}/>
        
        <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.email}
                                               editable={true}
                                               candidateId={props.inherited.candidateId}
                                               keywords={props.inherited.keywords}
                                               displayedKey={"Email"}
                                               keyToUpdate={"email"}
                                               updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
        />
  
        <Spaces n={0}/>
        
        <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.phone}
                                               editable={true}
                                               candidateId={props.inherited?.candidateId}
                                               keywords={props.inherited.keywords || []}
                                               displayedKey={"Téléphone"}
                                               keyToUpdate={"phone"}
                                               updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
        />

        <Spaces n={0}/>

        <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.linkedin}
                                               editable={true}
                                               candidateId={props.inherited.candidateId}
                                               keywords={props.inherited.keywords}
                                               displayedKey={"Linkedin"}
                                               keyToUpdate={"linkedin"}
                                               updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
        />
      </div>
      
      <Spaces n={0}/>
      
      {/*<EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.salaryMin}*/}
      {/*                                       editable={false}*/}
      {/*                                       keywords={props.inherited.keywords}*/}
      {/*                                       displayedKey={"Salaire minimum"}*/}
      {/*                                       keyToUpdate={"recruiter"}*/}
      {/*                                       updateKeyValueFront={() => {}}*/}
      {/*/>*/}
      
      {/*/!*----------------- todo add salary by*!/*/}
      
      {/*<EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.salaryMax}*/}
      {/*                                       editable={false}*/}
      {/*                                       keywords={props.inherited.keywords}*/}
      {/*                                       displayedKey={"Salaire maximum"}*/}
      {/*                                       keyToUpdate={"recruiter"}*/}
      {/*                                       updateKeyValueFront={() => {}}*/}
      {/*/>*/}
      
      {/*<Spaces n={0}/>*/}
      
      {/*<EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.recruiter}*/}
      {/*                                       editable={false}*/}
      {/*                                       keywords={props.inherited.keywords}*/}
      {/*                                       displayedKey={"Recruteur responsable"}*/}
      {/*                                       keyToUpdate={"recruiter"}*/}
      {/*                                       updateKeyValueFront={() => {}}*/}
      {/*/>*/}
      
    </div>
    
    {/*----------------------------------------------------- Part 2: editable lists */}
    <div className={"white-container white-container--grey space-margin--small"}>
      <ProfileSimpleListElement title={"Fonctions"} keywords={props.inherited.keywords}
                                dictionary={props.dictionaries.positions} category={"positionsWanted"}
                                updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                                inherited={props.inherited}
                                array={props.candidate?.candidate?.positionsWanted}/>
    </div>
    
    <div className={"white-container white-container--grey space-margin--small"}>
      <ProfileSimpleListElement title={"Secteurs"} keywords={props.inherited.keywords}
                                dictionary={props.dictionaries.sectors} category={"sectors"}
                                updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                                inherited={props.inherited}
                                array={props.candidate?.candidate?.sectors}/>
    </div>
    
    <div className={"white-container white-container--grey space-margin--small"}>
      <ProfileSimpleListElement title={"Mobilité"} keywords={props.inherited.keywords}
                                dictionary={props.dictionaries.mobilities} category={"mobility"}
                                updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                                inherited={props.inherited}
                                array={props.candidate?.candidate?.mobility}/>
    </div>
    
    <div className={"white-container white-container--grey space-margin--small"}>
      <ProfileAdvancedListElement title={"Langues"} keywords={props.inherited.keywords}
                                  dictionary={props.dictionaries.languages} category={"languages"}
                                  updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                                  inherited={props.inherited}
                                  array={props.candidate?.candidate?.languages}/>
    </div>
    
    <div className={"white-container white-container--grey space-margin--small"}>
      <ProfileAdvancedListElement title={"Logiciels"} keywords={props.inherited.keywords}
                                  dictionary={props.dictionaries.softwares} category={"softwares"}
                                  updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                                  inherited={props.inherited}
                                  array={props.candidate?.candidate?.softwares}/>
    </div>
    
    <div className={"white-container white-container--grey space-margin--small"}>
      <ProfileSimpleListElement title={"Compétences"} keywords={props.inherited.keywords}
                                dictionary={props.dictionaries.skills} category={"skills"}
                                updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                                inherited={props.inherited}
                                array={props.candidate?.candidate?.skills}/>
    </div>
    
    <Spaces n={1}/>
  
  </div>);
}

function ProfileSimpleListElement(props: {
  updateCandidateFrontInWindow: Function,
  inherited: any,
  title: string;
  array: any[];
  keywords: string;
  category: string,
  dictionary: any;
}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [editorMode, setEditorMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newValue, setNewValue] = useState("");
  
  const deleteItem = async(item: string) => {
    try {
      // update front in the window
      props.updateCandidateFrontInWindow({[props.category]: [...props.array].filter((item2: any) => item2 !== item),});
      // update front in the kanban
      setIsLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/${props.category}/delete`, {
        value: item,
        candidateId: props.inherited.candidateId,
      }, {
        timeout: 20_000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Retrait avec succès", "success");
    } catch (error) {
      devLog(error);
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const addItem = async() => {
    try {
      // update front in the window
      props.updateCandidateFrontInWindow({[props.category]: props.array ? [...props.array, newValue] : [newValue],});
      // update front in the kanban
      setIsLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/${props.category}/add`, {
        value: newValue,
        candidateId: props.inherited.candidateId,
      }, {
        timeout: 20_000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setIsLoading(false);
      setNewValue("");
      setEditorMode(false);
      notification(appContextV2.dispatchPanels, "Ajout effectué", "success");
    } catch (error) {
      devLog(error);
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<>
    <div className={"profile-edit__title"}>{props.title}</div>
    {props.array?.map((item: any, key: number) => (
      <div key={key} className={`profile-edit__value ${isHighlighted(props.keywords, item) ? "profile--highlighted" : ""}`}>
        <div className={"profile-edit__value__body"}>
          {item}
        </div>
        <div className={"profile-edit__value__buttons"}>
          <CloseButton eventOnClick={() => deleteItem(item)}/>
        </div>
      </div>))}
    {props.array?.length === 0 && <div className={"text--center"}>Aucune entrée</div>}
    <Spaces n={0}/>
    <div className={"space-flex--center"}>
      {!editorMode && (props.array?.length < 20 || !props.array) &&
      <div className={"animation-fade-in"}><ButtonBigPlusV2 cb={() => setEditorMode(true)} title={"Ajoute un élément"}/></div>}
      {editorMode && <>
        <input type={"text"} list={props.category} className={"input--v2 animation-fade-in"} value={newValue} onChange={(e) => {
          if(e.target.value.length < 300) {
            setNewValue(e.target.value);
          }
        }}/>
        <datalist id={props.category}>
          {props.dictionary?.sort((a: any, b: any) => (b.stringValue - a.stringValue)).map((item: any) => <option>{item.stringValue}</option>)}
        </datalist>
        <button disabled={isLoading} className={"profile-edit-btn animation-fade-in"} onClick={() => setEditorMode(false)}>Annuler</button>
        <button disabled={isLoading || !newValue} onClick={() => addItem()} className={"profile-edit-btn animation-fade-in"}>Enregistrer</button>
      </>}
    </div>
    {isLoading && <>
      <div className={"space-flex--center animation-fade-in"}><LoadingStyle2/></div>
      <Spaces n={0}/></>}
  </>);
}

function ProfileAdvancedListElement(props: {
  updateCandidateFrontInWindow: Function,
  inherited: any,
  title: string;
  array: any[];
  category: string,
  keywords: string;
  dictionary: any;
}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [editorMode, setEditorMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [newLevel, setNewLevel] = useState("");
  
  const deleteItem = async(itemId: string) => {
    try {
      // update front in the window
      props.updateCandidateFrontInWindow({[props.category]: [...props.array].filter((item2: any) => item2._id !== itemId),});
      // update front in the kanban
      setIsLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/${props.category}/delete`, {
        itemId: itemId,
        candidateId: props.inherited.candidateId,
      }, {
        timeout: 20_000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Retrait avec succès", "success");
    } catch (error) {
      devLog(error);
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const addItem = async() => {
    try {
      // update front in the window
      props.updateCandidateFrontInWindow({
        [props.category]: props.array ? [...props.array, {
          value: newValue,
          level: newLevel,
        }] : [{
          value: newValue,
          level: newLevel,
        }],
      });
      // update front in the kanban
      setIsLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/${props.category}/add`, {
        newItem: {
          value: newValue,
          level: newLevel,
        },
        candidateId: props.inherited.candidateId,
      }, {
        timeout: 20_000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setIsLoading(false);
      setNewValue("");
      setEditorMode(false);
      notification(appContextV2.dispatchPanels, "Ajout effectué", "success");
    } catch (error) {
      devLog(error);
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<>
    <div className={"profile-edit__title"}>{props.title}</div>
    {props.array?.map((item: any, key: number) => (
      <div key={key} className={`profile-edit__value ${isHighlighted(props.keywords, item) ? "profile--highlighted" : ""}`}>
        <div className={"profile-edit__value__body"}>
          {item.value} ({item.level})
        </div>
        <div className={"profile-edit__value__buttons"}>
          {item._id && <CloseButton eventOnClick={() => deleteItem(item._id)}/>}
        </div>
      </div>))}
    {props.array?.length === 0 && <div className={"text--center"}>Aucune entrée</div>}
    
    {/*-------------------------- Button to add an item*/}
    <Spaces n={0}/>
    <div className={"space-flex--center"}>
      {!editorMode && (props.array?.length < 20 || !props.array) &&
      <div className={"animation-fade-in"}><ButtonBigPlusV2 cb={() => setEditorMode(true)} title={"Ajoute un élément"}/></div>}
      {editorMode && <>
        <input list={props.category} type={"text"} className={"input--v2 animation-fade-in"} value={newValue} onChange={(e) => {
          if(e.target.value.length < 300) {
            setNewValue(e.target.value);
          }
        }}/>
        <datalist id={props.category}>
          {props.dictionary?.sort((a: any, b: any) => (b.stringValue - a.stringValue)).map((item: any) => <option>{item.stringValue}</option>)}
        </datalist>
        <select className={"input--v2"} onChange={(e) => setNewLevel(e.target.value)}>
          <option value={0}>Niveau non renseigné</option>
          <option value={5}>Notions (faible)</option>
          <option value={4}>Professionnel</option>
          <option value={3}>Intermédiaire</option>
          <option value={2}>Bon</option>
          <option value={1}>Excellent (fort)</option>
        </select>
      </>}
    </div>
    {editorMode && <div className={"space-flex--center"}>
      <button disabled={isLoading} className={"profile-edit-btn animation-fade-in"} onClick={() => setEditorMode(false)}>Annuler</button>
      <button disabled={isLoading || !newValue} onClick={() => addItem()} className={"profile-edit-btn animation-fade-in"}>Enregistrer</button>
    </div>}
    {isLoading && <>
      <div className={"space-flex--center animation-fade-in"}><LoadingStyle2/></div>
      <Spaces n={0}/></>}
  </>);
}