import React, {useContext} from "react";
import WindowWrapper from "../../layouts/WindowLayout";
import Spaces from "../Spaces";
import {useWizard} from "use-wizard";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import {devLog} from "../../../../utils/devLog/devLog";
import {notification} from "../../../../utils/notification";
import axios from "axios";

export {WindowManageInvite};

const WindowManageInvite = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [step, wizard] = useWizard(["email", "role", "validation", "pending"]);
  
  const teamId: string = appContextV2.appPanels.window.windowOptions.data?.teamId;
  const inviteId: string = appContextV2.appPanels.window.windowOptions.data?.inviteId;
  const email: string = appContextV2.appPanels.window.windowOptions.data?.email;
  const role: string = appContextV2.appPanels.window.windowOptions.data?.role;
  
  const deleteInvite = () => {
    (async() => {
      try {
        
        notification(appContextV2.dispatchPanels, "Suppression de l'invitation", "information");
        wizard.goToStep('pending');
        
        const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/ent/t/invite/delete`, {
          teamId: teamId,
          inviteId: inviteId,
        }, {withCredentials: true});

        if(fetched.data.status !== "ok") {
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
        appContextV2.appPanels.window?.windowOptions?.data?.deleteInviteFront(inviteId);
        notification(appContextV2.dispatchPanels, "Succès de la suppression", "success");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        
      } catch (error) {
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }
    })();
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center"}>
      L'invitation n'a pas encore été acceptée. Tu peux la supprimer et en lancer une nouvelle si le lien n'est plus valide ou si des informations sont à
      modifier.
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <span className={"text--magnified2x"}>{email}</span>
      <br/><br/>
      Rôle: {role === "admin" && "Admin"}
      {role === "manager" && "Manager"}
      {role === "user" && "Utilisateur"}
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button className={"btn-main"} onClick={() => deleteInvite()}>
        Supprimer
      </button>
    </div>
  </WindowWrapper>);
  
}