import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import fetchAmbassadors from "../componentsRecruiterAmbassadors/dependenciesRecruiterAmbasssadors/fetch/fetchAmbassadors";
import {devLog} from "../../../utils/devLog/devLog";
import Spaces from "../../sharedComponents/components/Spaces";
import {notification} from "../../../utils/notification";

export {RecruiterLinksAmbassador}

const RecruiterLinksAmbassador = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  /** Downloaded ambassadors for a specific team and fetch status */
  const [ambassadors, setAmbassadors]: [any, any] = useState([]);
  const [fetchStatusAmbassador, setFetchStatusAmbassadors]: [any, any] = useState("asleep");
  const [selectedAmbassador, setSelectedAmbassadors]: [any, any] = useState("");
  
  /** Initial ambassadors-fetch (of the current team only) */
  useEffect(() => {fetchAmbassadors(setFetchStatusAmbassadors, setAmbassadors)}, []);
  devLog(ambassadors);
  
  const url: string = `${window.location.origin}/corpo/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}?a=${selectedAmbassador}`;
  
  if(ambassadors.length === 0) {
    return (<></>);
  }
  
  return (<div className={"container white-container animation-fade-in"}>
    <h1 className={"profile-edit__title"}>Créé un lien pour un ambassadeur</h1>
    
    <div className={"text--center"}>
      Sélectionne un ambassadeur puis transmets-lui son lien unique vers la page de l'entreprise.
      
      <Spaces n={0}/>
      
      <select className={"input--v2"} value={selectedAmbassador} name={"selectedAmbassador"} onChange={(e) => {
        setSelectedAmbassadors(e.target.value);
      }}>
        <option value={""} disabled={true}>-- Sélectionne un ambassadeur --</option>
        {ambassadors.map((item: any, key: number) => (<option value={item.slug}>
          {item.fullName}
        </option>))}
      </select>
  
      <Spaces n={0}/>
  
      <button className={"btn-secondary"} disabled={!selectedAmbassador} onClick={()=>{
        notification(appContextV2.dispatchPanels, "Lien copié", "success");
        navigator.clipboard.writeText(url);
      }}>
        Copier
      </button>
      
    </div>
  
  </div>);
}