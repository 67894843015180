import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../componentsApp/context/AppContext";
import PageLayoutWithHeader from "../sharedComponents/layouts/PageLayoutWithHeader";


export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return(
    <PageLayoutWithHeader page={props.page} status={"ok"}>
      
      <section className={"hide-on-computers"}>
        <p className={"text--center"}>
          La page demandée n'est pas encore disponible
        </p>
      </section>
      
      <section className={"hide-on-smartphones"}>
      
      </section>
    
    </PageLayoutWithHeader>);
}
