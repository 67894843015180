import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";

export default (props: {
  page: "" | "cv" | "doc" | "profile" | "results" | "trigram" | "mail" | "history" | "finder",
  setPage: Function,
  inherited: any
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return (<div className={"window-candidate-v2__navbar"}>
    
    <button className={`window-candidate-v2__navbar__item ${props.page === "cv" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("cv");
    }}>
      CV/DOCS
    </button>
  
    {/*<button className={`window-candidate-v2__navbar__item ${props.page === "doc" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{*/}
    {/*  props.setPage("doc");*/}
    {/*}}>*/}
    {/*  DOCS/CR*/}
    {/*</button>*/}
  
    <button className={`window-candidate-v2__navbar__item ${props.page === "profile" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("profile");
    }}>
      Profil
    </button>
  
    {
      props.inherited.category === "mission" && <button className={`window-candidate-v2__navbar__item ${props.page === "results" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
        props.setPage("results");
      }}>
        Candidature
      </button>
    }
    
    <button className={`window-candidate-v2__navbar__item ${props.page === "trigram" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("trigram");
    }}>
      Notes
    </button>
    
    <button disabled={false} className={`window-candidate-v2__navbar__item ${props.page === "mail" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("mail");
    }}>
      Messages
    </button>
  
    <button disabled={false} className={`window-candidate-v2__navbar__item ${props.page === "finder" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("finder");
    }}>
      Trouver
    </button>
    
    {/*<button className={`window-candidate-v2__navbar__item ${props.page === "history" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{*/}
    {/*  props.setPage("history");*/}
    {/*}}>*/}
    {/*  Historique*/}
    {/*</button>*/}
  
  </div>);
  
}
