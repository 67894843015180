import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../../sharedComponents/components/Spaces";
import {useForm} from "use-formidable";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";

export {RecruiterProfilesBusinessPublicHeader};

const RecruiterProfilesBusinessPublicHeader = (props: {
  assistantGet: any,
  profile: any,
  updateSingleton: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [businessPageMention, setMention] = useState(props.profile.businessPageMention || "");
  
  const [formBan, updateBan, formidableBan] = useForm({});
  
  const [visibleBan, updateVisibleBan] = useState(props.profile.avatar?.publicBanUrl || "");
  
  useEffect(() => {
    updateVisibleBan(props.profile.avatar?.publicBanUrl || "");
  }, [props.profile?.avatar?.publicBanUrl]);
  
  useEffect(() => {
    setMention(props.profile.businessPageMention || "");
  }, [props.profile?.businessPageMention]);
  
  useEffect(() => {
    (async() => {
      if(!!formBan.businesspublicban && !!formBan.businesspublicban[0]) {
  
        if(formBan.businesspublicban.length > 1) {
          return notification(appContextV2.dispatchPanels, "Vous ne pouvez envoyer qu'un seul fichier à la fois", "warning");
        }
        
        if(!formidableBan.isFileMime(formBan.businesspublicban[0], ["jpeg", "png", "gif"])) {
          return notification(appContextV2.dispatchPanels, "Le format du fichier n'est pas valide. Vous pouvez essayer de le ré-enregistrer.", "warning");
        }
        
        if(!formidableBan.isFileSmallerThan(formBan.businesspublicban[0], 820000)) {
          return notification(appContextV2.dispatchPanels, "La taille du fichier est trop importante (maximum: 800ko).", "warning");
        }
        
        notification(appContextV2.dispatchPanels, "Envoi en cours ...", "information");
        
        let dataToSend: any = new FormData();
        
        dataToSend.append("avatar", formBan.businesspublicban[0], formBan.businesspublicban[0].name);
        
        const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/publicban/avatar`, dataToSend, {
          withCredentials: true, headers: {
            'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
          }
        });
        
        if(fetched.data.status === 'ok') {
          notification(appContextV2.dispatchPanels, "La banière a été mise à jour", "success");
          // update front
          updateVisibleBan(fetched.data.data);
        } else {
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
      }
    })();
  }, [formBan.businesspublicban]);
  
  return (<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Bannière publique</h1>
  
    {/*------- Display Ban */}
    <div className={"text--center"}>
      {!!visibleBan && <img className={"profile-edit--banV2"} src={visibleBan} />}
      {!visibleBan && <>Aucune bannière détéctée</>}
    </div>
  
    <Spaces n={0}/>
    
    {/*------- Import Ban */}
    <div className={"space-flex--center"}>
      <div className={"header__upload-image__button"} title={"Ajoute une banière sur la page publique de l'entreprise"}>
      <input type={"file"} name={"businesspublicban"} id={"businesspublicban"} className="input-camera" onChange={updateBan()}/>
      <label htmlFor={"businesspublicban"} className={`btn-camera2 animation-straight-fade-in space-medium`}>
        <img src={"https://static.myshortlist.co/btn-camera.svg"} alt={"modify avatar"}/>
      </label>
      </div>
    </div>
    
    <Spaces n={0}/>
    
    <div className={"text--center text--grey"}>
      (taille conseillée: 1500px x 550px)
    </div>
    
    <Spaces n={1} />
  
    {/*------- Change catch phrase */}
  
    <div className={"space-flex--left"}>Mention sur la page publique, sous le nom de l'entreprise</div>
    <input type={"text"} value={businessPageMention} className={"profile-edit__input3"} onChange={(e) => {
      if(e.target.value.length <= 200) {
        setMention(e.target.value);
      }
    }} />
  
    <Spaces n={1} />
    
    <div className={"text--center"}>
      <button className={"btn-new-blue"} onClick={async() => {
        await props.updateSingleton("businessPageMention", "businessPageMention", businessPageMention);
      }}>
        Sauvegarder la mention
      </button>
    </div>

  </div>);
  
}