import React, {useContext, useEffect, useState} from "react";
import {devLog} from "../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../sharedComponents/layouts/SubPageLayout";
import Spaces from "../../../sharedComponents/components/Spaces";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import RecruiterSharedMissionLink from "../../recruiterSharedMissionLink/RecruiterSharedMissionLink";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [mission, setMission]: [any, any] = useState({});
  const [fetchMissionStatus, setFetchMissionStatus]: ["asleep" | "fetching" | "fetched" | "error", Function] = useState("asleep");
  
  devLog("mission", mission);
  
  /** Fetch mission */
  useEffect(() => {
    (async() => {
      try {
        
        setFetchMissionStatus("fetching");
        const missionId = props.match.params.missionid;
        const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/ent/m/mission`, {
          headers: {
            missionid: missionId
          },
          withCredentials: true
        }));
        
        if(fetched.data.status !== "ok") {
          return setFetchMissionStatus("error");
        }
        
        setMission(fetched.data.data);
        setFetchMissionStatus("fetched");
        
      } catch (error) {
        devLog(error);
        setFetchMissionStatus("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, []);
  
  return (<SubPageLayout status={fetchMissionStatus} subPage={props.subPage}>
    
    <div className={"container"}>
      <Spaces n={2}/>
      
      <RecruiterSharedMissionLink missionTokens={{
        rank: mission?.rank,
        mailHandler: mission?.mailHandler,
      }} white={true}/>
    </div>
  
  </SubPageLayout>);
}