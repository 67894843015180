import axios from "axios";
import {devLog} from "../../../../../../utils/devLog/devLog";
import {notification} from "../../../../../../utils/notification";

export default async function (args: {
  missionId: string,
  setFetchStatus: Function,
  dispatchDataV2: any,
  setDisplayedUnsubscribedShortlist: Function,
  dispatchPanels: Function,
}) {
  try {
    args.setFetchStatus("fetching");
    const missionId = args.missionId;
    const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/mat/ca/shortlist`, {
      headers: {
        missionid: missionId
      },
      withCredentials: true
    }));
    if (fetched.data.status !== "ok") {
      return args.setFetchStatus("error");
    }
    args.dispatchDataV2({type: "INITIALIZE_DATA", value: {
      businessProfile: fetched.data.data.businessProfile,
      missionProfile: fetched.data.data.missionProfile,
      shortlist: fetched.data.data.shortlist,
      shortlistUnsubscribed: fetched.data.data.shortlistUnsubscribed
    }});
    args.setDisplayedUnsubscribedShortlist(fetched.data.data.shortlistUnsubscribed || []);
    args.setFetchStatus("fetched");
  } catch (error) {
    devLog(error);
    args.setFetchStatus("error");
    notification(args.dispatchPanels, "Une erreur est survenue", "error");
  }
}