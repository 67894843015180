import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import NavBarLogoContent from "./NavBarLogoContent";
import {SubMenuRecruiterMain} from "./SubNav/SubMenuRecruiterMain";
import {SubMenuRecruiterProfiles} from "./SubNav/SubMenuRecruiterProfiles";
import {SubMenuRecruiterParameters} from "./SubNav/SubMenuRecruiterParameters";
import {SubMenuRecruiterMission} from "../SubNavbarComponents/SubMenuRecruiterMission";
import {SubMenuRecruiterPool} from "../SubNavbarComponents/SubMenuRecruiterPool";

export function SubMenuRecruiterPc(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  /** List pages with a specific secondary navbar */
  const PagesWithSubMenuMain: string[] = ["RecruiterNewMission", "RecruiterMissions", "RecruiterPools", "RecruiterAmbassadors", "RecruiterResearch", "PageLink"];
  const PagesWithSubMenuProfiles: string[] = ["RecruiterProfiles",];
  const PagesWithSubMenuParameters: string[] = ["RecruiterParameters",];
  
  const PagesWithSubMenuMission: string[] = ["RecruiterMissionPageRouter"];
  const PagesWithSubMenuPool: string[] = ["RecruiterPoolPageRouter"];
  
  const currentPage: string = appContextV2.appNavigation.page;
  
  /** Use LogoContent as a default fallback submenu */
  let subMenu: any = <NavBarLogoContent/>;
  
  if(PagesWithSubMenuMain.includes(currentPage)) {
    subMenu = <SubMenuRecruiterMain/>;
  }
  
  if(PagesWithSubMenuProfiles.includes(currentPage)) {
    subMenu = <SubMenuRecruiterProfiles/>;
  }
  
  if(PagesWithSubMenuParameters.includes(currentPage)) {
    subMenu = <SubMenuRecruiterParameters/>;
  }
  
  if(PagesWithSubMenuMission.includes(currentPage)) {
    subMenu = <SubMenuRecruiterMission/>;
  }
  
  if(PagesWithSubMenuPool.includes(currentPage)) {
    subMenu = <SubMenuRecruiterPool/>;
  }
  
  return subMenu;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function SubMenuCandidatePc(props: any) {
  
  const subMenu: any = <NavBarLogoContent/>;
  return subMenu;
}