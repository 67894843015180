import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";

export default async(setFetchStatusAmbassadors: Function, setAmbassadors: Function) => {
  try {
    setFetchStatusAmbassadors("fetching");
    const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/amb/m/get`, {withCredentials: true});
    if(fetched.data.status !== "ok") {
      return setFetchStatusAmbassadors("error");
    }
    setAmbassadors(fetched.data.data);
    setFetchStatusAmbassadors("fetched");
  } catch (error) {
    devLog(error);
    setFetchStatusAmbassadors("error");
  }
}