import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import CloseButton from "../components/Buttons/CloseButton";
import {Loading} from "../components/Loading/Loading";

interface IWindowLayout {
  windowTitle: string,
  size?: 'big' | 'small' | 'bigger',
  children: any,
  status?: "ok" | "pending" | "error" | "asleep" | "fetching" | "fetched"
}

export default (props: IWindowLayout) => {
  
  const status: "ok" | "pending" | "error" | "asleep" | "fetching" | "fetched" = props.status ? props.status : "pending";
  
  if(status === "pending" || status === "asleep" || status === "fetching") {
    return (<WindowLayout {...props}>
      <Loading fullPage={false} />
    </WindowLayout>);
  }
  
  if(status === "error") {
    return (<WindowLayout {...props}>
      Une erreur est survenue
    </WindowLayout>);
  }
  
  return (
    <WindowLayout {...props}>
      {props.children}
    </WindowLayout>
  );
}

function WindowLayout(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  let size = !!props.size ? props.size : 'small';
  
  return (
    <WindowBlankLayout size={size}>
      
      <article className={"window__header"}>
        <span className={"window__header__logo"}></span>
        <CloseButton eventOnClick={() => {
          appContextV2.dispatchPanels({type: "CLOSING_WINDOW", value: null});
          const timer = setTimeout(() => {
            appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
            clearTimeout(timer);
          }, 130);
        }}/>
      </article>
      
      <article className={"window__title"}>
        {props.windowTitle}
      </article>
      
      {props.children}
    
    </WindowBlankLayout>);
  
}

function WindowBlankLayout(props: { size: 'small' | 'big' | 'bigger', status?: "ok" | "pending" | "error", children: any }) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (
    <>
      <div
        className={`window-container window-container--appear ${appContextV2.appPanels.window.isWindowClosing ? "window-container--disappear" : ""}`}
        onClick={(e) => {
          e.preventDefault();
        }}>
        <section
          className={`window window--appear ${appContextV2.appPanels.window.isWindowClosing ? "window--disappear" : ""} ${props.size === 'big' ? 'window--big' : ''} ${props.size === 'bigger' ? 'window--bigger' : ''}`}
          onClick={(e) => {e.stopPropagation();}}>
          
          <article className={"animation-fade-in--faster"}>
            
            {props.children}
          
          </article>
        
        </section>
      </div>
    </>
  );
}


