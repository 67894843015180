import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {CASError} from "./CASError";
import {CASSuccess} from "./CASSuccess";
import Spaces from "../../../components/Spaces";

export {CASAuthorize};

function CASAuthorize(props: any) {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  const [dataFetchingStatus, setDataFetchingStatus] = useState("asleep");

  useEffect(() => {
    try {
      (async () => {
        await sendRequest();
      })();
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }, []);

  const sendRequest = async () => {
    try {

      setDataFetchingStatus("fetching");

      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/pool/ca/authorize`, {
        token: props.token,
      }, {
        withCredentials: true,
      });

      if (fetched.data.status !== "ok") {
        setDataFetchingStatus("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return;
      }

      setDataFetchingStatus("fetched");

      notification(appContextV2.dispatchPanels, "Succès", "success");

      return;

    } catch (error) {
      setDataFetchingStatus("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }

  if (dataFetchingStatus === "error") return (<CASError/>);

  if (dataFetchingStatus === "fetched") return (<CASSuccess/>);

  if (dataFetchingStatus === "fetching") return (<>
    <Spaces n={3}/>
    <div className={"text--center"}>Chargement...</div>
    <Spaces n={3}/>
  </>);

  return (<>
  </>);
}