import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../../../Spaces";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import axios from "axios";
import {notification} from "../../../../../../../utils/notification";

export default (props: any) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

  const [isLoading, setIsLoading] = useState(true);
  const [fetched, setFetched] = useState({
    communities: [],
    missions: []
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/can/mc/finder`, {
          timeout: 5000,
          withCredentials: true,
          headers: {
            candidateid: props.inherited.candidateId,
          }
        });

        if (fetched.data.status !== "ok") {
          setIsLoading(false);
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }

        setFetched({
          communities: fetched.data.data?.communities,
          missions: fetched.data.data?.missions
        });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })()
  }, []);

  return (<>

    <Spaces n={1}/>

    <h1 className={"results-v2__title"}>
      Le candidat se trouve dans les communautés suivantes:
    </h1>

    <Spaces n={0}/>

    {
      isLoading && <LoadingStyle2/>
    }

    {
      fetched.communities.length === 0 && <div className={"text--center"}>
          Historique vide
      </div>
    }

    {
      fetched.communities.length !== 0 && <div className={"text--center"}>
        {fetched.communities.map((item: any, key: number) => (
          <div className={"finder__place"}>
            {item.name}
          </div>
        ))}
      </div>
    }

    <Spaces n={4}/>

    <h1 className={"results-v2__title"}>
      Le candidat a des candidatures pour les postes suivants:
    </h1>

    <Spaces n={0}/>

    {
      isLoading && <LoadingStyle2/>
    }

    {
      fetched.missions.length === 0 && <div className={"text--center"}>
          Historique vide
      </div>
    }

    {
      fetched.missions.length !== 0 && <div className={"text--center"}>
        {fetched.missions.map((item: any, key: number) => (
          <div className={"finder__place"}>
            {item.name}
          </div>
        ))}
      </div>
    }

  </>);
}
