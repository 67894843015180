import Spaces from "../../../Spaces";
import React, {useState} from "react";
import axios from "axios";
import {RecruiterAmbassador} from "../../../../../recruitersPages/componentsRecruiterAmbassadors/RecruiterAmbassador";
import {IColumnData} from "../WindowColumnOptionsV2";
import {isEmailValid} from "../../../../../../utils/typeGuards";

export {WCONotifyV2};

const WCONotifyV2 = (props: {
  type: "kanban" | "preset",
  missionId: string,
  numberOfCandidates: number,
  columnData: IColumnData,
  updateColumnData: Function,
  saveColumnData: Function,
  switchColumnAction: Function,
  isWindowLoading: boolean,
  deleteColumn: Function
}) => {
  
  return (<>
    
    <article className={`white-container white-container--grey`}>
      
      {/*------------------- Header -----------------------------------------------------*/}
      <div className={"space-flex--space-between"}>
        <span className={"text--bold text--magnified11x"}>Activer l'action "Demander une évaluation" ?</span>
        <div>
          <label className={"switch-V2"}>
            <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.columnData.activateNotify}
                   onChange={() => {
                     if(!!props.columnData.activateNotify) {
                       props.updateColumnData({activateNotify: false})
                       props.saveColumnData({activateNotify: false});
                     }
                     if(!props.columnData.activateNotify) {
                       props.switchColumnAction("activateNotify");
                     }
                   }}/>
            <span className="slider"> </span>
          </label>
        </div>
      </div>
      
      <Spaces n={0}/>
      <div className={"text--center text--grey"}>
        Les personnes dont vous entrerez les email recevront une notification pour tout candidat entrant dans cette
        colonne. Cette notification les invitera à évaluer les candidats.
      </div>
      
      {/*------------------- Options -----------------------------------------------------*/}
      {props.columnData.activateNotify && <WCONotifyV2Options {...props} />}
    
    </article>
  
  </>);
  
}

function WCONotifyV2Options(props: {
  type: "kanban" | "preset",
  missionId: string,
  numberOfCandidates: number,
  columnData: IColumnData,
  updateColumnData: Function,
  saveColumnData: Function,
  switchColumnAction: Function,
  isWindowLoading: boolean,
  deleteColumn: Function
}) {
  
  const [emails, setEmails] = useState(props.columnData.notifyEmails ?? []);
  
  const isEmail1Wrong = emails[0] && !isEmailValid(emails[0]);
  const isEmail2Wrong = emails[1] && !isEmailValid(emails[1]);
  const isEmail3Wrong = emails[2] && !isEmailValid(emails[2]);
  const isEmail4Wrong = emails[3] && !isEmailValid(emails[3]);
  
  const isButtonSaveOk = !isEmail1Wrong && !isEmail2Wrong && !isEmail3Wrong &&!isEmail4Wrong;
  
  return (<>
    <Spaces n={0}/>
    
    <input className={`input--v2 input--v2--full-width ${isEmail1Wrong ? "input--wrong" : ""}`} value={emails[0] ?? ""}
           onChange={(e) => {
             const newValue = e.target.value;
             setEmails((ps: any) => ([newValue, ps[1], ps[2]]))
           }}/>
    <Spaces n={0}/>
    
    <input className={`input--v2 input--v2--full-width ${isEmail2Wrong ? "input--wrong" : ""}`} value={emails[1] ?? ""}
           disabled={!emails[0] || (emails[0]?.length === 0)}
           onChange={(e) => {
             const newValue = e.target.value;
             setEmails((ps: any) => ([ps[0], newValue, ps[2]]))
           }}/>
    <Spaces n={0}/>
    
    <input className={`input--v2 input--v2--full-width ${isEmail3Wrong ? "input--wrong" : ""}`} value={emails[2] ?? ""}
           disabled={(!emails[0] || !emails[1]) || ((emails[0]?.length === 0) && (emails[1]?.length === 0))}
           onChange={(e) => {
             const newValue = e.target.value;
             setEmails((ps: any) => ([ps[0], ps[1], newValue]))
           }}/>
    <Spaces n={0}/>
  
    <input className={`input--v2 input--v2--full-width ${isEmail4Wrong ? "input--wrong" : ""}`} value={emails[3] ?? ""}
           disabled={(!emails[0] || !emails[1] || !emails[2]) || ((emails[0]?.length === 0) || (emails[1]?.length === 0) || (emails[2]?.length === 0))}
           onChange={(e) => {
             const newValue = e.target.value;
             setEmails((ps: any) => ([ps[0], ps[1], ps[2], newValue]))
           }}/>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button disabled={!isButtonSaveOk} className={"btn-secondary"}
              onClick={() => {
                props.updateColumnData({notifyEmails: emails})
                props.saveColumnData({notifyEmails: emails});
              }}>
        Enregistrer
      </button>
    </div>
  
  </>);
}



