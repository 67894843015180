import React from "react";
import ProfileElementText from "../../../sharedComponents/layouts/ProfileElementText";

export {RecruiterProfilesUserAccount};

const RecruiterProfilesUserAccount = (props: {
  profile: any,
  updateSingleton: Function,
  assistantGet: any,
}) => {
  
  
  return(<>
  
    <div className={"container white-container"}>
      <h1 className={"profile-edit__title"}>Données du compte</h1>
    
      <div>
        Email: {props.profile.email?.value}
        <br/><br/>
        <ProfileElementText nameDisplayed={"Email de secours"}
                            nameToUpdateBack={"emailSecondary"}
                            nameToUpdateFront={"email.emailSecondary"}
                            description={""}
                            value={props.profile.email?.emailSecondary || "Aucun n'a été défini"}
                            updateData={props.updateSingleton}
                            validator={(v: any)=>{return props.assistantGet.isEmail(v)}}
                            modifier={(v: any)=>{return v.toLowerCase()}} />
        <br/>
        <ProfileElementText nameDisplayed={"Trigramme"}
                            nameToUpdateBack={"trigram"}
                            nameToUpdateFront={"trigram"}
                            description={"Le trigramme vous identifie auprès de votre équipe"}
                            value={props.profile.trigram}
                            updateData={props.updateSingleton}
                            validator={(v: any) => {
                              return props.assistantGet.isLengthUnder(v, 3) && props.assistantGet.isLengthAbove(v, 3)
                            }}
                            modifier={(v: any)=>{return v.toUpperCase()}} />
      </div>
  
    </div>
    
  </>);
}