import React from "react";
import PageLayout from "../layouts/PageLayout";
import Spaces from "../components/Spaces";
import {CandidateActionStrategy} from "./componentsCandidateAction/CandidateActionStrategy";

export {CandidateAction};

const CandidateAction = (props: any) => {
  
  const action: string = props.match.params.action || "";
  const token: string = props.match.params.token || "";
  
  const isActionSet: boolean = !!action;
  const isTokenSet: boolean = !!token;
  
  if(!isActionSet || !isTokenSet) {
    return (<PageLayout page={props.page} status={"ok"}>
      <Spaces n={14}/>
      <div className={"text--magnified11x text--center"}>Le lien ne semble pas valide</div>
      <Spaces n={8}/>
    </PageLayout>);
  }
  
  return (<PageLayout page={props.page} status={"ok"}>
    <Spaces n={10}/>
    
    <CandidateActionStrategy action={action} token={token} />
    
    <Spaces n={1}/>
  </PageLayout>);
}
