import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import {notification} from "../../../../../../../utils/notification";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import Spaces from "../../../../Spaces";
import axios from "axios";
import {devLog} from "../../../../../../../utils/devLog/devLog";
import {format} from "date-fns";
import {CW2MailChatBox} from "./componentsCW2Mail/CW2MailChatBox";
import {useWizard} from "use-wizard";
import {shortenText} from "../../../../../../../utils/shortenText";
import {CW2MailMessage} from "./componentsCW2Mail/CW2MailMessage";

export default (props: any) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

  const [messages, setMessages]: [any, any] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [step, wizard] = useWizard(["choice", "email", "sms"]);

  const addEmail = (subject: string, content: string) => {
    setMessages((ps: any) => ([{
      dateOfCreation: new Date().toString(),
      deleted: false,
      direction: "sent",
      message: content,
      saved: false,
      title: subject,
    }, ...ps]));
  }

  const addSms = (message: string) => {
    setMessages((ps: any) => ([{
      dateOfCreation: new Date().toString(),
      deleted: false,
      direction: "sent",
      message: message,
      saved: false,
    }, ...ps]));
  }
  
  /** Load messages */
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/messages/all`, {
          timeout: 20_000,
          headers: {
            candidateid: props.inherited.candidateId,
            mailhandler: props.candidate.candidate?.mailHandler,
          },
          withCredentials: true
        });
        if (fetched.data.status !== "ok") {
          setErrorLoading(true);
          setIsLoading(false);
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        setIsLoading(false);
        setMessages(fetched.data?.data?.reverse() || []);
      } catch (error) {
        devLog(error);
        setErrorLoading(true);
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, []);
  devLog("messages", messages);

  return (<div className={"text--grey container"}>

    {
      isLoading && <div className={"space-flex--center"}>
          <Spaces n={0}/>
          <LoadingStyle2/>
          <Spaces n={0}/>
      </div>
    }

    {
      ((!isLoading) && !errorLoading && (messages.length === 0)) && <div className={"text--center"}>
          <Spaces n={4}/>
          Aucun message n'est enregistré pour ce candidat
          <Spaces n={4}/>
      </div>
    }

    {
      errorLoading && <div className={"text--center"}>
          <Spaces n={4}/>
          Une erreur est survenue
          <Spaces n={4}/>
      </div>
    }

    <section className={"messages--v2"}>
      {messages?.map((item: any, key: number) => (<CW2MailMessage key={key} item={item}/>))}
    </section>

    <section className={`messages--v2__chat ${step !== "choice" ? "messages--v2__chat--extended" : ""}`}>

      <CW2MailChatBox step={step} wizard={wizard} {...props} addEmail={addEmail} addSms={addSms}/>

    </section>

    <Spaces n={5}/>

  </div>);
}