import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import LoadingStyle2 from "../../../sharedComponents/components/Loading/LoadingStyle2";
import axios from "axios";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import {devLog} from "../../../../utils/devLog/devLog";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [buttonIsActive, setButtonIsActive] = useState(false);
  
  useEffect(() => {
    (async() => {
      try {
        
        setButtonIsActive(false);
        
        const fetchJobs = axios.get(`${process.env.REACT_APP_API_URL}/data/jobs`, {
          withCredentials: true,
          timeout: 4000,
          headers: {
            language: appContextV2.appSession.app.language,
          }
        });
        
        const fetchAdvantages = axios.get(`${process.env.REACT_APP_API_URL}/data/advantages2`, {
          withCredentials: true,
          timeout: 4000,
          headers: {
            language: appContextV2.appSession.app.language,
          }
        });
        
        const fetchDefaultLocation = axios.get(`${process.env.REACT_APP_API_URL}/ent/b/default/location`, {
          withCredentials: true,
          timeout: 4000,
        });
        
        const fetchers = await Promise.allSettled([fetchJobs, fetchAdvantages, fetchDefaultLocation]);
        
        devLog("fetchers", fetchers);
        
        if(fetchers[0]?.status === 'fulfilled') {
          if(fetchers[0]?.value?.data?.status === "ok") {
            props.setJobs({
              public: fetchers[0].value?.data?.data?.public || [],
              private: fetchers[0].value?.data?.data?.private || [],
            });
          }
        }
        
        if(fetchers[1]?.status === 'fulfilled') {
          if(fetchers[1]?.value?.data?.status === "ok") {
            props.setAdvantages([...(fetchers[1]?.value?.data?.data.private || []), ...(fetchers[1]?.value?.data?.data.public || [])]);
          }
        }
        
        if(fetchers[2].status === 'fulfilled') {
          if(fetchers[2]?.value?.data?.status === "ok") {
            props.setDefaultLocation(fetchers[2]?.value?.data?.data || {});
          }
        }
        
        setButtonIsActive(true);
        
      } catch (error) {
        console.log('Dictionaries and presets were not loaded');
        setButtonIsActive(true);
      }
    })();
  }, []);
  
  return (<section className={"animation-fade-in--slow"}>
    
    <div className={"text--center assistant--v2__splash__title"}>
      Notre assistant t'aide à construire ta recherche
    </div>
    <Spaces n={0}/>
    
    <div className={"text--center assistant--v2__splash__description"}>
      Réponds d'abord à quelques questions pour définir précisément ton besoin.
      <br/>
      Tu pourras ensuite construire un formulaire interactif qui t'aidera à
      <br/>
      trier les candidatures.
    </div>
    <Spaces n={0}/>
    
    <div className={"text--center assistant--v2__splash__time"}>
      Temps estimé: 6 à 12 minutes
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      
      {
        appContextV2.appSession.sessionBusiness.openedMissions < 50 && <button className={"btn-assistant-main"}
                                                                               disabled={!buttonIsActive}
                                                                               onClick={() => {
                                                                                 props.setTimeStamps((ps: any) => ({
                                                                                   ...ps,
                                                                                   startTime: Math.round(new Date().getTime() / 1000),
                                                                                 }));
                                                                                 props.wizard.nextStep();
                                                                               }}>
          Lancer l'assistant
        </button>
      }
      
      {
        appContextV2.appSession.sessionBusiness.openedMissions >= 50 && <div className={"text--center"}>
          Vous avez trop de postes ouverts en cours dans cette équipe. Archivez un poste pour en créer un nouveau.
        </div>
      }
      
      {
        !buttonIsActive && <>
          <br/>
          <LoadingStyle2/>
        </>
      }
    
    </div>
  
  </section>);
}