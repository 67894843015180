import axios from "axios";
import {devLog} from "../../../../../../utils/devLog/devLog";

export default async(pageInfos: any, setMissionFetchStatus: any, setMission: any) => {
  try {
    if(pageInfos.pageType === 'apply') {
      setMissionFetchStatus("fetching");
      const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/ent/mfc/get/mission/form`, {
        headers: {
          slugname: pageInfos.slugname,
          slugnumber: pageInfos.slugnumber,
          rank: pageInfos.rank,
        },
        withCredentials: true});
      if(fetched.data.status === "ok") {
        setMission(fetched.data.data);
      } else {
        setMission({
          error: fetched.data.status,
        });
      }
      setMissionFetchStatus("fetched");
    }
  } catch (error) {
    devLog(error);
    setMissionFetchStatus("error");
  }
}