import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import NavBarLogoContent from "../NavBarLogoContent";
import SubMenuRecruiterMainContent from "../../SubNavbarComponents/SubMenuRecruiterMainContent";

export function SubMenuRecruiterMain(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<>
    <div className={"space-flex--left"}>
      {/*<div className={'hide-on-computers'}>*/}
      <NavBarLogoContent/>
      {/*</div>*/}
      <ul className={"subnavbar-new hide-on-smartphones animation-straight-fade-in"}>
        <SubMenuRecruiterMainContent/>
      </ul>
    </div>
  </>);
}