import React, {useContext} from "react";
import AppContext, {IAppContext} from "../../componentsApp/context/AppContext";

export default function ButtonWrapper(props: { children: any, buttonStatus: string }) {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  switch(props.buttonStatus) {
    case 'pending':
      return <>
        {appContext.appState.language === "FR" ? "Chargement..." : ""}
        {appContext.appState.language === "EN" ? "Loading..." : ""}
      </>;
    case 'error':
      return <>
        {appContext.appState.language === "FR" ? "Erreur" : ""}
        {appContext.appState.language === "EN" ? "Error" : ""}</>;
    case 'success':
      return <>
        {appContext.appState.language === "FR" ? "Succès" : ""}
        {appContext.appState.language === "EN" ? "Success" : ""}</>;
    case 'ready':
    default:
      return (<>{props.children}</>)
  }
  
}

export function ButtonWrapperV2(props: { children: any, fetchingStatus: 'asleep' | 'fetching' | 'fetched' | 'error' }) {
  switch(props.fetchingStatus) {
    case 'fetching':
      return (<>
        Chargement...
      </>);
    case 'error':
      return (<>
        Erreur
      </>);
    case 'fetched':
      return (<>
        Succès
      </>);
    case 'asleep':
    default:
      return (<>{props.children}</>)
  }
}