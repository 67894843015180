import React from "react";
import {ProfileElementBoolean} from "../../../sharedComponents/layouts/ProfileElementBoolean";

export {RecruiterParametersOptions}

const RecruiterParametersOptions = (props: {
  parameters: any,
  updateSingleton: Function,
  assistantGet: any,
}) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Paramètres (entreprise)</h1>
  
    <ProfileElementBoolean text={"Dans l'entreprise, 5 est la meilleur note (sinon, 1 sera la meilleur note)"}
                                       nameToUpdateBack={"invertRanking"}
                                       nameToUpdateFront={"invertRanking"}
                                       description={`Actuellement la meilleur note est ${props.parameters?.invertRanking ? "5" : "1"}`}
                                       value={props.parameters?.invertRanking}
                                       updateData={props.updateSingleton} />
    
  </div>);
}