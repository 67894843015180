import React from "react";
import {ProfileElementBoolean} from "../../../sharedComponents/layouts/ProfileElementBoolean";

export {RecruiterParametersPersonnal}

const RecruiterParametersPersonnal = (props: {
  parameters: any,
  updateSingletonParametersUser: Function,
  assistantGet: any,
}) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Notifications par email</h1>

    <ProfileElementBoolean text={"Recevoir un email en cas de feedback manager"}
                           nameToUpdateBack={"notifyEvaluation"}
                           nameToUpdateFront={"user.access.notifyEvaluation"}
                           description={""}
                           value={props.parameters?.user?.access?.notifyEvaluation}
                           updateData={props.updateSingletonParametersUser} />

    <ProfileElementBoolean text={"Recevoir un email en cas de réponse d’un candidat"}
                           nameToUpdateBack={"notifyAnswer"}
                           nameToUpdateFront={"user.access.notifyAnswer"}
                           description={""}
                           value={props.parameters?.user?.access?.notifyAnswer}
                           updateData={props.updateSingletonParametersUser} />

    <ProfileElementBoolean text={"Recevoir un email lorsqu’on me tag"}
                           nameToUpdateBack={"notifyTag"}
                           nameToUpdateFront={"user.access.notifyTag"}
                           description={""}
                           value={props.parameters?.user?.access?.notifyTag}
                           updateData={props.updateSingletonParametersUser} />

  </div>);
}