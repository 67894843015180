import React from "react";
import CloseButton from "../../../sharedComponents/components/Buttons/CloseButton";

export default (props: any) => {
  
  const deleteAnswer = (questionIndex: number, answerIndex: number) => {
    const newAnswers = [...[...props.item.answers].splice(0, answerIndex), ...[...props.item.answers].splice(answerIndex + 1, props.item.answers.length - answerIndex - 1)];
    props.updateQuestion(props.id, questionIndex, {"answers": newAnswers});
  }
  
  const addAnswer = (questionIndex: number) => {
    const newAnswers = [...props.item.answers, {
      answer: "",
      answerData: props.item?.blocking === "true" ? "keep" : "0",
    }];
    props.updateQuestion(props.id, questionIndex, {"answers": newAnswers});
  }
  
  const updateAnswer = (questionIndex: number, answerIndex: number, keyValuePair: any) => {
    const newAnswers = [...props.item.answers];
    newAnswers[answerIndex] = {
      ...newAnswers[answerIndex],
      ...keyValuePair,
    }
    props.updateQuestion(props.id, questionIndex, {"answers": newAnswers});
  }
  
  switch(props.item.type) {
    
    case 'singleChoice':
    case 'multipleChoice':
      return (<div draggable={true}
                   onDragStart={(e: any) => {
                     e.preventDefault();
                     e.stopPropagation();
                   }}>
        <div className={"editor-v2__section__question__titles"}><ChooseIfQuestionIsBlocking {...props} /></div>
        
        <div className={"text--magnified2x editor-v2__section__question__titles"}><br/>Réponses<br/></div>
        {props.item?.answers?.map((itemAnswer: any, key: number) => (<div className={"editor-v2__section__question__answer"}
                                                                          // draggable={true}
                                                                          // onDragOver={(e) => {e.preventDefault();}}
                                                                          // onDragStart={()=>{console.log("lol")}}
                                                                          // onDrop={() => {console.log("dropped")}}
                                                                          key={key}
        >
          <div className={"space-flex--left"}>
            <img className={"img-drag"} src={"https://static.myshortlist.co/icon-drag.svg"}/>
            <textarea className={"editor-v2__section__question__answer__input"} value={itemAnswer.answer} onChange={(e) => {
              updateAnswer(props.index, key, {
                answer: e.target.value,
              })
            }}>
            </textarea>
            <AnswerWeight {...props} itemAnswer={itemAnswer} indexAnswer={key} updateAnswer={updateAnswer}/>
          </div>
          <div className={"space-flex--right"}>
            <CloseButton title={'Supprime la réponse'} eventOnClick={() => deleteAnswer(props.index, key)}/>
          </div>
        </div>))}
        
        <div>
          <br/>
          <br/>
        </div>
        
        {
          props.item.answers.length < 20 && <div>
            <div className={"text--grey text--center"}>Ajoute une réponse avec le boutton "+"</div>
            <div className={"text--grey text--center"}>
              <button className={"btn--hollow"} onClick={() => addAnswer(props.index)}>
                <img className={"btn-close"} title={"ajoute une réponse"} src={"https://static.myshortlist.co/btn-plus.svg"}/>
              </button>
            </div>
          </div>
        }
      
      </div>);
    
    default:
    case 'freeText':
      return (<></>);
  }
  
}

function ChooseIfQuestionIsBlocking(props: any) {
  
  const switchBlockingOrNot = () => {
    
    const newValue = props.item.blocking === "true" ? "false" : "true";
    
    const newAnswers = [...props.item.answers].map((item: any) => ({
      ...item,
      answerData: newValue === "true" ? "keep" : "0",
    }));
    
    props.updateQuestion(props.id, props.index, {
      "blocking": newValue,
      "answers": newAnswers,
    });
  }
  
  return (<div className={"space-flex--left"}>
    <br/><br/>
    <label className={"switch-V2"}>
      <input type={"checkbox"} className={"checkbox-on-off"} checked={(props.item.blocking === "true")} onChange={() => switchBlockingOrNot()}/>
      <span className="slider"> </span>
    </label>
    <div className={"small-margin-left"}>Cocher si la question porte sur un critère éliminatoire</div>
  
  </div>);
}

function AnswerWeight(props: any) {
  
  switch(props.item?.blocking) {
    case 'true':
      return (<>
        <button title={"Cette réponse doit être éliminatoire"} className={"btn--hollow btn-answer-editor-btn"} onClick={() => {
          props.updateAnswer(props.index, props.indexAnswer, {answerData: "block"})
        }}>
          {props.itemAnswer.answerData === "block" && <img className={`btn-answer-editor`} src={"https://static.myshortlist.co/zero-blue.svg"}/>}
          {props.itemAnswer.answerData !== "block" &&
          <img className={`btn-answer-editor btn-answer-editor--disabled`} src={"https://static.myshortlist.co/zero-blue.svg"}/>}
        </button>
        <button title={'Cette réponse n\'est pas éliminatoire'} className={"btn--hollow btn-answer-editor-btn"} onClick={() => {
          props.updateAnswer(props.index, props.indexAnswer, {answerData: "keep"})
        }}>
          {props.itemAnswer.answerData === "keep" && <img className={`btn-answer-editor`} src={"https://static.myshortlist.co/check-blue.svg"}/>}
          {props.itemAnswer.answerData !== "keep" &&
          <img className={`btn-answer-editor btn-answer-editor--disabled`} src={"https://static.myshortlist.co/check-blue.svg"}/>}
        </button>
      </>);
    case 'false':
      return (<>
        <button title={"Cette réponse est neutre (elle n'est cependant pas éliminatoire)"} className={"btn--hollow btn-answer-editor-btn"} onClick={() => {
          props.updateAnswer(props.index, props.indexAnswer, {answerData: "0"});
        }}>
          {props.itemAnswer.answerData === "0" && <img className={`btn-answer-editor`} src={"https://static.myshortlist.co/zero-blue.svg"}/>}
          {props.itemAnswer.answerData !== "0" &&
          <img className={`btn-answer-editor btn-answer-editor--disabled`} src={"https://static.myshortlist.co/zero-blue.svg"}/>}
        </button>
        <button title={"Cette réponse est faiblement valorisée"} className={"btn--hollow btn-answer-editor-btn"} onClick={() => {
          props.updateAnswer(props.index, props.indexAnswer, {answerData: "2"});
        }}>
          {props.itemAnswer.answerData === "2" && <img className={`btn-answer-editor`} src={"https://static.myshortlist.co/plus-blue.svg"}/>}
          {props.itemAnswer.answerData !== "2" &&
          <img className={`btn-answer-editor btn-answer-editor--disabled`} src={"https://static.myshortlist.co/plus-blue.svg"}/>}
        </button>
        <button title={"Cette réponse est fortement valorisée"} className={"btn--hollow btn-answer-editor-btn"} onClick={() => {
          props.updateAnswer(props.index, props.indexAnswer, {answerData: "5"});
        }}>
          {props.itemAnswer.answerData === "5" && <img className={`btn-answer-editor`} src={"https://static.myshortlist.co/plusplus-blue.svg"}/>}
          {props.itemAnswer.answerData !== "5" &&
          <img className={`btn-answer-editor btn-answer-editor--disabled`} src={"https://static.myshortlist.co/plusplus-blue.svg"}/>}
        </button>
      </>);
    default:
      return (<></>);
  }
  
}