import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import Spaces from "../../../../../components/Spaces";

export default (props: any) => {
    
    useEffect(() => {
        (() => {
            props.wizard.nextStep();
        })();
    }, []);

    return(<><Spaces n={15}/></>);
}