import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import MenuRecruiterContent from "../DropDownComponents/MenuRecruiterDropDownContent";
import MenuCandidateContent from "../DropDownComponents/MenuCandidateDropDownContent";
import MenuPublicDropDownContent from "../DropDownComponents/MenuPublicDropDownContent";
import LinkButton from "../../Buttons/LinkButton";
import StatsButton from "../../Buttons/StatsButton";
import MicButton from "../../Buttons/MicButton";


export default function MenuDefault(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const isSpaceCandidate: boolean =  appContextV2.appSession.app.space === 'candidate';
  const isSpaceRecruiter: boolean =  appContextV2.appSession.app.space === 'recruiter';
  
  return (<MenuDefaultWrapper>
    {
      isSpaceRecruiter && <ul className={"navbar-smartphone__items"}>
        <MenuRecruiterContent/>
      </ul>
    }
    {
      (!isSpaceRecruiter && !isSpaceCandidate) && <ul className={"navbar-smartphone__items"}>
        <MenuPublicDropDownContent/>
      </ul>
    }
    {/*{*/}
    {/*  isSpaceCandidate && <ul className={"navbar-smartphone__items"}>*/}
    {/*    <MenuCandidateContent/>*/}
    {/*  </ul>*/}
    {/*}*/}
  </MenuDefaultWrapper>);
}

function MenuDefaultWrapper(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<>
  
    {appContextV2.appSession.app.space === "candidate" && <>
      <p>
              <span className={"btn-holder"}>
                <LinkButton />
              </span>
      </p>
    </>
    }
    
    {appContextV2.appSession.app.space === "recruiter" && <>
      <p>
              <span className={"btn-holder"}>
                <LinkButton />
              </span>
      </p>
    </>
    }
    
    {props.children}
  
  </>)
}

