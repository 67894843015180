import React, {useContext, useState} from "react";
import Spaces from "../../../../sharedComponents/components/Spaces";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import axios from "axios";
import LoadingStyle2 from "../../../../sharedComponents/components/Loading/LoadingStyle2";

export default (props: {

    frontUpdates: any,
    setFilterByWords: Function,
    setFilterByMark: Function,
    filterByWords: string,
    filterByMark: string,
    filterByYearsOfExperience: number,
    setFilterByYearsOfExperience: Function,
    filterBySalaryWanted: number,
    setFilterBySalaryWanted: Function,
    filterByDateOfAvailability: any,
    setFilterByDateOfAvailability: Function,
    filterByTraining: string,
    setFilterByTraining: Function,
    setIsLoading: Function,
    isLoading: boolean,
    numberOfColumns: number,
    numberOfCandidates: number,
    numberOfCandidates1: number,
    numberOfCandidates2: number,
    numberOfCandidates3: number,
    numberOfCandidates4: number,
    numberOfCandidatesImported: number,
    numberOfCandidatesBlocked: number,
}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    return (<div className={"kanban-template__nav__content"}>

        <Spaces n={1}/>

        <div className={"text--center"}>
            {props.numberOfCandidates} Candidat(es)
        </div>

        <Spaces n={0}/>

        <div className={`${props.isLoading ? "" : "invisible"}`}>
            <LoadingStyle2/>
        </div>

        <Spaces n={0}/>

        <h1 className={"title--bold text--center"}>
            Recherche
        </h1>
        <input disabled={false} type={"text"} placeholder={"Nom, Prénom"} className={"input-shortlist-filter"}
               value={props.filterByWords} onChange={(e) => props.setFilterByWords(e.target.value)}/>
        <input disabled={false} type={"number"} placeholder={"Expérience supérieure à …"}
               className={"input-shortlist-filter"}
               value={props.filterByYearsOfExperience} onChange={(e) => props.setFilterByYearsOfExperience(e.target.value)}/>
        <input disabled={false} type={"number"} placeholder={"Prétention salariale maximum"}
               className={"input-shortlist-filter"}
               value={props.filterBySalaryWanted} onChange={(e) => props.setFilterBySalaryWanted(e.target.value)}/>
        <input disabled={false} type={"date"} placeholder={"Disponible avant le …"} className={"input-shortlist-filter"}
               value={props.filterByDateOfAvailability} onChange={(e) => props.setFilterByDateOfAvailability(e.target.value)}/>
        <input disabled={false} type={"text"} placeholder={"Niveau de formation"} className={"input-shortlist-filter"}
               value={props.filterByTraining} onChange={(e) => props.setFilterByTraining(e.target.value)}/>

        <Spaces n={1}/>

        <h1 className={"title--bold text--center"}>
            Filtres
        </h1>
        <button disabled={false}
                className={`btn-shortlist-filter space-flex--left ${props.filterByMark === "100" ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                    if (props.filterByMark === "100") {
                        props.setFilterByMark("");
                    } else {
                        props.setFilterByMark("100");
                    }
                }}>
            <div className={"btn-shortlist-filter__small_logo btn-shortlist-filter__small_logo--color1"}></div>
            Supérieurs à 90% ({props.numberOfCandidates1})
        </button>
        <button disabled={false}
                className={`btn-shortlist-filter space-flex--left ${props.filterByMark === "90" ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                    if (props.filterByMark === "90") {
                        props.setFilterByMark("");
                    } else {
                        props.setFilterByMark("90");
                    }
                }}>
            <div className={"btn-shortlist-filter__small_logo btn-shortlist-filter__small_logo--color2"}></div>
            De 71% à 90% ({props.numberOfCandidates2})
        </button>
        <button disabled={false}
                className={`btn-shortlist-filter space-flex--left ${props.filterByMark === "70" ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                    if (props.filterByMark === "70") {
                        props.setFilterByMark("");
                    } else {
                        props.setFilterByMark("70");
                    }
                }}>
            <div className={"btn-shortlist-filter__small_logo btn-shortlist-filter__small_logo--color3"}></div>
            De 51% à 70% ({props.numberOfCandidates3})
        </button>
        <button disabled={false}
                className={`btn-shortlist-filter space-flex--left ${props.filterByMark === "50" ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                    if (props.filterByMark === "50") {
                        props.setFilterByMark("");
                    } else {
                        props.setFilterByMark("50");
                    }
                }}>
            <div className={"btn-shortlist-filter__small_logo btn-shortlist-filter__small_logo--color4"}></div>
            Moins de 50% ({props.numberOfCandidates4})
        </button>
        <button disabled={false}
                className={`btn-shortlist-filter space-flex--left ${props.filterByMark === "blocked" ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                    if (props.filterByMark === "blocked") {
                        props.setFilterByMark("");
                    } else {
                        props.setFilterByMark("blocked");
                    }
                }}>
            <div className={"btn-shortlist-filter__small_logo btn-shortlist-filter__small_logo--blocked"}></div>
            Bloqués ({props.numberOfCandidatesBlocked})
        </button>

        <button disabled={false}
                className={`btn-shortlist-filter space-flex--left ${props.filterByMark === "imported" ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                    if (props.filterByMark === "imported") {
                        props.setFilterByMark("");
                    } else {
                        props.setFilterByMark("imported");
                    }
                }}>
            <div className={"btn-shortlist-filter__small_logo btn-shortlist-filter__small_logo--imported"}></div>
            Importés ({props.numberOfCandidatesImported})
        </button>

        <Spaces n={1}/>

        <h1 className={"title--bold text--center"}>
            Actions
        </h1>
        <button disabled={props.isLoading} className={`btn-shortlist-filter`} onClick={async () => {
            try {
                if (props.numberOfColumns >= 8) {
                    return notification(appContextV2.dispatchPanels, "Nombre maximum de colonnes atteint", "warning");
                }
                notification(appContextV2.dispatchPanels, "Indexation de la colonne", "information");
                props.setIsLoading(true);
                // Update front
                // props.frontUpdates.addColumnFront();
                // Update back
                const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/mat/com/add/column`, {
                    missionId: appContextV2.appNavigation.missionId
                }, {withCredentials: true});
                if (fetched.data.status !== "ok") {
                    props.setIsLoading(false);
                    props.frontUpdates.deleteColumnFront("");
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
                // Update columnId
                props.frontUpdates.setsColumnId(fetched.data.data);
                notification(appContextV2.dispatchPanels, "Succès de l'ajout de colonne", "success");
                props.setIsLoading(false);
            } catch (error) {
                props.setIsLoading(false);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                props.frontUpdates.deleteColumnFront("");
            }

        }}>
            Ajouter une colonne
        </button>
        <button disabled={true} title={"Arrive bientôt"}
                className={`btn-shortlist-filter ${false ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                }}>
            Mode séléction (à venir)
        </button>
        <button disabled={true} title={"Arrive bientôt"}
                className={`btn-shortlist-filter ${false ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                }}>
            Action de groupe (à venir)
        </button>

        <Spaces n={1}/>

        <h1 className={"title--bold text--center"}>
            Import/Export
        </h1>
        <button disabled={false} title={"Importe un candidat d'une communauté"}
                className={`btn-shortlist-filter ${false ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                    appContextV2.dispatchPanels({
                        type: "OPEN_WINDOW", value: {
                            type: "importFromCommunity",
                            data: {
                                missionId: appContextV2.appNavigation.missionId,
                                addCandidacyFront: props.frontUpdates.addCandidacyFront,
                            }
                        }
                    });
                }}>
            Importer d'une communauté
        </button>
        <button disabled={false} title={"Importe un candidat d'une autre recherche"}
                className={`btn-shortlist-filter ${false ? "btn-shortlist-filter--selected" : ""}`}
                onClick={() => {
                    appContextV2.dispatchPanels({
                        type: "OPEN_WINDOW", value: {
                            type: "importFromOtherResearch",
                            data: {
                                missionId: appContextV2.appNavigation.missionId,
                                addCandidacyFront: props.frontUpdates.addCandidacyFront,
                            }
                        }
                    });
                }}>
            Importer d'une recherche
        </button>

        <Spaces n={6}/>

    </div>)

}