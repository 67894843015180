import AppContext, {IAppContext, IAppV2Context} from "../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import CloseButton from "../Buttons/CloseButton";
import Spaces from "../Spaces";
import MenuDefault from "./componentsMenuFromBottom/MenuDefault";
import MenuForCandidate from "./componentsMenuFromBottom/MenuForCandidate";
import MenuForMissionStatus from "./componentsMenuFromBottom/MenuForMissionStatus";

export default function MenuDropFromBottom(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const type: any = appContextV2.appPanels.menuFromBottom.menuFromBottomOptions.type;
  
  switch(type) {
  
    /**
     * --------------------------------------------------------
     * Default menu for public, recruiter and candidate pages
     */
    case 'menu':
      return(<MenuDropFromBottomWrapper>
        <MenuDefault />
      </MenuDropFromBottomWrapper>);
      
    case 'missionStatus':
      return(<MenuDropFromBottomWrapper>
        <MenuForMissionStatus />
      </MenuDropFromBottomWrapper>);
      
    // case 'candidate':
    //   console.log("------3")
    //   return(<MenuDropFromBottomWrapper>
    //     <MenuForCandidate />
    //   </MenuDropFromBottomWrapper>);
      
    // case 'pool':
    //   return(<MenuDropFromBottomWrapper>
    //     <MenuForPool />
    //   </MenuDropFromBottomWrapper>);
      
    default:
      return(<>
      </>);
  }
  
}

function MenuDropFromBottomWrapper (props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<div className={`navbar-smartphone-container ${!appContextV2.appPanels.menuFromBottom.isMenuFromBottomVisible ? "navbar-smartphone-container--hidden" : ""}`}>
    <section className={`navbar-smartphone navbar-smartphone--appear ${appContextV2.appPanels.menuFromBottom.isMenuFromBottomClosing ? "navbar-smartphone--disappear" : ""}`}>
      
      <Spaces n={0}/>
      
      <article>
        
        <CloseButton eventOnClick={() => {
          appContextV2.dispatchPanels({type: "CLOSING_MENU_FROM_BOTTOM", value: null});
          const timer = setTimeout(() => {
            appContextV2.dispatchPanels({type: "CLOSE_MENU_FROM_BOTTOM", value: null});
            clearTimeout(timer);
          }, 125);
        }}/>
        
      </article>
      
      {props.children}
      
    </section>
  </div>);
}
