import React, {useContext, useState} from "react";
import FirstLoginTutorialContent from "./FirstLoginTutorialContent";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";
import {notification} from "../../../../utils/notification";
import CloseButton from "../Buttons/CloseButton";
import Spaces from "../Spaces";

export default function FirstLoginTutorial(props: any) {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  const hideTutorialForEver = async () => {
    try {

      const answer = await axios.put(`${process.env.REACT_APP_API_URL}/session/maskTutorial`, {}, {withCredentials: true});
      appContextV2.dispatchSession({
        type: "CLOSE_TUTORIAL",
        value: null
      });

      notification(appContextV2.dispatchPanels, "Succès", "success");

    } catch (error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  switch(appContextV2.appSession.app.space) {
    
    case 'recruiter':
      return (
        <div className={"window-tutorial__main-container"}>
          <div className={"window-tutorial"}>
            <CloseButton eventOnClick={() => appContextV2.dispatchSession({
              type: "CLOSE_TUTORIAL",
              value: null
            })}/>
            <Spaces n={0}/>
            <FirstLoginTutorialContent hideTutorialForEver={hideTutorialForEver}/>
          </div>
        </div>);
      
    case 'candidate':
      return(<></>);
      
    default:
      return(<></>);
  }

}