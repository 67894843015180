import React, {useState} from "react";
import Spaces from "../../../Spaces";

export default (props: {
  fieldToUpdate: string,
  modifier: Function,
  mission: any,
}) => {
  
  const [remote, setRemote] = useState(!!props.mission?.remote);
  const [roaming, setRoaming] = useState(!!props.mission?.roaming);
  const [roamingPlace, setRoamingPlace] = useState(props.mission?.roamingPlace);
  // const [location, setLocation] = useState(props.mission?.location || {});
  
  const [country, setCountry] = useState(props.mission?.location?.country || "");
  const [postalCode, setPostalCode] = useState(props.mission?.location?.postalCode || "");
  const [city, setCity] = useState(props.mission?.location?.city || "");
  const [street, setStreet] = useState(props.mission?.location?.street || "");
  
  return (<>
    
    <div className={"white-container white-container--grey"}>
      
      <input type={"checkbox"} checked={remote} onClick={() => {
        props.modifier("remote", !remote);
        setRemote((ps: boolean) => !ps);
      }}/> Le poste est en télétravail
      
      {
        !remote && <>
          <Spaces n={0}/>
          <input type={"checkbox"} checked={roaming} onClick={() => {
            props.modifier("roaming", !roaming);
            setRoaming((ps: boolean) => !ps);
          }}/> Le poste est en itinérance ou sur plusieurs sites
        </>
      }
      
      {
        !remote && roaming && <>
          <Spaces n={0}/>
          <label>
            <span className={"text--minified2x"}>Préciser le lieu du poste</span><br/>
            <input type={"text"} className={"input--v2"} value={roamingPlace} onChange={(e) => {
              if(e.target.value.length < 200) {
                props.modifier("roamingPlace", e.target.value);
                setRoamingPlace(e.target.value);
              }
            }}/>
          </label>
        </>
      }
      
      {
        !remote && !roaming && <>
          
          {/* ------------------------------------ Location -- */}
          
          <Spaces n={0}/>

          <label>
            <span className={"text--minified2x"}>Pays</span><br/>
            <input type={"text"} className={"input--v2"} value={country} onChange={(e) => {
              if(e.target.value.length < 200) {
                props.modifier("location.country", e.target.value);
                setCountry(e.target?.value);
              }
            }}/>
          </label>

          <Spaces n={0}/>

          <label>
            <span className={"text--minified2x"}>Code postal</span><br/>
            <input type={"number"} className={"input--v2"} value={postalCode} onChange={(e) => {
              if(e.target.value.length < 9) {
                props.modifier("location.postalCode", e.target.value);
                setPostalCode(e.target?.value);
              }
            }}/>
          </label>

          <Spaces n={0}/>

          <label>
            <span className={"text--minified2x"}>Ville</span><br/>
            <input type={"text"} className={"input--v2"} value={city} onChange={(e) => {
              if(e.target.value.length < 100) {
                props.modifier("location.city", e.target.value);
                setCity(e.target?.value);
              }
            }}/>
          </label>

          <Spaces n={0}/>

          <label>
            <span className={"text--minified2x"}>Rue</span><br/>
            <input type={"text"} className={"input--v2"} value={street} onChange={(e) => {
              if(e.target.value.length < 100) {
                props.modifier("location.street", e.target.value);
                setStreet(e.target?.value);
              }
            }}/>
          </label>
          
        </>
      }
    
    </div>
  
  </>);
  
}