import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/Spaces";
import {RecruiterAmbassador} from "../../../recruitersPages/componentsRecruiterAmbassadors/RecruiterAmbassador";
import {Link} from "react-router-dom";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import DisplayCandidatesEvaluations from "../../components/DisplayCandidatesEvaluations";

export {AllCandidatesEvaluations};

/**
 * --------------------------------------------
 * Page that handles business page
 * Primary-level page
 * --------------------------------------------
 */
const AllCandidatesEvaluations = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const token: string = props.match.params.token || "";
  const [candidates, setCandidates]: any = useState({});
  const [fetchedStatus, setFetchedStatus]: ["asleep" | "fetching" | "fetched" | "forbidden" | "error", Function] = useState("asleep");
  const [displayedCandidates, setDisplayedCandidates]: any = useState([]);
  const [textFilter, setTextFilter]: any = useState("");
  let isResearchActive: boolean = !!textFilter;
  
  useEffect(() => {
    (async() => {
      try {
        setFetchedStatus("fetching");
        
        const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/mat/cam/already/evaluate/candidate`, {
          headers: {
            token: token
          },
          withCredentials: true
        });
        
        if(fetched.data.status !== "ok") {
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
        setCandidates(fetched?.data?.data);
        setDisplayedCandidates(fetched?.data?.data?.candidatesToEvaluate);
        
        setFetchedStatus("fetched");
        
      } catch(error) {
        setFetchedStatus("error");
        devLog(error);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, []);
  
  useEffect(() => {
    
     if(textFilter === "" ){
       isResearchActive=false
       return setDisplayedCandidates(candidates.candidatesToEvaluate);
     }

    const filter = (candidates?.candidatesToEvaluate ?? []).filter((item: any) => ((item.candidate?.firstName?.toLowerCase()
        .includes(textFilter.toLowerCase())) || (item.candidate?.lastName?.toLowerCase()
        .includes(textFilter.toLowerCase())) || (item.mission?.positionsWanted?.toLowerCase()
        .includes(textFilter.toLowerCase())))).map((item: any) => (item));

    isResearchActive=true;
    setDisplayedCandidates([
      ...filter]);
// =======
//     // if firstNameFilter === "" => return setCandidates par défaut
//
//     const firstNameFilter = (candidates?.candidatesToEvaluate ?? []).filter((item: any) => (item.candidate?.firstName?.toLowerCase()
//         .includes(textFilter.toLowerCase()))).map((item: any) => (item));
//
//     const lastNameFilter= (candidates?.candidatesToEvaluate ?? []).filter((item: any) => (item.candidate?.lastName?.toLowerCase()
//         .includes(textFilter.toLowerCase()))).map((item: any) => (item));
//
//     const positionWantedFilter = (candidates?.candidatesToEvaluate ?? []).filter((item: any) => (item.mission?.positionsWanted?.toLowerCase()
//         .includes(textFilter.toLowerCase()))).map((item: any) => (item));
//
//     setDisplayedCandidates([
//       ...firstNameFilter,
//       ...lastNameFilter,
//       ...positionWantedFilter]);
// >>>>>>> avatarInEval
    
  }, [textFilter,candidates.candidatesToEvaluate]);

  
  return (<PageLayout fullWidth={true} grey={true} page={props.page} status={fetchedStatus}>
    
    <Spaces n={5}/>
    
    <h1 className={"profile-edit__title text--center"}>Mes évaluations</h1>
    
    <Spaces n={1}/>
    
    <div className={"candidates-evaluations-research"}>
      <div className={"filters__element__title text--center"}>
        Recherche
      </div>
      <div>
        <input className={`candidates-evaluations-research-input ${textFilter.length!==0 ? "candidates-evaluations-research-input--selected" : ""}`}
               type={"text"} value={textFilter} onChange={(e) => {
          setTextFilter(e.target.value);
        }}/>
      </div>
    </div>
  
    <Spaces n={1}/>
    
    
    <h2 className={" text--center text--new-blue"}>Évaluations à faire</h2>
    <Spaces n={0}/>
    
    <DisplayCandidatesEvaluations  displayedCandidates={displayedCandidates}
                                   setDisplayedCandidates={setDisplayedCandidates}
                                    status={"pending"}
                                   isResearchActive={isResearchActive}
                                    setCandidates={setCandidates}/>
  
    <Spaces n={1}/>

    <h2 className={" text--center text--new-blue"}>Évaluations faites</h2>

    <Spaces n={0}/>

    <DisplayCandidatesEvaluations displayedCandidates={displayedCandidates}
                                  setDisplayedCandidates={setDisplayedCandidates}
                                  status={"done"}
                                  isResearchActive={isResearchActive}
                                  setCandidates={setCandidates}/>
  
    <Spaces n={3}/>
  
  </PageLayout>);
}
