import React, {useEffect, useState} from "react";
import Spaces from "../../../sharedComponents/components/Spaces";

export {RecruiterProfilesBusinessSocials};

const RecruiterProfilesBusinessSocials = (props: {
  assistantGet: any,
  profile: any,
  updateSingleton: Function,
}) => {
  
  const [businessUrl, setWebsite] = useState(props.profile.businessUrl || "");
  const [linkedinUrl, setLinkedin] = useState(props.profile.linkedinUrl || "");
  const [twitterUrl, setTwitter] = useState(props.profile.twitterUrl || "");
  const [facebookUrl, setFacebook] = useState(props.profile.facebookUrl || "");
  const [youtubeUrl, setYoutube] = useState(props.profile.youtubeUrl || "");
  
  useEffect(() => {
    setWebsite(props.profile.businessUrl || "");
  }, [props.profile.businessUrl]);
  
  useEffect(() => {
    setLinkedin(props.profile.linkedinUrl || "");
  }, [props.profile.linkedinUrl]);
  
  useEffect(() => {
    setTwitter(props.profile.twitterUrl || "");
  }, [props.profile.twitterUrl]);
  
  useEffect(() => {
    setFacebook(props.profile.facebookUrl || "");
  }, [props.profile.facebookUrl]);
  
  useEffect(() => {
    setYoutube(props.profile.youtubeUrl || "");
  }, [props.profile.youtubeUrl]);
  
  return (<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Réseaux sociaux & internet</h1>
    
    <div>Site de l'entreprise</div>
    <input type={"text"} value={businessUrl} className={"profile-edit__input3"} onChange={(e) => {
      if(e.target.value.length <= 200) {
        setWebsite(e.target.value);
      }
    }}/>
    
    <Spaces n={0}/>
    
    <div className={"space-flex--left"}><img className={"profile-edit__label__image"} src={"https://static.myshortlist.co/btn-black-linkedin.svg"}/> LinkedIn
    </div>
    <input type={"text"} value={linkedinUrl} className={"profile-edit__input3"} onChange={(e) => {
      if(e.target.value.length <= 200) {
        setLinkedin(e.target.value)
      }
    }}/>
    
    <Spaces n={0}/>
    
    <div className={"space-flex--left"}><img className={"profile-edit__label__image"} src={"https://static.myshortlist.co/btn-black-facebook.svg"}/> FaceBook
    </div>
    <input type={"text"} value={facebookUrl} className={"profile-edit__input3"} onChange={(e) => {
      if(e.target.value.length <= 200) {
        setFacebook(e.target.value);
      }
    }}/>
    
    <Spaces n={0}/>
    
    <div className={"space-flex--left"}><img className={"profile-edit__label__image"} src={"https://static.myshortlist.co/btn-black-twitter.svg"}/> Twitter
    </div>
    <input type={"text"} value={twitterUrl} className={"profile-edit__input3"} onChange={(e) => {
      if(e.target.value.length <= 200) {
        setTwitter(e.target.value)
      }
    }}/>
    
    <Spaces n={0}/>
    
    <div className={"space-flex--left"}><img className={"profile-edit__label__image"} src={"https://static.myshortlist.co/btn-black-youtube.svg"}/> YouTube
    </div>
    <input type={"text"} value={youtubeUrl} className={"profile-edit__input3"} onChange={(e) => {
      if(e.target.value.length <= 200) {
        setYoutube(e.target.value);
      }
    }}/>
    
    <Spaces n={2}/>
    
    <div className={"text--center"}>
      <button className={"btn-new-blue"} onClick={async() => {
        if(businessUrl !== props.profile.businessUrl) {
          await props.updateSingleton("businessUrl", "businessUrl", businessUrl);
        }
        if(linkedinUrl !== props.profile.linkedinUrl) {
          await props.updateSingleton("linkedinUrl", "linkedinUrl", linkedinUrl);
        }
        if(facebookUrl !== props.profile.facebookUrl) {
          await props.updateSingleton("facebookUrl", "facebookUrl", facebookUrl);
        }
        if(twitterUrl !== props.profile.twitterUrl) {
          await props.updateSingleton("twitterUrl", "twitterUrl", twitterUrl);
        }
        if(youtubeUrl !== props.profile.youtubeUrl) {
          await props.updateSingleton("youtubeUrl", "youtubeUrl", youtubeUrl);
        }
        // todo: merge into one single fetch ...
      }}>
        Sauvegarder
      </button>
    </div>
  </div>);
  
}