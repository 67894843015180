import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import CandidateWindowV2Profile from "./componentsCWV2/CandidateWindowV2Profile";
import CandidateWindowV2Loading from "./componentsCWV2/CandidateWindowV2Loading";
import CandidateWindowV2Navbar from "./componentsCWV2/CandidateWindowV2Navbar";
import CandidateWindowV2Content from "./componentsCWV2/CandidateWindowV2Content";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [candidate, setCandidate]: [any, Function] = useState({
    candidate: {},
    candidacy: {
      _id: "",
    }
  });
  const [windowStatus, setWindowStatus] = useState("asleep");
  const [dictionaries, setDictionaries] = useState({});
  const [page, setPage]: ["" | "cv" | "doc" | "profile" | "results" | "trigram" | "mail" | "history" | "share", Function] = useState("");
  
  const inherited = {
    category: appContextV2.appPanels.window.windowOptions.data.category, // mission or pool
    keywords: appContextV2.appPanels.window.windowOptions.data.keywords,
    candidateId: appContextV2.appPanels.window.windowOptions.data.candidateId,
    missionId: appContextV2.appPanels.window.windowOptions.data.missionId,
    basicData: appContextV2.appPanels.window.windowOptions.data.basicData,
    frontUpdatePool: appContextV2.appPanels.window.windowOptions.data.frontUpdatePool,
    frontUpdateMission: appContextV2.appPanels.window.windowOptions.data.frontUpdateMission,
    frontUpdateCandidacy: appContextV2.appPanels.window.windowOptions.data.updateCandidacyFront,
  };
  
  /**
   * Remove notifications
   */
  useEffect(() => {
    (async function() {
      try {
        const numberOfNotifications = appContextV2.appNotifications.recruiter?.notifications?.filter((item: any) => (["newCandidacy", "newTrigram", "newEvaluation", "newMessage"].includes(item.actionType) &&
            item.seenByMe === false && item.onCandidateId===inherited.candidateId)).length;
        if(numberOfNotifications !== 0) {
          appContextV2.dispatchNotifications({type: 'UPDATE_SEEN_FOR_CANDIDATE', value: inherited.candidateId});
          await axios.put(`${process.env.REACT_APP_API_URL}/notifications/seen/message/trigram/evaluation`, {
              candidateId:inherited.candidateId,
            }, {withCredentials: true});
          }
      }
      catch(error) {
        devLog(error);
      }
    })()
  }, []);
  
  useEffect(() => {
    if(inherited.category === "pool") setPage("cv");
    if(inherited.category === "mission") setPage("results");
  }, []);
  
  useEffect(() => {
    (async() => {
      const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/data/get/dictionaries/full`, {
        timeout: 20_000,
        withCredentials: true
      });
      if(fetched.data.status === "ok") {
        setDictionaries(fetched.data.data);
      }
    })();
  }, []);
  
  useEffect(() => {
    (async() => {
      try {
        setWindowStatus("fetching");
        const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/pool/cm/candidate/v2`, {
          timeout: 20_000,
          headers: {
            candidateid: inherited.candidateId,
            missionid: inherited.missionId,
            category: inherited.category,
          },
          withCredentials: true
        });
        if(fetched.data.status !== "ok") {
          setWindowStatus("error");
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        setCandidate(fetched.data.data);
        setWindowStatus("fetched");
      } catch(error) {
        devLog(error);
        setWindowStatus("fetched");
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, []);
  
  const updateCandidateFrontInWindow = (keyValuePairs: any) => {
    setCandidate((ps: any) => ({
      ...ps,
      candidate: {
        ...ps.candidate,
        ...keyValuePairs
      }
    }));
    // Update front in pool and mission
    inherited.frontUpdatePool?.updateCandidatesBulk([inherited.candidateId], {...keyValuePairs});
  }

  const updateCandidateFrontTokenName = (name: any, sharingLinkId:string) => {
    setCandidate((ps: any) => ({
      ...ps,
      candidate: {
        ...ps.candidate,
        sharingLinks:
            ps.candidate.sharingLinks.map((item: any, key: number) => ({
              ...item,
              tokenName:item._id===sharingLinkId? name:item.tokenName,
            })
    )
      }}))}

  const deleteShareFrontInWindow = (linkID: any) => {
    setCandidate((ps: any) => ({
      ...ps,
      candidate: {
        ...ps.candidate,
        sharingLinks: [...ps.candidate.sharingLinks.filter((item: any) => (item._id !== linkID))]
        
      }
      
    }));
  }
  
  const addShareFrontInWindow = (sharingLink: any) => {
    setCandidate((ps: any) => ({
      ...ps,
      candidate: {
        ...ps.candidate,
        sharingLinks: [
          ...(ps.candidate?.sharingLinks ?? []),
          sharingLink,]
      }
      
    }));
  }
  
  const updateCandidacyFrontInWindow = (keyValuePairs: any) => {
    setCandidate((ps: any) => ({
      ...ps,
      candidacy: {
        ...ps.candidacy,
        ...keyValuePairs
      }
    }));
    // Update front in pool and mission
    inherited?.frontUpdateCandidacy(candidate.candidacy._id, {...keyValuePairs});
  }
  
  const toggleAvailability = () => {
    setCandidate((ps: any) => ({
      ...ps,
      candidate: {
        ...ps.candidate,
        isListeningToMarket: !ps.candidate.isListeningToMarket
      }
    }));
  }
  
  return (<WindowWrapper size={"bigger"} status={"ok"} windowTitle={""}>
    
    <section className={"window-candidate-v2"}>
      
      <article className={`window-candidate-v2__body`}>
        
        {["asleep", "fetching"].includes(windowStatus) && <CandidateWindowV2Loading/>}
        
        {["fetched", "error"].includes(windowStatus) && <>

          <CandidateWindowV2Navbar inherited={inherited} page={page} setPage={setPage}/>

          <Spaces n={2}/>

          <CandidateWindowV2Content page={page}
                                    dictionaries={dictionaries}
                                    candidate={candidate}
                                    inherited={inherited}
                                    updateCandidateFrontInWindow={updateCandidateFrontInWindow}
                                    updateCandidacyFrontInWindow={updateCandidacyFrontInWindow}
                                    setCandidate={setCandidate}
                                    deleteShareFrontInWindow={deleteShareFrontInWindow}
                                    addShareFrontInWindow={addShareFrontInWindow}
                                    updateCandidateFrontTokenName={updateCandidateFrontTokenName}/>
        </>}
      
      </article>
      <article className={"window-candidate-v2__nav"}>
        
        <CandidateWindowV2Profile candidate={candidate}
                                  inherited={inherited}
                                  toggleAvailability={toggleAvailability}
                                  updateCandidateFrontInWindow={updateCandidateFrontInWindow}
                                  windowStatus={windowStatus}
                                  setPage={setPage}/>
      
      </article>
    
    </section>
  
  </WindowWrapper>);
}
