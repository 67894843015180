import React from "react";
import {IAssistantContext} from "./dependencies/interfaces/IAssistantContext";
import RNMAssistantStart from "./AssistantSteps/RNMAssistantStart";
import RNMError from "./AssistantSteps/RNMError";
import RNMPositionWanted from "./AssistantSteps/RNMPositionWanted";
import RNMPositionWantedCustom from "./AssistantSteps/RNMPositionWantedCustom";
import RNMContract from "./AssistantSteps/RNMContract";
import RNMContractCustom from "./AssistantSteps/RNMContractCustom";
import RNMFullTime from "./AssistantSteps/RNMFullTime";
import RNMExperience from "./AssistantSteps/RNMExperience";
import RNMSkills from "./AssistantSteps/RNMSkills";
import RNMAsSoonAsPossible from "./AssistantSteps/RNMAsSoonAsPossible";
import RNMDateCustom from "./AssistantSteps/RNMDateCustom";
import RNMDuration from "./AssistantSteps/RNMDuration";
import RNMDurationCustom from "./AssistantSteps/RNMDurationCustom";
import RNMSalaryBy from "./AssistantSteps/RNMSalaryBy";
import RNMSalary from "./AssistantSteps/RNMSalary";
import RNMAdvantages from "./AssistantSteps/RNMAdvantages";
import RNMWithCv from "./AssistantSteps/RNMWithCv";
import RNMCvMandatory from "./AssistantSteps/RNMCvMandatory";
import RNMRemote from "./AssistantSteps/RNMRemote";
import RNMlastAssistantStep from "./AssistantSteps/RNMlastAssistantStep";
import RNMRoaming from "./AssistantSteps/RNMRoaming";
import RNMRoamingPlace from "./AssistantSteps/RNMRoamingPlace";
import RNMUseSavedLocation from "./AssistantSteps/RNMUseSavedLocation";
import RNMLocation from "./AssistantSteps/RNMLocation";
import RNMEditor from "./AssistantSteps/RNMEditor";
import RNMSuccess from "./AssistantSteps/RNMSuccess";
import RNMLinkedinMandatory from "./AssistantSteps/RNMLinkedinMandatory";

export {NewMissionRouter};

interface INewMissionRouter extends IAssistantContext {

}

const NewMissionRouter = (props: INewMissionRouter) => {
  
  switch(props.step) {
    
    case 'assistantStart': // Load data
      return(<RNMAssistantStart {...props} />);

    case 'positionWanted': // Can be jumped automatically
      return(<RNMPositionWanted {...props} />);

    case 'positionWantedCustom':
      return(<RNMPositionWantedCustom {...props} />);

    case 'contract': // Load data (skills)
      return(<RNMContract {...props} />);

    case 'contractCustom':
      return(<RNMContractCustom {...props} />);
  
    case 'fullTime': // Can be jumped automatically
      return(<RNMFullTime {...props} />);

    case 'experience':
      return(<RNMExperience {...props} />);

    case 'skills':
      return(<RNMSkills{...props} />);

    case 'asSoonAsPossible':
      return(<RNMAsSoonAsPossible {...props} />);

    case 'dateCustom':
      return(<RNMDateCustom {...props} />);
  
    case 'duration': // Can be jumped automatically
      return(<RNMDuration {...props} />);
  
    case 'durationCustom':
    return(<RNMDurationCustom {...props} />);
  
    case 'renewal':
      // return(<RNMDuration {...props} />);

    case 'salaryBy':
      return(<RNMSalaryBy {...props} />);
  
    case 'salary':
      return(<RNMSalary {...props} />);

    case 'salaryIn':
    //   return(<RNMSalaryIn {...props} />);
  
    case 'salaryVar':
    //   return(<RNMSalaryVar {...props} />);

    case 'advantages':
      return(<RNMAdvantages{...props} />);

    case 'withCv':
    //  return(<RNMWithCv {...props} />);

    case 'cVMandatory':
     // return(<RNMCvMandatory {...props} />);

    case 'linkedinMandatory':
      return(<RNMLinkedinMandatory {...props} />);

    case 'remote':
      return(<RNMRemote {...props} />);

    case 'roaming':
      return(<RNMRoaming{...props} />);

    case 'roamingPlace':
      return(<RNMRoamingPlace {...props} />);

    case 'useSavedLocation': // Can be jumped automatically
      return(<RNMUseSavedLocation {...props} />);

    case 'location':
      return(<RNMLocation {...props} />);

    case 'lastAssistantStep':
      return(<RNMlastAssistantStep {...props} />);

    case 'editor':
      return(<RNMEditor {...props} />);

    case 'success':
      return(<RNMSuccess {...props} />);

    case 'error':
    default:
      return(<RNMError {...props}/>);
  }
  
}