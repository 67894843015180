import React, {useContext} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import WindowWrapper from "../../../layouts/WindowLayout";
import RecruiterSharedMissionLink from "../../../../recruitersPages/recruiterSharedMissionLink/RecruiterSharedMissionLink";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return(<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
  
    <div className={"container"}>
      <RecruiterSharedMissionLink missionTokens={{
        rank: appContextV2.appPanels.window.windowOptions.data.rank,
        mailHandler: appContextV2.appPanels.window.windowOptions.data.mailHandler,
      }}/>
    </div>
    
  </WindowWrapper>);
}
