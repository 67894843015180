import AppContext, {IAppContext, IAppV2Context} from "../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import SwitchLanguage from "./SwitchLanguage";
import axios from "axios";

export default function Footer(props: any) {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  const isPageNewMission: boolean = appContextV2.appNavigation.page === 'RecruiterNewMission';

  return (
    <>
      {
        !isPageNewMission && <>
          <div className={"footer"}>
            <section className={"footer__sub"}>
              <div className={"container"}>
                <section className={"footer__sub__list"}>
                  <ul>
                    <li className={"footer__sub__title"}>
                      Aide & support
                    </li>
                    <li>
                      <Link to={"/contact"}>
                        <button disabled={false} className={`btn--hollow`}>
                          {language === "FR" && <>Nous contacter</>}
                          {language === "EN" && <>Contact us</>}
                        </button>
                      </Link>
                    </li>
                    <li className={"footer__sub__title"}>
                      {/*<div className={"text--grey"}>Assistant</div>*/}
                      <button disabled={false} className={`btn--hollow`} onClick={(e) => {
                        appContextV2.dispatchSession({
                          type: "OPEN_TUTORIAL",
                          value: null
                        });
                      }}
                      > Afficher l'assistant
                      </button>
                    </li>
                  </ul>
                  <ul>
                    <li className={"footer__sub__title"}>
                      Confidentialité & RGPD
                    </li>
                    <li>
                      <Link to={"/policy"}>
                        <button disabled={false} className={`btn--hollow`}>
                          {language === "FR" && <>Conditions Générales d'Utilisation</>}
                          {language === "EN" && <>Terms of Use</>}
                        </button>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/policy"}>
                        <button disabled={false} className={`btn--hollow`}>
                          {language === "FR" && <>Politique de confidentialité & Cookies</>}
                          {language === "EN" && <>Privacy policy & Cookies</>}
                        </button>
                      </Link>
                    </li>
                  </ul>
                  {/*<ul>*/}
                  {/*  <li className={"footer__sub__title"}>*/}
                  {/*    Communication*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <Link to={"/aboutus"}>*/}
                  {/*      <button disabled={true} className={`btn--hollow text--grey`}>*/}
                  {/*        {language === "FR" && <>Qui sommes nous ?</>}*/}
                  {/*        {language === "EN" && <>Who are we ?</>}*/}
                  {/*      </button>*/}
                  {/*    </Link>*/}
                  {/*  </li>*/}
                  {/*  <li className={""}>*/}
                  {/*    <button disabled={true} className={`btn--hollow text--grey`}>*/}
                  {/*      /!*<a className={""} href={"https://blog.myshortlist.co"} target={"_blank"}>*!/*/}
                  {/*      {language === "FR" && <>Le blog</>}*/}
                  {/*      {language === "EN" && <>The blog</>}*/}
                  {/*      /!*</a>*!/*/}
                  {/*    </button>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </section>
                {
                  appContextV2.appNavigation.page !== "RecruiterResearch" && <SwitchLanguage/>
                }
              </div>
              {/*<hr/>*/}
              <p><br/><br/><br/></p>
              <p className={"text--center"}>
                @2020 Myshortlist.co
              </p>
            </section>
          </div>
        </>
      }
    </>
  );
}

