import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import AssistantLayout from "../../../../layouts/AssistantLayout";
import {isEmailValid} from "../../../../../../utils/typeGuards";
import Spaces from "../../../Spaces";
import {ButtonBack} from "../../../Buttons/ButtonBack";

/**
 * In the future: needs a wizard to choose between linkedin or email
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  const isEmailValid = props.windowContext.formHandler.isEmail(props.windowContext.form.email);
  
  const assistantTitle2: string = ((language: string) => {
      switch(language) {
        case 'FR':
          return "Entre ton email";
        case 'EN':
        default:
          return "Type your email";
      }
    })(language);
  
    const assistantComment2: any = (<div>
      <p className={"text--small text--grey text--center"}>
        Myshortlist n'utilise pas de mot de passe. Un lien smart-login sera envoyé à ton adresse.
      </p>
      <Spaces n={0}/>
      <div className={"text--center"}>
        <button className={"btn-main"} disabled={!isEmailValid} onClick={async() => {
          props.windowContext.wizard.nextStep();
        }}>
          {language === 'FR' ? "Valider" : ""}
          {language === 'EN' ? "Validate" : ""}
        </button>
      </div>
    </div>);
  
  return(<>
    
    {/*<ButtonBack back={props.windowContext.wizard.previousStep} />*/}
    
    <AssistantLayout title={assistantTitle2} comment={assistantComment2} animated={true}>
    
      <form className={""} onSubmit={(e) => {
        e.preventDefault();
        if(isEmailValid) {
          props.windowContext.wizard.nextStep();
        }
      }}>
        <input className={`input--v2 ${isEmailValid ? "input--ok":""} ${(!isEmailValid && !!props.windowContext.form.email) ? "input--wrong":""}`}
               type={"email"} name={"email"} value={props.windowContext.form.email}
               onChange={props.windowContext.updateForm({})}/>
      </form>
  
    </AssistantLayout>
    
  </>);
}
