import React from "react";
import Spaces from "../../../components/Spaces";

export default (props: any) => {
  
  return (<>
    <section className={"candidate-evaluate__container"}>
      <div>
        <div className={"space-small-text-margin"}>
          Identité:
          <span
            className={"text--new-blue"}> {props?.candidateToEvaluate?.candidate?.firstName} {props?.candidateToEvaluate?.candidate?.lastName}</span>
        </div>
        
        {/*<div>*/}
        {/*  Email:*/}
        {/*  <span*/}
        {/*    className={"text--new-blue"}> {props?.candidateToEvaluate?.candidate?.email}</span>*/}
        {/*</div>*/}
        {/*<Spaces n={0}/>*/}
        
        {/*<div>*/}
        {/*  Téléphone:*/}
        {/*  <span*/}
        {/*    className={"text--new-blue"}> {props?.candidateToEvaluate?.candidate?.phone}</span>*/}
        {/*</div>*/}
        {/*<Spaces n={0}/>*/}
        
        <div className={"space-small-text-margin"}>
          Fonctions occupées:
          {
            props?.candidateToEvaluate?.candidate?.positionsWanted?.length !== 0 && <div>
              {props?.candidateToEvaluate?.candidate?.positionsWanted?.map((item: any, key: number) => (<>{item && <div key={key}>
                - <span className={"text--new-blue"}>{item}</span>
              </div>}</>))}
            </div>
          }
          {
            props?.candidateToEvaluate?.candidate?.positionsWanted?.length === 0 && <span className={"text--new-blue"}>
                    - Aucune fonction
                </span>
          }
        </div>
        
        <div className={"space-small-text-margin"}>
          Secteurs d'activité:
          {
            props?.candidateToEvaluate?.candidate?.sectors?.length !== 0 && <div>
              {props?.candidateToEvaluate?.candidate?.sectors?.map((item: any, key: number) => (<>{item && <div key={key}>
                - <span className={"text--new-blue"}>{item}</span>
              </div>}</>))}
            </div>
          }
          {
            props?.candidateToEvaluate?.candidate?.sectors?.length === 0 && <span className={"text--new-blue"}>
                    - Aucun secteur
                </span>
          }
        </div>
        
        <div className={"space-small-text-margin"}>
          Mobilité:
          {
            props?.candidateToEvaluate?.candidate?.mobility?.length !== 0 && <div>
              {props?.candidateToEvaluate?.candidate?.mobility?.map((item: any, key: number) => (<>{item && <div key={key}>
                - <span className={"text--new-blue"}>{item}</span>
              </div>}</>))}
            </div>
          }
          {
            props?.candidateToEvaluate?.candidate?.mobility?.length === 0 && <span className={"text--new-blue"}>
                    - Aucune mobilite
                </span>
          }
        </div>
        
        <div className={"space-small-text-margin"}>
          Langages:
          {
            props?.candidateToEvaluate?.candidate?.languages?.length !== 0 && <div>
              {props?.candidateToEvaluate?.candidate?.languages?.map((item: any, key: number) => (<div key={key}>
                - <span className={"text--new-blue"}>{item.value}</span>
              </div>))}
            </div>
          }
          {
            props?.candidateToEvaluate?.candidate?.languages?.length === 0 && <span className={"text--new-blue"}>
                    - Aucun langage
                </span>
          }
        </div>
        
        <div className={"space-small-text-margin"}>
          Logiciels:
          {
            props?.candidateToEvaluate?.candidate?.softwares?.length !== 0 && <div>
              {props?.candidateToEvaluate?.candidate?.softwares?.map((item: any, key: number) => (<div key={key}>
                - <span className={"text--new-blue"}>{item.value}</span>
              </div>))}
            </div>
          }
          {
            props?.candidateToEvaluate?.candidate?.softwares?.length === 0 && <span className={"text--new-blue"}>
                    - Aucun logiciel
                </span>
          }
        </div>
        
        <div className={"space-small-text-margin"}>
          Compétences:
          {
            props?.candidateToEvaluate?.candidate?.skills?.length !== 0 && <div>
              {props?.candidateToEvaluate?.candidate?.skills?.map((item: any, key: number) => (<>{item && <div key={key}>
                - <span className={"text--new-blue"}>{item}</span>
              </div>}</>))}
            </div>
          }
          {
            props?.candidateToEvaluate?.candidate?.skills?.length === 0 && <span className={"text--new-blue"}>
                    - Aucune competence
                </span>
          }
        </div>
        <Spaces n={0}/>
      
      </div>
    </section>
  </>);
  
}