import React, {useContext, useState} from "react";
import Spaces from "../../../Spaces";
import {notification} from "../../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import CloseButton from "../../../Buttons/CloseButton";
import {ButtonBigPlusV2} from "../../../Buttons/ButtonBigPlus";

export default (props: {
  fieldToUpdate: string,
  modifier: Function,
  mission: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [value, setValue]: [any, Function] = useState(props.mission?.advantages);
  
  const [valueToAdd, setValueToAdd] = useState("");
  
  return (<>
    
    <div className={"white-container white-container--grey"}>
      {value.map((item: any, key: number) => (<div className={"space-flex--space-between"}>
        <div>- {item}</div>
        <CloseButton eventOnClick={() => setValue((ps: string[]) => ps.filter((item2: string) => item !== item2))}/>
      </div>))}
    </div>
    
    <Spaces n={0}/>
    
    <div className={"text--center"}>
      <input type={"text"} value={valueToAdd} className={"input--v2"} onChange={(e) => {
        if(e.target.value.length < 200) {
          setValueToAdd(e.target.value);
        }
      }} />
    </div>
    
    <div className={"space-flex--center"}>
      <ButtonBigPlusV2 cb={() => {
        setValue((ps: string[]) => [...ps, valueToAdd]);
        setValueToAdd("");
      }} disabled={!valueToAdd} title={'Ajoute un avantage'} />
    </div>
    
    <Spaces n={0}/>
    
    <div className={"text--center"}>
      <button className={"btn-secondary"} onClick={() => {
        props.modifier("advantages", value);
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        Mettre à jour
      </button>
    </div>
    
  </>);
  
}