import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import {Link} from "react-router-dom";


export default function BackToLandingPage(props: any) {
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  return (
    <Link to={"/"}>
      <li className={"hide-on-smartphones subnavbar-new__item"}>
        <button disabled={appContextV2.appNavigation.page === "RecruiterNewMission"}
                className={`btn--hollow text--hollow--hover ${appContextV2.appNavigation.page === "RecruiterResearch" ? "selected" : ""}`}>
            <span>
              <img className={"navbar-secondary-btn-image--smaller"} src={"https://static.myshortlist.co/btn-back.svg"}
                   alt={"research"}/>
            </span>
        </button>
      </li>
    </Link>
  );
}