import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import Spaces from "../../sharedComponents/components/Spaces";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<div className={"landing-page--v2-bg--shape1"}>
    
    <div className={"landing-page--v2__splash"}>
      
      <Spaces n={0}/>
      
      <div className={"landing-page--v2__splash__side-by-side"}>
        
        <div className={"landing-page--v2__splash__side-by-side__box landing-page--v2__splash__side-by-side__box1"}>
          
          <h1 className={"landing-page--v2__splash__title"}>
            Construisez et animez<br/>
            simplement votre<br/>
            communauté de candidats<br/>
          </h1>
          
          <div className={"landing-page--v2__splash__subtitle"}>
            Myshortlist.co permet à votre équipe de recruter mieux et plus vite en vous focalisant sur l'essentiel tout en améliorant l'expérience recrutement
            pour vos candidats.
          </div>
          
          <div className={"landing-page--v2__splash__button-space"}>
            <button className={"landing-page--v2__splash__button"} onClick={(e) => {
              e.preventDefault();
              appContextV2.dispatchPanels({
                type: "OPEN_WINDOW", value: {
                  type: "subscribe",
                  data: {},
                }
              });
            }}>
              Commencer <img src={"https://static.myshortlist.co/btn-arrow-list.svg"}/>
            </button>
          </div>
        
        </div>
        <div className={"landing-page--v2__splash__side-by-side__box landing-page--v2__splash__side-by-side__box2"}>
          
          <div className={"landing-page--v2__video-container"}>
            <video width="100%" height="100%" playsInline={true} controls={false} autoPlay={true} loop={true} muted={true}>
              <source src="https://static.myshortlist.co/videos/v1_communities.webm" type="video/webm"/>
              <source src="https://static.myshortlist.co/videos/v1_communities.mp4" type="video/mp4"/>
              <source src="https://static.myshortlist.co/videos/v1_communities.ogg" type="video/ogg"/>
              Your browser does not support the video tag.
            </video>
          </div>
        
        </div>
      
      </div>
    
    </div>
    
    <Spaces n={4}/>
    
    <h1 className={"landing-page--v2__title2"}>
      Rejoignez les entreprises qui utilisent déjà myshortlist
    </h1>
    
    <Spaces n={0}/>
    
    <section className={"landing-page--v2__adopters"}>
  
      <article className={"landing-page--v2__adopters__adopter"}>
        <img src={"https://static.myshortlist.co/adopters/mazars.png"}/>
      </article>
      
      <article className={"landing-page--v2__adopters__adopter"}>
        <img src={"https://static.myshortlist.co/adopters/veolia.png"}/>
      </article>
  
      <article className={"landing-page--v2__adopters__adopter"}>
        <img src={"https://static.myshortlist.co/adopters/sncf.png"}/>
      </article>
  
      <article className={"landing-page--v2__adopters__adopter"}>
        <img src={"https://static.myshortlist.co/adopters/lmdw.png"}/>
      </article>
      
      <article className={"landing-page--v2__adopters__adopter"}>
        <img src={"https://static.myshortlist.co/adopters/medadom.png"}/>
      </article>
  
      <article className={"landing-page--v2__adopters__adopter"}>
        <img src={"https://static.myshortlist.co/adopters/aramis.png"}/>
      </article>
      
      <article className={"landing-page--v2__adopters__adopter"}>
        <img src={"https://static.myshortlist.co/adopters/auzy.png"}/>
      </article>
      
      <article className={"landing-page--v2__adopters__adopter"}>
        <img src={"https://static.myshortlist.co/adopters/xanlite.png"}/>
      </article>
    
    </section>
  
  
  </div>);
}