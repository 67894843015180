import React from "react";
import Spaces from "../../../components/Spaces";

export {CASSuccess};

function CASSuccess(props: any) {
  
  return(<>
    <Spaces n={2}/>
    <div className={"text--center"}>
      L'action a été enregistrée avec succès. Vous pouvez fermer cette fenêtre.
    </div>
    <Spaces n={2}/>
  </>);
  
}