import React from "react";
import LinkedInIcon from "../../components/Buttons/LinkedinIcon";
import {capitalizeSentence} from "../../../../utils/converters";
import Spaces from "../../components/Spaces";

export default (props: {
  candidate: any;
  candidateIndex: number;
  positionWanted: string;
}) => {
  
  return (<>
    <section className={"candidate-evaluate__container header"}>
      
      <div className={"header--up"}>
        
        <div className={"space-flex--center"}>
          <div className={"header__avatar-container"}>
            <img className={"header__avatar"} src={props.candidate?.candidate?.avatarUrl}
                 alt={"avatar"}/>
          </div>
        </div>
        
        <div className={"header__name text--center"}>
          {props.candidateIndex + 1}. {props.candidate?.candidate?.firstName?.toUpperCase()} {props.candidate?.candidate?.lastName?.toUpperCase()}
        </div>
        
        <div className={"text--center"}>
          Pour le poste de: <span
          className={"text--new-blue"}> {`${capitalizeSentence(props.positionWanted)} F/H`}</span> |
          {props.candidate?.candidate?.currentSalary &&
          <span> Salaire brut annuel actuel:
            <span className={"text--new-blue"}> {` ${props.candidate?.candidate?.currentSalary} `}  </span> |
          </span>}
          {props.candidate?.candidate?.salaryMin &&
          <span> Prétentions salariales brutes annuelles:
            <span className={"text--new-blue"}> {`${props.candidate?.candidate?.salaryMin}`}</span> |
          </span>}
          {props.candidate?.candidate?.yearsOfExperience &&
          <span> Expérience à ce poste:
            <span className={"text--new-blue"}> {`${props.candidate?.candidate?.yearsOfExperience}`} an(s)</span>
          </span>}
        </div>
        
        <Spaces n={0}/>
        
        <div className={"space-flex--center"}>
          {
            props.candidate?.candidate?.linkedin &&
            <a href={props.candidate?.candidate?.linkedin}
               target={"_blank"}>
              <button className={"btn--hollow header__linkedin"}>
                <div className={"button--linkedin"}><img
                  src={"https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/btn-linkedin.svg"}/></div>
              </button>
            </a>
          }
          {
            !props.candidate?.candidate?.linkedin &&
            <button className={"btn--hollow hollow header__linkedin"} disabled={true}>
              <div className={"button--linkedin"}><img
                src={"https://s3.eu-west-3.amazonaws.com/static.myshortlist.co/btn-linkedin.svg"}/></div>
            </button>
          }
        </div>
      
      </div>
    
    </section>
  </>);
}