import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext, useState} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {WCONameV2} from "./componentsWCO2/WCONameV2";
import {WCONotifyV2} from "./componentsWCO2/WCONotifyV2";
import {WCOEmailV2} from "./componentsWCO2/WCOEmailV2";
import {WCOSmsV2} from "./componentsWCO2/WCOSmsV2";
import {WCODeleteV2} from "./componentsWCO2/WCODeleteV2";
import {WCOCommunityV2} from "./componentsWCO2/WCOCommunityV2";
import updateColumnFrontFromWindow from "./componentsWCO2/dependenciesWCO2/updateColumnFrontFromWindow";
import deleteColumnFrontFromWindow from "./componentsWCO2/dependenciesWCO2/deleteColumnFrontFromWindow";
import switchActionFromColumn from "./componentsWCO2/dependenciesWCO2/switchActionFromColumn";
import saveColumnData from "./componentsWCO2/dependenciesWCO2/saveColumnData";
import updateColumn from "./componentsWCO2/dependenciesWCO2/updateColumn";
import axios from "axios";
import {notification} from "../../../../../utils/notification";

export interface IColumnData {
  _id?: string;
  name?: string;
  description?: string;
  activateMail?: boolean;
  mailSubject?: string;
  mailDelay?: number;
  mailContent?: string;
  activatePool?: boolean;
  poolId?: string;
  activateSms?: boolean;
  smsText?: string;
  activateAskCv?: boolean;
  askCvText?: string;
  activateNotify?: boolean;
  notifyEmails?: any;
}

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  /**
   * Cet objet contient tout ce qu'on a passé à la fenêtre, on pourrait faire ça pour toutes les fenêtres ? Tu me dira ce que tu en pense
   */
  const inherited = {
    type: appContextV2.appPanels.window.windowOptions.data.type as "kanban" | "preset",
    missionId: appContextV2.appNavigation.missionId ?? "",
    columnData: appContextV2.appPanels.window.windowOptions.data.columnData,
    updateColumnFront: appContextV2.appPanels.window.windowOptions.data.updateColumnFront,
    deleteColumnFront: appContextV2.appPanels.window.windowOptions.data.deleteColumnFront,
    numberOfCandidates: appContextV2.appPanels.window.windowOptions.data.numberOfCandidates ?? 0,
    setIsLoading: appContextV2.appPanels.window.windowOptions.data.setIsLoading ?? (() => {
    }),
  }
  
  /**
   * On initialise columnData, qui contient nos parametres de la fenêtre
   */
  const [columnData, setColumnData] = useState<IColumnData>(inherited.columnData);
  const [isWindowLoading, setIsWindowLoading] = useState(false);
  
  /**
   * Update en temps réel columnData dans la fenêtre quand l'utilisateur entre des datas
   * @param keyValuePairs
   */
  const updateColumnData = (keyValuePairs: any) => {
    try {
      setColumnData((ps: IColumnData) => ({
        ...ps,
        ...keyValuePairs
      }));
    } catch(error) {
      console.log(error);
    }
  }
  
  /**
   * Sauvegarde les données en back et en front
   * @param keyValuePairs
   */
  
  const saveColumnData = async(keyValuePairs: any) => {
    try {
      
      setIsWindowLoading(true);
      notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
      
      if(inherited.type === "preset") {
        
        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/ent/b/business/save/default/kanban`, {
          columnId: columnData._id,
          keyValuePairs: keyValuePairs, // todo hadassa: checker dans le back
        }, {withCredentials: true});
        
        if(inherited.type === "preset") {
          
          const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/ent/b/business/save/default/kanban`, {
            columnId: columnData._id,
            keyValuePairs: keyValuePairs,
          }, {withCredentials: true});
          
          if(fetched.data.status !== "ok") {
            setIsWindowLoading(false);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          }
          
          // Update parameters
          inherited.updateColumnFront({
            type: "UPDATE_COLUMN", value: {
              columnId: columnData._id,
              keyValuePairs: keyValuePairs,
            }
          });
          
        }
        
        // Update parameters
        inherited.updateColumnFront({
          type: "UPDATE_COLUMN", value: {
            columnId: columnData._id,
            keyValuePairs: keyValuePairs,
          }
        });
        
      }
      
      if(inherited.type === "kanban") {
        
        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/mat/com/update/column`, {
          missionId: inherited.missionId,
          columnId: columnData._id,
          keyValuePairs: keyValuePairs, // todo hadassa: checker dans le back
        }, {withCredentials: true});
        
        if(fetched.data.status !== "ok") {
          setIsWindowLoading(false);
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
        // Update kanban
        inherited.updateColumnFront(columnData._id, keyValuePairs);
      }
      
      setIsWindowLoading(false);
      return notification(appContextV2.dispatchPanels, "Mise à jour effectuée", "success");
      
    } catch(error) {
      
      setIsWindowLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      throw (error);
    }
  }
  
  /**
   * Fonction appelée quand l'utilisateur "switch" une action, elle met à jour en front et elle sauvegarde (la fonction de sauvegarde s'occupe elle même de mettre à jour le front
   * @param action
   */
  
  const switchColumnAction = (action: string) => {
    try {
      
      const newActions = {
        activateMail: action === "activateMail",
        activateSms: action === "activateSms",
        activatePool: action === "activatePool",
        activateAskCv: action === "activateAskCv",
        activateNotify: action === "activateNotify"
      };
      
      updateColumnData(newActions);
      
      saveColumnData(newActions);
      
    } catch(error) {
      throw (error);
    }
  }
  
  const deleteColumn = async() => {
    try {
      notification(appContextV2.dispatchPanels, "Désindexation de la colonne", "information");
      
      if(inherited.type === "preset") {
        inherited.deleteColumnFront({
          type: "DELETE_COLUMN", value: columnData._id,
          
        });
        setIsWindowLoading(true);
        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/ent/b/business/delete/column/default/kanban`, {
          columnId: columnData._id,
        }, {withCredentials: true});
        
        if(fetched.data.status !== "ok") {
          setIsWindowLoading(false);
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
      }
      if(inherited.type === "kanban") {
        inherited.deleteColumnFront(columnData._id);
        setIsWindowLoading(true);
        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/mat/com/delete/column`, {
          missionId: inherited.missionId,
          columnId: inherited.columnData._id,
        }, {withCredentials: true});
        
        if(fetched.data.status !== "ok") {
          setIsWindowLoading(false);
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
      }
      setIsWindowLoading(false);
      notification(appContextV2.dispatchPanels, "Colonne supprimée avec succès", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
    } catch(error) {
      setIsWindowLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      throw (error);
    }
  }
  
  const windowContext = {
    type: inherited.type,
    missionId: inherited.missionId,
    numberOfCandidates: inherited.numberOfCandidates,
    columnData,
    updateColumnData,
    saveColumnData,
    switchColumnAction,
    isWindowLoading,
    deleteColumn
  }
  
  /**
   * Ici j'ai commenté le code pour que tu puisse te concentrer juste sur une option à la fois, on va commencer par le nom de la fenêtre
   */
  return (<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
    
    <div className={"container"}>
      
      {/*--------------------------------------------- Name ---------------------------------------------*/}
      <WCONameV2 {...windowContext}/>
      
      <Spaces n={1}/>
      
      <h1 className={"text--center"}>
        Choisissez une action de colonne
      </h1>
      
      <div className={"text--minified text--center text--grey"}>
        Une "action de colonne" permet d'effectuer une action lorsqu'un candidat est déplacé dans cette colonne.
      </div>
      
      {/*<Spaces n={0}/>*/}
      
      {/*/!*<WCOAskCv {}>*!/*/}
      
      <Spaces n={0}/>
      
      <WCONotifyV2 {...windowContext}/>
      
      <Spaces n={0}/>
      
      <WCOEmailV2 {...windowContext}/>
      
      <Spaces n={0}/>
      
      <WCOSmsV2 {...windowContext}/>
      
      <Spaces n={0}/>
      
      <WCOCommunityV2 {...windowContext}/>
      
      <Spaces n={0}/>
      
      <WCODeleteV2 {...windowContext}/>
    
    </div>
  
  </WindowWrapper>);
}
