import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {Link} from "react-router-dom";
import RecruiterMissionLink from "../../recruiterSharedMissionLink/RecruiterSharedMissionLink";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const getStatusText = (status: 'collaboration' | 'draft' | 'published') => {
    switch(status) {
      case 'collaboration':
        return "en collaboration";
      case 'draft':
        return "en brouillon";
      case 'published':
        return "publiée";
      default:
        return "";
    }
  }
  
  return (<section>
    
    <div className={"container"}>
      
      <div className={"text--center"}>
        Temps de création: <span className={"text--bold text--magnified11x"}>{Math.floor(props.timeStamps.endTimeEditorInSeconds / 60) + 1} minute(s)</span>
      </div>
      
      <Spaces n={1}/>
      
      <article className={"white-container white-container--grey"}>
        
        <div>
          <span className={"text--bold text--magnified11x"}>Bravo !</span>
          {/*La recherche a été correctement enregistrée. Son statut est <span*/}
          {/*className={"text--bold text--magnified11x"}>{getStatusText(props.missionTokens.status)}</span>.*/}
        </div>
        
        <Spaces n={0}/>
        
        <div>
          {/*<span className={"text--bold text--magnified11x"}>Et maintenant ? </span>*/}
          {props.missionTokens.status === "published" && "Ta recherche est publiée. Partage le lien sur différentes plateformes pour commencer à recevoir des candidatures."}
          {props.missionTokens.status === "collaboration" && "Ta recherche est en collaboration. Tes collègues peuvent également construire le formulaire et ajouter des questions qui leur sont pertinentes. Publiez-la quand vous serez prêts."}
          {props.missionTokens.status === "draft" && "Ta recherche est en brouillon. Seul toi peut la voir et la modifier. Passe-la en 'collaboration' pour que tes collègues participent au questionnaire et publie-la quand tu sera prêt."}
        </div>
        
        <Spaces n={0}/>
        
        <div className={"text--center"}>
          <Link to={"/business/jobs"}>
            <button className={"btn-secondary"}>
              Retour à mes postes
            </button>
          </Link>
        </div>
      
      </article>
      
      <Spaces n={0}/>
      
      <RecruiterMissionLink missionTokens={props.missionTokens} />
      
      <Spaces n={1}/>
    
    </div>
  
  </section>);
}