import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext, useState} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {abstractPostFetch, abstractPutFetch} from "../../../../../utils/abstractFetch";
import ButtonWrapper from "../../../layouts/ButtonWrapper";
import {capitalizeMulti} from "../../../../../utils/converters";
import {useForm} from "use-formidable";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {WKAAMail} from "./componentsWKAA/WKAAMail";
import {WKAASms} from "./componentsWKAA/WKAASms";
import {WKAAButtons} from "./componentsWKAA/WKAAButtons";
import {WKAAAskCv} from "./componentsWKAA/WKAAAskCv";
import {WKAANotify} from "./componentsWKAA/WKAANotify";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const candidateData = appContextV2.appPanels.window.windowOptions.data.candidateData;
  
  const initialColumnData = {...appContextV2.appPanels.window.windowOptions.data.columnData};
  const [columnData, updateColumnData, formidableData] = useForm(initialColumnData);
  
  const [isPageLoading, setIsPageLoading] = useState(false);
  
  const isEmailActionSet = initialColumnData.activateMail && initialColumnData.mailSubject && initialColumnData.mailContent;
  const isSmsActionSet = initialColumnData.activateSms && initialColumnData.smsText;
  const isAskCvActionSet = initialColumnData.activateAskCv;
  const isNotifySet = initialColumnData.activateNotify;
  
  const moveCandidacyFront = appContextV2.appPanels.window.windowOptions.data.moveCandidacyFront;
  const toColumnId = appContextV2.appPanels.window.windowOptions.data.toColumnId;
  const fromColumnId = appContextV2.appPanels.window.windowOptions.data.fromColumnId;
  const setIsLoading = appContextV2.appPanels.window.windowOptions.data.setIsLoading;
  const wasCandidateAlreadyInPool=appContextV2.appPanels.window.windowOptions.data.wasCandidateAlreadyInPool;
  
  return (<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
    <div className={"container"}>
      
      <div className={"space-flex--left"}>Action de cette colonne:
        {isEmailActionSet ? <div className={"action-label"}>Email</div> : ""}
        {isSmsActionSet ? <div className={"action-label"}>Sms</div> : ""}
        {isSmsActionSet ? <div className={"action-label"}>Conserver</div> : ""}
        {isAskCvActionSet ? <div className={"action-label"}>Demande de CV</div> : ""}
        {isNotifySet ? <div className={"action-label"}>Demander une évaluation</div> : ""}

      </div>
      
      <Spaces n={0}/>
      
      <WKAAButtons columnData={columnData}
                   setIsLoading={setIsLoading}
                   candidateData={candidateData}
                   isPageLoading={isPageLoading}
                   moveCandidacyFront={moveCandidacyFront}
                   setIsPageLoading={setIsPageLoading}
                   toColumnId={toColumnId}
                   fromColumnId={fromColumnId}/>
      
      <Spaces n={0}/>

      {wasCandidateAlreadyInPool && <div className={"text--red text--center"}>
        Attention, le candidat a déja été dans cette colonne!
      </div>}
      <Spaces n={0}/>
      
      {
        isEmailActionSet && <>
          <WKAAMail columnData={columnData}
                    updateColumnData={updateColumnData}
                    white={props.white}/>
          <Spaces n={0}/></>
      }
      
      {
        isSmsActionSet && <>
          <WKAASms columnData={columnData}
                   updateColumnData={updateColumnData}
                   white={props.white}/>
          <Spaces n={0}/></>
      }

      {
        isNotifySet && <>
          <WKAANotify columnData={columnData}
                   updateColumnData={updateColumnData}
                   white={props.white}/>
          <Spaces n={0}/></>
      }
      
      <Spaces n={0}/>
      
      {/*{*/}
      {/*  isAskCvActionSet && <>*/}
      {/*    <WKAAAskCv columnData={columnData}*/}
      {/*               updateColumnData={updateColumnData}*/}
      {/*               white={props.white}/>*/}
      {/*    <Spaces n={0}/></>*/}
      {/*}*/}
      
      {/*<Spaces n={0}/>*/}
      
    </div>
  </WindowWrapper>);
}
