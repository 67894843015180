import React, {useEffect, useState} from "react";
import PageLayout from "../layouts/PageLayout";
import Spaces from "../components/Spaces";
import {useWizard} from "use-wizard";
import axios from "axios";
import {Link} from "react-router-dom";
import {devLog} from "../../../utils/devLog/devLog";
import BPAV3SuccessResult
  from "./PublicProfilesAndApply/componentsBusinessPage/componentsBusinessPageApplyV3/componentsBPAV3Success/BPAV3SuccessResult";

const qs = require("qs");

export {LinkedinApply};

const LinkedinApply = (props: any) => {
  
  const [step, wizard] = useWizard(["pending", "error", "ok", "tooManyApplies"]);
  
  const [data, setData]: [any, Function] = useState({});
  
  useEffect(() => {
    (async() => {
      try {
        
        const parsedUriParameters = (qs.parse((props.location.search || {}), {ignoreQueryPrefix: true})) || {};
        
        const answer = await axios.post(`${process.env.REACT_APP_API_URL}/mat/ca/candidacy/linkedin`, parsedUriParameters, {withCredentials: true});
        
        setData(answer.data.data);
        wizard.goToStep(answer.data.status);
        
      } catch(error) {
        
        devLog(error);
        wizard.goToStep("error");
      }
      
    })();
  }, []);
  
  const subscribe = async() => {
    // setSending(true);
    const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/ent/u/new/user/applying`, {
      email: props.answer.email,
      areTermsAccepted: true,
      isPolicyAccepted: true,
    }, {
      withCredentials: true,
      timeout: 70000,
    });
  }
  
  switch(step) {
    case 'pending':
      return (<PageLayout page={props.page} status={"ok"}>
        <Spaces n={10}/>
        <Spaces n={6}/>
      </PageLayout>);
    
    case 'ok':
      return (<PageLayout page={props.page} status={"ok"}>
        <Spaces n={5}/>
        <div className={"apply-V2__title text--new-blue"}>
          Candidature enregistrée avec succès !
        </div>
        
        <Spaces n={1}/>
        
        {!data.hideResult && <><BPAV3SuccessResult globalMark={data.globalMark}/><Spaces n={1}/></>}
        
        
        {
          props.business.hideButtonSubscribeToCandidates && <>
            Merci d'avoir posté votre candidature, vous pouvez maintenant fermer cette fenêtre.
          </>
        }
        
        {
          !props.business.hideButtonSubscribeToCandidates && <>
            <section className={"container text--center"}>

              <article className={"white-container white-container--grey"}>
                Vous pouvez maintenant fermer cette fenêtre.
                <Spaces n={0}/>
                {
                  data.businessName !== "Omega-intérim IDF" && !data.hideButtonSubscribeToCandidates && <>Pour recevoir
                    des
                    offres similaires, n'hésitez pas à vous inscrire sur la plateforme.</>
                }
              </article>

            </section>

            <Spaces n={1}/>

            <div className={"text--center"}>
              {
                data.businessName !== "Omega-intérim IDF" && !data.hideButtonSubscribeToCandidates &&
                <button className={"btn-secondary"} onClick={() => subscribe()}>
                  Je m'inscris pour recevoir d'autres offres
                </button>
              }
            </div>
          </>
        }
        
        <Spaces n={3}/>
      </PageLayout>);
    
    case 'tooManyApplies':
      return (<PageLayout page={props.page} status={"ok"}>
        <Spaces n={10}/>
        <div className={"text--center"}>
          Vous avez déjà postulé pour ce poste. Il n'est pas possible de postuler une seconde fois.
          <Spaces n={1}/>
          <Link to={"/"}>
            <button className={"btn-secondary"}>Retour à l'accueil</button>
          </Link>
        </div>
        <Spaces n={3}/>
      </PageLayout>);
    
    case 'error':
      return (<PageLayout page={props.page} status={"ok"}>
        <Spaces n={10}/>
        <div className={"text--center"}>
          Il semble qu'une erreur soit survenue
          <Spaces n={1}/>
          <Link to={"/"}>
            <button className={"btn-secondary"}>Retour à l'accueil</button>
          </Link>
        </div>
        <Spaces n={1}/>
      </PageLayout>);
    
    default:
      return (<></>);
  }
  
}
