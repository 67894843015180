import React, {useContext} from "react";
import {Link} from "react-router-dom";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";

export {BusinessPageNavBarV3}

const BusinessPageNavBarV3 = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return (<section className={"business-page--V2__navbar"}>
    
    {/*<div className={"container"}>*/}
    
    <ul className={"business-page--V2__navbar__ul"}>
  
      <li className={"business-page--V2__navbar__li hide-on-smartphones"}>
        <button className={"btn--hollow business-page--V2__navbar__li__btn"} disabled={true} title={"Bientôt disponible"}>
          <div className={"hide-on-smartphones"}>Stories</div>
          <div className={"hide-on-computers"}>
            <img className={`navbar-secondary-btn-image ${props.smaller ? "navbar-secondary-btn-image--smaller" : ""}`} src={"https://static.myshortlist.co/video.svg"} alt={""}/>
          </div>
        </button>
      </li>
      
      <li className={`business-page--V2__navbar__li`}>
        <Link to={`${props.pageInfos.uri}/profile`}>
          <button className={`btn--hollow business-page--V2__navbar__li__btn ${appContextV2.appNavigation.subPage === "BusinessPageProfile" ? "business-page--V2__navbar__li--selected" : ""}`}>
            <div className={"hide-on-smartphones"}>Page entreprise</div>
            <div className={"hide-on-computers"}>
              <img className={`navbar-secondary-btn-image ${props.smaller ? "navbar-secondary-btn-image--smaller" : ""}`} src={"https://static.myshortlist.co/btn-industry.svg"} alt={""}/>
            </div>
          </button>
        </Link>
      </li>
      
      <li
        className={`business-page--V2__navbar__li ${appContextV2.appNavigation.subPage === "BusinessPageJobs" ? "business-page--V2__navbar__li--selected" : ""}`}>
        <Link to={`${props.pageInfos.uri}/jobs`}>
          <button className={`btn--hollow business-page--V2__navbar__li__btn ${appContextV2.appNavigation.subPage === "BusinessPageJobs" ? "business-page--V2__navbar__li--selected" : ""}`}>
            <div className={"hide-on-smartphones"}>Postes ouverts</div>
            <div className={"hide-on-computers"}>
              <img className={`navbar-secondary-btn-image ${props.smaller ? "navbar-secondary-btn-image--smaller" : ""}`} src={"https://static.myshortlist.co/work.svg"} alt={""}/>
            </div>
          </button>
        </Link>
      </li>
    
    </ul>
    
    {/*</div>*/}
  
  </section>);
}
