import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../componentsApp/context/AppContext";
import PageLayoutWithHeader from "../sharedComponents/layouts/PageLayoutWithHeader";
import {Route, Switch} from "react-router-dom";
import FourHundredFour from "../sharedComponents/pages/errors/FourHundredFour";
import RecruiterProfilesUser from "./componentsRecruiterProfiles/RecruiterProfilesUser";
import RecruiterProfilesBusiness from "./componentsRecruiterProfiles/RecruiterProfilesBusiness";

/**
 * --------------------------------------
 * Displays personnal and business profiles
 * Primary-level page
 * --------------------------------------
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  //
  
  const title = ((subPage: any) => {
    switch(subPage) {
      case 'RecruiterProfilesUser':
        return "Profil utilisateur";
      case 'RecruiterProfilesBusiness':
        return "Profil entreprise";
      default:
        return "";
    }
  })(appContextV2.appNavigation.subPage);
  
  return (<PageLayoutWithHeader small={true} page={props.page} grey={true} fullWidth={true} title={title} status={"ok"}>
    
    <Switch>
      
      <Route exact path="/business/profiles" render={(componentProps) => <RecruiterProfilesUser {...componentProps} subPage={"RecruiterProfilesUser"}/>}/>
      
      <Route exact path="/business/profiles/user" render={(componentProps) => <RecruiterProfilesUser {...componentProps} subPage={"RecruiterProfilesUser"}/>}/>
      <Route exact path="/business/profiles/business"
             render={(componentProps) => <RecruiterProfilesBusiness {...componentProps} subPage={"RecruiterProfilesBusiness"}/>}/>
      
      {/* 404 ------------------------------------------------- */}
      <Route render={(componentProps) => <FourHundredFour {...componentProps} page="FourHundredFour" fullPage={false} setPage={false}/>}/>
    
    </Switch>
  
  </PageLayoutWithHeader>);
  
}
