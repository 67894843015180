import React, {useContext} from "react";
import Spaces from "../../../Spaces";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import {IColumnData} from "../WindowColumnOptionsV2";

export {WCOSmsV2};

const WCOSmsV2 = (props: {
    type: "kanban" | "preset",
    missionId: string,
    numberOfCandidates: number,
    columnData: IColumnData,
    updateColumnData: Function,
    saveColumnData: Function,
    switchColumnAction: Function,
    isWindowLoading: boolean,
    deleteColumn: Function
}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    return (<>

        <article className={`white-container white-container--grey`}>

            <div className={"space-flex--space-between"}>
                <span className={"text--bold text--magnified11x"}>Activer l'action "Envoyer un SMS" ?</span>
                <div>
                    <label className={"switch-V2"}>
                        <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.columnData.activateSms} onChange={() => {
                            if(!!props.columnData.activateSms) {
                                props.updateColumnData({ activateSms: false});
                                props.saveColumnData({ activateSms: false});
                            }
                            if(!props.columnData.activateSms) {
                                props.switchColumnAction("activateSms");
                            }
                        }}/>
                        <span className="slider"> </span>
                    </label>
                </div>
            </div>

            <Spaces n={0}/>
            <div className={"text--center text--grey"}>
                Les candidats déplacés dans cette colonne recevront un SMS de votre choix.
            </div>

            {/*------------------- Content*/}
            {
                !!props.columnData.activateSms &&
                <div>

                    <Spaces n={0}/>

                    {appContextV2.appSession.sessionBusiness.creditForSms < 0.05 &&
                    <div className={"text--red text--center"}>
                        Attention, votre crédit sms ne vous permet pas d'envoyer de textos.
                        <Spaces n={0}/>
                    </div>}

                    <textarea className={"textarea--v2 textarea--v2--full-width"} value={props.columnData.smsText}
                              placeholder={"Contenu du SMS (celui ci commencera automatiquement par \"Bonjour \"nom_du_candidat\"\" et se terminera par le prénom de l'opérateur qui a effectué l'action)"}
                              name={"smsText"}
                              onChange={(e) => {
                                  if (e.target.value.length < 120) {
                                      props.updateColumnData({smsText: e.target.value});
                                  }
                              }}>
            </textarea>
                    <div className={"text--center text--grey"}>
                        {(props.columnData.smsText || "").length} caractères sur 120
                    </div>

                    <Spaces n={0}/>

                    <div className={"text--center"}>
                        <button disabled={props?.columnData?.name?.length === 0}
                                className={"btn-secondary"} onClick={() => {
                            props.updateColumnData({smsText: props.columnData.smsText});
                            props.saveColumnData({smsText: props.columnData.smsText});
                        }}>
                            Enregistrer
                        </button>
                    </div>
                </div>
            }

        </article>

    </>);
}