import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import {isPhoneNumberValid} from "../../../../../../utils/typeGuards";
import {ButtonBack} from "../../../Buttons/ButtonBack";
import Spaces from "../../../Spaces";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language = appContextV2.appSession.app.language;
  
  const next = props.assistantContext.wizard.nextStep;
  const updateForm = props.assistantContext.updateForm;
  
  return (<>
    <ButtonBack back={props.assistantContext.wizard.previousStep}/>
  
    <Spaces n={0} />
    
    <div className={"text--center text--magnified1x title--bold"}>
      {language === 'FR' && <>À propos de ton entreprise</>}
      {language !== 'FR' && <>About your business</>}
    </div>
    
    <p><br/><br/></p>
    
    <p className={"text--center"}>
      Êtes-vous une agence de recrutement ?
    </p>
    
    <p><br/><br/></p>
    
    <p className={"text--center"}>
      <button key={1} className={"animation-assistant-appear btn-main space-light"} onClick={() => {
        updateForm()({target: {value: true, name: "business.isRecruitingAgency", type: "custom"}});
        next();
      }}>
        {language === 'FR' && <>Oui</>}
        {language !== 'FR' && <>Yes</>}
      </button>
      <button key={2} className={"animation-assistant-appear btn-main space-light"} onClick={() => {
        updateForm()({target: {value: false, name: "business.isRecruitingAgency", type: "custom"}});
        next();
      }}>
        {language === 'FR' && <>Non</>}
        {language !== 'FR' && <>No</>}
      </button>
    </p>
    
    <p><br/><br/></p>
  
  </>);
  
}