import React, {useContext, useEffect} from "react";
import {useForm} from "use-formidable";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import axios from "axios";

export {HeaderRecruiterAvatar};

const HeaderRecruiterAvatar = (props: {
  
  userAvatar: string,
  businessAvatar: string,
  isUserAvatarEditorOn: boolean,
  isBusinessAvatarEditorOn: boolean,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [formAvatarBusiness, updateAvatarBusiness, formidableAvatarBusiness] = useForm({});
  
  const [formAvatarUser, updateAvatarUser, formidableAvatarUser] = useForm({});
  
  useEffect(() => {
    (async() => {
      if(!!formAvatarBusiness.businessAvatar && !!formAvatarBusiness.businessAvatar[0]) {
        
        if(formAvatarBusiness.businessAvatar.length > 1) {
          return notification(appContextV2.dispatchPanels, "Vous ne pouvez envoyer qu'un seul fichier à la fois", "warning");
        }
      
        if(!formidableAvatarUser.isFileMime(formAvatarBusiness.businessAvatar[0], ["jpeg", "png", "gif"])) {
          return notification(appContextV2.dispatchPanels, "Le format du fichier n'est pas valide. Vous pouvez essayer de le ré-enregistrer.", "warning");
        }
      
        if(!formidableAvatarUser.isFileSmallerThan(formAvatarBusiness.businessAvatar[0], 650000)) {
          return notification(appContextV2.dispatchPanels, "La taille du fichier est trop importante (maximum: 600ko).", "warning");
        }
  
        notification(appContextV2.dispatchPanels, "Envoi en cours ...", "information");
  
        const banType = "businessavatar";
  
        let dataToSend: any = new FormData();
  
        dataToSend.append("avatar", formAvatarBusiness.businessAvatar[0], formAvatarBusiness.businessAvatar[0].name);
  
        const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/${banType}/avatar`, dataToSend, {
          withCredentials: true, headers: {
            'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
          }
        });
  
        if(fetched.data.status === 'ok') {
          notification(appContextV2.dispatchPanels, "La banière a été mise à jour", "success")
          appContextV2.dispatchSession({type: "UPDATE_SESSION_BUSINESS", value: {
              key: "businessAvatar",
              value: fetched.data.data,
            }});
        } else {
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error")
        }
      }
    })();
  }, [formAvatarBusiness.businessAvatar]);
  
  useEffect(() => {
    (async () => {
      if(!!formAvatarUser.userAvatar && !!formAvatarUser.userAvatar[0]) {
        
        if(formAvatarUser.userAvatar.length > 1) {
          return notification(appContextV2.dispatchPanels, "Vous ne pouvez envoyer qu'un seul fichier à la fois", "warning");
        }
        
        if(!formidableAvatarUser.isFileMime(formAvatarUser.userAvatar[0], ["jpeg", "png", "gif"])) {
          return notification(appContextV2.dispatchPanels, "Le format du fichier n'est pas valide. Vous pouvez essayer de le ré-enregistrer.", "warning");
        }
        
        if(!formidableAvatarUser.isFileSmallerThan(formAvatarUser.userAvatar[0], 650000)) {
          return notification(appContextV2.dispatchPanels, "La taille du fichier est trop importante (maximum: 600ko).", "warning");
        }
  
  
        notification(appContextV2.dispatchPanels, "Envoi en cours ...", "information");
  
        const banType = "useravatar";
  
        let dataToSend: any = new FormData();
  
        dataToSend.append("avatar", formAvatarUser.userAvatar[0], formAvatarUser.userAvatar[0].name);
  
        const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/${banType}/avatar`, dataToSend, {
          withCredentials: true, headers: {
            'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
          }
        });
  
        if(fetched.data.status === 'ok') {
          notification(appContextV2.dispatchPanels, "La banière a été mise à jour", "success")
          appContextV2.dispatchSession({type: "UPDATE_SESSION", value: {
              key: "userAvatar",
              value: fetched.data.data,
            }});
        } else {
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error")
        }
      }
    })();
  }, [formAvatarUser.userAvatar]);
  
  return (<div className={"page-layout__header__avatar hide-on-smartphones"}>
    
    <div className={"page-layout__header__avatar__big-avatar"}>
      
      {
        props.isBusinessAvatarEditorOn && <div className={"page-layout__header__avatar__editor"}>
          <div className={"header__upload-image__button"} title={"Ajoute un avatar pour l'entreprise"}>
            <input type={"file"} name={"businessAvatar"} id={"businessAvatar"} className="input-camera" onChange={(e) => updateAvatarBusiness()(e)}/>
            <label htmlFor={"businessAvatar"} className={`btn-camera2 animation-straight-fade-in space-medium`}>
              <img src={"https://static.myshortlist.co/btn-camera.svg"} alt={"modify avatar"}/>
            </label>
          </div>
        </div>
      }
      {!!props.businessAvatar && <img className={"page-layout__header__avatar__big-avatar__img"} src={props.businessAvatar}/>}
    
    </div>
    
    <div className={"page-layout__header__avatar__small-avatar"}>
      
      {
        props.isUserAvatarEditorOn && <div className={"page-layout__header__avatar__editor"}>
          <div className={"header__upload-image__button"} title={"Ajoute un avatar personnel"}>
            <input type={"file"} name={"userAvatar"} id={"userAvatar"} className="input-camera" onChange={updateAvatarUser()}/>
            <label htmlFor={"userAvatar"} className={`btn-camera2 animation-straight-fade-in space-medium`}>
              <img src={"https://static.myshortlist.co/btn-camera.svg"} alt={"modify avatar"}/>
            </label>
          </div>
        </div>
      }
      {!!props.userAvatar && <img className={"page-layout__header__avatar__big-avatar__img"} src={props.userAvatar}/>}
    
    </div>
  
  </div>);
}