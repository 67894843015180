import React, {useContext, useState} from "react";
import Spaces from "../../../Spaces";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";

export {WKAASms};

const WKAASms = (props: {columnData: any,
  updateColumnData: Function,
  white: boolean,}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [editorMode, setEditorMode] = useState(false);
  
  return (<>
    
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      
      <div className={"text--center profile-edit__title"}>
        SMS
      </div>
  
      <Spaces n={0}/>
  
      {appContextV2.appSession.sessionBusiness.creditForSms < 0.05 && <div className={"text--red text--center"}>
        Attention, votre crédit sms ne vous permet pas d'envoyer de textos.
        <Spaces n={0}/>
      </div>}
      
      <textarea disabled={!editorMode} className={"textarea--v2 textarea--v2--full-width"}
                value={props.columnData.smsText}
                placeholder={"Contenu du sms (celui-ci commencera automatiquement par \"Bonjour \"nom_du_candidat\"\" et se terminera par le prénom de l'opérateur qui a effectué l'action)"}
                name={"smsText"}
                onChange={props.updateColumnData({maximumLength: 120})}>
            </textarea>
      <div className={"text--center text--grey"}>
        {(props.columnData.smsText || "").length} caractères sur 120
      </div>
      
      <Spaces n={0}/>
      <div className={"text--center"}>Le SMS commencera toujours par "Bonjour nom_du_candidat" et se terminera par le prénom de la personne qui effectue
        l'action.
      </div>
      
      <Spaces n={0}/>
      
      {/*----------------------------------------- Modify default mail*/}
      <div className={"text--center"}>
        <button disabled={!!editorMode} className={"btn--grey-art"} onClick={() => {
          setEditorMode(true);
        }}>
          Personnaliser le SMS
        </button>
      </div>
    
    </article>
  
  </>);
}