import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext, useState} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {notification} from "../../../../../utils/notification";
import {useForm} from "use-formidable";
import {devLog} from "../../../../../utils/devLog/devLog";
import axios from "axios";
import {WCOName} from "./componentsWCO/WCOName";
import {WCOEmail} from "./componentsWCO/WCOEmail";
import {WCOCommunity} from "./componentsWCO/WCOCommunity";
import {WCODelete} from "./componentsWCO/WCODelete";
import {WCOSms} from "./componentsWCO/WCOSms";
import {WCOAskCv} from "./componentsWCO/WCOAskCv";
import {WCONotify} from "./componentsWCO/WCONotify";

interface IColumnData {
  _id?: string;
  name?: string;
  description?: string;
  activateMail?: boolean;
  mailSubject?: string;
  mailDelay?: number;
  mailContent?: string;
  activatePool?: boolean;
  poolId?: string;
  activateSms?: boolean;
  smsText?: string;
  activateAskCv?: boolean;
  askCvText?: string;
}

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [data, updateData, formidableData] = useForm(appContextV2.appPanels.window.windowOptions.data.columnData);
  const updateColumnFront = appContextV2.appPanels.window.windowOptions.data.updateColumnFront;
  const deleteColumnFront = appContextV2.appPanels.window.windowOptions.data.deleteColumnFront;
  const numberOfCandidates = appContextV2.appPanels.window.windowOptions.data.numberOfCandidates;
  const setIsLoading = appContextV2.appPanels.window.windowOptions.data.setIsLoading;
  
  const [isPageLoading, setIsPageLoading] = useState(false);
  devLog("data", data);
  
  const saveColumnData = async(columnId: string, columnData: any) => {
    try {
      setIsPageLoading(true)
      notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
      setIsLoading(true);
      
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/mat/com/update/column`, {
        missionId: appContextV2.appNavigation.missionId,
        columnId: columnId,
        columnData: columnData,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        setIsPageLoading(false)
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setIsPageLoading(false);
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Mise à jour effectuée", "success");
      
    } catch(error) {
      setIsPageLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      setIsLoading(false);
    }
  }
  
  const switchAction = async(action: string) => {
    try {
      const newActions = {
        activateMail: action === "activateMail",
        activateSms: action === "activateSms",
        activatePool: action === "activatePool",
        activateAskCv: action === "activateAskCv",
        activateNotify: action === "activateNotify"
      };
      
      updateColumnFront(data._id, newActions);
      updateData()({target: {type: "custom", name: "activateMail", value: action === "activateMail"}});
      updateData()({target: {type: "custom", name: "activateSms", value: action === "activateSms"}});
      updateData()({target: {type: "custom", name: "activatePool", value: action === "activatePool"}});
      updateData()({target: {type: "custom", name: "activateAskCv", value: action === "activateAskCv"}});
      updateData()({target: {type: "custom", name: "activateNotify", value: action === "activateNotify"}});
      
      saveColumnData(data._id, newActions);
    } catch(error) {
      throw (error);
    }
  }
  
  return (<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
    
    <div className={"container"}>
      
      {/*--------------------------------------------- Name ---------------------------------------------*/}
      <WCOName data={data}
               isPageLoading={isPageLoading}
               saveColumnData={saveColumnData}
               updateColumnFront={updateColumnFront}
               updateData={updateData}
               white={props.white}
      
      />
      
      <Spaces n={1}/>
      
      <h1 className={"text--center"}>
        Choisissez une action de colonne
      </h1>
      <div className={"text--minified text--center text--grey"}>
        Une "action de colonne" permet d'effectuer une action lorsqu'un candidat est déplacé dans cette colonne.
      </div>
      
      {/*<Spaces n={0}/>*/}
      
      {/*/!*--------------------------------------------- AskCv ---------------------------------------------*!/*/}
      {/*<WCOAskCv white={props.white}*/}
      {/*          updateData={updateData}*/}
      {/*          updateColumnFront={updateColumnFront}*/}
      {/*          saveColumnData={saveColumnData}*/}
      {/*switchAction={switchAction}*/}
      {/*          isPageLoading={isPageLoading}*/}
      {/*          data={data}*/}
      {/*/>*/}
      
      <Spaces n={0}/>
      
      {/*--------------------------------------------- Notify ---------------------------------------------*/}
      <WCONotify white={props.white}
                 updateData={updateData}
                 updateColumnFront={updateColumnFront}
                 saveColumnData={saveColumnData}
                 switchAction={switchAction}
                 isPageLoading={isPageLoading}
                 data={data}
                 missionId={appContextV2.appNavigation.missionId}
      />
      
      <Spaces n={0}/>
      
      
      {/*--------------------------------------------- Email ---------------------------------------------*/}
      <WCOEmail white={props.white}
                updateData={updateData}
                updateColumnFront={updateColumnFront}
                saveColumnData={saveColumnData}
                switchAction={switchAction}
                isPageLoading={isPageLoading}
                data={data}
      />
      
      <Spaces n={0}/>
      
      {/*--------------------------------------------- SMS ---------------------------------------------*/}
      <WCOSms white={props.white}
              updateData={updateData}
              updateColumnFront={updateColumnFront}
              saveColumnData={saveColumnData}
              switchAction={switchAction}
              isPageLoading={isPageLoading}
              data={data}/>
      
      <Spaces n={0}/>
      
      {/*--------------------------------------------- Community ---------------------------------------------*/}
      {/*<WCOCommunity white={props.white}*/}
      {/*              updateData={updateData}*/}
      {/*              updateColumnFront={updateColumnFront}*/}
      {/*              saveColumnData={saveColumnData}*/}
      {/*              switchAction={switchAction}*/}
      {/*              isPageLoading={isPageLoading}*/}
      {/*              data={data}/>*/}
      
      <Spaces n={0}/>
      
      {/*--------------------------------------------- Delete ---------------------------------------------*/}
      <WCODelete data={data}
                 isPageLoading={isPageLoading}
                 white={props.white}
                 setIsLoading={setIsLoading}
                 deleteColumnFront={deleteColumnFront}
                 numberOfCandidates={numberOfCandidates}
      />
    
    </div>
  
  </WindowWrapper>);
}

