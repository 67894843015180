import React from "react";

export default (props: {
  tab: string,
  setTab: Function,
  candidacyImported: boolean,
}) => {
  
  const isCandidacyButtonActive = !props.candidacyImported;
  
  return (<section className={"candidate-evaluate__container"}>
    <div>
      <button
        className={`window-candidate-v2__navbar__item ${props.tab === "cv" ? " window-candidate-v2__navbar__item window-candidate-v2__navbar__item--selected" : ""}`}
        onClick={() => props.setTab("cv")}> CV
      </button>
      {/*<button*/}
      {/*  className={`window-candidate-v2__navbar__item ${props.tab === "profile" ? " window-candidate-v2__navbar__item window-candidate-v2__navbar__item--selected" : ""}`}*/}
      {/*  onClick={() => props.setTab("profile")}> Profil*/}
      {/*</button>*/}
      {
        isCandidacyButtonActive && <button
          className={`window-candidate-v2__navbar__item ${props.tab === "candidacy" ? " window-candidate-v2__navbar__item window-candidate-v2__navbar__item--selected" : ""}`}
          onClick={() => props.setTab("candidacy")}> Candidature
        </button>
      }
    </div>
  </section>);
}

