import React, {useContext} from "react";
import Spaces from "../../sharedComponents/components/Spaces";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import {notification} from "../../../utils/notification";

export {RecruiterLinksBusinessPage}

const RecruiterLinksBusinessPage = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const url: string = `${window.location.origin}/corpo/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}`;
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Diffuse le lien de ta page entreprise</h1>
    
    <div className={"text--center text--grey"}>
      Diffuse ta page entreprise pour valoriser ta marque employeur
    </div>
    
    <Spaces n={2}/>
    
    <div className={"link-box2"}>
      {url}
    </div>
  
    <Spaces n={1}/>
  
    <div className={"text--center"}>
      <button className={"btn-secondary"} onClick={()=>{
        notification(appContextV2.dispatchPanels, "Lien copié", "success");
        navigator.clipboard.writeText(url);
      }}>
        Copier
      </button>
    </div>
  
  </div>);
}