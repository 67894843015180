import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import getTeamName from "../../../../../utils/getTeamName";

export {HeaderRecruiterTitle};

const HeaderRecruiterTitle = (props: {
  businessName: string,
  numberOfOpenedPositions: number,
}) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  const displayTeamName = appContextV2.appSession?.sessionBusiness?.teamId && appContextV2.appSession?.sessionBusiness?.teams;
  const teamName = getTeamName(appContextV2.appSession.sessionBusiness.teamId, appContextV2.appSession.sessionBusiness.teams);
  
  return(<div className={"page-layout__header__main-content--recruiter"}>

    <div className={"page-layout__header__main-content--recruiter__title"}>
      {props.businessName}
    </div>

    <div className={"page-layout__header__main-content--recruiter__subtitle"}>
      {props.numberOfOpenedPositions} recrutement{props.numberOfOpenedPositions !== 1 && "s"} en cours
    </div>

    {displayTeamName && <div className={"page-layout__header__main-content--recruiter__team"}>
        Équipe {teamName}
    </div>}

    {
      !appContextV2.appSession.session.ban && <div className={"page-layout__header__main-content--recruiter__big-title"}>
        {props.businessName}
      </div>
    }

  </div>);
}