import React, {useContext, useEffect} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import { Switch, Route } from "react-router-dom";
import FourHundredFour from "../../sharedComponents/pages/errors/FourHundredFour";
import RecruiterMissionKanban from "./componentsRMissionPageRouter/RecruiterMissionKanban";
import RecruiterMissionEditor from "./componentsRMissionPageRouter/RecruiterMissionEditor";
import RecruiterMissionLinks from "./componentsRMissionPageRouter/RecruiterMissionLinks";
import RecruiterMissionStats from "./componentsRMissionPageRouter/RecruiterMissionStats";
import PageLayout from "../../sharedComponents/layouts/PageLayout";

/**
 * --------------------------------------
 * Displays all pages for a specific mission
 * Secondary-level page
 * --------------------------------------
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(() => {
    appContextV2.dispatchNavigation({type: "SET_MISSIONID", value: props.match.params.missionid});
  }, []);
  
  return(<PageLayout small={true} grey={["RecruiterMissionLinks"].includes(appContextV2.appNavigation.subPage)} page={props.page} title={""} fullWidth={true} status={"ok"}>
    
    <Switch>
      
      <Route exact path="/business/mission/:missionid" render={(componentProps) => <RecruiterMissionKanban {...componentProps} subPage={"RecruiterMissionKanban"} />}/>
      
      <Route exact path="/business/mission/:missionid/editor" render={(componentProps) => <RecruiterMissionEditor {...componentProps} subPage={"RecruiterMissionEditor"} />}/>
      <Route exact path="/business/mission/:missionid/links" render={(componentProps) => <RecruiterMissionLinks {...componentProps} subPage={"RecruiterMissionLinks"} />}/>
      <Route exact path="/business/mission/:missionid/kanban" render={(componentProps) => <RecruiterMissionKanban {...componentProps} subPage={"RecruiterMissionKanban"} />}/>
      <Route exact path="/business/mission/:missionid/statistics" render={(componentProps) => <RecruiterMissionStats {...componentProps} subPage={"RecruiterMissionStats"} />}/>
  
      {/* 404 ------------------------------------------------- */}
      <Route render={(componentProps) => <FourHundredFour {...componentProps} page="FourHundredFour" fullPage={false} setPage={false}/>}/>
    
    </Switch>
  
  </PageLayout>);
}