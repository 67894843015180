import React from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const isButtonActive: boolean = !!props.form.location.postalCode && !!props.form.location.country;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Quel est le lieu du poste ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
      
      <label className={"text--minified3x"}>Rue<br/>
      <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"} name={"location.street"}
             value={props.form.location.street} onChange={props.updateForm({maximumLength: 50})}/>
      </label>
  
      <Spaces n={0}/>
  
      <label className={"text--minified3x"}>Ville<br/>
        <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"} name={"location.city"}
               value={props.form.location.city} onChange={props.updateForm({maximumLength: 50})}/>
      </label>
  
      <Spaces n={0}/>
  
      <label className={"text--minified3x"}>Code postal*<br/>
        <input type={"number"} className={"assistant--v2-input assistant--v2-input--center"} name={"location.postalCode"}
               value={props.form.location.postalCode} onChange={props.updateForm()}/>
      </label>
  
      <Spaces n={0}/>
  
      <label className={"text--minified3x"}>Pays*<br/>
        <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"} name={"location.country"}
               value={props.form.location.country} onChange={props.updateForm({maximumLength: 50})}/>
      </label>
      
      <Spaces n={1}/>
      
      <button disabled={!isButtonActive} className={"btn-assistant-main"}
              onClick={async() => {
                props.wizard.goToStep("lastAssistantStep");
              }}>
        Accéder à la phase 2
      </button>
    
    </div>
  
  </section>);
}