import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";
import {ButtonBigPlusV2} from "../../../sharedComponents/components/Buttons/ButtonBigPlus";
import CloseButton from "../../../sharedComponents/components/Buttons/CloseButton";
import tickSkill from "../dependencies/tickSkill";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Skills manipulation happens here, when "next" is hit it saves to the form */
  const [skills, setSkills]: [any, Function] = useState([]);
  const [skill, setSkill]: [string, Function] = useState("");
  
  /** Drag and drop */
  // todo
  
  useEffect(() => {
    console.log('pppppppppppppppppp', props.form.skills)
    setSkills(props.form.skills || []);
  }, []);
  
  const remove = (index: number) => {
    setSkills((ps: any) => [
      ...[...ps].splice(0, index),
      ...[...ps].splice(index + 1, ps.length - 1 - index)
    ]);
  }
  const isButtonAvailable= (skill.length===0)?true:false;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Quelles sont les missions principales en terme de compétences nécéssaires pour le poste ?
    </div>
    
    <Spaces n={1}/>
    
    {/*-------------------------------------------- Elements */}
    <section className={"assistant--v2-container assistant--v2-container--column"}>
      
      {skills.length === 0 && <article className={"assistant--v2-container__label"}>
        Aucune mission / compétence n'a été ajoutée
      </article>}
      
      {skills.map((item: any, key: number) => (<article key={key} className={"assistant--v2-container__label assistant--v2-container__label--color1"}>
        {item.skill}
        <div className={"assistant--v2-container__label__buttons"}>
          <CloseButton eventOnClick={() => remove(key)}/>
        </div>
      </article>))}
      
    </section>
    
    <Spaces n={1}/>
    
    {/*--------------------------------------------- Control */}
    {
      skills.length <= 10 && <div className={"assistant--v2-container--no-flex text--center"}>
        <input type={"text"} list={"skills"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"newSkill"}
               placeholder={"Ajoute une compétence"}
               value={skill} onChange={(e) => {
                 if(e.target.value.length < 200) {
                   setSkill(e.target.value);
                 }
               }}/>
        <datalist id={"skills"}>
          {props?.skills?.map((item: any, key: number) => (<option key={key}>{item.stringValue}</option>))}
        </datalist>
        <div className={"space-flex--center"}>
          <ButtonBigPlusV2 cb={() => {
            tickSkill(skill, appContextV2.appSession.app.language);
            if(!props.advantages.includes(skill)) {
              props.setAdvantages((ps: any) => [...ps, skill]);
            }
            setSkills((ps: any) => [...ps, {
              skill: skill,
              needed: true,
            }]);
            setSkill("");
          }} disabled={!skill} title={"Ajoute une compétence"}/>
        </div>
      </div>
    }
  
    <Spaces n={0} />
    
    {/*---------------------------------------------- Next */}
    <div className={"text--center"}>
      <span className={`text--red text--minified3x ${!skill && "invisible"}`}> Appuyer sur + pour ajouter cette competence</span>
      <br/>
      <button className={"btn-assistant-main"}
              disabled={!isButtonAvailable}
              onClick={async() => {
                props.updateForm()({target: {type: "custom", name: "skills", value: skills}});
                props.wizard.nextStep();
              }}>
        Suivant
      </button>
    </div>
    
  </section>);
}