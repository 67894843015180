import React from "react";
import AppContext from "../componentsApp/context/AppContext";
import PageLayoutWithHeader from "../sharedComponents/layouts/PageLayoutWithHeader";
import {abstractGetFetch} from "../../utils/abstractFetch";
import Spaces from "../sharedComponents/components/Spaces";
import {capitalizeMulti, capitalizeSentence} from "../../utils/converters";
import {format} from "date-fns";
import {Link} from "react-router-dom";

/**
 * --------------------------------------
 * Displays and manage user calendar
 * Primary-level page
 * --------------------------------------
 */
export default class RecruiterCalendar extends React.Component<any, any> {
  
  static contextType = AppContext;
  
  constructor(props: any) {
    super(props);
    this.state = {
      pageStatus: "pending",
      calendar: []
    };
  }
  
  componentDidMount(): void {
    this.fetchCalendar();
  }
  
  fetchCalendar = async() => {
    try {
      const fetched: any = await abstractGetFetch("/cal/recruiter/calendar", {});
      
      if(fetched.status === "ok") {
        const appointments: any = [];
        const currentDateUnix: number = new Date().getTime();
        for(let appointment of fetched.data) {
          const isToday: boolean = false;
          appointment.appointmentIsPast = appointment.appointmentDateUnix < currentDateUnix;
          appointment.isToday = isToday;
          appointments.push(appointment);
        }
        this.setState({
          pageStatus: "ok",
          calendar: appointments || [],
        })
      } else {
        throw new Error('No calendar fetched');
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  render() {
    
    const language: string = this.context.appState.language;
    const title: string = language === 'FR' ? "Calendrier" : "Calendar";
    
    return (
      <PageLayoutWithHeader title={title} small={true} page={this.props.page} status={this.state.pageStatus}>
        
        <Spaces n={4}/>
        
        {this.state.calendar?.length === 0 && <p className={"text--magnified15x text--center"}>
          Aucun rendez-vous détecté
        </p>}
        
        <div className={"space-flex--center"}>
          <table className={"calendar"}>
            <tbody>
              {this.state.calendar.filter((item: any) => (item.appointmentIsOlderThanTwoWeeks === false)).map((item: any) => (<>
                <tr className={`${item.appointmentIsPast ? "calendar__item--past":""} ${item.appointmentIsToday ? "calendar__item--today":""}`}>
                  <td>
                    <div className={getCalendarStatus(item)}> </div>
                  </td>
                  <td>
                    {item.recruiterTrigram} - <button className={"btn--hollow modern-link"} onClick={()=>{
                    this.context.toggleWindowVisibility({type: "candidacyPreview", data: {
                        candidacyId: item.candidacyId,
                        missionId: item.missionId,
                        page: "cv",
                        fetchShortlist: ()=>{},
                      }}, "open");
                  }}>{capitalizeMulti(item.firstName)} {capitalizeMulti(item.lastName)}</button>
                  </td>
                  <td>
                    <Link to={`/business/kanban/${item.missionId}`}>
                      <button className={"btn--hollow modern-link"}>{capitalizeSentence(item.positionWanted)}</button>
                    </Link>
                  </td>
                  <td>
                    le {format(new Date(item.appointmentDay), "dd-MM-yyyy")} à {item.appointmentHour}:{item.appointmentMin}
                  </td>
                  <td>
                    <button className={"btn-picto--minimalist2"} onClick={()=>{
                    this.context.toggleWindowVisibility({type: "candidateCalendar", data: {
                        candidacyId: item.candidacyId,
                        missionId: item.missionId,
                        fetchShortlist: ()=>{},
                      }}, "open");
                  }}><img src={"https://static.myshortlist.co/icon-appointment--empty.svg"} alt={'link to your page'} className={""}/></button>
                  </td>
                  <td>
                    {item.appointmentMedia === "video" ? "video":""}
                    {item.appointmentMedia === "phone" ? "Par téléphone":""}
                  </td>
                  <td>
                    <Link to={`/video/v2/${item.appointmentVideoRoom}`}>
                      <button className={"btn--hollow modern-link"}>Lien vidéo</button>
                    </Link>
                  </td>
                </tr>
              </>))}
            </tbody>
          </table>
        </div>
        
        
        <Spaces n={4}/>
      
      </PageLayoutWithHeader>);
  }
  
}


function getCalendarStatus(item: any): string {
  switch(item.appointmentStatus) {
    case 'candidateAccepted':
      if(item.appointmentIsToday) {
        return "calendar__status calendar__status--today";
      }
      if(item.appointmentIsPast) {
        return "calendar__status calendar__status--past";
      }
      return "calendar__status calendar__status--accepted";
    case 'offeredByRecruiter':
      return "calendar__status calendar__status--pending";
    case 'candidateAskedNewDates':
    case 'candidateWithdrawed':
    case 'candidateCancelled':
    case 'recruiterCancelled':
    default:
      return "calendar__status calendar__status--cancelled";
  }
  
}