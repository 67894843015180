import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import {HeaderBan} from "./componentsHeaderLayout/HeaderBan";
import {HeaderMailValidation} from "./componentsHeaderLayout/HeaderMailValidation";
import {HeaderRecruiterTitle} from "./componentsHeaderLayout/HeaderRecruiterTitle";
import {HeaderRecruiterAvatar} from "./componentsHeaderLayout/HeaderRecruiterAvatar";
import {HeaderUpdateban} from "./componentsHeaderLayout/HeaderUpdateBan";

export {HeaderLayout};

const HeaderLayout = (props: { isHeaderVisibleOnPage: boolean }) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const currentPage: string = appContextV2.appNavigation.page;
  const userQuality: string = appContextV2.appSession.app.space;
  
  // ----- Determines if the page is in editor mode
  const isPageEditorMode: boolean = currentPage === "RecruiterProfiles" || currentPage === "CandidateProfile";
  
  // ----- Determines user quality
  const isUserCandidate: boolean = userQuality === "candidate";
  const isUserRecruiter: boolean = userQuality === "recruiter";
  
  // ----- Determines if datas are set
  const isEmailVerified: boolean = appContextV2.appSession.session.isEmailVerified;
  const doesBanExist: boolean = !!appContextV2.appSession.session.ban;
  const doesUserAvatarExist: boolean = !!appContextV2.appSession.session.userAvatar;
  const doesBusinessAvatarExist: boolean = !!appContextV2.appSession.sessionBusiness?.businessAvatar;
  const doesBusinessNameExist: boolean = !!appContextV2.appSession.sessionBusiness?.businessName;
  const numberOfOpenedPositions: number = appContextV2.appSession.sessionBusiness?.openedMissions;
  const isUserAdmin: boolean = appContextV2.appSession.session.userRole === "admin";
  
  // ----- Determines if components are in editor mode for the candidate-user
  const isUserBanEditorOn: boolean = (isUserCandidate) && (isPageEditorMode);
  
  // ----- Determines if components are in editor mode for the recruiter-user
  const isUserAvatarEditorOn: boolean = isPageEditorMode || !doesUserAvatarExist;
  const isBusinessAvatarEditorOn: boolean = (isUserRecruiter) && (isUserAdmin) && (isPageEditorMode || !doesBusinessAvatarExist);
  const isBusinessBanEditorOn: boolean = (isUserRecruiter) && (isUserAdmin) && (isPageEditorMode);
  
  if(userQuality === "candidate") {
    return (<>
      <section className={`page-layout__header ${!props.isHeaderVisibleOnPage ? "page-layout__header--hidden" : ""}`}>
      
      </section>
    </>);
  }
  
  if(userQuality === "recruiter") {
    
    return (<>
      <section className={`page-layout__header ${!props.isHeaderVisibleOnPage ? "page-layout__header--hidden" : ""}`}>
        
        {/*---------- Display Ban */}
        <HeaderBan src={appContextV2.appSession.session.ban}
                   isEditorOn={isUserBanEditorOn || isBusinessBanEditorOn}
                   userQuality={userQuality}/>
        
        {/* -------- Message about mail validation (if needed) ---------------------*/}
        {!isEmailVerified && <HeaderMailValidation/>}
        
        {/*---------- Display avatar(s) */}
        {isUserRecruiter && <HeaderRecruiterAvatar userAvatar={appContextV2.appSession.session.userAvatar}
                                                   businessAvatar={appContextV2.appSession.sessionBusiness?.businessAvatar}
                                                   isUserAvatarEditorOn={isUserAvatarEditorOn}
                                                   isBusinessAvatarEditorOn={isBusinessAvatarEditorOn}/>}
        
        {/*---------- Display main content */}
        {isUserRecruiter && doesBusinessNameExist && <HeaderRecruiterTitle
          businessName={appContextV2.appSession.sessionBusiness?.businessName}
          numberOfOpenedPositions={numberOfOpenedPositions}/>}

        {/*-------- Buttons to update / delete the ban */}
        <HeaderUpdateban userQuality={userQuality} isEditorOn={isUserBanEditorOn || isBusinessBanEditorOn}/>
      
        {/*---------- Link to the profile if it is not complete */}
      
      </section>
    </>);
  }
  
  return (<></>);
  
}