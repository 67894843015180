import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import {format} from "date-fns";
import Spaces from "../../components/Spaces";
import {capitalizeSentence} from "../../../../utils/converters";
import PageLayout from "../../layouts/PageLayout";
import {Loading} from "../../components/Loading/Loading";
import Routing from "../../../componentsApp/componentsSwitchAppStatus/Routing";
import FourHundredThree from "../errors/FourHundredThree";
import {devLog} from "../../../../utils/devLog/devLog";
import CW2DocumentDisplayer
  from "../../components/Window/componentsWindow/componentsCWV2/subWindows/CW2DocumentDisplayer";
import {ButtonBigPlusV2} from "../../components/Buttons/ButtonBigPlus";
import {FileDrop} from "../../components/Buttons/FileDrop";
import LoadingStyle2 from "../../components/Loading/LoadingStyle2";
import ECCDCv from "./componentsECCandidateDetails/ECCDCv";
import ECCDProfile from "./componentsECCandidateDetails/ECCDProfile";
import ECCDCandidacy from "./componentsECCandidateDetails/ECCDCandidacy";

export default function ECCandidateDetails(props: {
  candidateToEvaluate: any
  tab: string,
}) {
  
  switch(props.tab) {
    
    case 'cv':
      return (<ECCDCv {...props} />);
    
    case 'profile':
      return (<ECCDProfile {...props} />);
    
    case 'candidacy':
      return (<ECCDCandidacy {...props} />);
    
    default:
      return (<div>Cette page n'existe pas</div>)
  }
}
