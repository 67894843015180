import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import LoadingStyle2 from "../../Loading/LoadingStyle2";

export {WindowSendSMSToSelectedCandidates};

const WindowSendSMSToSelectedCandidates = () => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [fetchedStatus, setFetchedStatus] = useState("asleep");
  const [smsContent, setSmsContent] = useState("");
  
  const poolId: string [] = appContextV2.appPanels.window.windowOptions.data.poolId;
  const candidatesIds: string [] = appContextV2.appPanels.window.windowOptions.data.candidatesIds;
  const isButtonActive: boolean = fetchedStatus === "asleep" && !!smsContent;
  
  const sendMassMail = async() => {
    try {
      
      setFetchedStatus("fetching");
      notification(appContextV2.dispatchPanels, "Envoie de la demande", "information");
      
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/pool/cma/mass/sms`, {
        poolId: poolId,
        candidatesIds: candidatesIds,
        smsContent: smsContent,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        setFetchedStatus("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return;
      }
      
      setFetchedStatus("fetched");
      
      notification(appContextV2.dispatchPanels, "SMS envoyé avec succès", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch (error) {
      
      devLog(error);
      setFetchedStatus("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      
    }
  }
  
  return (<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
    <div className={"text--center"}>
      Tu es sur le point d'envoyer un SMS à {candidatesIds.length} personnes.
    </div>
    <br/><br/>
    <div className={"white-container white-container--grey"}>
      <textarea className={"textarea--v2 textarea--v2--full-width"} value={smsContent}
                id={"content"}
                placeholder={"Contenu du sms (celui ci commencera automatiquement par \"Bonjour \"nom_du_candidat\"\" et se terminera par le prénom de l'opérateur qui a effectué l'action)"}
                name={"mailContent"}
                onChange={(e) => {
                  if(e.target.value?.length < 120) {
                    setSmsContent(e.target.value);
                  }
                }}>
      </textarea>
      <div className={"text--center text--grey"}>
        {(smsContent || "").length} caractères sur 120
      </div>
      
    </div>
    <Spaces n={1}/>
    <div className={"text--center"}>
      <button disabled={!isButtonActive} className={"btn-secondary"} onClick={() => {sendMassMail()}}>
        Envoyer
      </button>
    </div>
    {
      fetchedStatus === "fetching" && <div className={"space-flex--center"}>
        <LoadingStyle2/>
      </div>
    }
  </WindowWrapper>);
}
