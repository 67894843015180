import React, {useContext, useEffect} from "react";
import {devLog} from "../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../sharedComponents/layouts/SubPageLayout";
import getTimeElapsed from "../../../../utils/getTimeElapsed";
import Spaces from "../../../sharedComponents/components/Spaces";
// import {XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries} from 'react-vis';
const reactVis = require("react-vis");
const RadialChart = require("react-vis").RadialChart;
const VerticalBarSeries = require("react-vis").VerticalBarSeries;
const XYPlot = require("react-vis").XYPlot;
const XAxis = require("react-vis").XAxis;
const YAxis = require("react-vis").YAxis;
const LabelSeries = require("react-vis").LabelSeries;


export default (props: any) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  /** Fetch */
  useEffect(() => {
    (async() => {
      try {

      } catch (error) {
        devLog(error);
      }
    })();
  }, []);

  return(<SubPageLayout status={"ok"} subPage={props.subPage}>

    <Spaces n={4}/>

    Stats

  </SubPageLayout>);
}
//
//
// export default class extends React.Component<any, any> {
//
//   static contextType = AppContext;
//
//   constructor(props: any) {
//     super(props);
//     const missionId = this.props.match.params.missionid || "";
//     this.state = {
//       pageStatus: 'pending',
//       shortlist: [],
//       missionProfile: {},
//       stats: {
//         nCandidacies: 0,
//         repartitionOrigins: {
//           repartition: {},
//           repartitionList: [],
//         }
//       },
//       missionId: missionId,
//     };
//   }
//
//   componentDidMount = async() => {
//     await this.context.setMissionId(this.state.missionId);
//     await this.fetchShortlist();
//     await this.getStats();
//   }
//
//   getStats = async() => {
//     try {
//       const shortlist: any = this.state.shortlist;
//       if(typeof shortlist === "undefined") {
//         return this.setState((ps: any) => ({
//           ...ps,
//           stats: {
//             nCandidacies: 0
//           },
//         }));
//       }
//
//       const {
//         nCandidacies,
//         nBlockedByAlgo,
//         nBlockedByAlgoP,
//         nRejected,
//         nRejectedP,
//         nWithdraw,
//         nWithdrawP,
//         nRecruited,
//         averageTime
//       } = getNumberOfCandidacies(shortlist);
//
//       const repartitionGrades: any = getRepartitionGrades(shortlist);
//       const listOfGrades: any = repartitionGrades.listOfGrades;
//       const average: number = getAverage(listOfGrades);
//       const variance: number = getVariance(listOfGrades);
//       const repartitionDates: any = getRepartitionDates(shortlist);
//       const repartitionOrigins: any = getRepartitionOrigins(shortlist);
//       const averagesPerOrigin: any = getAveragesPerOrigin(shortlist);
//       const firstAction: any = getFirstAction(shortlist);
//       const timeToHire: any = getTimeToHire(shortlist);
//       const withoutAction: any = getWithoutAction(shortlist);
//
//
//       await this.setState((previousState: any) => ({
//         ...previousState,
//         stats: {
//           nCandidacies: nCandidacies,
//           nBlockedByAlgo: nBlockedByAlgo,
//           nBlockedByAlgoP: nBlockedByAlgoP,
//           nRejected: nRejected,
//           nRejectedP: nRejectedP,
//           nWithdraw: nWithdraw,
//           nWithdrawP: nWithdrawP,
//           nRecruited: nRecruited,
//           average: average,
//           averageTime: averageTime,
//           variance: variance,
//           repartitionGrades: repartitionGrades,
//           repartitionDates: repartitionDates,
//           repartitionOrigins: repartitionOrigins,
//           postedSince: getTimeElapsed(this.state.missionProfile.dateOfCreation, "FR", false),
//           firstAction: firstAction,
//           timeToHire: timeToHire,
//           // lastAction: lastAction,
//           withoutAction: withoutAction,
//           averagesPerOrigin: averagesPerOrigin,
//         }
//       }));
//     } catch (error) {
//       console.log(error);
//     }
//   }
//
//   fetchShortlist = async() => {
//     const fetched: any = await abstractGetFetch("/mat/get/shortlist", {missionid: this.state.missionId});
//     if(fetched.status === "ok") {
//       await this.setState({
//         pageStatus: 'ok',
//         shortlist: fetched.data?.shortlist || [],
//         missionProfile: fetched.data?.missionProfile || {},
//       });
//     } else {
//       this.setState({
//         pageStatus: 'error',
//         shortlist: [],
//       });
//     }
//   };
//
//   render() {
//
//     return (<>
//
//       <PageLayout small={false} title={""} fullWidth={true} page={this.props.page} status={this.state.pageStatus}>
//
//         <div className={"hide-on-computers"}>
//           <Spaces n={7}/>
//           <p className={"text--center text--magnified2x"}>Cette page n'est pas encore disponible sur portable</p>
//           <Spaces n={3}/>
//         </div>
//
//         <div className={"hide-on-smartphones"}>
//
//           <Spaces n={0}/>
//
//           {
//             this.state.stats?.nCandidacies < 2 && <>
//               <Spaces n={5} />
//               <p className={"text--center text--magnified2x"}>
//                 Deux candidatures minimum sont nécéssaires pour avoir des statistiques
//               </p>
//               <Spaces n={4} />
//             </>
//           }
//
//           {
//             this.state.stats?.nCandidacies >= 2 && <>
//
//               <div className={"stats__grey"}>
//
//                 <section className={"container"}>
//                   <h1 className={"stats__title"}>Chiffres clés</h1>
//                   <div className={"stats__keys"}>
//
//                     <article title={"Nombre de candidatures"}>
//                     <span className={"stats__keys__title"}>
//                       Candidatures
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.nCandidacies}
//                     </span>
//                     </article>
//
//                     <article title={"Nombre de personnes ayant vu la page"}>
//                     <span className={"stats__keys__title"}>
//                       Ouvertures
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.missionProfile?.viewedByIp}
//                     </span>
//                     </article>
//
//                     <article title={"Candidatures éliminées par l'algorithme"}>
//                     <span className={"stats__keys__title"}>
//                       Éliminées
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.nBlockedByAlgo} ({this.state.stats?.nBlockedByAlgoP}%)
//                     </span>
//                     </article>
//
//                     <article title={"Candidatures écartées par ton équipe"}>
//                     <span className={"stats__keys__title"}>
//                       Écartées
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.nRejected} ({this.state.stats?.nRejectedP}%)
//                     </span>
//                     </article>
//
//                     <article title={"Candidats s'étant retirés de la course"}>
//                     <span className={"stats__keys__title"}>
//                       Retirées
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.nWithdraw}
//                     </span>
//                     </article>
//
//                     <article title={"Moyenne des notes au formulaire"}>
//                     <span className={"stats__keys__title"}>
//                       Note moyenne
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.average}
//                     </span>
//                     </article>
//
//                     <article title={"Écart moyen entre les notes et la moyenne"}>
//                     <span className={"stats__keys__title"}>
//                       Écart-type
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.variance}
//                     </span>
//                     </article>
//
//                     <article title={"Temps moyen passé par un candidat pour répondre au questionnaire"}>
//                     <span className={"stats__keys__title"}>
//                       Temps moyen
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {Math.round(this.state.stats?.averageTime / 60)} minutes
//                     </span>
//                     </article>
//
//                     <article title={"Durée depuis la création de l'annonce"}>
//                     <span className={"stats__keys__title"}>
//                       Création depuis
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.postedSince}
//                     </span>
//                     </article>
//
//                     <article title={"Durée moyenne pour la première action sur un candidat"}>
//                     <span className={"stats__keys__title"}>
//                       Délai moyen 1ere action
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.firstAction || "---"}
//                     </span>
//                     </article>
//
//                     <article title={"Durée moyenne par recrutement"}>
//                     <span className={"stats__keys__title"}>
//                       Time to hire
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.timeToHire || "---"}
//                     </span>
//                     </article>
//
//                     <article title={"Candidatures sur lesquelles aucune action n'existe"}>
//                     <span className={"stats__keys__title"}>
//                       Candidatures non traitées
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.withoutAction}
//                     </span>
//                     </article>
//
//                     <article title={"Nombre de recrutements"}>
//                     <span className={"stats__keys__title"}>
//                       Recrutements
//                     </span>
//                       <br/>
//                       <span className={"stats__keys__value"}>
//                       {this.state.stats?.nRecruited}
//                     </span>
//                     </article>
//                   </div>
//                 </section>
//
//               </div>
//
//               <Spaces n={3}/>
//
//               {
//                 (Array.isArray(this.state.stats?.repartitionOrigins?.repartitionList)) && <>
//                   {
//                     (this.state.stats?.repartitionOrigins?.repartitionList.length) > 0 &&
//                     <>
//                       <section className={"container"}>
//                         <h1 className={"stats__title"}>Tracking des candidatures</h1>
//                         <div className={"space-flex--center"}>
//                           <RadialChart
//                             data={this.state.stats?.repartitionOrigins?.repartitionList}
//                             showLabels={true}
//                             width={450}
//                             height={450}/>
//                         </div>
//                       </section>
//
//                       <Spaces n={1}/>
//
//                       <Spaces n={0}/>
//                       <div className={"stats__grey"}>
//                         <div className={"space-flex--center"}>
//                           <table className={"table-stats-percent"}>
//                             <thead>
//                             <tr>
//                               <td className={"text--center text--magnified2x space-padding--small"}>
//                                 Source
//                               </td>
//                               <td className={"text--center text--magnified2x space-padding--small"}>
//                                 Nombre
//                               </td>
//                               <td className={"text--center text--magnified2x space-padding--small"}>
//                                 Part de cette source
//                               </td>
//                               <td className={"text--center text--magnified2x space-padding--small"}>
//                                 Résultats au questionnaire
//                               </td>
//                             </tr>
//                             </thead>
//                             <tbody>
//                             {this.state.stats?.averagesPerOrigin?.map((item: any) => (<tr>
//                               <td className={"text--center text--magnified2x space-padding--small"}>
//                                 {item.origin}
//                               </td>
//                               <td className={"text--center text--magnified2x space-padding--small"}>
//                                 {item.quantity}
//                               </td>
//                               <td className={"text--center text--magnified2x space-padding--small"}>
//                                 {item.proportion} %
//                               </td>
//                               <td className={"text--center text--magnified2x space-padding--small"}>
//                                 {item.average} %
//                               </td>
//                             </tr>))}
//                             </tbody>
//                           </table>
//                         </div>
//                       </div>
//
//                       <Spaces n={3}/>
//                     </>
//                   }
//                 </>
//
//               }
//
//               <section className={"container"}>
//                 <h1 className={"stats__title"}>Répartition des résultats</h1>
//                 <div className={"space-flex--center"}>
//
//                   <XYPlot
//                     xType="ordinal"
//                     width={700}
//                     height={350}
//                     yDomain={[0, this.state.stats?.repartitionGrades?.highest || 10]}
//                   >
//                     <XAxis/>
//                     <YAxis/>
//                     <VerticalBarSeries
//                       data={[
//                         {"y": this.state.stats?.repartitionGrades?.nUnder10 || 0, "x": "0-10"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder20 || 0, "x": "10-20"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder30 || 0, "x": "20-30"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder40 || 0, "x": "30-40"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder50 || 0, "x": "40-50"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder60 || 0, "x": "50-60"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder70 || 0, "x": "60-70"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder80 || 0, "x": "70-80"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder90 || 0, "x": "80-90"},
//                         {"y": this.state.stats?.repartitionGrades?.nUnder100 || 0, "x": "90-100"},
//                       ]}
//                     />
//
//                   </XYPlot>
//
//                 </div>
//               </section>
//
//             </>
//           }
//
//         </div>
//
//       </PageLayout>
//     </>);
//   }
//
// }
