import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import React, {useContext, useState} from "react";
import PageLayout from "../layouts/PageLayout";
import Spaces from "../components/Spaces";
import RatingComponent from "../components/RatingComponent";
import ButtonWrapper from "../layouts/ButtonWrapper";
import axios from "axios";
import {notification} from "../../../utils/notification";
import LoadingStyle2 from "../components/Loading/LoadingStyle2";
import DisplayFeedbacksOnPlatform from "../components/DisplayFeedbacksOnPlatform";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [fetchStatus, setSendStatus]: any = useState("asleep");
  const [step, setStep]: any = useState(1);
  const token: string = props.match.params.token || "";
  
  const [answer1, setAnswer1]: any = useState(0);
  const [answer2, setAnswer2]: any = useState(0);
  const [answer3, setAnswer3]: any = useState(0);
  const [answer4, setAnswer4]: any = useState("");
  const [answer5, setAnswer5]: any = useState("");
  
  const sendEvaluation = async() => {
    try {
      setSendStatus("fetching");
      await axios.post(`${process.env.REACT_APP_API_URL}/mat/cam/save/candidate/feedback`, {
        answer1: answer1,
        answer2: answer2,
        answer3: answer3,
        answer4: answer4,
        answer5: answer5,
        token: token
      }, {withCredentials: true});
      
      setStep(2);
      
      setSendStatus("fetched");
      notification(appContextV2.dispatchPanels, "Votre feedback à bien été transmis !", "success-long");
      
    } catch(error) {
      setSendStatus("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      throw error;
    }
  }
  
  const isButtonNotActive = !answer1 || !answer2 || !answer3 || !answer4 || fetchStatus !== "asleep";
  
  if(step === 2) {
    return (<>
      <PageLayout fullWidth={true} grey={false} page={props.page} status={"ok"}>
        <Spaces n={11}/>
        <div className={"text--magnified11x text--center"}>
          Merci d'avoir pris le temps de nous répondre ! Vous pouvez maintenant fermer cette fenêtre.
        </div>
        <Spaces n={8}/>
      </PageLayout>
    </>);
  }
  
  return (
    <PageLayout fullWidth={true} grey={true} page={props.page} status={"ok"}>
      
      <Spaces n={4}/>
      
      <h1 className={"profile-edit__title text--center"}>Votre avis nous intéresse !</h1>
      
      <Spaces n={0}/>
      
      <p className={"text--center text--grey"}>Ce sondage est facultatif et anonyme.</p>
      
      <Spaces n={0}/>
      
      <section className={"candidate-evaluate__container "}>
        <div>1. Avez-vous apprécié cette nouvelle façon de postuler ? *</div>
        <RatingComponent selected={answer1} setSelected={setAnswer1}/>
        
        <Spaces n={1}/>
        
        <div>2. Comment trouvez-vous cette façon de postuler par rapport à l'envoi d'un CV traditionnel ? *</div>
        <RatingComponent selected={answer2} setSelected={setAnswer2}/>
        
        <Spaces n={1}/>
        
        <div>3. Comment trouvez-vous cette façon de postuler par rapport à d'autres plateformes ? *</div>
        <RatingComponent selected={answer3} setSelected={setAnswer3}/>
        
        {/*<section className={"candidate-evaluate__container "}>*/}
        {/*  <div>Recommanderiez vous cette nouvelle façon de postuler à un(e) ami(e), collègue ?</div>*/}
        {/*  <RatingComponent selected={answer4} setSelected={setAnswer4}/>*/}
        {/*</section>*/}
        
        {/*<section className={"candidate-evaluate__container "}>*/}
        {/*  <div>Avez vous trouver cette nouvelle façon de postuler plus simple et plus agréable que les sites*/}
        {/*    traditionnels ?*/}
        {/*  </div>*/}
        {/*  <div className={"rating--component--center"}>*/}
        {/*              <textarea className={"rating-component--textarea"} placeholder={"Votre reponse..."} value={answer1}*/}
        {/*                        onChange={(e) => {*/}
        {/*                          setAnswer1(e.target.value);*/}
        {/*                        }}>*/}
        {/*              </textarea></div>*/}
        {/*</section>*/}
        
        <Spaces n={1}/>
        
        <div>4. Pour quelle(s) raison(s) avez-vous donné ces notes ? *</div>
        <div className={"rating--component--center"}>
                    <textarea className={"rating-component--textarea"} placeholder={"Votre reponse..."} value={answer4}
                              onChange={(e) => {
                                  if(e.target.value.length < 131) {
                                setAnswer4(e.target.value);
                              }}}>
                    </textarea>
            <div className={"text--grey text--magnified11x"}>{answer4.length}/130</div></div>



        
        <Spaces n={1}/>
        
        <div>5. Quelle(s) amélioration(s) pourrai(en)t être apportée(s) ?</div>
        <div className={"rating--component--center"}>
                    <textarea className={"rating-component--textarea"} placeholder={"Votre reponse..."} value={answer5}
                              onChange={(e) => {
                                  if(e.target.value.length < 131) {
                                setAnswer5(e.target.value);
                              }}}>
                    </textarea>
            <div className={"text--grey text--magnified11x"}>{answer5.length}/130</div>
        </div>
      </section>
      
      <Spaces n={0}/>
      
      {isButtonNotActive &&
      <div className={"text--red text--minified3x text--center"}> Merci de répondre aux questions 1, 2, 3, 4, 5 pour poster
        votre évaluation ! <Spaces n={0}/></div>
      }
      <div className={"rating--component--center"}>
        <button className={"btn-secondary"} onClick={() => sendEvaluation()}
                disabled={isButtonNotActive}> Envoyer
        </button>
      </div>
      
      {
        fetchStatus !== "asleep" && <div><LoadingStyle2/></div>
      }
    
    </PageLayout>);
}
