export default (setAmbassadors: Function) => (args: {
  ambassadorId: string,
  fullName: string,
  email: string,
  phone: string,
}) => {
  
  return setAmbassadors((ps: any) => ps.map((item: any) => ({
    ...item,
    fullName: item._id === args.ambassadorId ? args.fullName : item.fullName,
    email: item._id === args.ambassadorId ? args.email : item.email,
    phone: item._id === args.ambassadorId ? args.phone : item.phone,
  })));
}