import React, {useContext, useEffect} from "react";
import Spaces from "../../../../Spaces";
import {format} from "date-fns";
import AppContext, {IAppV2Context} from "../../../../../../componentsApp/context/AppContext";
import axios from "axios";
import {notification} from "../../../../../../../utils/notification";
import {devLog} from "../../../../../../../utils/devLog/devLog";

export default (props: any) => {
  
  return (<>
    <CandidateAnswers answers={props.candidate?.candidacy?.answers}
                      defaultQuestions={props.candidate?.missionDefaultQuestions}
                      defaultAnswers={props.candidate?.candidacy?.defaultAnswers}
                      skills={props.candidate?.candidacy?.answers}
                      candidacy={props.candidate?.candidacy}
                      inherited={props.inherited}
                      updateCandidacyFrontInWindow={props.updateCandidacyFrontInWindow}/>
  </>);
}

function CandidateAnswers(props: {
  answers: any,
  defaultQuestions: any,
  defaultAnswers: any,
  skills: any,
  candidacy: any,
  inherited: any,
  updateCandidacyFrontInWindow: any
}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return (<>
    
    <div className={"results-V2__metadata"}>
      {props.candidacy?.dateOfCreation && <>Date de la candidature: {format(new Date(props.candidacy?.dateOfCreation), "dd-MM-yyyy")}</>}
      <br/>
      
      {props.candidacy?.webAmbassador && <>
        Ambassadeur / source: {props.candidacy?.webAmbassador}
        <br/>
      </>}
      
      {(props.candidacy?.webOrigin && appContextV2.appSession?.sessionBusiness?.businessName !== "MEDADOM" && props.candidacy?.webOrigin && appContextV2.appSession?.sessionBusiness?.businessName !== "Black-Belt/M") && <>
        Source: {props.candidacy?.webOrigin}
        <br/>
      </>}
    
    </div>
    
    <Spaces n={0}/>
    
    <section className={"results-V2"}>
      <article className={"results-V2__item"}>
        <div className={"results-V2__item__title"}>
          Questionnaire
        </div>
        <div className={"results-V2__item__value"}>
          {props.candidacy?.markForm} %
        </div>
        <div className={"results-V2__item__bar"}>
          <div className={`results-V2__item__bar--selected ${getProgressionStyle(props.candidacy?.markForm)}`}>
          </div>
        </div>
      </article>
      <article className={"results-V2__item"}>
        <div className={"results-V2__item__title"}>
          Compétences
        </div>
        <div className={"results-V2__item__value"}>
          {props.candidacy?.markSkills} %
        </div>
        <div className={"results-V2__item__bar"}>
          <div className={`results-V2__item__bar--selected ${getProgressionStyle(props.candidacy?.markSkills)}`}>
          </div>
        </div>
      </article>
      <article className={"results-V2__item"}>
        <div className={"results-V2__item__title"}>
          Réputation
        </div>
        <div className={"results-V2__item__value"}>
          À venir
        </div>
        <div className={"results-V2__item__bar"}>
          <div className={`results-V2__item__bar--selected`}>
          </div>
        </div>
      </article>
    </section>
    
    <Spaces n={1}/>
    
    <div className={"space-flex--center"}>
      
      {props.candidacy.status === "accepted" && <button className={"message--v2__chat__button"} onClick={async() => {
        try {
          await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/status`, {
            candidacyId: props.candidacy?._id,
            status: "rejected",
          }, {
            withCredentials: true
          });
          props.updateCandidacyFrontInWindow({"status": "rejected"});
          notification(appContextV2.dispatchPanels, "Candidature mise a jour avec succès", "success");
          
        } catch(error) {
          devLog(error);
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          
        }
      }}> Retirer la candidature</button>}
      {props.candidacy.status !== "accepted" && <button className={"message--v2__chat__button"} onClick={async() => {
        try {
          await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/status`, {
            candidacyId: props.candidacy?._id,
            status: "accepted",
          }, {
            withCredentials: true
          });
          props.updateCandidacyFrontInWindow({"status": "accepted"});
          // (props.inherited?.frontUpdateCandidacy ?? (()=>{}))( props.candidacy?._id, {'status': "accepted"});
          notification(appContextV2.dispatchPanels, "Candidature mise a jour avec succès", "success");
          
        } catch(error) {
          devLog(error);
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          
        }
      }}>Réactiver la candidature</button>}
    
    </div>
    
    <Spaces n={2}/>
    <p className={"results-v2__title"}>
      Réponses du candidat
    </p>
    
    <Spaces n={0}/>
    
    <div className={"text--center text--grey text--minified2x"}>
      Une réponse valorisée apparait en vert. Une réponse non valorisée apparait en grise. Une réponse éliminatoire
      apparait en rouge.
    </div>
    
    <Spaces n={0}/>
    {!props.inherited?.candidacy?.imported && <>
      {
        props.defaultQuestions?.isAvailable && typeof props.defaultAnswers?.isAvailable !== "undefined" &&
        <article className={""}>
          <div className={"results-v2__answer__question"}>Êtes-vous disponible actuellement ?</div>
          <p className={`results-v2__answer text--center`}>
            {props.defaultAnswers?.isAvailable && <div>Oui</div>}
            {!props.defaultAnswers?.isAvailable && <div>Non</div>}
          </p>
        </article>
      }
      
      {!props.defaultAnswers?.isAvailable && typeof props.defaultAnswers?.dateOfAvailability !== "undefined" &&
      <article className={""}>
        <div className={"results-v2__answer__question"}>À quelle date serez-vous disponible ?</div>
        <p className={"results-v2__answer text--center"}>
    {props.defaultAnswers?.dateOfAvailability ? format(new Date(props.defaultAnswers?.dateOfAvailability), "dd/MM/yyyy") : ""}<br/>
      </p>
      </article>}
      
      {props.defaultQuestions?.currentSalary && typeof props.defaultAnswers?.currentSalary !== "undefined" &&
      <article className={""}>
        <div className={"results-v2__answer__question"}>Quelle est votre niveau de remunération annuel brut actuel ?
        </div>
        <p className={"results-v2__answer text--center"}>
    {props.defaultAnswers?.currentSalary}<br/>
      </p>
      </article>}
      
      {props.defaultQuestions?.salaryWanted && typeof props.defaultAnswers?.salaryWanted !== "undefined" &&
      <article className={""}>
        <div className={"results-v2__answer__question"}>Quelle est votre rémunération annuelle brute souhaitée ?</div>
        <p className={"results-v2__answer text--center"}>
    {props.defaultAnswers?.salaryWanted}<br/>
      </p>
      </article>}
      
      {props.defaultQuestions?.yearsOfExperience && typeof props.defaultAnswers?.yearsOfExperience !== "undefined" &&
      <article className={""}>
        <div className={"results-v2__answer__question"}>Quelle est votre expérience à ce poste ?</div>
        <div className={"results-v2__answer text--center"}>{props.defaultAnswers?.yearsOfExperience} an(s)</div>
      </article>}
      
      {props.defaultQuestions?.training && typeof props.defaultAnswers?.training !== "undefined" &&
      <article className={""}>
        <div className={"results-v2__answer__question"}>Quel est votre niveau de formation ?</div>
        <div className={"results-v2__answer text--center"}>{props.defaultAnswers?.training}</div>
      </article>}

      <Spaces n={1}/>
      
      {props.answers?.filter((item: any) => (['freeText', 'singleChoice', 'multipleChoice'].includes(item.question?.type))).map((item: any, key: any) => (
        <article key={key} className={`results-v2__answer`}>
          <div className={"text--minified3x"}>Temps passé sur la
            question: {item.timeSpent} seconde{item.timeSpent !== 1 ? "s" : ""}</div>
          <div className={"results-v2__answer__question"}>({key + 1}) {item.question?.question}</div>
          <CandidateAnswer item={item} index={key}/>
          {/*<Spaces n={1}/>*/}
        </article>))} </>}
    
    <Spaces n={1}/>
    
    {
      props.candidacy?.skills?.length !== 0 && <>
        <p className={"results-v2__title"}>
          Compétences métier
        </p>
        <Spaces n={0}/>
        <div className={"text--center text--grey text--minified2x"}>
          En vert: le candidat a séléctionné cette compétence.
        </div>
        <Spaces n={0}/>
        {props.candidacy?.skills?.map((item: any, key: any) => (<section key={key}
                                                                         className={`results-v2__skill ${item?.selected ? "results-v2__skill--green" : "results-v2__skill--red"}`}>
          {/*<CandidateSkill item={item} index={key}/>*/}
          «{item.skill}»
        </section>))}
      </>
    }
    <Spaces n={1}/>
  </>);
}

function CandidateAnswer(props: any) {
  switch(props.item?.question?.type) {
    case 'freeText':
      return (<p className={"text--left"}>
        
        {props.item?.answer ? props.item.answer?.split('\n').map(function(item: any) {
          return (
            <span className={"trigram-v2__messages__message__body--span"}>
              {item}
              <br/>
              </span>
          )
        }) : ""}
      
      </p>);
    case 'singleChoice':
      switch(props.item?.question?.blocking) {
        case 'true':
          return (<>{props.item?.question?.answers?.map((item2: any, key: any) => (<p key={key} className={`candidate-answers__answer
            ${(props.item.answer === key && (item2.answerData === "keep")) ? "candidate-answers__answer--good" : ""}
            ${(props.item.answer === key && (item2.answerData === "block")) ? "candidate-answers__answer--wrong" : ""}`}>
            {item2.answer}
          </p>))}</>);
        case 'false':
          return (<>{props.item?.question?.answers?.map((item2: any, key: any) => (<p key={key} className={`candidate-answers__answer
            ${(props.item.answer === key && (item2.answerData !== "0")) ? "candidate-answers__answer--good" : ""}
            ${(props.item.answer === key && (item2.answerData === "0")) ? "candidate-answers__answer--grey" : ""}`}>
            {item2.answer} (importance: {item2.answerData === "0" ? "nulle" : ""} {item2.answerData === "2" ? "faible" : ""} {item2.answerData === "5" ? "forte" : ""})
          </p>))}</>);
        default:
          return (<>Err</>);
      }
    case 'multipleChoice':
      switch(props.item?.question?.blocking) {
        case 'true':
          return (<>{props.item?.question?.answers?.map((item2: any, key: any) => {
            return <p className={`candidate-answers__answer
            ${(props.item?.answer.includes(key) && (item2.answerData === "keep")) ? "candidate-answers__answer--good" : ""}
            ${(props.item?.answer.includes(key) && (item2.answerData === "block")) ? "candidate-answers__answer--wrong" : ""}
            `}>
              {item2.answer}
            </p>
          })}</>);
        case 'false':
          return (<>{props.item?.question?.answers?.map((item2: any, key: any) => {
            return <p className={`candidate-answers__answer
            ${(props.item?.answer.includes(key) && (item2.answerData !== "0")) ? "candidate-answers__answer--good" : ""}
            ${(props.item?.answer.includes(key) && (item2.answerData === "0")) ? "candidate-answers__answer--grey" : ""}
            `}>
              {item2.answer}
            </p>
          })}</>);
        default:
          return (<>Err</>);
      }
  }
  return (<>
  </>);
}

function CandidateSkill(props: any) {
  const isSkillNeeded: boolean = props.item?.needed;
  const isSkillSelected: boolean = props.item?.selected;
  const shouldSkillBeDisplayed: boolean = isSkillSelected || isSkillNeeded;
  const isSkillGreen: boolean = isSkillNeeded && isSkillSelected;
  const isSkillRed: boolean = isSkillNeeded && !isSkillSelected;
  if(shouldSkillBeDisplayed) {
    return (<p
      className={`candidate-answers__answer ${isSkillGreen ? "candidate-answers__answer--good" : ""} ${isSkillRed ? "candidate-answers__answer--wrong" : ""}`}>
      * {isSkillRed ? "Le candidat n'a pas séléctionné cette compétence: " : ""} "{props.item.skill}"
    </p>);
  } else {
    return (<></>);
  }
}

function getProgressionStyle(mark: number) {
  
  if(!mark) return "";
  
  if(mark === 100) return "results-V2__item__bar--selected--100";
  if(mark >= 90) return "results-V2__item__bar--selected--90";
  if(mark >= 80) return "results-V2__item__bar--selected--80";
  if(mark >= 70) return "results-V2__item__bar--selected--70";
  if(mark >= 60) return "results-V2__item__bar--selected--60";
  if(mark >= 50) return "results-V2__item__bar--selected--50";
  if(mark >= 40) return "results-V2__item__bar--selected--40";
  if(mark >= 30) return "results-V2__item__bar--selected--30";
  if(mark >= 20) return "results-V2__item__bar--selected--20";
  if(mark >= 10) return "results-V2__item__bar--selected--10";
  
  return "";
}