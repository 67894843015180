import React, {useEffect, useState} from "react";
import CW2DocumentDisplayer
  from "../../../components/Window/componentsWindow/componentsCWV2/subWindows/CW2DocumentDisplayer";
import Spaces from "../../../components/Spaces";

export default function(props: any) {
  
  const isCvUrlsDefined = !!props.candidateToEvaluate?.candidate?.cvUrls;
  const isCvUrlDefined = !!props.candidateToEvaluate?.candidate?.cvUrl;
  const isCvUrlsNotEmpty = props.candidateToEvaluate?.candidate?.cvUrls?.length !== 0;
  
  const cvUrl = props.candidateToEvaluate?.candidate?.cvUrls[(props.candidateToEvaluate?.candidate?.cvUrls?.length) - 1] ??
    props.candidateToEvaluate?.candidate?.cvUrl ?? "";
  
  const displayCvComponent = isCvUrlDefined || (isCvUrlsDefined && isCvUrlsNotEmpty);
  
  return (<>
    <section className={"candidate-evaluate__container"}>
      <div>
        
        <div className={"white-container white-container--grey space-margin--small"}>
          
          <div className={"profile-edit__title text--center"}>CV</div>
          
          <Spaces n={0}/>
          
          <CW2DocumentDisplayer fileUrl={cvUrl}/>
          
          <Spaces n={0}/>
        
        </div>
      </div>
    </section>
  </>);
  
}