import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import SubPageLayout from "../../sharedComponents/layouts/SubPageLayout";
import Spaces from "../../sharedComponents/components/Spaces";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    <div className={"container white-container"}>
      <h1 className={"profile-edit__title"}>Abonnement actuel</h1>
      <Spaces n={0}/>
      <div className={"text--grey text--center"}>
        Contactez l'équipe pour changer d'abonnement
      </div>
      <Spaces n={0}/>
      <div className={"space-flex--center"}>
        <div className={`business-parameter-plan ${appContextV2.appSession.sessionBusiness.plan !== 3 ? "business-parameter-plan--disabled" : ""}`}>Essai</div>
        <div className={`business-parameter-plan ${(appContextV2.appSession.sessionBusiness.plan !== 2 && appContextV2.appSession.sessionBusiness.plan !== 1) ? "business-parameter-plan--disabled" : ""}`}>Entreprise</div>
        {
          appContextV2.appSession.sessionBusiness.plan === 4 && <div className={`business-parameter-plan ${appContextV2.appSession.sessionBusiness.plan !== 4 ? "business-parameter-plan--disabled" : ""}`}>Pilote</div>
        }
        
      </div>
    </div>
    
    <Spaces n={0}/>
    
    <div className={"container white-container"}>
      <h1 className={"profile-edit__title"}>Crédit SMS</h1>
      <Spaces n={0}/>
      <div className={"text--grey text--center"}>
        Contactez l'équipe pour charger votre crédit SMS
      </div>
      <Spaces n={0}/>
      <div className={"space-flex--center"}>
        <div className={"business-parameter-plan"}>
          {(Math.floor((appContextV2.appSession.sessionBusiness.creditForSms || 0) * 100 ))/ 100} euros
        </div>
      </div>
    </div>
  
  </SubPageLayout>);
}
