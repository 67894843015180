import moveFromIndexToIndex from "../../../../../utils/moveFromIndexToIndex";

interface IState {
  businessProfile: any,
  missionProfile: any,
  shortlist: any,
  shortlistUnsubscribed: any,
}

export default (previousState: IState, action: { type: string, value: any }): any => {
  
  switch(action.type) {
    
    case 'INITIALIZE_DATA':
      return ({
        ...previousState,
        ...action.value
      });
    
    case 'ADD_COLUMN':
      return ({
        ...previousState,
        missionProfile: {
          ...previousState.missionProfile,
          customKanbanView: [...previousState.missionProfile?.customKanbanView, {
            ...action.value
          }]
        }
      });
    
    case 'SET_COLUMN_ID':
      return ({
        ...previousState,
        missionProfile: {
          ...previousState.missionProfile,
          customKanbanView: [
            ...previousState.missionProfile?.customKanbanView, {
              ...action.value
            }
          ]
        }
      });
    
    case 'DELETE_COLUMN':
      return ({
        ...previousState,
        missionProfile: {
          ...previousState.missionProfile,
          customKanbanView: previousState.missionProfile?.customKanbanView?.filter((column: any) => column._id !== action.value)
        }
      });
    
    case 'UPDATE_COLUMN':
      return ({
        ...previousState,
        missionProfile: {
          ...previousState.missionProfile,
          customKanbanView: previousState.missionProfile?.customKanbanView?.map((column: any) => {
            if(column._id !== action.value.columnId) return column;
            return {
              ...column,
              ...action.value.keyValuePairs,
            }
          })
        }
      });
      
    case 'MOVE_COLUMN':
      const index: number = [...previousState.missionProfile?.customKanbanView].map((item: any) => (item._id)).indexOf(action.value.columnId);
      return({
        ...previousState,
        missionProfile: {
          ...previousState.missionProfile,
          customKanbanView: moveFromIndexToIndex([...previousState.missionProfile.customKanbanView], index, index + action.value.direction)
        }
      });
  
    case 'SET_NEW_COLUMNS':
      return({
        ...previousState,
        missionProfile: {
          customKanbanView: action.value.newColumns
        }
      });
      
    case 'MOVE_CANDIDACY':
      return ({
        ...previousState,
        shortlist: previousState.shortlist.map((candidacy: any) => ({
          ...candidacy,
          kanbanColumn: candidacy._id === action.value?.candidacyId ? action.value.toColumnId : candidacy.kanbanColumn,
          kanbanColumnHistory: candidacy._id === action.value?.candidacyId ? [...candidacy.kanbanColumnHistory, action.value.toColumnId] : [...candidacy.kanbanColumnHistory]
        }))
      });
      
    case 'ADD_CANDIDACY':
      return ({
        ...previousState,
        shortlist: [...previousState.shortlist, action.value]
      });
      
    case 'UPDATE_CANDIDACIES_BULK':
      return({
        ...previousState,
        shortlist: previousState.shortlist.map((candidacy: any) => ({
          ...candidacy,
          ...action.value.keyValuePairs
        }))
      });
    
    case 'UPDATE_CANDIDACY':
      return ({
        ...previousState,
        shortlist: previousState.shortlist.map((candidacy: any) => {
          if(candidacy.candidacyId !== action.value.candidacyId) return candidacy;
          return ({
            ...candidacy,
            ...action.value.keyValuesPairs
          });
        })
      });
      
    default:
      return previousState;
  }
  
}