export default (setMissions: Function) => (missionId: string, value: any) => {
  
  console.log(missionId, value)
  
  return setMissions((ps: any) =>
    ps.map((item: any) => {
      if(item.missionId === missionId) {
        return ({
          ...item,
          ...value,
        });
      }
      return (item);
    }));
  
}