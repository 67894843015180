import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import {ButtonThreeDots} from "../../../../sharedComponents/components/Buttons/ButtonThreeDots";

export {AddImageToHeaderElements};

const AddImageToHeaderElements = (props: {
  positionKey: string,
  updateProfileFront: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<div className={"profile-editor-V2__header__element-edit"}>
      <ButtonThreeDots cb={()=>{
        appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
            type: "addMediaToBusinessPage",
            data: {
              positionKey: props.positionKey,
              updateProfileFront: props.updateProfileFront,
            }
          }});
      }} title={"Ajoute une image"}/>
  </div>
  );
}