import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import WindowLoginMail from "./componentsWL/WindowLoginMail";
import WindowLoginNoUser from "./componentsWL/WindowLoginNoUser";
import WindowLoginSuccess from "./componentsWL/WindowLoginSuccess";
import AssistantLayout from "../../../layouts/AssistantLayout";
import {useForm} from "use-formidable";
import {useWizard} from "use-wizard";
import WindowError from "./WindowError";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  /** Handles email if one was set when user tried to login with unexisting mail */
  const initialEmail = appContextV2.appPanels.window.windowOptions.data.email ? appContextV2.appPanels.window.windowOptions.data.email : "";
  
  /** Sets wizard */
  const [step, wizard] = useWizard(["enterEmail", "success", "noUser", "error"]);
  
  /** Sets form */
  const [form, updateForm, formHandler] = useForm({
    email: initialEmail,
  });
  
  const windowContext = {
    email: form.email,
    updateForm: updateForm,
    formHandler: formHandler,
    wizard: wizard,
  }
  
  const windowTitle: string = ((language: string) => {
    return "";
    switch(language) {
      case 'FR':
        return "Connexion";
      case 'EN':
      default:
        return "Login";
    }
  })(language);
  
  switch(step) {
    
    case "enterEmail":
      return (<WindowWrapper windowTitle={windowTitle} status={"ok"}>
        <WindowLoginMail windowContext={windowContext}/>
      </WindowWrapper>);
      
    case "success":
      return(<WindowWrapper windowTitle={windowTitle} status={"ok"}>
        <WindowLoginSuccess windowContext={windowContext}/>
      </WindowWrapper>);
    
    case "noUser":
      if(appContextV2.appSession.app.allowSignup) {
        return(<WindowWrapper windowTitle={windowTitle} status={"ok"}>
          <WindowLoginNoUser windowContext={windowContext}/>
        </WindowWrapper>);
      } else {
        return(<WindowWrapper windowTitle={windowTitle} status={"ok"}>
          <AssistantLayout title={""} comment={""} animated={true}>
            <span>Il n'est pas possible de créer de compte pour le moment. Contactez Dan à l'adresse <span className={"text--bold"}>dan@black-belt.io</span> si vous souhaitez tester la plateforme.</span>
          </AssistantLayout>
        </WindowWrapper>);
      }
      
    default:
    case "error":
      return(<WindowError />);
  }
  
}
