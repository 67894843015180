import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Comment est défini le salaire ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "salaryBy", value: "hourly"}});
                props.wizard.nextStep();
              }}>
        Horaire
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "salaryBy", value: "monthly"}});
                props.wizard.nextStep();
              }}>
        Mensuel
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "salaryBy", value: "annually"}});
                props.wizard.nextStep();
              }}>
        Annuel
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "salaryBy", value: "budget"}});
                props.wizard.nextStep();
              }}>
        Budget
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.wizard.goToStep('advantages');
              }}>
        Ne pas renseigner
      </button>
    
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center text--grey assistant--v2-container--no-flex"}>
    </div>
  
  </section>);
}