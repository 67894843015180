import React, {useContext} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import axios from "axios";
import {notification} from "../../../../../utils/notification";
import Spaces from "../../Spaces";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [step, wizard] = useWizard(["entry", "warningDeletion", "pending"]);
  
  const [ambassador, updateForm, formidable] = useForm({...appContextV2.appPanels.window.windowOptions.data?.item});
  
  const deleteAmbassador = async() => {
    try {

      wizard.goToStep('pending');

      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/amb/m/delete`, {
        ambassadorId: ambassador._id,
      }, {withCredentials: true});

      if(fetched.data.status !== "ok") {
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }

      // Update front
      appContextV2.appPanels.window.windowOptions.data?.removeAmbassadorFront(ambassador._id);

      notification(appContextV2.dispatchPanels, "Suppression réussie", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});

    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const updateAmbassador = async() => {
    try {

      wizard.goToStep('pending');

      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/amb/m/update`, {
        ambassadorId: ambassador._id,
        fullName: ambassador.fullName,
        email: ambassador.email,
        phone: ambassador.phone,
      }, {withCredentials: true});

      if(fetched.data.status !== "ok") {
        wizard.goToStep('entry');
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      // Update front
      appContextV2.appPanels.window.windowOptions.data?.updateAmbassadorFront({
        ambassadorId: ambassador._id,
        fullName: ambassador.fullName,
        email: ambassador.email,
        phone: ambassador.phone,
      });

      notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");
      wizard.goToStep('entry');
  
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  if(step === "warningDeletion") {
    return (<WindowWrapper windowTitle={""} status={"ok"}>
      <div className={"text--center"}>
        Es-tu sûr(e) de vouloir supprimer cet ambassadeur ({ambassador.fullName} ?
      </div>
      <Spaces n={1}/>
      <div className={"text--center"}>
        <button className={"btn-main"} onClick={() => {
          wizard.goToStep("entry");
        }}>
          Annuler
        </button>
        <button className={"btn-main"} onClick={() => deleteAmbassador()}>
          Supprimer
        </button>
      </div>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    
    <div className={"text--center"}>
      Nom entier: <br/>
      <input type={"text"} name={"fullName"} value={ambassador.fullName} onChange={updateForm({maximumLength: 40})}/>
    </div>
  
    <div className={"text--center"}>
      Email: <br/>
      <input type={"text"} name={"email"} value={ambassador.email} onChange={updateForm({maximumLength: 100})}/>
    </div>
  
    <div className={"text--center"}>
      Téléphone: <br/>
      <input type={"text"} name={"phone"} value={ambassador.phone} onChange={updateForm({maximumLength: 20})}/>
    </div>
  
    <Spaces n={1}/>
  
    <div className={"text--center"}>
      <button className={"btn-main"} onClick={() => {
        wizard.goToStep("warningDeletion");
      }} title={"Supprimer cet ambassadeur"}>
        Supprimer
      </button>
      <button className={"btn-main"} title={"Enregistre les modifications"} onClick={() => updateAmbassador()}>
        Enregistrer
      </button>
    </div>
  </WindowWrapper>);
}