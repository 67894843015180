import React, {useContext, useEffect, useState} from "react";
import FormEditorNav from "./componentsMissionFormEditorV2/FormEditorNav";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import {notification} from "../../../utils/notification";
import LoadingStyle2 from "../../sharedComponents/components/Loading/LoadingStyle2";
import axios from "axios";
import Spaces from "../../sharedComponents/components/Spaces";
import EditorAddQuestion from "./componentsMissionFormEditorV2/EditorAddQuestion";
import EditorQuestion from "./componentsMissionFormEditorV2/EditorQuestion";
import FormEditorNavUpdate from "./componentsMissionFormEditorV2/FormEditorNavUpdate";
import {FormEditorOptions} from "./componentsMissionFormEditorV2/FormEditorOptions";
import dragQuestion from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dragQuestion";
import dropQuestion from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dropQuestion";
import dropQuestionOnEmptyFront
    from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dropQuestionOnEmptyFront";
import functionDeleteQuestionFront
    from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionDeleteQuestionFront";
import functionToggleExtendedFront
    from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionToggleExtendedFront";
import functionUpdateQuestionFront
    from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionUpdateQuestionFront";
import EmptyItemForm from "./componentsMissionFormEditorV2/EmptyItemForm";
import EditorSumUpV2 from "./componentsMissionFormEditorV2/EditorSumUpV2";
import functionCloseAllFront from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionCloseAllFront";
import {useForm} from "use-formidable";
import {devLog} from "../../../utils/devLog/devLog";
import {withRouter} from "react-router-dom";
import CloseButton from "../../sharedComponents/components/Buttons/CloseButton";
import {format} from "date-fns";

function MissionFormEditorV2(props: {
    editorMode: 'creation' | 'edition',
    isPageLoading: boolean,
    presets: any,
    form: any,
    setForm: Function,
    timeStamps: any,
    wizard: any,
    setMissionTokens: any,
    saveCallback: Function,
    setPresets: Function,
}) {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const [displayHelper, setDisplayHelper] = useState(true);

    /** Informations about dragged questions */
    const [dragged, setDragged] = useState({
        draggedFromFormId: "",
        draggedFromIndex: 0,
    });


    /** Jobdesc */
    const [jobDesc, setJobDesc, formidableJobDesc] = useForm({file: null});
    const [fetchingFile, setFetchingFile] = useState(false);

    const [isLocallyLoading, setIsLocallyLoading] = useState(false);

    const areButtonsActive: boolean = !props.isPageLoading && !fetchingFile && !isLocallyLoading;

    /** Handles file dropping */
    useEffect(() => {
        (() => {
            if (!!jobDesc.file) {
                if (jobDesc.file.length > 1) {
                    setJobDesc()({target: {type: "custom", name: "file", value: null}});
                    return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");

                }
                if (!formidableJobDesc.isFileMime(jobDesc.file[0], ["pdf", "docx"])) {
                    setJobDesc()({target: {type: "custom", name: "file", value: null}});
                    return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
                }
            }
        })();
    }, [jobDesc.file]);

    /** Drag and drop questions */
    const drag = (draggedFromFormId: string, draggedFromIndex: number) => dragQuestion(setDragged, draggedFromFormId, draggedFromIndex,);
    const drop = (draggedToFormId: string, draggedToIndex: number) => dropQuestion({
        dragged,
        draggedToFormId,
        draggedToIndex,
        props,
    });
    const dropOnEmptyFront = (draggedToFormId: string) => dropQuestionOnEmptyFront({dragged, draggedToFormId, props,});

    /** Update front */
    const deleteQuestionFront = functionDeleteQuestionFront(appContextV2, props);
    const toggleExtendedFront = functionToggleExtendedFront(props);
    const updateQuestionFront = functionUpdateQuestionFront(props);
    const closeAllFront = functionCloseAllFront(props);

    const addQuestion = (formId: string, question: any) => {
        if (formId === 'form') {
            const newForm1 = [...props.form.form, question];
            props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
            notification(appContextV2.dispatchPanels, "Succès de la sauvegarde", "success");
        }
        if (formId === 'form2') {
            const newForm2 = [...props.form.form2, question];
            props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
            notification(appContextV2.dispatchPanels, "Succès de la sauvegarde", "success");

            if (!formidableJobDesc.isFileSmallerThan(jobDesc.file[0], 9_000_000)) {
                setJobDesc()({target: {type: "custom", name: "file", value: null}});
                return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 8mo)", "error");
            }
            return notification(appContextV2.dispatchPanels, "Fichier accepté", "success");
        }
    }

    /** Function to save the jobDesc, always call it before saving document */
    const saveJobDesc = async () => {
        try {
            setFetchingFile(true);
            const dataToSend: any = new FormData();
            dataToSend.append("jobdesc", jobDesc.file[0], jobDesc.file[0].name);
            const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/jobdesc`, dataToSend, {
                withCredentials: true, headers: {
                    'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
                    // >>>>>>> newMain
                }
            });
            if (fetched.data.status !== "ok") {
                setFetchingFile(false);
                notification(appContextV2.dispatchPanels, "Le fichier n'a pas été ajouté", "warning");
                return "";
            }
            setFetchingFile(false);
            props.setForm()({target: {type: 'custom', name: 'documentUrl', value: fetched.data.data}});
            console.log("FETCHED FILE ---", fetched.data.data);
            return (fetched.data.data);
        } catch (error) {
            setFetchingFile(false);
            notification(appContextV2.dispatchPanels, "Le fichier n'a pas été ajouté", "warning");
            return "";
        }
    }

    const openPreset = (formId: string) => {
        try {
            appContextV2.dispatchPanels({
                type: 'OPEN_WINDOW', value: {
                    type: 'openPresets',
                    data: {
                        addQuestion: addQuestion,
                        formId: formId,
                        positionWanted: props.form.positionWanted,
                    }
                }
            })
        } catch (error) {
            throw (error)

        }
    }

    const duplicate = async () => {
        try {
            const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/ent/m/duplicate`, {
                missionId: appContextV2.appNavigation.missionId,
            }, {
                withCredentials: true,
            });
            if (fetched.data.status !== "ok") {
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
                return;
            }
            //@ts-ignore
            props.history.push('/');
            notification(appContextV2.dispatchPanels, "Succès de la duplication", "success");
        } catch (error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
        }
    }

    return (<main>

        {/*--------------------------- information */}

        {/*--------------------------- actions */}
        {props.editorMode === 'creation' && <FormEditorNav reduce={closeAllFront}
                                                           currentStatus={""}
                                                           jobDesc={jobDesc}
                                                           saveJobDesc={saveJobDesc}
                                                           areButtonsActive={areButtonsActive}
                                                           publish={props.saveCallback}/>}
        {props.editorMode === 'edition' && <FormEditorNavUpdate reduce={closeAllFront}
                                                                currentStatus={""}
                                                                form={props.form}
                                                                jobDesc={jobDesc}
                                                                saveJobDesc={saveJobDesc}
                                                                areButtonsActive={areButtonsActive}
                                                                duplicate={duplicate}
                                                                publish={props.saveCallback}/>}

        <div>{props.isPageLoading && <LoadingStyle2/>}</div>

        <Spaces n={1}/>


        {/*--------------------------- Default Questions */}
        <section className={"editor-v2__section"}>

            <article className={"editor-v2__section__title"}>Questions préliminaires</article>
            <div className={"text--center text--grey "}>Choisissez des questions les questions types que vous
                souhaitez
                poser au candidat.
                <br/><br/>
                Elles vous permettrons d'effectuer plus facilement des recherches.
            </div>

            <Spaces n={1}/>

            {/*<div>*/}
            {/*  <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}*/}
            {/*         name={"defaultQuestions.isWorking"}*/}
            {/*         onChange={props.setForm()}*/}
            {/*         checked={props.form.defaultQuestions?.isWorking}/>*/}
            {/*  Demander au candidat s'il est en poste actuellement ?*/}
            {/*</div>*/}
            {/*<Spaces n={0}/>*/}

            <div>
                <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
                       name={"defaultQuestions.isAvailable"}
                       onChange={props.setForm()}
                       checked={props.form.defaultQuestions?.isAvailable}/>
                Demander au candidat s'il est disponible actuellement. Si non, lui demander à quelle date.
            </div>
            <div>
                {!props.form.defaultQuestions?.isAvailabilityBlocking &&
                <button className={"editor-v2__default-questions__btn"}
                        onClick={() => {
                            props.setForm()({
                                target: {
                                    type: "custom",
                                    name: "defaultQuestions.isAvailabilityBlocking",
                                    value: true
                                }
                            });
                        }}>
                    Critère bloquant
                </button>}
                {props.form.defaultQuestions?.isAvailabilityBlocking && <div className={"editor-v2__default-questions__div"}>
                    <button className={"editor-v2__default-questions__btn"}
                            onClick={() => {
                                props.setForm()({
                                    target: {
                                        type: "custom",
                                        name: "defaultQuestions.isAvailabilityBlocking",
                                        value: false
                                    }
                                });
                            }}>
                        Annuler critère bloquant
                    </button>
                    <div className={"text--grey"}> Bloquer le candidat s'il n'est pas disponible avant le :</div>
                    <input type={"Date"} className={"editor-v2__default-questions__input"}
                           name={"defaultQuestions.blockingAvailabilityData"}
                           onChange={props.setForm()}
                           value={props.form.defaultQuestions?.blockingAvailabilityData ? format(new Date(props.form.defaultQuestions?.blockingAvailabilityData), "yyyy-MM-dd") : ''}
                    />
                    </div>}
            </div>

            <Spaces n={0}/>

            <div>
                <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
                       name={"defaultQuestions.currentSalary"}
                       onChange={props.setForm()}
                       checked={props.form.defaultQuestions?.currentSalary}/>
                Demander au candidat quel est son niveau de rémunération brut annuelle actuelle.
            </div>

            <Spaces n={0}/>

            <div>
                <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
                       name={"defaultQuestions.salaryWanted"}
                       onChange={props.setForm()}
                       checked={props.form.defaultQuestions?.salaryWanted}/>
                Demander au candidat quelles sont ses prétentions salariales brutes annuelles.
            </div>
            <div>
                {!props.form.defaultQuestions?.isSalaryBlocking &&
                <button className={"editor-v2__default-questions__btn"}
                        onClick={() => {
                            props.setForm()({
                                target: {
                                    type: "custom",
                                    name: "defaultQuestions.isSalaryBlocking",
                                    value: true
                                }
                            });
                        }}>
                    Critère bloquant
                </button>}
                {props.form.defaultQuestions?.isSalaryBlocking && <div className={"editor-v2__default-questions__div"}>
                    <button className={"editor-v2__default-questions__btn"}
                            name={"defaultQuestions.isSalaryBlocking"}
                            onClick={() => {
                                props.setForm()({
                                    target: {
                                        type: "custom",
                                        name: "defaultQuestions.isSalaryBlocking",
                                        value: false
                                    }
                                });
                            }}>
                        Annuler critère bloquant
                    </button>
                    <div className={"text--grey"}>Bloquer le candidat si le salaire demandé est supérieur à :</div>
                    <input type={"number"} className={"editor-v2__default-questions__input"}
                           name={"defaultQuestions.blockingSalaryData"}
                           onChange={props.setForm()}
                           value={props.form.defaultQuestions?.blockingSalaryData}
                    /></div>}
            </div>


            <Spaces n={0}/>

            <div><input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
                        name={"defaultQuestions.yearsOfExperience"}
                        onChange={props.setForm()}
                        checked={props.form.defaultQuestions?.yearsOfExperience}/>
                Demander au candidat quelle est son expérience à ce poste.
            </div>
            <div>
                {!props.form.defaultQuestions?.isExperienceBlocking &&
                <button className={"editor-v2__default-questions__btn"}
                        onClick={() => {
                            props.setForm()({
                                target: {
                                    type: "custom",
                                    name: "defaultQuestions.isExperienceBlocking",
                                    value: true
                                }
                            });
                        }}>
                    Critère bloquant
                </button>}
                {props.form.defaultQuestions?.isExperienceBlocking && <div className={"editor-v2__default-questions__div"}>
                    <button className={"editor-v2__default-questions__btn"}
                            onClick={() => {
                                props.setForm()({
                                    target: {
                                        type: "custom",
                                        name: "defaultQuestions.isExperienceBlocking",
                                        value: false
                                    }
                                });
                            }}>
                        Annuler critère bloquant
                    </button>
                    <div className={"text--grey"}> Bloquer le candidat si le nombre d'années expérience est inférieure à :</div>
                    <input type={"number"} className={"editor-v2__default-questions__input"}
                           name={"defaultQuestions.blockingExperienceData"}
                           onChange={props.setForm()}
                           value={props.form.defaultQuestions?.blockingExperienceData}
                    /></div>}
            </div>


            <Spaces n={0}/>

            <div>
                <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
                       name={"defaultQuestions.training"}
                       onChange={props.setForm()}
                       checked={props.form.defaultQuestions?.training}/>
                Demander au candidat quel est son niveau de formation.
            </div>

            <Spaces n={0}/>

        </section>

        <Spaces n={0}/>

        {/*--------------------------- form 1 */}

        <section className={"editor-v2__section"}>

            <article className={"editor-v2__section__title"}>Questions RH & Techniques</article>

            {props.editorMode === "creation" && displayHelper && <div className={"editor-v2__question-guide"}>
                <div className={"editor-v2__question-guide--button"}><CloseButton eventOnClick={() => {
                    setDisplayHelper(false);
                }}/></div>

                <h1 className={"editor-v2__section__question__titles"}>Ajoutez des questions pour trier les candidatures
                    plus
                    rapidement !</h1>
                <p>
                    Pour gagner du temps, ajoutez toutes les questions que vous avez l'habitude de poser en entretien.
                    Pour chaque question à choix unique ou à choix multiple, vous pouvez ajouter des choix parmis
                    lesquels le
                    candidat pourra faire son choix.
                    Vous pouvez attirbuer un coefficient d'importance à chaque réponse que vous proposez au candidat.
                    Vous pouvez
                    également définir une question comme 'rédibitoire' (vous recevrez quand même la candidature).
                    L'algorithme vous poposera alors les meilleurs candidats pour le poste !

                </p>
            </div>}

            {props.form.form?.length === 0 &&
            <div className={"editor-v2__question__warning--empty"}>Attention, votre questionnaire est vide ! Les
                candidats
                pourront postuler mais vous perdez l'avantage du tri automatique.</div>}


            {props.form?.form?.map((item: any, key: number) => (<EditorQuestion {...props}
                                                                                item={item}
                                                                                index={key}
                                                                                key={key}
                                                                                id={"form"}
                                                                                drag={drag}
                                                                                drop={drop}
                                                                                toggleExtended={toggleExtendedFront}
                                                                                updateQuestion={updateQuestionFront}
                                                                                deleteQuestion={deleteQuestionFront}
                                                                                positionWanted={props.form.positionWanted}/>))}

            <div>
                {/*{props.form.form?.length === 0 && <EmptyItemForm id={"form"} dropOnEmpty={dropOnEmptyFront}/>}*/}

                <Spaces n={2}/>

                {props.form?.form?.length < 20 &&
                <EditorAddQuestion {...props} addQuestion={addQuestion} dropOnEmpty={dropOnEmptyFront} id={"form"}
                                   openPreset={openPreset}/>}

            </div>

        </section>

        <Spaces n={0}/>

        {/*--------------------------- form 2 */}
        {/*<section className={"editor-v2__section"}>*/}
        {/*  */}
        {/*  <article className={"editor-v2__section__title"}>Questions techniques</article>*/}
        {/*  */}
        {/*  {props.form?.form2?.map((item: any, key: number) => (<EditorQuestion {...props}*/}
        {/*                                                                       item={item}*/}
        {/*                                                                       index={key}*/}
        {/*                                                                       key={key}*/}
        {/*                                                                       id={"form2"}*/}
        {/*                                                                       toggleExtended={toggleExtendedFront}*/}
        {/*                                                                       drag={drag}*/}
        {/*                                                                       drop={drop}*/}
        {/*                                                                       updateQuestion={updateQuestionFront}*/}
        {/*                                                                       deleteQuestion={deleteQuestionFront}*/}
        {/*                                                                       positionWanted={props.form.positionWanted}/>))}*/}
        {/*  */}
        {/*  {props.form.form2?.length === 0 && <EmptyItemForm id={"form2"} dropOnEmpty={dropOnEmptyFront}/>}*/}
        {/*  */}
        {/*  <Spaces n={2}/>*/}
        {/*  */}
        {/*  {props.form?.form2?.length < 20 &&*/}
        {/*  <EditorAddQuestion {...props} addQuestion={addQuestion} dropOnEmpty={dropOnEmptyFront} id={"form2"}*/}
        {/*                     openPreset={openPreset}/>}*/}

        {/*</section>*/}

        {/*<Spaces n={0}/>*/}

        {/*--------------------------- sumup / options */}
        <EditorSumUpV2 {...props} jobDesc={jobDesc} setJobDesc={setJobDesc}/>

        <Spaces n={0}/>

        {/*--------------------------- options */}
        <FormEditorOptions {...props} />

        <Spaces n={1}/>

        {/*--------------------------- actions */}
        {props.editorMode === 'creation' && <FormEditorNav reduce={closeAllFront}
                                                           currentStatus={""}
                                                           jobDesc={jobDesc}
                                                           saveJobDesc={saveJobDesc}
                                                           areButtonsActive={areButtonsActive}
                                                           publish={props.saveCallback}/>}
        {props.editorMode === 'edition' && <FormEditorNavUpdate reduce={closeAllFront}
                                                                currentStatus={""}
                                                                jobDesc={jobDesc}
                                                                form={props.form}
                                                                saveJobDesc={saveJobDesc}
                                                                duplicate={duplicate}
                                                                areButtonsActive={areButtonsActive}
                                                                publish={props.saveCallback}/>}

        <div>{props.isPageLoading && <LoadingStyle2/>}</div>

        <Spaces n={1}/>

    </main>);

}

// @ts-ignore
export default withRouter(MissionFormEditorV2);